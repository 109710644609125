import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Season} from '../models/resources/season';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SeasonApi implements LoggableAPI {
  public serviceName = 'Seasons';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public createSeason(leagueId: number, req: Season): Observable<Season> {
    return this.apiClient.postObj(Season, Endpoints.createLeagueSeasons(leagueId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSeason', err));
        return throwError(err);
      })
    );
  }

  getLeagueSeason(leagueId: number): Observable<Season[]> {
    return this.apiClient.recursiveGetArr(Season, Endpoints.getLeagueSeasons(leagueId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueSeason', err));
        return throwError(err);
      })
    );
  }

  getLeagueSeasonsById(leagueId: number, seasonId: number): Observable<Season[]> {
    return this.apiClient.recursiveGetArr(Season, Endpoints.getLeagueSeasonsById(leagueId, seasonId)).pipe(
      catchError(e => {
        const  err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueSeasonsById', err));
        return throwError(err);
      })
    );
  }

  updateLeagueSeasons(leagueId: number, seasonId: number, req: Season): Observable<Season> {
    return this.apiClient.putObj<Season>(Season, Endpoints.updateLeagueSeasons(leagueId, seasonId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateLeagueSeasons', err));
        return throwError(err);
      })
    );
  }

  createLeagueSeasons(leagueId: number, req: Season): Observable<Season> {
    return this.apiClient.postObj<Season>(Season, Endpoints.createLeagueSeasons(leagueId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueSeasons', err));
        return throwError(err);
      })
    );
  }

  deleteLeagueSeasons(leagueId: number, seasonId: number): Observable<Season> {
    return this.apiClient.deleteObj<Season>(Season, Endpoints.deleteLeagueSeasons(leagueId, seasonId), null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueSeasons', err));
        return throwError(err);
      })
    );
  }

}
