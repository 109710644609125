import {Deserializable} from '../protocols/deserializable';
import {FormListable} from '../protocols/form-listable';
import {Event} from './event';

export class EventId implements Deserializable, FormListable {
  id: string;
  eventId: number;
  event: string;
  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  constructor(id: number = null) {
    this.eventId = id;
  }
  static initWithEvent(event: Event): EventId {
    const l = new EventId();
    l.eventId = event.id;
    l.event = event.name;
    return l;
  }


  onDeserialize() {
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.event;
  }
}
