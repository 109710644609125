import {Component, EventEmitter} from '@angular/core';
import {AdminChangePasswordViewModel} from './admin-change-password-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ChangePasswordRequest} from '../../../../models/account/requests/change-password-request';
import {FormInputItem, FormInputType} from '../../../../models/shared/stylesheet/form-input-item';
import {PasswordValidatorDirective} from '../../../shared/components/form-group/validators/password-validator.directive';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-admin-change-password',
  templateUrl: './admin-change-password.component.html',
  styleUrls: ['./admin-change-password.component.scss'],
  providers: [AdminChangePasswordViewModel],
})
export class AdminChangePasswordComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ChangePasswordRequest = new ChangePasswordRequest();
  public updatedFormObject = new EventEmitter<void>();

  constructor(
    public viewModel: AdminChangePasswordViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  setupBindings() {

  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  formSubmitted(result: ChangePasswordRequest) {
    this.viewModel.changePassword(result);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const currentPassword = new FormInputItem();
    currentPassword.inputName = 'currentPassword';
    currentPassword.inputType = FormInputType.Password;
    currentPassword.label = $localize`Current Password`;
    currentPassword.placeholder = $localize`Current Password`;
    currentPassword.bindingProperty = 'currentPassword';
    currentPassword.required = true;
    items.push(currentPassword);

    const newPassword = new FormInputItem();
    newPassword.inputName = 'newPassword';
    newPassword.inputType = FormInputType.Password;
    newPassword.label = $localize`New Password`;
    newPassword.placeholder = $localize`New Password`;
    newPassword.bindingProperty = 'newPassword';
    newPassword.required = true;
    newPassword.customValidator = new PasswordValidatorDirective();
    items.push(newPassword);

    this.formItems = items;
  }

  setupFormStyling() {
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.primaryButtonContainerClass = 'd-flex justify-content-end';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Changes`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }
}
