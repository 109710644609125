import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  static MOBILE_WIDTH = 768;
  private isMobile = new BehaviorSubject<boolean>(false);

  constructor() { }

  setIsMobile(x: boolean) {
    this.isMobile.next(x);
  }

  public connectToIsMobile(): Observable<boolean> {
    return this.isMobile.asObservable().pipe(distinctUntilChanged());
  }
}
