import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {FormValidator} from '../../../../../models/protocols/form-validator';

export const alphanumericValidatorWithSpace = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    const expression = {
      pattern: '^[a-zA-Z0-9 _-]*$',
      message: 'Letters, numbers, and spaces only, no special characters.'
    };
    const regexp = new RegExp(expression.pattern);
    return !regexp.test(value) ? {alphanumeric: expression.message} : null;
  };
};

@Directive({
  selector: '[appAlphanumericWithSpaceDirective]',
  providers: [{provide: NG_VALIDATORS, useExisting: AlphanumericWithSpaceValidatorDirective, multi: true}]
})
export class AlphanumericWithSpaceValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } {
    return alphanumericValidatorWithSpace()(control);
  }

  errorName(): string {
    return 'alphanumeric';
  }
}
