import {Deserializable} from '../protocols/deserializable';
import {BaseModel} from '../base/base-model';

export class ProgramComment extends BaseModel implements Deserializable {
  public id: string;
  public comment: string = null;
  public programId: string;

  onDeserialize() {
    super.onDeserialize();
  }
}
