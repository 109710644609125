export {};
declare global {
  interface Array<T> {
    contains(v: T): boolean;

    unique(ignoreEmpty?: boolean): Array<T>;

    uniqueInstance(): T;

    filterNulls(): Array<T>;

    equals(arr: T[]): boolean;

    last(): T;

    toStringArray(): Array<string>;

    intersection(arr: T[]): T[];

    flatMap<V>(x: (y: T) => V): V;

    random(): T;
  }
}

Array.prototype.contains = function(v) {
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < this.length; i++) {
    if (this[i] === v) {
      return true;
    }
  }
  return false;
};

Array.prototype.unique = function(ignoreEmpty: boolean = true) {
  const arr = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < this.length; i++) {
    if (!arr.contains(this[i])) {
      if (ignoreEmpty) {
        if (this[i] && this[i] !== '') {
          arr.push(this[i]);
        }
      } else {
        arr.push(this[i]);
      }
    }
  }
  return arr;
};

Array.prototype.uniqueInstance = function() {
  const uniqueVals = this.unique(false);
  if (uniqueVals.length === 1) {
    return uniqueVals[0];
  } else {
    return null;
  }
};

Array.prototype.filterNulls = function() {
  return this.filter(v => v);
};

Array.prototype.equals = function(array) {
  if (!array) {
    return false;
  }
  if (this.length !== array.length) {
    return false;
  }
  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) {
        return false;
      }
    } else if (this[i] !== array[i]) {
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', {enumerable: false});

Array.prototype.last = function() {
  if (this.length === 0) {
    return null;
  } else {
    return this[this.length - 1];
  }
};

Array.prototype.toStringArray = function() {
  return this.map(v => v.toString());
};

Array.prototype.intersection = function <T>(compare: T[]): T[] {
  if (!compare) {
    return [];
  }
  const res = [];
  const {length: len1} = this;
  const {length: len2} = compare;
  const smaller = (len1 < len2 ? this : compare).slice();
  const bigger = (len1 >= len2 ? this : compare).slice();
  for (const item of smaller) {
    if (bigger.indexOf(item) !== -1) {
      res.push(item);
      bigger.splice(bigger.indexOf(item), 1, undefined);
    }
  }
  return res;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'intersection', {enumerable: false});

// [B](f: (A) ⇒ [B]): [B]  ; Although the types in the arrays aren't strict (:
Array.prototype.flatMap = function(lambda) {
  return Array.prototype.concat.apply([], this.map(lambda));
};

Array.prototype.random = function() {
  return this[Math.floor(Math.random() * this.length)];
};
