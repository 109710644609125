<app-form-group
  #newPasswordForm
  class="d-block pt-4"
  *ngIf="formItems?.length > 0"
  [formItems]="formItems"
  [formObject]="formObject"
  [styling]="formStyling"
  [options]="formOptions"
  (formSubmitted)="formSubmitted()"
  (formCancelled)="formCancelled()">
</app-form-group>
