import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BannerAdvertisementRowViewModel} from './banner-advertisement-row-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {BannerAdvertisement} from '../../../../models/resources/banner-advertisement';

@Component({
  selector: 'app-banner-advertisement-row',
  templateUrl: './banner-advertisement-row.component.html',
  styleUrls: ['./banner-advertisement-row.component.scss'],
  providers: [BannerAdvertisementRowViewModel],
})
export class BannerAdvertisementRowComponent extends BaseComponent implements OnChanges {

  @Input() bannerAdvertisements: BannerAdvertisement[];

  constructor(
    public viewModel: BannerAdvertisementRowViewModel,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.bannerAdvertisements) {
      this.viewModel.advertisementList$.next(this.bannerAdvertisements);
    }
  }
}
