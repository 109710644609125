import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Session} from '../dto/session';

export class RefreshSessionResponse implements Deserializable {
  public refreshToken: string;
  public session: Session;

  onDeserialize() {
    this.session = DeserializeHelper.deserializeToInstance(Session, this.session);
  }
}

