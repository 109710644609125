import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {DatatableOptions} from '../../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../../models/base/base-datatable-data-provider';
import {Router} from '@angular/router';
import {Plan} from '../../../../models/resources/plan';
import {DatatableColumn, DatatableColumnType} from '../../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../../models/shared/stylesheet/datatable-column-filter';
import {PlansTabViewModel} from './plans-tab-view-model';

@Component({
  selector: 'app-plans-tab',
  templateUrl: './plans-tab.component.html',
  styleUrls: ['./plans-tab.component.scss'],
  providers: [PlansTabViewModel],
})
export class PlansTabComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public planDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  public plans: Plan[] = [];

  constructor(
    public viewModel: PlansTabViewModel,
    public router: Router,
  ) {
    super();
    this.datatableFilter.enableResultsDisplayed = false;
  }

  setupBindings() {
    this.viewModel.getPlans(true).notNull().subscribe((p) => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = p;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);

    this.datatableOptions.ctaButtonText = $localize`Add New Plan`;
  }

  setupViews() {
    this.setupDatatable();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
    this.datatableOptions.ctaButtonClass = this.PermissionManagerService.isGranted(this.Types.AdminSections.Plans_Add_Plan) ?
      'preferred-button' : 'preferred-button disabled';
  }


  private getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const planName = new DatatableColumn(
      'plan',
      $localize`Plan Name`,
      DatatableColumnType.Text,
      (p: Plan): string => {
        return p.externalName;
      }
    );
    planName.isDefaultSortColumn = true;
    columns.push(planName);

    const category = new DatatableColumn(
      'category',
      $localize`Category`,
      DatatableColumnType.Text,
      (p: Plan): string => {
        return p.category === 1 ? $localize`League` : $localize`Event`;
      }
    );
    columns.push(category);
    category.columnFilter = this.getDefaultColumnFilter();
    category.columnFilter = this.getCategoryColumnFilter();

    const status = new DatatableColumn(
      'activePlan',
      $localize`Status`,
      DatatableColumnType.Text,
      (p: Plan): string => {
        return p.status === 1 ? $localize`Active` : $localize`Inactive`;
      }
    );
    columns.push(status);
    status.className = 'custom-datatable-header text-right mr-2';
    status.headerClassName = 'custom-datatable-header text-right mr-2';
    status.columnFilter = this.getStatusColumnFilter();

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  getCategoryColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`League`, $localize`League`),
      new DatatableColumnFilterListItem($localize`Event`, $localize`Event`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  getDefaultColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
}
