<div class="reorder-container"
     (cdkDropListDropped)="drop($event)"
     cdkDropList>
  <div class="reorder-item"
       *ngFor="let item of items"
       cdkDrag>
    <div class="reorder-value ellipsis-nowrap-text">
      {{item.getOrderableTitle()}}
    </div>
    <div class="reorder-icon">
      <img [src]="'assets/icons/dark/outline/menu.svg'" alt="">
    </div>
    <div class="reorder-placeholder" *cdkDragPlaceholder></div>
  </div>
</div>
