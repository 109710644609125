import {HttpErrorResponse} from '@angular/common/http';

export class CustomError {
  public code: number;
  public title: string;
  public message: string;
  public suggestion: string;
  public headers: any;
  public result: any;

  constructor(err?: HttpErrorResponse, serviceName?: string) {
    if (err) {
      this.result = err?.error;
      this.code = err?.status;
      this.headers = err?.headers;
      this.title = `${serviceName} Error`;
      if (typeof err?.error === 'string') {
        this.message = err?.error;
      } else if (typeof err?.error?.Message === 'string') {
        this.message = err?.error?.Message;
      } else if (Array.isArray(err?.error)) {
        const firstError = err?.error[0];
        this.message = firstError.errorMessage;
      } else {
        this.message = $localize`An error has occurred. Please try again.`;
      }
    }
  }
}
