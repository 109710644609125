import {CustomError} from './custom-error';

export class ApiErrorLog {
  public service: string;
  public method: string;
  public errorCode: number;
  public errorMessage: string;

  constructor(service, method: string, error: CustomError) {
    this.service = service;
    this.method = method;
    this.errorCode = error.code;
    this.errorMessage = error.message;
  }

  public logParams(): { [key: string]: any } {
    const params = new Map<string, any>();
    params.set('service', this.service);
    params.set('method', this.method);
    params.set('errorCode', this.errorCode);
    params.set('errorMessage', this.errorMessage);
    return params;
  }
}
