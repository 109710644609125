<div class="modal-body">
  <div class="modal-title">
    {{getModalTitle()}}
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (formSubmitted)="formSubmitted()">
  </app-form-group>
</div>
