<div class="modal-body">
  <div class="modal-title">
    {{getModalTitle()}}
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (secondaryButtonPressed)="deleteSeasonClicked()"
    (formSubmitted)="PermissionManagerService.isGranted(Types.AdminSections.Leagues_Edit_League_Seasons_Edit) ? formSubmitted() : null">
  </app-form-group>
  <div >
    <button
      [disabled]="!PermissionManagerService.isGranted(Types.AdminSections.Leagues_Edit_League_Seasons_Remove)"
      [ngClass]="{'preferred-button': true, 'disabled': !PermissionManagerService.isGranted(Types.AdminSections.Leagues_Edit_League_Seasons_Remove)}"
      class="custom-button destructive-button mr-auto" (click)="deleteSeasonClicked()"
      *ngIf="isEditing && canRemoveSeasonOnEdit"
      i18n>
      Delete Season
    </button>
  </div>
</div>
