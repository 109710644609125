import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject, throwError, timer} from 'rxjs';
import {Router} from '@angular/router';
import {SessionService} from '../session-service';
import {catchError, delayWhen, switchMap, take, tap} from 'rxjs/operators';
import {AccountAPI} from '../../api/account-api';
import {AccountDomainModel} from '../../domainModels/account-domain-model';
import {Session} from '../../models/account/dto/session';
import {IS_MULTI_PART_FORM_DATA} from '../../api/api-client';
import {AuthFlow} from '../../models/account/enum/auth-flow.enum';
import {OpenAuthModalOptions} from '../../models/account/open-auth-modal-options';

const API_CALL_DELAY_TIME_MS = 250;
const MAX_API_CALL_DELAY_TIME_MS = 10000; //10s

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

  private refreshObservable: ReplaySubject<Session>;
  // Track the count of all 5XX error responses to determine exponential backoff
  private serverErrorResponses = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private session: SessionService,
    private accountAPI: AccountAPI,
    private accountDomainModel: AccountDomainModel
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthenticationToken(request);
    // Get the number of 5XX server error and append a delay to the request to avoid overloading the server
    return this.serverErrorResponses.pipe(
      take(1),
      delayWhen(nFailed => timer(this.getFailedDelayRequestTime(nFailed))),
      switchMap(() => {
        return next.handle(request).pipe(
          tap(_ => this.serverErrorResponses.next(0)),
          catchError(err => {
            if (err?.status >= 500 && err?.status < 600) {
              // Increment the number of 5XX server errors, to increase the subsequent delays
              const nFails = this.serverErrorResponses.getValue();
              this.serverErrorResponses.next(nFails + 1);
            } else if (err?.status === 401) {
              const req = this.session.getRefreshSessionReq();
              if (req) {
                // Stall all incoming requests that wont have a valid token
                if (!this.refreshObservable) {
                  this.refreshObservable = new ReplaySubject<Session>(1);
                  this.refreshObservable.bind(this.accountDomainModel.isAuthenticated(true));
                }

                // Refresh Session
                return new Observable<HttpEvent<any>>(subscriber => {
                  this.refreshObservable.subscribe((sess) => {
                    if (!sess) {
                      const isAdmin = !!this.session.sessionContainer.getValue()?.userRole ?? false;
                      if (isAdmin) {
                        this.router.navigate(['/admin']).then();
                      } else {
                        const options = new OpenAuthModalOptions(AuthFlow.SignUp, this.router.url,
                          $localize`Sign in to continue`,
                          $localize`Sign up to continue`);
                        this.session.showAuthModal$.next(options);
                      }
                      return throwError('Authentication Failed');
                    }
                    this.refreshObservable = null;
                    next.handle(this.addAuthenticationToken(request, sess?.accessToken)).subscribe(response => {
                      subscriber.next(response);
                    }, e => {
                      subscriber.error(e);
                    });
                  }, error => {
                    this.refreshObservable = null;
                    this.session.destroySession.next(true);
                    subscriber.error(error);
                  });
                });
              } else {
                // Kill session and navigate to logout
                this.session.destroySession.next(true);
              }
            }
            return throwError(err);
          })
        );
      })
    );
  }

  private getFailedDelayRequestTime(nFailed): number {
    // Either return the delay calculated by nFailed, else return max delay time
    return Math.min((nFailed * API_CALL_DELAY_TIME_MS), MAX_API_CALL_DELAY_TIME_MS);
  }

  addAuthenticationToken(request: HttpRequest<any>, newToken?: string) {
    if (newToken) {
      return request.clone({
        headers: this.createHeaders(newToken, request.headers, request)
      });
    } else {
      const token = this.session.getAuthToken();
      return request.clone({
        headers: this.createHeaders(token, request.headers, request)
      });
    }
  }

  private createHeaders(token: string, headers: HttpHeaders, request: HttpRequest<any>): HttpHeaders {
    if (!headers.get('Content-Type') && request.context?.get(IS_MULTI_PART_FORM_DATA) !== true) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (!headers.get('Accept')) {
      headers = headers.append('Accept', 'application/json');
    }
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
}
