import {Injectable} from '@angular/core';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {catchError, map, shareReplay, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {Address} from '../../../../models/location/address';
import {VenueFormObject} from '../../../../models/resources/venue-form-object';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {TeamFormObject} from '../../../../models/resources/team-form-object';
import {VenueStream} from '../../../../models/resources/venue-stream';
import {Team} from '../../../../models/resources/team';
import {TeamId} from '../../../../models/resources/teamId';
import {LeagueFormObject} from '../../../../models/resources/league-form-object';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ImageSize} from '../../../../models/enum/dto/image-size.enum';
import {ImageApi} from '../../../../api/image-api';
import {PixellotVenues} from '../../../../models/resources/pixellot-venues';

@Injectable()
export class VenueDetailsViewModel extends BaseViewModel {

  public countryTypes$ = this.lookupDomainModel.countryTypes;

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true);
  programLoadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  venueId$ = this.activatedRoute.params.pipe(map(params => {
    if (!!params.venueId) {
      return Number(params.venueId);
    } else {
      return null;
    }
  }));
  addNewVenue$ = this.venueId$.pipe(map(venueId => !venueId));
  refreshVenueSubject$ = new BehaviorSubject<void>(null);
  venue$=combineLatest([this.venueId$,this.refreshVenueSubject$]).pipe(
    switchMap(([v, _])=>{
      return !!v ? this.domainModel.getHydratedVenue(v) : of(this.getDefaultHydratedVenue());
    }),
   shareReplay({bufferSize: 1, refCount: true})
  );

  subscriptionPlans$ = this.accountDomainModel.getSubscriptionPlans().pipe(
    indicateOnNext(this.loadingOpts, $localize`Loading Subscription Plans`),
    shareReplay({bufferSize: 1, refCount: true})
  );

  refreshVenuePrograms$ = new BehaviorSubject<void>(null);
  venuePrograms$: Observable<HydratedProgram[]> = this.refreshVenuePrograms$.pipe(switchMapTo(this.venueId$)).pipe(
    switchMap(leagueId => this.adminProgramDomainModel.getVenueHydratedPrograms(leagueId)),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Programs Failed`)),
    indicateOnNext(this.programLoadingOpts, $localize`Loading Programs`)
  );

  selectedFeedbackProgram: HydratedProgram;

  constructor(
    private domainModel: ResourceDomainModel,
    private router: Router,
    private accountDomainModel: AccountDomainModel,
    private imageApi: ImageApi,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private lookupDomainModel: LookupDomainModel,
    private adminProgramDomainModel: AdminProgramDomainModel,
  ) {
    super();
  }

  getDefaultHydratedVenue(): HydratedVenue {
    const v = new HydratedVenue();
    v.address = new Address();
    return v;
  }

  init() {
    super.init();
  }

  saveVenue(venueFormObject: VenueFormObject) {
    this.domainModel.saveVenue(venueFormObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue`))
      .subscribe(ven => {
        if (!!venueFormObject.venue.id) {
          this.toastService.publishSuccessMessage($localize`Venue Updated`, null);
          this.refreshVenuePrograms$.next();
          this.router.navigate(['/admin/venues'], {relativeTo: this.activatedRoute}).then();
        }else {
          this.toastService.publishSuccessMessage($localize`Venue Created`, null);
          this.router.navigate(['/admin/venues'], {relativeTo: this.activatedRoute}).then();
        }
      }, error => {
        if (!!venueFormObject.venue.id) {
          this.toastService.publishError(error, $localize`Save Venue Failed`);
        }
        else{
          this.toastService.publishError(error, $localize`Save Venue Failed`);
        }

      });
  }

  saveVenueTeam(team: TeamFormObject): Observable<Team> {
    return this.venue$
      .pipe(switchMap(v => this.domainModel.saveVenueTeam(team, v.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue Team`));
  }

  saveVenueTeamAssociation(team: Team,venueId: number): Observable<TeamId> {
    return this.domainModel.saveVenueTeamAssociation(team,venueId)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue Team`));
  }

  saveVenueStream(stream: VenueStream): Observable<VenueStream> {
    return this.venue$
    .pipe(switchMap(v => this.domainModel.saveVenueStream(stream, v.id)))
    .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue Stream`));
  }

  getPixellotVenues(): Observable<PixellotVenues[]> {
    return this.domainModel.getPixellotVenues();

  }

  getLogo(venueFormObject: VenueFormObject): Observable<string | SafeResourceUrl> {
    if (venueFormObject.imageToUpload) {
      return of(venueFormObject.imageToUpload);
    } else if (venueFormObject.existingImageId) {
      return this.imageApi.getHydratedVenueImage(venueFormObject.venue, ImageSize.Original, true);
    } else {
      return of(null);
    }
  }
}
