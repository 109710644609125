import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseDomainModel} from '../models/base/base-domain-model';
import '../utils/observable.extensions';

@Injectable({
  providedIn: 'root'
})
export class SearchDomainModel extends BaseDomainModel {

  showSearchSubject$ = new Subject<void>();

  constructor(
  ) {
    super();
    this.init();
  }

  public init() {
  }

  showSearchModal() {
    this.showSearchSubject$.next();
  }
}
