import {DatatableDataProvider} from '../protocols/datatable-data-provider';
import {DatatableData} from '../protocols/datatable-data';
import {DatatableColumn} from '../shared/stylesheet/datatable-column';
import {DatatableFilterOption} from '../shared/stylesheet/datatable-filter-option';
import {DatatableColumnFilterType} from '../shared/stylesheet/datatable-column-filter';

export class BaseDatatableDataProvider implements DatatableDataProvider {
  data: DatatableData[] = [];
  displayedData: DatatableData[] = [];
  filteredData: DatatableData[] = [];
  silentUpdateData = false;

  filterData(searchValue: string, columns: DatatableColumn[], applyCheckboxFilter: boolean, customFilters: DatatableFilterOption[]) {
    // Reset to full data set
    this.filteredData = this.data;

    // Perform filter on all visible properties
    this.filteredData = this.filteredData.filter((data) => {
      return columns.map((col) => {
        const cellValue = col.getCellValue(data);
        return cellValue?.toString().toLowerCase().indexOf(searchValue) !== -1;
      }).reduceRight((accu, curr) => {
        return accu || curr;
      });
    });

    // Apply Column Filters
    columns.filter(c => !!c.columnFilter).forEach(filterColumn => {
      const columnFilter = filterColumn.columnFilter;
      if (columnFilter.filterType === DatatableColumnFilterType.SelectionList) {
        if (columnFilter.selectedItem?.filterValue == null) {
          return;
        }
        this.filteredData = this.filteredData.filter((data) => {
          const cellValue = filterColumn.getCellValue(data);
          return (cellValue === columnFilter.selectedItem.filterValue);
        });
      } else if (columnFilter.filterType === DatatableColumnFilterType.DateRangeFilter) {
        if (columnFilter.appliedDateRange == null) {
          return;
        }

        this.filteredData = this.filteredData.filter((data) => {
          return columnFilter.dateRangeFilterMethod(data, columnFilter.appliedDateRange);
        });
      }
    });

    // Perform table filter if applicable
    if (applyCheckboxFilter) {
      this.tableCheckboxFilter();
    }
  }

  getNestedCellData(parent: DatatableData): DatatableData[] {
    return [];
  }

  getNestedObject(rowIndex, nestedIndex: number): DatatableData {
    return undefined;
  }

  resetData() {
    this.filteredData = this.data;
  }

  sortByColumn(col: DatatableColumn, ascending: boolean) {
    this.filteredData = this.filteredData.sort((one, two) => {
      if (col.customSortingMethod) {
        return col.customSortingMethod(one, two) * (ascending ? 1 : -1);
      } else if (col.getCellValue(one) === '' && col.getCellValue(two) === '') {
        return 0;
      } else if (col.getCellValue(one) === '') {
        return 1;
      } else if (col.getCellValue(two) === '') {
        return -1;
      } else {
        return (col.getCellValue(one)).localeCompare('' + col.getCellValue(two), 'en', {numeric: true}) * (ascending ? 1 : -1);
      }
    });
  }

  tableCheckboxFilter() {
  }

}
