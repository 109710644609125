import {Directive} from '@angular/core';
import {FormValidator} from '../../../../../models/protocols/form-validator';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';

export const passwordValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (!value || value === '') {
      return null;
    }
    // validate length
    if (value.length < 8) {
      return {password: $localize`Password must be at least 8 characters.`};
    }

    // Validate no white space
    const containsSpaces = {
      pattern: '^[\\S]+$',
      message: $localize`Password may not contain spaces.`
    };
    const spacesRegexp = new RegExp(containsSpaces.pattern);
    if (!spacesRegexp.test(value)) {
      return {password: containsSpaces.message};
    }

    // validate upper case
    const containsUppercase = {
      pattern: '(?=.*[A-Z])',
      message: $localize`Password must contain an upper case character.`
    };
    const containsUppercaseRegexp = new RegExp(containsUppercase.pattern);
    if (!containsUppercaseRegexp.test(value)) {
      return {password: containsUppercase.message};
    }

    // validate lower case
    const containsLowercase = {
      pattern: '(?=.*[a-z])',
      message: $localize`Password must contain a lower case character.`
    };
    const containsLowercaseRegexp = new RegExp(containsLowercase.pattern);
    if (!containsLowercaseRegexp.test(value)) {
      return {password: containsLowercase.message};
    }

    // validate number
    const containsNumber = {
      pattern: '(?=.*\\d)',
      message: $localize`Password must contain a number.`
    };
    const containsNumberRegexp = new RegExp(containsNumber.pattern);
    if (!containsNumberRegexp.test(value)) {
      return {password: containsNumber.message};
    }

    // validate special character
    const containsSpecialCharacter = {
      pattern: /(?=.*[!@#$%^&*()_+\-=\\\[\]{};':"|,.<>\/?`~])/,
      message: $localize`Password must contain a special character.`
    };
    const containsSpecialCharacterRegexp = new RegExp(containsSpecialCharacter.pattern);
    if (!containsSpecialCharacterRegexp.test(value)) {
      return {password: containsSpecialCharacter.message};
    }

    return null;
  };
};

@Directive({
  selector: '[appPasswordValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true}]

})
export class PasswordValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } {
    return passwordValidator()(control);
  }

  errorName(): string {
    return 'password';
  }
}
