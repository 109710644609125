import {Deserializable} from '../protocols/deserializable';
import {FormListable} from '../protocols/form-listable';
import {League} from './league';

export class LeagueId implements Deserializable, FormListable {
  id: string;
  leagueId: number;
  league: string;
  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  constructor(id: number = null) {
    this.leagueId = id;
  }
  static initWithLeague(league: League): LeagueId {
    const l = new LeagueId();
    l.leagueId = league.id;
    l.league = league.name;
    return l;
  }


  onDeserialize() {
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.league;
  }
}
