import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../models/base/base-component';
import {SportsViewModel} from './sports-view-model';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {SportType} from '../../../models/lookup/sport-type';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss']
})
export class SportsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetUsersTable = new EventEmitter();
  public updateSportsTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: SportsViewModel
  ) {
    super();
  }


  setupBindings() {
    this.viewModel.sportTypes$.notNull().subscribe(s => {
      const dataProvider = new BaseDatatableDataProvider();
      dataProvider.data = s;
      this.updateSportsTableData.next(dataProvider);
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  sportClicked(data: DatatableData) {
    this.viewModel.editSport(data as SportType);
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const sportName = new DatatableColumn(
      'sportName',
      $localize`Sport`,
      DatatableColumnType.Text,
      (s: SportType): string => {
        return s.name;
      }
    );
    sportName.isDefaultSortColumn = true;
    sportName.widthPercent = 80;
    columns.push(sportName);

    const activeSport = new DatatableColumn(
      'activeSport',
      $localize`Status`,
      DatatableColumnType.Text,
      (s: SportType): string => {
        return s.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    activeSport.className = 'custom-datatable-header text-right mr-2';
    activeSport.headerClassName = 'custom-datatable-header text-right mr-2';
    activeSport.columnFilter = this.getStatusColumnFilter();
    columns.push(activeSport);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
}
