import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {EventDetailsViewModel} from '../event-details-view-model';
import {DatatableOptions} from '../../../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../../../models/base/base-datatable-data-provider';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableData} from '../../../../../models/protocols/datatable-data';
import {HydratedProgram} from '../../../../../models/program/hydrated-program';
import {DatatableColumn, DatatableColumnType} from '../../../../../models/shared/stylesheet/datatable-column';
import {DateUtils} from '../../../../../utils/date-utils';
import {
  DatatableColumnFilter,
  DatatableColumnFilterType
} from '../../../../../models/shared/stylesheet/datatable-column-filter';
import {Placements} from 'ngx-popper';
import {HydratedShow} from '../../../../../models/program/hydrated-show';

@Component({
  selector: 'app-event-shows',
  templateUrl: './event-shows.component.html',
  styleUrls: ['./event-shows.component.scss'],
})
export class EventShowsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  private performSilentUpdate = false;

  constructor(
    public viewModel: EventDetailsViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  setupViews() {
    this.setupDatatable();
  }

  setupBindings() {
    this.viewModel.eventShows$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s;
        dataProvider.silentUpdateData = this.performSilentUpdate;
        this.performSilentUpdate = true;
        this.updateTableData.next(dataProvider);
      });
    });
  }

  showClicked(data: DatatableData) {
    const show = data as HydratedShow;
    this.router.navigate([`./shows/${show.id}`], {relativeTo: this.activatedRoute}).then();
  }

  addNewShowClicked() {
    this.router.navigate([`./shows/add`], {relativeTo: this.activatedRoute}).then();
  }

  public setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.datatableOptions.ctaButtonText = $localize`Add New Show`;
    this.datatableOptions.ctaButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Events_Edit_Event_Shows_Add) ?
        'preferred-button' : 'preferred-button disabled';
    this.setDatatableFilter();
  }

  getDatatableColumns() {
    const columns: DatatableColumn[] = [];

    const name = new DatatableColumn(
      'name',
      $localize`Name`,
      DatatableColumnType.Text,
      (s: HydratedShow): string => {
        return s.name;
      }
    );
    columns.push(name);

    const subscriptionPlanId = new DatatableColumn(
      'subscriptionPlanId',
      $localize`Subscription Plan`,
      DatatableColumnType.Text,
      (s: HydratedShow): string => {
        return s.subscriptionPlanId;
      }
    );
    columns.push(subscriptionPlanId);

    const streamDate = new DatatableColumn(
      'createdDate',
      $localize`Created Date`,
      DatatableColumnType.Text,
      (s: HydratedShow): string => {
        return s.createdDate ?
          $localize`${DateUtils.formatDateWithWeekdayToReadableString(s.createdDate)} at
           ${DateUtils.formatTimeToReadableString(s.createdDate)}` :
          $localize`N/A`;
      }
    );
    streamDate.customSortingMethod = ((a: HydratedShow, b: HydratedShow) => {
      return DateUtils.dateIsBefore(b.createdDate, a.createdDate);
    });
    streamDate.columnFilter = this.getDateColumnFilter();
    streamDate.isDefaultSortColumn = true;
    columns.push(streamDate);

    const programComments = new DatatableColumn(
      'programComments',
      $localize`Feedback`,
      DatatableColumnType.Icon,
      (s: HydratedShow): string => {
        return s.comments?.length > 0 ? '/assets/icons/dark/solid/notes-filled.svg' : '/assets/icons/dark/solid/notes.svg';
      }
    );
    programComments.className = 'custom-datatable-header text-right mr-2';
    programComments.headerClassName = 'custom-datatable-header text-right mr-2';
    programComments.iconClicked = ((s: HydratedShow) => {
      //this.viewModel.selectedFeedbackShow = s;
      //Commenting to hide the feedback tab
    });
    columns.push(programComments);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search shows`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getDateColumnFilter(): DatatableColumnFilter {
    const dateRangeFilter = new DatatableColumnFilter();
    dateRangeFilter.filterType = DatatableColumnFilterType.DateRangeFilter;
    dateRangeFilter.popperPlacement = Placements.BottomStart;
    dateRangeFilter.dateRangeFilterMethod = (p: HydratedProgram, dateRange) => {
      return DateUtils.dateRangeContains(dateRange.start, dateRange.end, p.startDateUtc);
    };
    return dateRangeFilter;
  }
}
