import {Component, ElementRef, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../models/base/base-component';
import {FormBuilder} from '@angular/forms';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../services/toast-service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-unsubscribe-landing',
  templateUrl: './unsubscribe-landing.component.html',
  styleUrls: ['./unsubscribe-landing.component.scss']
})
export class UnsubscribeComponent extends BaseComponent {

  userId: number;
  userEmail: string;
  comment: string = '';
  selectedReason: string = '';
  isOtherReasonSelected: boolean = false;
  isRadioButtonSelected: boolean = false;
  @ViewChild('commentArea') commentArea: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private domainModel: AccountDomainModel,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  setupBindings() {
    this.route.queryParams.subscribe(params => {
      this.userId = params.id;
        this.unsubscribeFromNotifications(this.userId).subscribe(response => {
          this.userEmail = response.email;
            this.toastService.publishSuccessMessage('Unsubscription successful for user', null);
          }, err => {
            console.error('Error while unsubscribing user: ', this.userId, 'Error: ', err);
            this.toastService.publishErrorMessage('Error while unsubscribing user', null);
        });
    });
  }

  setupViews() {
  }

  resubscribeForNotifications(id: number) {
    this.domainModel.resubscribeNotifications(id).subscribe(
      () => {
        this.toastService.publishSuccessMessage('Resubscription successful for user', null);
        this.router.navigate(['/resubscribe'], { queryParams: { email: this.userEmail } });
      },
      error => {
        console.error('Error while resubscribing user: ', id, 'Error: ', error);
        this.toastService.publishErrorMessage('Error while resubscribing user', null);
      }
    );
  }

  unsubscribeOnClick() {
    this.selectedReason = this.isOtherReasonSelected ? this.commentArea.nativeElement.value : this.selectedReason;
    const id = this.userId;
    const comment = this.comment;
    const reason = this.selectedReason;

    this.unsubscribeFromNotifications(id, comment, reason).subscribe(
      () => {
        this.toastService.publishSuccessMessage('Unsubscription successful for user', null);
        this.router.navigate(['/feedback']);
      },
      error => {
        console.error('Error while unsubscribing user: ', id, 'Error: ', error);
        this.toastService.publishErrorMessage('Error while unsubscribing user', null);
      }
    );
  }

  unsubscribeFromNotifications(id: number, comment?: string, reason?: string): Observable<any> {
    const feedback = {
      Comment: reason,
      Reason: reason
    };
    return this.domainModel.unsubscribeNotifications(id, feedback);
  }

  checkRadioButton(target: EventTarget) {
    const radioButton = target as HTMLInputElement;
    this.isRadioButtonSelected = radioButton.checked;
    if (radioButton.value !== 'other') {
      this.selectedReason = radioButton.value;
      this.commentArea.nativeElement.value = '';
    }
  }
  checkOtherReason(otherReasonRadio: HTMLInputElement) {
    this.isOtherReasonSelected = otherReasonRadio.checked;
  }
}
