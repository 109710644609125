import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ReorderOptions} from '../../../../models/shared/stylesheet/reorder-options';
import {Orderable} from '../../../../models/protocols/orderable';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {Observable} from 'rxjs';
import {ToastService} from '../../../../services/toast-service';
import {DeserializeHelper} from '../../../../models/protocols/deserializable';
import {CustomError} from '../../../../models/shared/custom-error';

@Component({
  selector: 'app-reorder-modal',
  templateUrl: './reorder-modal.component.html',
  styleUrls: ['./reorder-modal.component.scss']
})
export class ReorderModalComponent extends BaseModal implements OnInit, AfterViewInit {

  public loadingOpts: LoadingOptions = LoadingOptions.defaultLight();
  public reorderOptions: ReorderOptions;
  public items: Orderable[];
  public reorderOperation: (items: Orderable[]) => Observable<any>;
  private initialOrderIds: string[] = [];

  @ViewChild('modalBody') modalBody: HTMLDivElement;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    super();
  }

  setReorderOptions(opts: ReorderOptions) {
    this.reorderOptions = opts;
  }

  setReorderItems<T extends Orderable>(items: Orderable[], objectVal: new () => T) {
    // Create new instance of objects as to not affect the original memory address
    this.items = DeserializeHelper.deserializeArray(objectVal, items);
    this.initialOrderIds = items.map(i => i.getOrderableUniqueId());
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  cancel() {
    this.activeModal.close(false);
  }

  continue() {
    const lm = this.reorderOptions.loadingMess;
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.reorderOperation(this.items).subscribe((success) => {
        if (success) {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(this.reorderOptions.successMess, this.reorderOptions.successTitle);
          this.activeModal.close(this.items);
        } else {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishErrorMessage(this.reorderOptions.failureMess, this.reorderOptions.failureTitle);
        }
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  setupBindings() {
  }

  setupViews() {
  }

  orderHasChanged(): boolean {
    return !this.initialOrderIds.equals(this.items.map(i => i.getOrderableUniqueId()));
  }

}
