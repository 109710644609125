import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from '../../base/base-model';
import {DatatableData} from '../../protocols/datatable-data';
import {LeagueId} from '../../resources/leagueId';
import {TeamId} from '../../resources/teamId';
import {Address} from '../../location/address';
import {DepartmentType} from '../../lookup/department-type';

export class User extends BaseModel implements DatatableData, Deserializable {
  public id: number | string;
  public roleId: number = null;
  public role: string;
  public vip: boolean;
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public passwordChangedDate: Date;
  public eulaConfirmation: boolean;
  public firstLoginDate: Date;
  public lastLoginDate: Date;
  public active: boolean;
  public favouriteLeagues: LeagueId[];
  public favouriteTeams: TeamId[];
  public address: Address;
  public department: DepartmentType;

  public onDeserialize() {
    super.onDeserialize();
    this.firstLoginDate = DeserializeHelper.deserializeToDate(this.firstLoginDate);
    this.lastLoginDate = DeserializeHelper.deserializeToDate(this.lastLoginDate);
    this.passwordChangedDate = DeserializeHelper.deserializeToDate(this.passwordChangedDate);
    this.favouriteLeagues = DeserializeHelper.deserializeArray(LeagueId, this.favouriteLeagues);
    this.favouriteTeams = DeserializeHelper.deserializeArray(TeamId, this.favouriteTeams);
    this.address = DeserializeHelper.deserializeToInstance(Address, this.address ?? new Address());
    this.department = DeserializeHelper.deserializeToInstance(DepartmentType, this.department ?? new DepartmentType());
  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}
