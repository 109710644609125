import {Component, EventEmitter, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {User} from '../../../../../models/account/dto/user';
import {AuthModalViewModel} from '../auth-modal-view-model';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {FormGroupComponent} from '../../../components/form-group/form-group.component';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent extends BaseComponent {

  @Input() primaryButtonClicked: EventEmitter<void>;
  @Input() secondaryButtonClicked: EventEmitter<void>;
  @Input() tertiaryButtonClicked: EventEmitter<void>;
  @ViewChild('forgotPasswordForm') forgotPasswordForm: FormGroupComponent;

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: User = new User();

  constructor(
    public viewModel: AuthModalViewModel,
    private router: Router
  ) {
    super();
  }

  setupBindings() {
    this.primaryButtonClicked.subscribe(() => {
      this.forgotPasswordForm.submitForm();
    }).addTo(this.subscriptions);

    this.secondaryButtonClicked.subscribe(() => {
      this.formCancelled();
    }).addTo(this.subscriptions);

    this.tertiaryButtonClicked.subscribe(() => {
      this.navigateToNewPassword();
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.viewModel.clearErrorMessage();
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Enter your email`;
    email.bindingProperty = 'email';
    email.required = true;
    items.push(email);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.viewModel.sendForgotPasswordCode(this.formObject.email);
  }

  formCancelled() {
    this.viewModel.authFlow = AuthFlow.SignIn;
  }

  navigateToNewPassword() {
    this.viewModel.authFlow = AuthFlow.SetNewPassword;
  }

}
