import {Component} from '@angular/core';
import firebase from 'firebase';
import {BaseComponent} from '../../../../models/base/base-component';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import User = firebase.User;
import {CreateAdminRequest} from '../../../../models/account/requests/create-admin-request';
import {AdminUsersViewModel} from '../admin-users/admin-users-view-model';
import {map} from 'rxjs/operators';
import {Radiobutton} from '../../../../models/shared/stylesheet/radiobutton';
import {DepartmentType} from '../../../../models/lookup/department-type';

@Component({
  selector: 'app-edit-admin-users',
  templateUrl: './edit-admin-users.component.html',
  styleUrls: ['./edit-admin-users.component.scss'],
  providers: [AdminUsersViewModel]
})
export class EditAdminUsersComponent extends BaseComponent {


  user: User;
  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formObject: CreateAdminRequest = new CreateAdminRequest();
  public formOptions = new FormOptions();

  constructor(
    public viewModel: AdminUsersViewModel,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  setupBindings() {
    this.activatedRoute.params.pipe(map(params => params.id))
      .subscribe(id => {
        if (id) {
          this.viewModel.getAdminById(id).notNull()
          .subscribe((adminUser: CreateAdminRequest) => {
            setTimeout(() => {
              this.formObject = CreateAdminRequest.initWithUser(adminUser);
              this.formObject.itemChanged = true;
              this.viewModel?.departmentTypes$.notNull()
              .subscribe((department) => {
                this.setupFormItems(extractAdminAccessLevel(adminUser.groupList));
              }).addTo(this.subscriptions);
            });
          });
        } else {
          this.formObject = new CreateAdminRequest();
          this.formObject.itemChanged = false;
          this.viewModel?.departmentTypes$.notNull()
          .subscribe((department) => {
                      this.setupFormItems();
              }).addTo(this.subscriptions);
        }
      });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save User`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormItems(acessLevel?: string) {
    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.itemType = FormItemType.Input;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`First Name`;
    firstName.inputName = 'firstName';
    firstName.required = true;
    firstName.bindingProperty = 'firstName';
    firstName.overrideFullWidth = false;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    lastName.overrideFullWidth = false;
    items.push(lastName);

    const department = new FormInputItem();
    department.inputName = 'department';
    department.itemType = FormItemType.Dropdown;
    department.label = $localize`Department`;
    department.placeholder = $localize`Select Department`;
    department.bindingProperty = 'departmentId';
    department.required = false;
    department.dropdownIsObject = true;
    department.dropdownOptions = this.viewModel.departmentTypes$.getValue();
    department.overrideFullWidth = false;
    items.push(department);

    const designation = new FormInputItem();
    designation.inputName = 'designation';
    designation.inputType = FormInputType.Text;
    designation.label = $localize`Designation`;
    designation.placeholder = $localize`Designation`;
    designation.bindingProperty = 'designation';
    designation.required = true;
    designation.overrideFullWidth = false;
    items.push(designation);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Email`;
    email.bindingProperty = 'email';
    email.overrideFullWidth = true;
    email.required = true;
    email.overrideFullWidth = false;
    items.push(email);

    const accessLevel = new FormInputItem();
    accessLevel.inputName = 'accessLevel';
    accessLevel.itemType = FormItemType.RadioGroup;
    accessLevel.label = $localize`Access Level`;
    accessLevel.bindingProperty = 'accessLevel';
    accessLevel.overrideFullWidth = true;
    const isUpdate=!!acessLevel;
    accessLevel.groupRadios =
    [new Radiobutton('L3', isDefault('L3',acessLevel),
    false, 'Level 3', 'AdminL3', '', '', '', 'accessLevel'),
      new Radiobutton('L2', isDefault('L2',acessLevel), false, 'Level 2', 'AdminL2', '', '', '', 'accessLevel'),
      new Radiobutton('L1', isDefault('L1',acessLevel), false, 'Level 1', 'AdminL1', '', '', '', 'accessLevel'),
      new Radiobutton('L0', isUpdate ? isDefault('L0',acessLevel) : true, false, 'Level 0', 'l0', '', '', '', 'accessLevel')];
    // The code below is for when we want to call an event in realtime when the radio button is clicked
    // accessLevel.valueChanged.subscribe((value) => {
    //   this.formObject.accessLevel = 'L5';
    // });
    items.push(accessLevel);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`Active`;
    active.bindingProperty = 'active';
    active.customClass = 'mb-4 mt-0';

    items.push(active);

    this.formItems = items;
  }


  formSubmitted() {
    this.formObject.department = new DepartmentType();
    this.formObject.department.id = this.formObject.departmentId;
    this.formObject.department.name = this.viewModel.departmentTypes$.getValue().find(d => d.id === this.formObject.departmentId).name;
    this.formObject.groupList = ['AdminUsers', this.formObject.accessLevel];

    if (this.formObject.id) {
      this.viewModel.updateAdminRequest(this.formObject);
    } else {
      this.viewModel.createAdmin(this.formObject);
      this.router.navigate(['admin/users']);
    }

  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }
}
const extractAdminAccessLevel = (groupList: string[]) => {
  if (groupList.includes('AdminL3')) {
    return 'L3';
  } else if (groupList.includes('AdminL2')) {
    return 'L2';
  } else if (groupList.includes('AdminL1')) {
    return 'L1';
  } else {
    return 'L0';
  }
};

const isDefault = (accessRadio: string, adminAccessLevel: string) => accessRadio === adminAccessLevel;


