<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{getModalTitle()}}
      </div>
    </div>
  </div>
  <div class="sticky-body-container">
    <app-form-group
      class="d-block pt-3"
      #teamFormGroup
      *ngIf="formItems?.length > 0"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (secondaryButtonPressed)="deleteClicked()"
      (formSubmitted)="PermissionManagerService.isGranted(Types.AdminSections.Teams_Edit_Team_Details_Edit) ? formSubmitted() : null">
      <div class="d-block w-100">
        <div class="team-photo-container" (click)="showEditPhotoModal()">
          <div class="team-photo mr-3">
            <img [src]="formObject?.imageToUpload" alt=""
                 *ngIf="formObject.imageToUpload">
            <img [src]="(formObject?.team?.imgSrc$|async) || ''" alt=""
                 *ngIf="formObject.existingImageId && formObject.imageToUpload === null && !formObject.deleteImageId">
          </div>
          <div class="photo-text">
            <ng-container
              *ngIf="!(formObject.imageToUpload || formObject.existingImageId)"
              i18n>Add a Team Logo
            </ng-container>
            <ng-container
              *ngIf="formObject.imageToUpload || formObject.existingImageId"
              i18n>Change Team Logo
            </ng-container>
          </div>
        </div>
        <hr>
        <div class="label-md mb-4" i18n>Banner Image</div>
        <app-upload-asset
          *ngIf="!formObject.bannerImageToUpload && !formObject.existingBannerImageId"
          [allowTypes]="['image']"
          [maxAssets]="1"
          [id]="bannerImageInputId"
          [parentHandler]="this">
        </app-upload-asset>
        <div class="banner-image-container" *ngIf="formObject.bannerImageToUpload || formObject.existingBannerImageId">
          <div class="banner-image">
            <img *ngIf="formObject.existingBannerImageId" [src]="(formObject.team.bannerImgSrc$|async)" alt="">
            <img *ngIf="!formObject.existingBannerImageId" [src]="formObject.bannerImageToUpload" alt="">
          </div>
          <div class="text-link-button my-4" (click)="removeBannerImageClicked()" i18n>Remove Banner Image</div>
        </div>
        <hr>
        <div class="label-md mb-4" i18n>Card Images</div>
        <div class="card-image-preview-container"
             *ngFor="let cardImage of formObject.cardImageFormObjects"
             [hidden]="!cardImage.imageToUpload && !cardImage.existingImageId">
          <div class="card-image-preview">
            <img *ngIf="cardImage.existingImageId" [src]="(cardImage.cardImgSrc$|async)" alt="">
            <img *ngIf="!cardImage.existingImageId" [src]="cardImage.imageToUpload" alt="">
          </div>
          <div class="text-link-button my-4" (click)="removeCardImgClicked(cardImage)" i18n>Remove</div>
        </div>
        <app-upload-asset
          [allowTypes]="['image']"
          [maxAssets]="1"
          [displayList]="false"
          [parentHandler]="this">
        </app-upload-asset>
      </div>
    </app-form-group>
  </div>
  <div class="sticky-footer-container modal-footer-flex-end pt-3">
    <button
      (click)="deleteClicked()"
      *ngIf="isEditing && canRemoveTeamOnEdit"
      class="custom-button destructive-button mr-auto"
      i18n>
      Remove Team
    </button>
    <button
      (click)="cancel()"
      class="custom-button neutral-button mr-3"
      i18n>
      Cancel
    </button>
    <button
      (click)="teamFormGroup.submitForm()"
      class="custom-button preferred-button {{PermissionManagerService.isGranted(Types.AdminSections.Teams_Edit_Team_Details_Edit) ? '' : 'disabled'}}"
      i18n>
      Save
    </button>
  </div>
</div>
