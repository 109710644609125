import {Component} from '@angular/core';
import {WatchViewModel} from './watch-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProgramRowType} from '../program-row/program-row.component';

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss'],
  providers: [WatchViewModel],
})
export class WatchComponent extends BaseComponent {
  programRowType = ProgramRowType;

  constructor(
    public viewModel: WatchViewModel,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    super();
  }

  setupViews() {

  }

  setupBindings() {
  }
}
