import {Component, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadAssetComponent} from '../upload-asset/upload-asset.component';
import {UploadImageInterface} from '../upload-asset/upload-image-interface';
import {CustomFile} from '../../../../models/shared/custom-file';
import {Subject} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Image} from '../../../../models/image/dto/image';

@Component({
  selector: 'app-upload-image-modal',
  templateUrl: './upload-image-modal.component.html',
  styleUrls: ['./upload-image-modal.component.scss']
})
export class UploadImageModalComponent implements OnInit, UploadImageInterface {

  @ViewChild('uploadContainer') uploadContainer: UploadAssetComponent;

  titleText = '';
  editTitleText = '';
  croppedImage: any = '';
  roundedOverlay = true;
  loadingImage = false;

  image: Image;
  imageToUpload: string | ArrayBuffer | SafeResourceUrl;
  clearImages: Subject<boolean> = new Subject<boolean>();
  transform = {
    scale: 1,
    rotate: 0,
    flipH: false,
    flipV: false,
  };

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {

  }

  initWith(title: string, editTitle: string, existingImage: string | SafeResourceUrl) {
    this.titleText = title;
    this.editTitleText = editTitle;
    if (existingImage) {
      if (typeof existingImage === 'string') {
        this.imageToUpload = existingImage;
      } else {
        this.imageToUpload = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, existingImage);
      }
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  removeImage() {
    this.activeModal.close();
  }

  apply() {
    this.activeModal.close(this.croppedImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
    this.loadingImage = false;
  }

  loadImageFailed() {
    // show message
  }

  fileList(f: CustomFile[], id: number) {
    if (f.length > 0) {
      this.imageToUpload = f[0].url;
    } else {
      this.imageToUpload = undefined;
    }
  }

  scaleImageDown() {
    let updatedScale = this.transform.scale - 0.1;
    if (updatedScale < 0.1) {
      updatedScale = 0.1;
    }
    this.transform = {
      scale: updatedScale,
      rotate: 0,
      flipH: false,
      flipV: false,
    };
  }

  scaleImageUp() {
    let updatedScale = this.transform.scale + 0.1;
    if (updatedScale > 3) {
      updatedScale = 3;
    }
    this.transform = {
      scale: updatedScale,
      rotate: 0,
      flipH: false,
      flipV: false,
    };
  }
}
