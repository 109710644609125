import {ApiClient} from './api-client';
import {Observable, throwError} from 'rxjs';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {Endpoints} from './endpoints';
import {SignInPortal, SignInRequest} from '../models/account/requests/sign-in-request';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {LoggingService} from '../services/logging-service';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {CustomError} from '../models/shared/custom-error';
import {SignOutRequest} from '../models/account/requests/sign-out-request';
import {User} from '../models/account/dto/user';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {environment} from '../../environments/environment';
import {RefreshSessionResponse} from '../models/account/requests/refresh-session-response';
import {ResetPasswordResponse} from '../models/account/requests/reset-password-response';
import {ResetPasswordRequest} from '../models/account/requests/reset-password-request';
import {GetSessionResponse} from '../models/account/requests/get-session-response';
import {HttpClient} from '@angular/common/http';
import {EmailValidator} from '@angular/forms';
import {EmailVerification} from '../models/account/requests/email-verification';

@Injectable({
  providedIn: 'root',
})
export class AccountAPI implements LoggableAPI {
  public serviceName = 'Account';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private http: HttpClient
  ) {
  }

  // Variables

  // Session

  public signIn(req: SignInRequest, type: SignInPortal): Observable<HydratedUser> {
    let url = '';
    if (type === 'admin') {
      url = Endpoints.adminLogin(Endpoints.encodeParam(req.email));
    } else if (type === 'subscriber') {
      url = Endpoints.subscriberLogin(Endpoints.encodeParam(req.email));
    }
    return this.apiClient.postObj<HydratedUser>(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'signIn', err));
        return throwError(err);
      })
    );
  }

  public signOut(email: string): Observable<any> {
    const url = Endpoints.signOut(Endpoints.encodeParam(email));
    return this.apiClient.postObj<any>(User, url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'signOut', err));
        return throwError(err);
      })
    );
  }

  public subscriberSignOut(email: string): Observable<any> {
    const url = Endpoints.subscriberSignOut(Endpoints.encodeParam(email));
    return this.apiClient.postObj<any>(User, url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'subscriberSignOut', err));
        return throwError(err);
      })
    );
  }

  public refreshSession(email: string, req: RefreshSessionRequest, isSubscriber: boolean): Observable<RefreshSessionResponse> {
    const url = isSubscriber ?
      Endpoints.subscriberRefreshSession(Endpoints.encodeParam(email)) :
      Endpoints.adminRefreshSession(Endpoints.encodeParam(email));
    return this.apiClient.postObj<RefreshSessionResponse>(RefreshSessionResponse, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'refreshSession', err));
        return throwError(err);
      })
    );
  }

  public  getSubscriberSession(email: string, refreshToken: string): Observable<GetSessionResponse> {
    const url = Endpoints.getSubscriberSession(Endpoints.encodeParam(email), Endpoints.encodeParam(refreshToken));
    return this.apiClient.getObj<GetSessionResponse>(GetSessionResponse, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSubscriberSession', err));
        return throwError(err);
      })
    );
  }

  public getPasswordResetCode(email: string, isAdmin: boolean): Observable<string> {
    let url = '';
    if (isAdmin) {
      url = Endpoints.getAdminForgotPasswordCode(Endpoints.encodeParam(email));
    } else {
      url = Endpoints.getSubscriberForgotPasswordCode(Endpoints.encodeParam(email));
    }
    return this.apiClient.postObj<undefined>(undefined, url, undefined).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPasswordResetCode', err));
        return throwError(err);
      })
    );
  }

  public resetForgottenPassword(email: string, isAdmin: boolean, resetPassReq: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    let url = '';
    if (isAdmin) {
      url = Endpoints.resetForgottenAdminPassword(Endpoints.encodeParam(email));
    } else {
      url = Endpoints.resetForgottenSubscriberPassword(Endpoints.encodeParam(email));
    }
    return this.apiClient.postObj<ResetPasswordResponse>(ResetPasswordResponse, url, resetPassReq).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'forgotPasswordCode', err));
        return throwError(err);
      })
    );
  }

  public emailVerification(req: EmailVerification): Observable<EmailVerification> {
    const url = Endpoints.getEmailVerification();
    return this.apiClient.postObj<EmailVerification>(EmailVerification, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'emailVerification', err));
        return throwError(err);
      }
    ));
  }

  public reverifyEmail(req: EmailVerification): Observable<EmailVerification> {
    const url = Endpoints.getReverifyEmail();
    return this.apiClient.postObj<EmailVerification>(EmailVerification, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'reverifyEmail', err));
        return throwError(err);
      }
    ));
  }

  public validateEmailVerificationToken(token: string): Observable<EmailVerification> {
    const url = Endpoints.validateEmailToken(token);
    return this.apiClient.getObj<EmailVerification>(EmailVerification, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'validateEmailVerificationToken', err));
        return throwError(err);
      }
    ));
  }


public unsubscribeNotifications(id: number, param?: any): Observable<any> {
    const url = Endpoints.unsubscribeNotification(id);
    return this.apiClient.postObj<any>(User, url, param, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'unsubscribeNotifications', err));
        return throwError(err);
      })
    );
}

  public resubscribeNotifications(id: number): Observable<any> {
    const url = Endpoints.resubscribeNotification(id);
    return this.apiClient.postObj<any>(User, url, '', null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'resubscribeNotifications', err));
        return throwError(err);
      })
    );
  }
}
