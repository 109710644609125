import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import { FormValidator } from '../../../../../models/protocols/form-validator';

export const pastDateValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const inputDate = new Date(control.value);
    inputDate.setDate(inputDate.getDate() + 1);
    inputDate.setHours(23, 59, 59, 999);

    return inputDate < today ? { pastDate: 'The date cannot be in the past' } : null;
  };
};


@Directive({
  selector: '[appPastDateDirective]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PastDateValidatorDirective, multi: true }]
})
export class PastDateValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return pastDateValidator()(control);
  }

  errorName(): string {
    return 'pastDate';
  }
}
