import {Component, EventEmitter} from '@angular/core';
import {ProfileViewModel} from './profile-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {FormInputItem, FormInputType} from '../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../models/shared/stylesheet/form-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime} from 'rxjs/operators';
import {DeserializeHelper} from '../../../models/protocols/deserializable';
import {ActivatedRoute, Router} from '@angular/router';
import {HydratedUser} from '../../../models/account/dto/hydrated-user';
import {DepartmentType} from '../../../models/lookup/department-type';
import {UsersDomainModel} from '../../../domainModels/users-domain-model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileViewModel],
})
export class ProfileComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: HydratedUser;
  public updatedFormObject = new EventEmitter<void>();
  public department: DepartmentType;
  public userArray = [];

  constructor(
    public viewModel: ProfileViewModel,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersDomainModel: UsersDomainModel,
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.viewModel.user$.notNull().pipe(debounceTime(100)).subscribe(u => {
      this.viewModel.admins$.subscribe(adminUser => {
        return adminUser;
      });
      this.viewModel.getAdminUserById(u.id as number).subscribe({
        next: (user) => {
          this.department = user.department;
        }
      });
      const userCopy = DeserializeHelper.deserializeToInstance(HydratedUser, u);
      (userCopy as any).placeholderPassword = 'placeholder';
      this.formObject = userCopy;
    }).addTo(this.subscriptions);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`Enter your First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Enter your Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    items.push(lastName);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Enter your email`;
    email.bindingProperty = 'email';
    email.required = true;
    email.enabled = false;
    email.overrideFullWidth = true;
    items.push(email);

    const password = new FormInputItem();
    password.inputName = 'password';
    password.inputType = FormInputType.Password;
    password.label = $localize`Password`;
    password.placeholder = $localize`Password`;
    password.bindingProperty = 'placeholderPassword';
    password.enabled = false;
    password.overrideFullWidth = true;
    items.push(password);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.secondaryButtonFloat = 'right';
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.resetButtonText = '';
    this.formStyling.secondaryButtonText = $localize`Change Password`;
    this.formStyling.secondaryButtonClass = 'secondary-button';
    this.formStyling.submitButtonText = $localize`Save Changes`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: HydratedUser) {
    this.viewModel.updateUser(result, this.department);
  }

  changePasswordClicked() {
    this.router.navigate(['./change-password'], {relativeTo: this.activatedRoute}).then();
  }
}

