import {BaseViewModel} from '../../../models/base/base-view-model';
import {Venue} from '../../../models/resources/venue';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastService} from '../../../services/toast-service';

@Injectable()
export class VenuesViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 200);
  venues$ = this.domainModel.venues$.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Venues`));

  constructor(
    private domainModel: ResourceDomainModel,
    private router: Router,
    private toastService: ToastService
  ) {
    super();
  }

  init() {
    super.init();
  }

  addNewVenueClicked() {
    this.router.navigate([`admin/venues/add`]).then(_ => {
    });
  }

  venueClicked(v: Venue) {
    this.router.navigate([`admin/venues/${v.id}`], {
      queryParams: { forceUpdate: true }
    }).then(_ => {
    });
  }
}
