import {Component, ElementRef, ViewChild} from '@angular/core';
import {SearchModalViewModel} from './search-modal-view-model';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {SearchListable} from '../../../../models/protocols/search-listable';
import {Team} from '../../../../models/resources/team';
import {League} from '../../../../models/resources/league';
import {Event} from '../../../../models/resources/event';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import {
  VenueStreamPickerModalComponent
} from '../../modals/venue-stream-picker-modal/venue-stream-picker-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {VenueStream} from '../../../../models/resources/venue-stream';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
  providers: [SearchModalViewModel],
})
export class SearchModalComponent extends BaseModal {

  @ViewChild('searchField') searchField: ElementRef;

  constructor(
    public viewModel: SearchModalViewModel,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 600); // wait for transition animation to complete before focus input
  }

  cancel() {
    this.activeModal.dismiss();
  }

  resultClicked(result: SearchListable) {
    if (result instanceof Team) {
      this.router.navigate([`/team/${result.id}`]).then();
    } else if (result instanceof HydratedVenue) {
      this.venueResultClicked(result);
    } else if (result instanceof League) {
      this.router.navigate([`/home/leagues/${result.id}`]).then();
    } else if (result instanceof Event) {
      this.router.navigate([`/home/events/${result.id}`]).then();
    }
    this.activeModal.close();
  }

  getNoResultsText() {
    return $localize`No Results for ‘${this.viewModel.searchText$.value}’.`;
  }

  venueResultClicked(result: HydratedVenue) {
    if (result.streams?.length === 1) {
      this.navigateToStream(result.streams[0]);
    } else {
      this.showVenueStreamPickerModal(result);
    }
  }

  showVenueStreamPickerModal(hydratedVenue: HydratedVenue) {
    const modalRef = this.modalService.open(
      VenueStreamPickerModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    (modalRef.componentInstance as VenueStreamPickerModalComponent).initWithHydratedVenue(hydratedVenue);
    modalRef.result.then(stream => {
      this.navigateToStream(stream);
    }, () => {

    });
  }

  private navigateToStream(stream: VenueStream) {
    this.router.navigate([`/home/venues/stream/${stream.id}`]).then();
  }
}
