import {Component} from '@angular/core';
import {SubscriberEventDetailsViewModel} from './subscriber-event-details-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ProgramRowType} from '../program-row/program-row.component';
import {NoContentType} from '../no-content/no-content.component';
import {Cardable} from '../../../../models/protocols/cardable';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-subscriber-event-details',
  templateUrl: './subscriber-event-details.component.html',
  styleUrls: ['./subscriber-event-details.component.scss'],
  providers: [SubscriberEventDetailsViewModel],
})
export class SubscriberEventDetailsComponent extends BaseComponent {

  noContentType = NoContentType;
  programRowType = ProgramRowType;

  constructor(
    public viewModel: SubscriberEventDetailsViewModel,
    private router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  programCardClicked(card: Cardable) {
    if (card instanceof Program) {
      this.viewModel.isShow$.next(false);
      this.viewModel.programId$.next(card.id);
    } else if (card instanceof Show) {
      this.viewModel.isShow$.next(true);
      this.viewModel.programId$.next(card.id);
    }
    this.scrollToVideoPlayer();
  }

  seeAllPastGames = () => {
    const eventId = this.viewModel.eventId$.getValue();
    this.router.navigate([`/see-all`], {queryParams: eventId ? {eventId} : null}).then();
  };
}
