<app-content-container [unsetMinHeight]="true">
  <mat-tab-group class="mt-4"
    dynamicHeight animationDuration="0ms"
    (selectedIndexChange)="tabSelected($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>All</ng-container>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Favourites</ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-content-container>
