export class DatatableAction {
  public id: DatatableActionId;
  public iconSrc: string;
  public tooltipText: string;
  public clickHandler: (rowData: any) => void;
  public iconSrcFn: (rowData: any) => string;
  public tooltipTextFn: (rowData: any) => string;

  constructor(
    id: DatatableActionId,
    iconSrc,
    tooltipText: string,
    clickHandler,
    iconSrcFn: (rowData: any) => string,
    tooltipTextFn: (rowData: any) => string,
    ) {
    this.id = id;
    this.iconSrc = iconSrc;
    this.tooltipText = tooltipText;
    this.clickHandler = clickHandler;
    this.iconSrcFn = iconSrcFn;
    this.tooltipTextFn = tooltipTextFn;
  }
}

export enum DatatableActionId {
  View,
  Edit,
  DisplayAttributes,
  Delete
}
