import {Component} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../../../models/account/dto/user';
import {DeserializeHelper} from '../../../../models/protocols/deserializable';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent extends BaseModal {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: User;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  initWithUser(user: User) {
    setTimeout(() => {
      this.formObject = DeserializeHelper.deserializeToInstance(User, user);
    });
  }

  setupBindings() {
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    items.push(lastName);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    active.customClass = 'mt-0';
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Changes`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject);
  }
}
