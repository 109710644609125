import {Component, Input, OnInit} from '@angular/core';
import {Breadcrumb} from '../../../../models/shared/stylesheet/breadcrumb';
import {Router} from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: Breadcrumb[];

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  breadcrumbClicked(bc: Breadcrumb) {
    if (!bc.active && !bc.disabled) {
      this.router.navigateByUrl(bc.targetUrl).then(() => {
      });
    }
  }

}
