import {Image} from '../image/dto/image';
import {DeserializeHelper} from '../protocols/deserializable';
import {Team} from './team';
import {Observable} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';

export class HydratedTeam extends Team {
  logos: Image[];
  bannerImages: Image[];
  cardImages: Image[];

  bannerImgSrc$: Observable<string | SafeResourceUrl>;
  cardImgSrcMap: Map<string, Observable<string | SafeResourceUrl>>;

  onDeserialize() {
    super.onDeserialize();
    this.logos = DeserializeHelper.deserializeArray(Image, this.logos);
    this.bannerImages = DeserializeHelper.deserializeArray(Image, this.bannerImages);
    this.cardImages = DeserializeHelper.deserializeArray(Image, this.cardImages);
  }
}
