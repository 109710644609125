import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from 'src/app/models/base/base-component';
import {ProgramRowViewModel} from './program-row-view-model';
import {CardRowComponent, CardRowStyle} from '../../../shared/components/card-row/card-row.component';
import {Program} from '../../../../models/program/program';
import {Cardable} from '../../../../models/protocols/cardable';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentQuery} from '../../../../models/program/content-query';
import {Show} from '../../../../models/program/show';
import { Observable } from 'rxjs';
import { SessionContainer } from 'src/app/models/shared/session-container';
import { AccountDomainModel } from 'src/app/domainModels/account-domain-model';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-program-row',
  templateUrl: './program-row.component.html',
  styleUrls: ['./program-row.component.scss'],
  providers: [ProgramRowViewModel],
})
export class ProgramRowComponent extends BaseComponent implements OnChanges {

  @ViewChild('cardRowComponent') cardRowComponent: CardRowComponent;
  @Input() programRowType: ProgramRowType;
  @Input() programContentQuery: ContentQuery;
  @Input() navigateOnClick: boolean = true;
  @Input() seeAllGamesClicked: (() => void) = this.seeAllPastGames;
  @Output() noResults = new EventEmitter<boolean>();
  @Output() handleCardClicked = new EventEmitter<Cardable>();
  cardRowStyles = CardRowStyle;
  favouritesTabIndex = 1;
  @Output() cardCountStatus = new EventEmitter<{ type: ProgramRowType; value: boolean }>();

  sessionContainer$ = this.accountDomainModel.sessionContainer$;
  isSignedIn$ = this.sessionContainer$.pipe(map(s => !!s));
  constructor(
    public viewModel: ProgramRowViewModel,
    public router: Router,
    private accountDomainModel: AccountDomainModel,
    private activatedRoute: ActivatedRoute

  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.programRowType) {
      this.viewModel.programRowType$.next(this.programRowType);
    }
    if (!!changes.programContentQuery) {
      this.viewModel.programContentQuery$.next(this.programContentQuery);
    }
  }

  setupViews() {
  }

  setupBindings() {
    this.viewModel.cards$.subscribe(c => {
      this.noResults.next(!!c && c?.length === 0);
      this.cardRowComponent.updatedCards();
      this.emitCardCountStatus(!!c && c?.length === 0);
    }).addTo(this.subscriptions);
  }
  emitCardCountStatus(cardAvailable: boolean) {
    this.cardCountStatus.emit({ type: this.programRowType, value: cardAvailable });
  }

  getType(): string{
    return this.router.url.includes('leagues') ? 'leagues' :
      this.router.url.includes('events')
      ? 'events' : this.router.url.includes('venues') ? 'venues' : 'home';
  }

  seeAllPastGames() {
    this.router.navigate([`/see-all`]).then();
  }

  cardClicked(card: Cardable) {
    this.handleCardClicked.next(card);

    if (!this.navigateOnClick) {
      return;
    }

    if (card instanceof Program) {
      const program = card as Program;
      if (!!program?.venueId && !!program?.venueStreamId) {
        this.router.navigate([`/home/venues/stream/${program.venueStreamId}/${program.id}`]).then();
      } else {
        this.router.navigate([`/home/${program.id}`]).then();
      }
    }

    if (card instanceof Show) {
      this.router.navigate([`/home/show/${card.id}`]).then();
    }
  }

  updatePreferencesClicked() {
    this.router.navigate(['/account'], {fragment: 'preferences'});
  }

  loadMorePrograms() {
    this.viewModel.loadNextPage$.next();
  }

  getResult(): Observable<boolean>{
    return this.viewModel.noResult$.pipe(c=>{
      return c;
    });
  }
}

export enum ProgramRowType {
  Live,
  Upcoming,
  Past,
  Shows
}
