import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CustomError} from '../models/shared/custom-error';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastr: ToastrService
  ) {
  }

  publishError(err: CustomError, title?: string) {
    this.toastr.error(err.message, title ?? err.title);
  }

  publishAndThrowError(err: CustomError, title?: string): Observable<never> {
    this.toastr.error(err.message, title ?? err.title);
    return throwError(err);
  }

  publishErrorMessage(mess, title: string) {
    this.toastr.error(mess, title);
  }

  publishSuccessMessage(mess, title: string) {
    this.toastr.success(mess, title);
  }

  publishInfoMessage(mess, title: string) {
    this.toastr.info(mess, title);
  }

  publishWarningMessage(mess, title: string) {
    this.toastr.warning(mess, title);
  }
}
