export class ContentQuery {
  leaguesIds: number[];
  venueIds: number[];
  eventIds: number[];
  teamIds: number[];
  subscriptionPlanIds: string[];
  programQueryType: ProgramQueryType;
  startDate?: Date;
  endDate?: Date;
  venueStreamIds: number[];
  skip: number = 0;
  take: number = 10;

  constructor(programQueryType: ProgramQueryType,
              leaguesIds?: number[],
              teamIds?: number[],
              venueIds?: number[],
              eventIds?: number[],
              startDate?: Date,
              endDate?: Date,
              venueStreamIds?: number[],
              subscriptionPlanIds?: string[]) {
    this.leaguesIds = leaguesIds;
    this.venueIds = venueIds;
    this.eventIds = eventIds;
    this.teamIds = teamIds;
    this.programQueryType = programQueryType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.venueStreamIds = venueStreamIds;
    this.subscriptionPlanIds = subscriptionPlanIds;
  }

  getLeagueIdsString(): string {
    return this.leaguesIds?.join(',');
  }

  getVenueIdsString(): string {
    return this.venueIds?.join(',');
  }

  getEventIdsString(): string {
    return this.eventIds?.join(',');
  }

  getVenueStreamIdsString(): string {
    return this.venueStreamIds?.join(',');
  }

  getSubscriptionPlanIdsString(): string {
    return this.subscriptionPlanIds?.join(',');
  }

  getTeamIdsString(): string {
    return this.teamIds?.join(',');
  }
}

export enum ProgramQueryType {
  Default,
  Leagues,
  Venues,
  Events
}
