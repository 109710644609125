<div class="mt-4">
  <div *ngIf="isPremiumStream">
    <div class="label-md mb-4" i18n >Production Stream Info</div>
    <div class="stream-note" i18n>
      NOTE: This information will become available 30 minutes before the program start time and will be available until 30
      minutes after the program has completed.
    </div>
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="amazonIngestStreamUrl" i18n>Ingest Server</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).amazonIngestStreamUrl"
               id="amazonIngestStreamUrl"
               name="amazonIngestStreamUrl"
               i18n-placeholder
               placeholder="Ingest Server"
               type="text">
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).amazonIngestStreamUrl)"
                (click)="copyIngestUrlToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="amazonIngestStreamKey" i18n>Ingest Server Key</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).amazonIngestStreamKey"
               id="amazonIngestStreamKey"
               name="amazonIngestStreamKey"
               i18n-placeholder
               placeholder="Ingest Server Key"
               [type]="hideIngestServerKey ? 'password' : 'text'">
        <button class="custom-button text-button color-mid-grey ml-2 mb-4"
                (click)="hideIngestServerKey = !hideIngestServerKey"
                [hidden]="!((viewModel.program$|async).amazonIngestStreamKey)">
          <ng-container *ngIf="!hideIngestServerKey" i18n>Hide</ng-container>
          <ng-container *ngIf="hideIngestServerKey" i18n>View</ng-container>
        </button>
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).amazonIngestStreamKey)"
                (click)="copyIngestKeyToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
  </div>

    <!-- Channel ARN info-->
  <div *ngIf="((viewModel.program$.value.channelArn!=='') && !isAdminL1User && isPremiumStream)">
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="channelArn" i18n>ChannelArn</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).channelArn"
               id="channelArn"
               name="channelArn"
               i18n-placeholder
               placeholder="Channel Arn"
               type="text">
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).pixellotHDStreamUrl)"
                (click)="copyChannelArnToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="!(viewModel.program$.value.isPixellotSystem) || isPremiumStream">
    <div class="label-md mb-4" i18n >Source Stream Info

    </div>
      <hr>
      <div class="stream-note" i18n>
        NOTE: The {{cameraType}} stream urls will become available 5 minutes before the program start time.
      </div>
 </div>

    <!-- Stream URL info-->

  <div *ngIf="isPremiumStream && !(viewModel.program$.value.isPixellotSystem) ">
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="pixellotStreamUrl" i18n>{{cameraType}}  Stream Url</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).playbackStreamUrl"
               id="pixellotStreamUrl"
               name="pixellotStreamUrl"
               i18n-placeholder
               placeholder="{{cameraType}} Stream Url"
               type="text">
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).playbackStreamUrl)"
                (click)="copyPlaybackStreamUrlToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
  </div>

    <!-- Permanent Camera Stream URL info-->

  <div *ngIf="isPremiumStream && !(viewModel.program$.value.isPixellotSystem) ">
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="pixellotStreamUrl" i18n>Camera Permanent IVS  Stream Url</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).permanentIvsUrl"
               id="pixellotStreamUrl"
               name="pixellotStreamUrl"
               i18n-placeholder
               placeholder="{{cameraType}} Stream Url"
               type="text">
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).permanentIvsUrl)"
                (click)="copyPermanentUrlToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="isPremiumStream && (viewModel.program$.value.isPixellotSystem)">
      <!-- CameraSystem hd URL-->
      <form class="program-stream-form">
        <label class="custom-textfield-label" for="pixellotStreamUrl" i18n>{{cameraType}} HD Stream Url</label>
        <div class="stream-text-field-container">
          <input class="custom-textfield"
                 [readOnly]="true"
                 [ngModel]="(viewModel.program$|async).pixellotHDStreamUrl"
                 id="pixellotStreamUrl"
                 name="pixellotStreamUrl"
                 i18n-placeholder
                 placeholder="{{cameraType}}  Stream Url"
                 type="text">
          <button class="custom-button text-button ml-2 mb-4"
                  [hidden]="!((viewModel.program$|async).pixellotHDStreamUrl)"
                  (click)="copyPixellotStreamUrlToClipboard()"
                  i18n>
            Copy
          </button>
        </div>
      </form>
      <!-- CameraSystem pana hd URL-->
      <div *ngIf="isPremiumStream &&(viewModel.program$.value.isPixellotSystem)">
        <form class="program-stream-form">
          <label class="custom-textfield-label" for="pixellotPanoStreamUrl" i18n>{{cameraType}} Pano Stream Url</label>
          <div class="stream-text-field-container">
            <input class="custom-textfield"
                   [readOnly]="true"
                   [ngModel]="(viewModel.program$|async).pixellotPANOStreamUrl"
                   id="pixellotPanoStreamUrl"
                   name="pixellotPanoStreamUrl"
                   i18n-placeholder
                   placeholder="Pixellot Pano Stream Url"
                   type="text">
            <button class="custom-button text-button ml-2 mb-4"
                    [hidden]="!((viewModel.program$|async).pixellotPANOStreamUrl)"
                    (click)="pixellotPanoStreamUrl()"
                    i18n>
              Copy
            </button>
          </div>
        </form>
      </div>
  </div>


    <!-- Standard non pixellot Stream URL info-->
  <div *ngIf="!isPremiumStream && !(viewModel.program$.value.isPixellotSystem) ">
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="pixellotStreamUrl" i18n>{{cameraType}}  Stream Url</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).playbackStreamUrl"
               id="pixellotStreamUrl"
               name="pixellotStreamUrl"
               i18n-placeholder
               placeholder="{{cameraType}} Stream Url"
               type="text">
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).playbackStreamUrl)"
                (click)="copyPlaybackStreamUrlToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
  </div>

   <!-- Standard  pixellot Stream URL info-->
    <div *ngIf="!isPremiumStream && (viewModel.program$.value.isPixellotSystem) ">
    <form class="program-stream-form">
      <label class="custom-textfield-label" for="pixellotStreamUrl" i18n>{{cameraType}}  Stream Url</label>
      <div class="stream-text-field-container">
        <input class="custom-textfield"
               [readOnly]="true"
               [ngModel]="(viewModel.program$|async).playbackStreamUrl"
               id="pixellotStreamUrl"
               name="pixellotStreamUrl"
               i18n-placeholder
               placeholder="{{cameraType}} Stream Url"
               type="text">
        <button class="custom-button text-button ml-2 mb-4"
                [hidden]="!((viewModel.program$|async).playbackStreamUrl)"
                (click)="copyPlaybackStreamUrlToClipboard()"
                i18n>
          Copy
        </button>
      </div>
    </form>
  </div>





  </div>
