<app-detail-title
  [hrCustomClass]=" (viewModel.addNewLeague$|async) ? '' : 'd-none'"
  i18n>
  Leagues
</app-detail-title>
<app-content-container [narrow]="viewModel.addNewLeague$|async">
  <app-detail-back-button [backRoute]="'..'">
    <ng-container *ngIf="viewModel.addNewLeague$|async" i18n>Add a New League</ng-container>
    <ng-container *ngIf="!(viewModel.addNewLeague$|async)" i18n>{{(viewModel.league$|async)?.name}}</ng-container>
  </app-detail-back-button>
  <app-tab-bar [class.hide-tab-header]="viewModel.addNewLeague$|async" [tabs]="tabs"></app-tab-bar>
</app-content-container>
