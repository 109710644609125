export class SegmentedControlOption {
  public title: string;
  public value: any;
  public selected: boolean = false;
  public disabled: boolean = false;
  public tooltipText: string;

  constructor(title?: string, value?: any) {
    this.title = title;
    this.value = value;
  }
}
