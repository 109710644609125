import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';

@Injectable()
export class EditAdvertisingPartnerModalViewModel extends BaseViewModel {

  constructor() {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
