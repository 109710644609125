import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Viewable} from '../../../models/protocols/viewable';
import {TabBarItem} from '../../../models/shared/stylesheet/tab-bar-item';
import {TestTabComponent} from '../../shared/components/tab-bar/test-tab/test-tab.component';
import {DropDownItem} from '../../../models/shared/stylesheet/drop-down-item';
import {PillItem} from '../../../models/shared/stylesheet/pill-item';
import {ToastrService} from 'ngx-toastr';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {FormInputItem, FormInputType, FormItemType} from '../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../models/shared/stylesheet/form-group-styling';
import {PasswordValidatorDirective} from '../../shared/components/form-group/validators/password-validator.directive';
import {Selectable} from '../../../models/protocols/selectable';
import {LetterOnlyValidatorDirective} from '../../shared/components/form-group/validators/letter-only-validator.directive';
import {Checkbox} from 'src/app/models/shared/stylesheet/checkbox';
import {DropDownMenuHeader, DropDownMenuItem, DropDownMenuSection} from '../../../models/shared/stylesheet/drop-down-menu-section';
import {ComponentCanDeactivate} from '../../../models/protocols/component-can-deactivate';
import {FormGroupComponent} from '../../shared/components/form-group/form-group.component';
import {Card} from 'src/app/models/shared/stylesheet/card';
import {Breadcrumb} from '../../../models/shared/stylesheet/breadcrumb';
import {FormOptions} from '../../../models/shared/stylesheet/form-options';
import {ModalUtils} from '../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../models/shared/stylesheet/confirmation-options';
import {SegmentedControlOption} from '../../../models/shared/stylesheet/segmented-control-option';

class FormObject {
  firstName: string = 'Andrew';
  lastName: string = 'Cretin';
  middleInitial: string = 'J';
  email: string = 'andrew@krugoapp.com';
  password: string = 'Abc123!!';
  confirmPassword: string = 'Abc123!!';
  favouriteColor: string = '';
  aboutMe: string = 'This is me';
  crazyColor: string = '#99aa66';
  birthday: Date = null;
  age: number;
  time: string;
  searchedColor: any = null;
  phoneNumber = null;
  checkbox: boolean = true;
}

class FavoriteColor implements Selectable {
  id: string;
  value: string;

  constructor(id, value: string) {
    this.id = id;
    this.value = value;
  }

  getId(): any {
    return this.id;
  }

  getSelectionTitle(): any {
    return this.value;
  }

  getSelectionValue(): any {
    return this.value;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }


}

@Component({
  selector: 'app-stylesheet',
  templateUrl: './stylesheet.component.html',
  styleUrls: [
    './stylesheet.component.scss',
  ],
})
export class StylesheetComponent implements OnInit, Viewable, ComponentCanDeactivate {

  // View References
  @ViewChild(FormGroupComponent) deactivatableForm: FormGroupComponent;

  public tabs: TabBarItem[] = [];
  public dropdowns: DropDownItem[] = [];
  public pills: PillItem[] = [];
  public testCheck = new Checkbox('Checkbox Object', true, false);
  public checkBoxes: Checkbox[] = [];
  public dropDownMenuSections: DropDownMenuSection[] = [];

  // Loading
  public indeterminateOptions = LoadingOptions.defaultLight();
  public determinateOptions = LoadingOptions.default(true);

  // Form Group
  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: FormObject = new FormObject();
  backPressed: EventEmitter<any> = new EventEmitter<any>();

  // Cards
  public cards: Card[] = [];

  // Breadcrumbs
  public breadcrumbs: Breadcrumb[] = [];

  // Segmented Controls
  public segmentedControls: SegmentedControlOption[] = [];

  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setupTabBar();
    this.setupDropDown();
    this.setupPills();
    this.setupFormStyling();
    this.setupFormOpts();
    this.setupFormItems();
    this.setupCheckboxes();
    this.setupDropDownMenu();
    this.setupCards();
    this.setupBreadcrumbs();
    this.setupSegmentedControl();
  }

  setupBindings() {
  }

  setupTabBar() {
    this.tabs = [];
    const tab1 = new TabBarItem(TestTabComponent, 1, $localize`Test Tab 1`, '/assets/icons/light/solid/drop-down.svg');
    this.tabs.push(tab1);
    const tab2 = new TabBarItem(TestTabComponent, 2, $localize`Test Tab 2`, '');
    tab2.active = true;
    this.tabs.push(tab2);
  }

  setupDropDown() {
    this.dropdowns = [];
    const d1 = new DropDownItem('Drop down 1', 'd1');
    this.dropdowns.push(d1);
    const d2 = new DropDownItem('Drop down 2', 'd2');
    this.dropdowns.push(d2);
    const d3 = new DropDownItem('Drop down 3', 'd3');
    this.dropdowns.push(d3);
  }

  setupPills() {
    const p1 = new PillItem('Pill', false, false, false);
    const p2 = new PillItem('A much larger pill with a lot of text', false, false, false);
    const p3 = new PillItem('Disabled Pill', false, false, true);
    const p4 = new PillItem('Selected Removable Pill', true, true, false);
    this.pills.push(p1, p2, p3, p4);
    this.pills.push(p1, p2, p3, p4);
    this.pills.push(p1, p2, p3, p4);
    this.pills.push(p1, p2, p3, p4);
  }

  setupFormOpts() {
    this.formOptions.checkboxBindingProperty = ['checkbox', this.formObject.checkbox];
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.cancelButtonText = 'Cancel';
    this.formStyling.resetButtonText = 'Reset';
    this.formStyling.submitButtonText = 'Submit';
    // secondary buttons
    this.formStyling.secondaryButtonFloat = 'right';
    this.formStyling.secondaryButtonText = 'Secondary Button Action';
    this.formStyling.secondaryButtonClass = 'text-button';
    // tertiary buttons
    this.formStyling.tertiaryButtonFloat = 'right';
    this.formStyling.tertiaryButtonText = 'Tertiary Button Action';
    this.formStyling.tertiaryButtonClass = 'text-button';
    // Checkbox
    this.formStyling.checkboxLabel = 'Here is a checked at the end of the form.';
  }

  handleDestructiveButtonClick() {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = 'Are you sure you want to do that?';
    opts.bodyText = 'That is a super destructive action and you will not be able to undo your actions. You should really think about this.';
    opts.cancelText = 'Cancel';
    opts.continueText = 'Send it';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cont) => {
      if (cont) {
        this.toastr.success('Did something destructive!');
      } else {
        this.toastr.info('Phew, saved the day and cancelled!');
      }
    });
  }

  setupFormItems() {
    const input1 = new FormInputItem();
    input1.inputName = 'firstName';
    input1.inputType = FormInputType.Text;
    input1.label = 'First Name';
    input1.placeholder = 'Enter your first name';
    input1.bindingProperty = 'firstName';
    input1.showRequiredAsterisk = true;
    input1.required = true;
    input1.minLength = 2;
    input1.maxLength = 15;
    input1.customValidator = new LetterOnlyValidatorDirective();
    input1.customError = new Map<string, string>([
      ['letterOnly', 'First name may not contain spaces or special characters.']
    ]);

    const input2 = new FormInputItem();
    input2.inputName = 'lastName';
    input2.inputType = FormInputType.Text;
    input2.label = 'Last Name';
    input2.placeholder = 'Enter your last name';
    input2.bindingProperty = 'lastName';
    input2.required = true;
    input2.minLength = 2;
    input2.maxLength = 15;
    input2.inlineLabel = true;

    const input3 = new FormInputItem();
    input3.inputName = 'middleInitial';
    input3.inputType = FormInputType.Text;
    input3.label = 'Middle Initial';
    input3.placeholder = 'Enter your middle initial';
    input3.bindingProperty = 'middleInitial';
    input3.minLength = 1;
    input3.maxLength = 1;
    input3.customError = new Map<string, string>([
      ['minlength', 'Middle name may only be 1 character.'],
      ['maxlength', 'Middle name may only be 1 character.']
    ]);

    const input4 = new FormInputItem();
    input4.inputName = 'email';
    input4.inputType = FormInputType.Email;
    input4.label = 'Email';
    input4.placeholder = 'Enter your email';
    input4.bindingProperty = 'email';
    input4.required = true;

    const input5 = new FormInputItem();
    input5.inputName = 'password';
    input5.inputType = FormInputType.Password;
    input5.label = 'Password';
    input5.placeholder = 'Enter a secure password';
    input5.bindingProperty = 'password';
    input5.required = true;
    input5.customValidator = new PasswordValidatorDirective();

    const input6 = new FormInputItem();
    input6.inputName = 'confirmPassword';
    input6.mustMatchInputName = 'password';
    input6.inputType = FormInputType.ConfirmPassword;
    input6.label = 'Confirm Password';
    input6.placeholder = 'Enter your password again.';
    input6.bindingProperty = 'confirmPassword';
    input6.required = true;
    input6.customValidator = new PasswordValidatorDirective();

    const input7 = new FormInputItem();
    input7.itemType = FormItemType.Dropdown;
    input7.inputName = 'favouriteColor';
    input7.label = 'Fav Color';
    input7.placeholder = 'Pick a color';
    input7.bindingProperty = 'favouriteColor';
    input7.required = true;
    input7.inlineLabel = true;
    input7.dropdownOptions = new Array<FavoriteColor>(
      new FavoriteColor('1', 'red'),
      new FavoriteColor('2', 'blue'),
      new FavoriteColor('3', 'green'),
    );

    const input8 = new FormInputItem();
    input8.itemType = FormItemType.Textarea;
    input8.inputName = 'aboutMe';
    input8.label = 'About Me';
    input8.placeholder = 'Tell us about yourself';
    input8.bindingProperty = 'aboutMe';
    input8.required = true;
    input8.inlineLabel = true;

    const input9 = new FormInputItem();
    input9.itemType = FormItemType.Input;
    input9.inputType = FormInputType.Color;
    input9.inputName = 'crazyColor';
    input9.label = 'Crazy Color';
    input9.placeholder = 'Pick a crazy color.';
    input9.bindingProperty = 'crazyColor';
    input9.required = true;
    input9.inlineLabel = true;

    const input10 = new FormInputItem();
    input10.itemType = FormItemType.Input;
    input10.inputType = FormInputType.NumberInput;
    input10.inputName = 'age';
    input10.label = 'Age';
    input10.placeholder = 'How old are you';
    input10.bindingProperty = 'age';
    input10.required = true;
    input10.inlineLabel = true;
    input10.minValue = 1;
    input10.maxValue = 99;

    const input11 = new FormInputItem();
    input11.itemType = FormItemType.Input;
    input11.inputType = FormInputType.Date;
    input11.inputName = 'birthday';
    input11.label = 'Birthday';
    input11.placeholder = 'When is your birthday';
    input11.bindingProperty = 'birthday';
    input11.required = true;
    input11.inlineLabel = true;

    const input12 = new FormInputItem();
    input12.itemType = FormItemType.Input;
    input12.inputType = FormInputType.Time;
    input12.inputName = 'time';
    input12.label = 'Birth Time';
    input12.placeholder = 'What time were you born';
    input12.bindingProperty = 'time';
    input12.required = true;
    input12.inlineLabel = true;

    const input13 = new FormInputItem();
    input13.itemType = FormItemType.Input;
    input13.inputType = FormInputType.Search;
    input13.inputName = 'search';
    input13.label = 'Search For';
    input13.placeholder = 'Search for color';
    input13.bindingProperty = 'searchedColor';
    input13.required = true;
    input13.inlineLabel = true;
    input13.customError = new Map<string, string>([
      ['searchFor', 'Item must be in list'],
    ]);
    input13.searchable = Array(
      {lookupKey: 'red', value: 'redValue'},
      {lookupKey: 'green', value: 'greenValue'},
      {lookupKey: 'orange', value: 'orangeValue'},
      {lookupKey: 'blue', value: 'blueValue'},
    );

    const input14 = new FormInputItem();
    input14.itemType = FormItemType.Input;
    input14.inputType = FormInputType.Tel;
    input14.inputName = 'telephone';
    input14.label = 'Phone Number';
    input14.placeholder = '+ 1 306 690 9999';
    input14.bindingProperty = 'phoneNumber';
    input14.required = true;
    input14.inlineLabel = false;

    this.formItems.push(
      input1,
      input2,
      input3,
      input4,
      input5,
      input6,
      input7,
      input8,
      input9,
      input10,
      input11,
      input12,
      input13,
      input14
    );
  }

  selectionChanged(e: any) {
    console.log(e);
  }

  setupCheckboxes() {
    const c1 = new Checkbox('Check 1');
    const c2 = new Checkbox('Check 2');
    const c3 = new Checkbox('Check 3');
    this.checkBoxes.push(c1, c2, c3);
  }

  setupDropDownMenu() {
    const d0 = new DropDownMenuSection(
      null,
      Array<DropDownMenuItem>(
        new DropDownMenuItem('I do not have a header', 'II1', new Set<string>()),
        new DropDownMenuItem('Beep beep', 'II2', new Set<string>()),
        new DropDownMenuItem('I am item 3', 'II3', new Set<string>().add('red-text')),
      )
    );
    const d1 = new DropDownMenuSection(
      new DropDownMenuHeader('Header 1'),
      Array<DropDownMenuItem>(
        new DropDownMenuItem('Item 1', 'I1', new Set<string>()),
        new DropDownMenuItem('Item 2', 'I2', new Set<string>()),
        new DropDownMenuItem('Item 3', 'I3', new Set<string>()),
      )
    );
    const d2 = new DropDownMenuSection(
      new DropDownMenuHeader('Header 2'),
      Array<DropDownMenuItem>(
        new DropDownMenuItem('A long item with more than your average length of text', 'II1', new Set<string>()),
        new DropDownMenuItem('I am item number 2 in section 2', 'II2', new Set<string>()),
        new DropDownMenuItem('I am item 3', 'II3', new Set<string>().add('red-text')),
      )
    );
    this.dropDownMenuSections.push(d0, d1, d2);
  }

  // Cards

  setupCards() {
    const card1 = new Card(
      'Label 1',
      'Text 1',
    );
    card1.imageSrc = 'assets/img/sample.jpg';
    this.cards.push(card1);

    const card2 = new Card(
      'Label 2',
      'Text 2',
    );
    card2.imageSrc = 'assets/img/sample.jpg';
    this.cards.push(card2);
  }

  setupBreadcrumbs() {
    this.breadcrumbs = [];
    const bc1 = new Breadcrumb(
      'Route to Account',
      'account'
    );
    this.breadcrumbs.push(bc1);

    const bc2 = new Breadcrumb(
      'Stylesheet',
      'stylesheet'
    );
    bc2.active = true;
    this.breadcrumbs.push(bc2);

    const bc3 = new Breadcrumb(
      'Stylesheet Disabled',
      ''
    );
    bc3.disabled = true;
    this.breadcrumbs.push(bc3);
  }

  setupSegmentedControl() {
    this.segmentedControls = [];
    this.segmentedControls.push(new SegmentedControlOption('Option 1', 1));
    this.segmentedControls.push(new SegmentedControlOption('Option 2', 2));
    this.segmentedControls.push(new SegmentedControlOption('Option 3 with long name', 3));
  }

  segmentedControlSelected(o: SegmentedControlOption) {
    this.toastr.info(o.title, 'Control Selected');
  }

  cardPressed(card: Card) {
    this.toastr.info(card.label, 'Card Pressed');
  }

  // Toast
  showSuccessToast(includeTitle: boolean) {
    if (includeTitle) {
      this.toastr.success('Here is a success message.', 'Success Title');
    } else {
      this.toastr.success('Here is a success message....');
    }
  }

  showErrorToast(includeTitle: boolean) {
    if (includeTitle) {
      this.toastr.error('Here is an error message of what happened.', 'Error Title');
    } else {
      this.toastr.error('Here is an error message of what happened....');
    }
  }

  showInfoToast(includeTitle: boolean) {
    if (includeTitle) {
      this.toastr.info('Here is an info message.', 'Info Title');
    } else {
      this.toastr.info('Here is an info message....');
    }
  }

  showWarningToast(includeTitle:
                     boolean
  ) {
    if (includeTitle) {
      this.toastr.warning('Here is a warning message.', 'Warning Title');
    } else {
      this.toastr.warning('Here is a warning message....');
    }
  }


  // Loading

  showLoadingSpinner(loadingText: string, fullscreen: boolean) {
    this.indeterminateOptions.loadingText = loadingText;
    this.indeterminateOptions.fullscreen = fullscreen;
    this.indeterminateOptions.isLoading = true;

    setTimeout(
      function() {
        this.indeterminateOptions.isLoading = false;
        this.indeterminateOptions.loadingText = '';
        this.indeterminateOptions.fullscreen = false;
      }.bind(this), 300000);
  }

  showLoadingBar(loadingText: string, fullscreen: boolean) {
    this.determinateOptions.loadingText = loadingText;
    this.determinateOptions.fullscreen = fullscreen;
    this.determinateOptions.isLoading = true;
    this.determinateOptions.progress = 0;
    this.simulateDeterminateLoading();
  }

  simulateDeterminateLoading() {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.determinateOptions.progress === 100) {
          this.determinateOptions.isLoading = false;
          this.determinateOptions.loadingText = '';
          this.determinateOptions.fullscreen = false;
          clearInterval(progressInterval);
        } else {
          this.determinateOptions.progress += 10;
        }
      }, 200);
    }, 500);
  }

  formSubmitted(hydratedFormObject: FormObject) {
    this.toastr.success('Check console for object output.', 'Form Submitted');
    console.log(hydratedFormObject);
  }

  formCancelled() {
    this.toastr.error('Form Cancelled');
  }

  secondaryButtonPressed() {
    this.toastr.info('Secondary Button Pressed');
  }

  tertiaryButtonPressed() {
    this.toastr.info('Tertiary Button Pressed');
  }

  canDeactivate(): boolean | Promise<any> {
    return !this.deactivatableForm.shouldDisplayAbandonDialog();
  }

}
