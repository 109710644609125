import {HydratedTeam} from './hydrated-team';
import {SafeResourceUrl} from '@angular/platform-browser';
import {FormListable} from '../protocols/form-listable';
import {DeserializeHelper} from '../protocols/deserializable';
import {CardImageFormObject} from './card-image-form-object';

export class TeamFormObject implements FormListable {
  public team: HydratedTeam;
  imageToUpload: string | SafeResourceUrl;
  existingImageId: string;
  deleteImageId: string;
  cardImageFormObjects: CardImageFormObject[] = [];

  bannerImageToUpload: string | SafeResourceUrl;
  existingBannerImageId: string;
  bannerImageToUploadFormat: string;
  deleteBannerImageId: string;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  static initWithTeam(team: HydratedTeam): TeamFormObject {
    const formObject = new TeamFormObject();
    formObject.setTeam(team);
    return formObject;
  }

  setTeam(t: HydratedTeam) {
    this.team = t;
    this.existingImageId = t.logos?.length > 0 ? t.logos[0]?.id : null;
    this.existingBannerImageId = t.bannerImages?.length > 0 ? t.bannerImages[0]?.id : null;
    this.cardImageFormObjects = t.cardImages?.map(i => {
      return new CardImageFormObject(i, t.cardImgSrcMap.get(i.id));
    }) ?? [];
  }

  getCopy(): TeamFormObject {
    const teamCopy = DeserializeHelper.deserializeToInstance(HydratedTeam, this.team);
    const formObjectCopy = Object.assign(new TeamFormObject(), this);
    formObjectCopy.setTeam(teamCopy);
    return formObjectCopy;
  }

  getEnabledStatus(): boolean {
    return this.team.active;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.team.fullTeamName();
  }
}

