<div class="d-flex mt-3 h-100 min-height-15">
  <app-loading
    [hidden]="!viewModel.programLoadingOpts?.isLoading"
    [options]="viewModel.programLoadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="dataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="PermissionManagerService.isGranted(Types.AdminSections.Events_Edit_Event_Shows_Add) ? showClicked($event) : null"
    (ctaButtonClicked)="PermissionManagerService.isGranted(Types.AdminSections.Events_Edit_Event_Shows_Add) ? addNewShowClicked() : null"
    [tableOptions]="datatableOptions">
  </app-data-table>
  <app-program-feedback
    [hidden]="!viewModel.selectedFeedbackShow"
    (closeButtonClicked)="viewModel.selectedFeedbackShow = null"
    [program]="viewModel.selectedFeedbackShow">
  </app-program-feedback>
</div>
