<app-detail-title>
  <div class="d-flex justify-content-between">
    <ng-container i18n>Events</ng-container>
    <button [disabled]="!PermissionManagerService.isGranted(Types.AdminSections.Events_Add_Event)"
            [ngClass]="{'preferred-button': true, 'disabled': !PermissionManagerService.isGranted(Types.AdminSections.Events_Add_Event)}"
            class="custom-button preferred-button" (click)="addNewEvent()" i18n>
      Add a New Event
    </button>
  </div>
</app-detail-title>
<div class="mt-3 position-relative">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="userDataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="rowClicked($event)"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
