import {BaseModel} from '../base/base-model';
import {FormListable} from '../protocols/form-listable';
import {Selectable} from '../protocols/selectable';

export class VenueStream extends BaseModel implements FormListable, Selectable {
  id: number;
  name: string = '';
  cameraName: string = '';
  cameraId: string = '';
  active: boolean = true;
  sportId: number = null;
  sport: string = null;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  onDeserialize() {
    super.onDeserialize();
    this.cameraId = this.cameraId ?? null;
  }

  getEnabledStatus(): boolean {
    return this.active;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.name;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}
