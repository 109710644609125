<mat-button-toggle-group
  (change)="controlSelected($event)"
  [multiple]="allowMultipleSelection"
  [vertical]="verticalLayout"
  class="custom-segmented-control-group"
  name="{{ controlName }}">
  <mat-button-toggle
    *ngFor="let opt of options"
    [checked]="opt.selected"
    [disabled]="opt.disabled"
    [value]="opt.value"
    [matTooltipClass]="'custom-mat-tooltip'"
    [matTooltip]="opt.tooltipText"
    class="custom-segmented-control">
    {{ opt.title }}
  </mat-button-toggle>
</mat-button-toggle-group>
