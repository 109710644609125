<app-detail-title i18n>
  Profile
</app-detail-title>
<app-content-container [narrow]="true">
  <div class="position-relative min-height-20 h-100">
    <app-loading
      [hidden]="!(viewModel.loadingOpts?.isLoading)"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <app-form-group
      class="d-block pt-3 mt-2"
      *ngIf="formItems?.length > 0 && !!formObject"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (secondaryButtonPressed)="changePasswordClicked()"
      (formSubmitted)="formSubmitted($event)">
    </app-form-group>
  </div>
</app-content-container>
