import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {TeamFormObject} from '../../../models/resources/team-form-object';
import {ToastService} from '../../../services/toast-service';

@Injectable()
export class AdminTeamsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  teams$ = this.domainModel.allHydratedTeams$.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Teams`));

  constructor(private domainModel: ResourceDomainModel,
              private toastService: ToastService) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  saveTeam(teamFormObject: TeamFormObject) {
    this.domainModel.saveTeam(teamFormObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Team`))
      .subscribe((t) => {
        this.toastService.publishSuccessMessage(null, $localize`Team Saved`);
      }, error => {
        this.toastService.publishError(error, $localize`Team Save Error`);
      });
  }
}
