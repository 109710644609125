
export class RadiobuttonContainerOptions {
  public inlineOptions: boolean = false;
  public touched: boolean = false;
  public customRadiobuttonMargin: string;
  public showDivider: boolean = false;
  constructor(showDivider: boolean) {
    this.showDivider = showDivider;
  }
}
