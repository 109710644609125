import {BaseModel} from '../base/base-model';
import {Selectable} from '../protocols/selectable';
import {Address} from '../location/address';
import {DeserializeHelper} from '../protocols/deserializable';
import {DatatableData} from '../protocols/datatable-data';
import {FormListable} from '../protocols/form-listable';
import {Observable} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {SearchListable} from '../protocols/search-listable';
import AddressUtils from '../../utils/address-utils';

export class Venue extends BaseModel implements Selectable, DatatableData, FormListable, SearchListable {
  id: number;
  name: string = '';
  subscriptionPlanId: string = null;
  address: Address;
  active: boolean = true;
  enableTransmitads: boolean=false;

  imgSrc$: Observable<string | SafeResourceUrl>;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  onDeserialize() {
    super.onDeserialize();
    this.address = DeserializeHelper.deserializeToInstance(Address, this.address);
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getEnabledStatus(): boolean {
    return this.active;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.name;
  }

  getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
    return this.imgSrc$;
  }

  getSearchListablePrimaryKey(): string {
    return this.name;
  }

  getSearchListableSecondaryKey(): string {
    return AddressUtils.singleLineFormat(this.address);
  }

  getSearchListableSubTitle(): string {
    return $localize`Venue`;
  }

  getSearchListableTitle(): string {
    return this.name;
  }
}

