import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {CouponConstraintCriteria} from './coupon-constraint-criteria';
import {CouponConstraint} from './coupon-constraint';
import {SubscriptionPricingOption} from './subscription-plan';
import {NumberUtils} from '../../../utils/number-utils';

export class Coupon implements Deserializable {
  public id: string;
  public name: string;
  public status: CouponStatus;
  public discountType: CouponDiscountType;
  public discountPercentage: string;
  public discountAmount: string;
  public durationType: CouponDurationType;
  public period: number;
  public periodUnit: string;
  public applyOn: CouponApplyOnType;
  public couponConstraints: CouponConstraint[];
  public couponConstraintCriteria: CouponConstraintCriteria[];

  onDeserialize() {
    this.couponConstraints = DeserializeHelper.deserializeArray(CouponConstraint, this.couponConstraints);
    this.couponConstraintCriteria = DeserializeHelper.deserializeArray(CouponConstraintCriteria, this.couponConstraintCriteria);
  }

  getDiscountOnPrice(price: number): number {
    if (this.discountType === CouponDiscountType.FixedAmount) {
      let discountAmountFloat = parseFloat(this.discountAmount);
      if (isNaN(discountAmountFloat)) {
        discountAmountFloat = 0;
      }
      return price - discountAmountFloat;
    } else if (this.discountType === CouponDiscountType.Percentage) {
      let discountPercentFloat = parseFloat(this.discountPercentage);
      if (isNaN(discountPercentFloat)) {
        discountPercentFloat = 0;
      }
      const discountAmountFloat = price * (discountPercentFloat / 100);
      return price - discountAmountFloat;
    }
    return price;
  }

  getPaymentBillingCycle(): string {
    if (this.periodUnit === 'year') {
      return 'yearly';
    }
    return this.periodUnit;
  }

  getCouponPeriodDescription(): string {
    if (this.durationType !== CouponDurationType.LimitedPeriod) {
      return '';
    }

    const periodUnitString = this.period === 1 ? this.getPaymentBillingCycle() : `${this.getPaymentBillingCycle()}s`;
    return `for ${this.period} ${periodUnitString}`;
  }

  getCouponDiscountValueDescription(): string {
    if (this.discountType === CouponDiscountType.FixedAmount) {
      let discountAmountFloat = parseFloat(this.discountAmount);
      if (isNaN(discountAmountFloat)) {
        discountAmountFloat = 0;
      }
      return `${NumberUtils.formatNumberAsCurrency(discountAmountFloat)} off`;
    } else if (this.discountType === CouponDiscountType.Percentage) {
      let discountPercentFloat = parseFloat(this.discountPercentage);
      if (isNaN(discountPercentFloat)) {
        discountPercentFloat = 0;
      }
      return `${NumberUtils.formatNumberAsPercentString(discountPercentFloat)} off`;
    }
    return '';
  }

  getCouponDescription(): string {
    return `${this.getCouponDiscountValueDescription()} ${this.getCouponPeriodDescription()}`;
  }
}

export enum CouponStatus {
  Active = 'active',
  Archived = 'archived',
  Expired = 'expired',
  Invalid = 'invalid',
  Redeemed = 'redeemed',
  NotFound = 'notfound',
  Error = 'error',
  Inactive = 'inactive',
}

export enum CouponDiscountType {
  FixedAmount = 'fixed_amount',
  Percentage = 'percentage',
}

export enum CouponDurationType {
  OneTime = 'one_time',
  Forever = 'forever',
  LimitedPeriod = 'limited_period',
}

export enum CouponApplyOnType {
  InvoiceAmount = 'invoice_amount', // applied to invoice subtotal
  EachSpecifiedItem = 'each_specified_item',
}
