import {Observable} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {Image} from '../image/dto/image';

export class BannerAdvertisement extends Image {
  advertisementName: string = '';
  advertisementUrl: string = '';
  imgSrc$: Observable<string | SafeResourceUrl>;
}

