import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {SessionService} from '../../../../services/session-service';
import {debounceTime, filter, map} from 'rxjs/operators';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {environment} from '../../../../../environments/environment';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import Fuse from 'fuse.js';
import {SearchListItemContainer} from '../../../shared/components/search-modal/search-list-item-container';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {SearchListable} from '../../../../models/protocols/search-listable';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';

@Injectable()
export class SearchVenuesBannerViewModel extends BaseViewModel {

  hydratedLeague$ = new BehaviorSubject<HydratedLeague>(null);
  isSignedIn$ = this.sessionService.sessionContainer.pipe(map(s => !!s));
  venues$ = this.resourceDomainModel.activeHydratedVenues$;
  searchText$ = new BehaviorSubject<string>(null);
  trimmedSearchText$ = this.searchText$.pipe(map(s => s?.replaceAll(/\s/g, '')));

  searchListItems$: Observable<Fuse<SearchListItemContainer>> = this.venues$.pipe(
    map(venues => SearchListItemContainer.generateFuse(venues))
  );

  searchResults$: Observable<HydratedVenue[]> = combineLatest([
    this.trimmedSearchText$,
    this.searchListItems$
  ]).pipe(
    debounceTime(200),
    map(([searchText, searchFuse]) => {
      if (!searchText) {
        return null;
      }
      return searchFuse?.search(searchText, {limit: 10})?.map(r => r.item.searchListableValue as HydratedVenue);
    })
  );

  noResults$ =  combineLatest([
    this.trimmedSearchText$,
    this.searchResults$
  ]).pipe(map(([searchText, results]) => searchText?.length > 2 && results?.length === 0));

  constructor(private sessionService: SessionService,
              private resourceDomainModel: ResourceDomainModel,
              private accountDomainModel: AccountDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
