import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class EventLandingViewModel extends BaseViewModel {

  events$ = this.resourceDomainModel.activeHydratedEvents$;
  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
  eventTabs$ = new BehaviorSubject<string[]>(null);
  fetchEventTabs = this.events$.pipe(map(events => {
    return [$localize`All`, ...(events?.map(l => l.abbreviation) ?? [])];
  })).subscribe(this.eventTabs$).addTo(this.subscriptions);

  private _selectedTabIndex$ = new BehaviorSubject<number>(0);
  selectedEventId$ = combineLatest([this._selectedTabIndex$, this.events$])
    .pipe(map(([index, events]) => {
      if (index === 0) {
        return null;
      }
      return events[index - 1]?.id;
    }));

  selectedHydratedEvent$ = combineLatest([this.selectedEventId$, this.events$]).pipe(
    map(([eventId, events]) => {
      if (!eventId) {
        return null;
      }
      return events?.find(l => l.id === eventId);
    }),
  );

  isSubscribedToEvent$ = combineLatest([this.selectedHydratedEvent$, this.accountDomainModel.subscriberSubscriptions$]).pipe(
    map(([event, subscriptions]) => {
      if (!event) {
        return true;
      }
      const eventPlanId = event?.subscriptionPlanId;
      return eventPlanId === environment.freePlanId || subscriptions?.some(s => s.planId === eventPlanId);
    })
  );

  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  showsNoResults = new BehaviorSubject<boolean>(false);
  showNoResults$ = combineLatest([
    this.liveProgramsNoResults,
    this.upcomingProgramsNoResults,
    this.pastProgramsNoResults,
    this.showsNoResults
  ]).pipe(map(noResults => noResults.every(x => !!x)));

  contentQuery$ = this.selectedEventId$.pipe(map(eventId => {
    return new ContentQuery(ProgramQueryType.Events, null, null, null,!!eventId ? [eventId] : null);
  }));

  programContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  fetchProgramQuery = combineLatest([this.accountDomainModel.subscriberSubscriptions$, this.events$, this.selectedEventId$])
    .subscribe(([subscriberSubscriptions, events, selectedEventId]) => {
      const subscriptionIds = subscriberSubscriptions
        ?.filter(s => !s.isSubscriptionInactive())
        ?.map(s => s.planId) ?? [];
      const eventIds = !!selectedEventId ? [selectedEventId] : events
        .filter(l => l.subscriptionPlanId === environment.freePlanId || subscriptionIds.includes(l.subscriptionPlanId))
        .map(l => l.id);
      this.programContentQuery$.next(new ContentQuery(ProgramQueryType.Events, null, null, null, eventIds));
    }).addTo(this.subscriptions);

  programId$ = new BehaviorSubject<string>(null);
  isShow$ = new BehaviorSubject<boolean>(null);

  constructor(
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  selectedTabIndex(index: number) {
    this._selectedTabIndex$.next(index);
  }

}
