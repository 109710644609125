import {Component} from '@angular/core';
import {VenueStreamPickerModalViewModel} from './venue-stream-picker-modal-view-model';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import {VenueStream} from '../../../../models/resources/venue-stream';

@Component({
  selector: 'app-venue-stream-picker-modal',
  templateUrl: './venue-stream-picker-modal.component.html',
  styleUrls: ['./venue-stream-picker-modal.component.scss'],
  providers: [VenueStreamPickerModalViewModel],
})
export class VenueStreamPickerModalComponent extends BaseModal {

  constructor(
    public viewModel: VenueStreamPickerModalViewModel,
    private activeModal: NgbActiveModal,
    private router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  initWithHydratedVenue(hydratedVenue: HydratedVenue) {
    this.viewModel.hydratedVenue$.next(hydratedVenue);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  streamClicked(stream: VenueStream) {
    this.activeModal.close(stream);
  }
}
