export class PillItem {

  public clickable: boolean;
  public selected: boolean;
  public disabled: boolean;
  public text: string;

  constructor(text: string = '', clickable: boolean = true, selected: boolean = false, disabled: boolean = false) {
    this.clickable = clickable;
    this.selected = selected;
    this.disabled = disabled;
    this.text = text;
  }

}
