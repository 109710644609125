import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {BehaviorSubject, combineLatest, interval, Observable, of} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {startWith, switchMap} from 'rxjs/operators';
import {BannerAdvertisement} from '../../../../models/resources/banner-advertisement';

@Injectable()
export class BannerAdvertisementRowViewModel extends BaseViewModel {

  initialAdIndex = new BehaviorSubject<number>(null);
  currentAdvertisement$ = new BehaviorSubject<BannerAdvertisement>(null);
  currentAdvertisementImgSrc$: Observable<string | SafeResourceUrl> = this.currentAdvertisement$
    .pipe(switchMap(a => a?.imgSrc$ ?? of(null)));
  advertisementList$ = new BehaviorSubject<BannerAdvertisement[]>(null);
  adUpdateInterval$ = interval(10 * 60 * 1000).pipe(startWith(0)); // change ad every 10 minutes

  listedToAdIndex = combineLatest([
    this.adUpdateInterval$,
    this.initialAdIndex.notNull(),
    this.advertisementList$.notNull()
  ]).subscribe(([adInterval, index, adList]) => {
    const adCount = adList?.length ?? 0;
    const displayAdIndex = (adInterval + index) % adCount;
    this.currentAdvertisement$.next(adList[displayAdIndex]);
  }).addTo(this.subscriptions);

  listenToAds = this.advertisementList$.notNull().subscribe(adList => {
    const adCount = adList?.length ?? 0;
    const randomAdIndex = adCount === 0 ? null : Math.floor(Math.random() * adCount);
    this.initialAdIndex.next(randomAdIndex);
  }).addTo(this.subscriptions);

  constructor() {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  advertisementClicked() {
    const url = this.currentAdvertisement$.value?.advertisementUrl;
    if (!!url) {
      window.open(this.currentAdvertisement$.value?.advertisementUrl, '_blank');
    }
  }
}
