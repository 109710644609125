import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {VenueDetailsViewModel} from '../venue-details-view-model';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {VenueFormObject} from '../../../../../models/resources/venue-form-object';
import {VenueStream} from '../../../../../models/resources/venue-stream';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime} from 'rxjs/operators';
import {EditVenueStreamModalComponent} from '../../edit-venue-stream-modal/edit-venue-stream-modal.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {DeserializeHelper} from '../../../../../models/protocols/deserializable';
import {ToastService} from '../../../../../services/toast-service';

@Component({
  selector: 'app-venue-streams',
  templateUrl: './venue-streams.component.html',
  styleUrls: ['./venue-streams.component.scss'],
})
export class VenueStreamsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: VenueFormObject;
  private addStreamClicked = new EventEmitter();
  private editStreamClicked = new EventEmitter<VenueStream>();

  constructor(
    public viewModel: VenueDetailsViewModel,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super();
  }

  setupBindings() {
    this.setupFormStyling();

    this.viewModel.venue$
      .pipe(debounceTime(100))
      .subscribe(venue => {
        this.formObject = VenueFormObject.initWithVenue(venue);
        this.setupFormItems();
      }).addTo(this.subscriptions);

    this.addStreamClicked.subscribe(() => {
      this.addNewStream();
    }).addTo(this.subscriptions);

    this.editStreamClicked.subscribe((vs) => {
      this.editStream(vs);
    }).addTo(this.subscriptions);
  }

  setupFormBindings() {
  }

  setupViews() {
    this.PermissionManagerService.loadPermissionFactoryGetInstance();
    this.setupFormOptions();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const streamsTitle = new FormInputItem();
    streamsTitle.itemType = FormItemType.Title;
    streamsTitle.label = $localize`Streams`;
    streamsTitle.titleButtonText = $localize`Add a Stream`;
    streamsTitle.titleButtonClicked = this.addStreamClicked;
    streamsTitle.enabled = this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Streams_Add);
    streamsTitle.overrideFullWidth = true;
    items.push(streamsTitle);

    const streams = new FormInputItem();
    streams.inputName = 'streams';
    streams.itemType = FormItemType.List;
    streams.bindingProperty = 'venue.streams';
    streams.listItemButtonText = $localize`Edit`;
    streams.listItemClicked = this.editStreamClicked;
    streams.listItemClicked = this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Streams_Edit) ?
      this.editStreamClicked : null;
    streams.listItemButtonClass = this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Streams_Edit) ?
      '' : 'text-link disabled';
    items.push(streams);

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueFormObject) {
  }

  cancel() {
  }

  addNewStream() {
    const modalRef = this.modalService.open(
      EditVenueStreamModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    modalRef.result.then((venueStream: VenueStream) => {
      if (venueStream) {
        this.formObject.venue.streams.push(venueStream);
        this.saveVenueStream(venueStream);
      }
    }, reason => {
    });
  }

  editStream(venueStream: VenueStream) {
    const modalRef = this.modalService.open(
      EditVenueStreamModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    modalRef.componentInstance.venueStream = venueStream;
    modalRef.componentInstance.isEditing = true;
    modalRef.result.then((updatedVenueStream: VenueStream) => {
      if (updatedVenueStream) {
        const index = this.formObject.venue.streams.indexOf(venueStream);
        this.formObject.venue.streams[index] = updatedVenueStream;
        this.saveVenueStream(updatedVenueStream);
      }
    }, reason => {
    });
  }

  saveVenueStream(venueStream: VenueStream) {
    this.viewModel.saveVenueStream(venueStream).subscribe((updatedVenueStream) => {
      this.toastService.publishSuccessMessage($localize`Venue Stream Saved`, null);
      const index = this.formObject.venue.streams.indexOf(venueStream);
      let pageReload = false;
      if (this.formObject.venue.streams[index].cameraName === null ||
        this.formObject.venue.streams[index].cameraName === undefined ||
        this.formObject.venue.streams[index].cameraName === '') {
        pageReload = true;
      }
      this.formObject.venue.streams[index] = updatedVenueStream;
      if (pageReload) {
        location.reload();
      }
    }, error => {
        if(venueStream.id>0){
          this.toastService.publishError(error, $localize`Venue Stream Save Failed`);
        }
        else{
          this.toastService.publishError(error, $localize`Venue Stream Save Failed`);
        }
    });
  }
}
