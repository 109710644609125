import {Deserializable} from '../protocols/deserializable';

export class PricingOptions implements Deserializable {
  public id: string;
  public name?: string;
  public status?: number;
  public price?: number;
  public currencyCode: string;
  public period?: string;
  public periodUnit: string;
  public trialPeriod?: number;

  constructor(currencyCode: string,
              periodUnit: string,
              price: number | null,
              id: string,
              status: number,
              name: string) {
    this.currencyCode = currencyCode;
    this.periodUnit = periodUnit;
    this.price = price;
    this.id = id;
    this.status = status;
    this.name = name;
  }

  onDeserialize() {
  }
}

