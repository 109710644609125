export class ConfirmationOptions {
  // Text
  public title: string = '';
  public bodyText: string = '';
  // CTAs
  public cancelText: string = 'Cancel';
  public cancelButtonClass: string = 'neutral-button';
  public continueText: string = 'Do it';
  public continueButtonClass: string = 'destructive-button';
}
