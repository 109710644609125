import {Deserializable} from '../../protocols/deserializable';

export class CouponConstraintCriteria implements Deserializable {
    public itemType: string;
    public currencies: string[];
    public billingFrequencies: string[];

    onDeserialize() {
        this.currencies = Array.from(this.currencies || []);
        this.billingFrequencies = Array.from(this.billingFrequencies || []);
    }

}
