import {BaseLookupType} from '../base/base-lookup-type';
import {StateType} from './state-type';
import {DeserializeHelper} from '../protocols/deserializable';
import {Selectable} from '../protocols/selectable';

export class CountryType extends BaseLookupType implements Selectable{
  public countryCode: string;
  public name: string;
  public id: number;
  public states: StateType[];

  static getCanadaCountryCode(): number {
    return 1;
  }

  onDeserialize() {
    super.onDeserialize();
    this.states = DeserializeHelper.deserializeArray(StateType, this.states);
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): string {
    return this.name + this.id;
  }


  getSelectionValue(): any {
    return this.id;
  }
}

