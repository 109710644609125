export enum AdminRole {
  Level0 = 'AdminL0',
  Level1 = 'AdminL1',
  Level2 = 'AdminL2',
  Level3 = 'AdminL3',
}

export enum AdminSections {
  // modules/feature/functionality/action
  // Users section
  Users = 'users',
  Users_MainPage = 'users/main_page',
  Users_Search = 'users/search',
  Users_Invite_User = 'users/invite_user',
  Users_Edit_User = 'users/edit_user',
  Users_Edit_User_General_Info = 'users/edit_user/general_info',
  Users_Edit_User_Active = 'users/edit_user/active',
  // Sports section
  Sports = 'sports',
  Sports_MainPage = 'sports/main_page',
  Sports_Search = 'sports/search',
  Sports_Add_Sport = 'sports/add',
  Sports_Edit_Sport = 'sports/edit_sport/view_info',
  Sports_Edit_Sport_Edit_Info = 'sports/edit_sport/edit_info',
  Sports_Edit_Sport_Active = 'sports/edit_sport/active',
  // Leagues section
  Leagues = 'leagues',
  Leagues_MainPage = 'leagues/main_page',
  Leagues_Search = 'leagues/search',
  Leagues_Add_League = 'leagues/add',
  Leagues_Edit_League_Details_View = 'leagues/edit_league/details/view_info',
  Leagues_Edit_League_Details_Edit = 'leagues/edit_league/details/edit_info',
  Leagues_Edit_League_Details_Active = 'leagues/edit_league/details/active',
  Leagues_Edit_League_Teams_View = 'leagues/edit_league/teams/view',
  Leagues_Edit_League_Teams_Add_Team_To_League = 'leagues/edit_league/teams/add_team_to_league',
  Leagues_Edit_League_Teams_Add = 'leagues/edit_league/teams/add',
  Leagues_Edit_League_Teams_Edit_Team = 'leagues/edit_league/teams/edit_team',
  Leagues_Edit_League_Teams_Active = 'leagues/edit_league/teams/active',
  Leagues_Edit_League_Teams_Remove_Team = 'leagues/edit_league/teams/remove_team',
  Leagues_Edit_League_Venues_view = 'leagues/edit_league/venues_view',
  Leagues_Edit_League_Venues_add = 'leagues/edit_league/venues_add',
  Leagues_Edit_League_Venues_remove = 'leagues/edit_league/venues_remove',
  Leagues_Edit_League_Venues_Remove_Venue = 'leagues/edit_league/venues_remove_venue',
  Leagues_Edit_League_Seasons_View = 'leagues/edit_league/Seasons_view',
  Leagues_Edit_League_Seasons_Add = 'leagues/edit_league/Seasons_add',
  Leagues_Edit_League_Seasons_Edit = 'leagues/edit_league/Seasons_edit',
  Leagues_Edit_League_Seasons_Remove = 'leagues/edit_league/Seasons_remove',
  Leagues_Edit_League_Programs_View = 'leagues/edit_league/Programs_view',
  Leagues_Edit_League_Programs_Program_Uploader = 'leagues/edit_league/Programs/Program_Uploader',
  Leagues_Edit_League_Programs_Complete = 'leagues/edit_league/Programs/Complete',
  Leagues_Edit_League_Programs_Add = 'leagues/edit_league/Programs_add',
  Leagues_Edit_League_Programs_Edit = 'leagues/edit_league/Programs_edit',
  Leagues_Edit_League_Programs_Remove = 'leagues/edit_league/Programs_remove',
  Leagues_Edit_League_Shows_View = 'leagues/edit_league/Shows_view',
  Leagues_Edit_League_Shows_Add = 'leagues/edit_league/Shows_add',
  Leagues_Edit_League_Shows_Edit = 'leagues/edit_league/Shows_edit',
  Leagues_Edit_League_Shows_Remove = 'leagues/edit_league/Shows_remove',
  Leagues_Edit_League_Banner_Ads_View = 'leagues/edit_league/Banner_Ads_view',
  Leagues_Edit_League_Banner_Ads_Add = 'leagues/edit_league/Banner_Ads_add',
  Leagues_Edit_League_Banner_Ads_Edit = 'leagues/edit_league/Banner_Ads_edit',
  Leagues_Edit_League_Banner_Ads_Remove = 'leagues/edit_league/Banner_Ads_remove',
  // Venues section
  Venues = 'venues',
  Venues_MainPage = 'venues/main_page',
  Venues_Search = 'venues/search',
  Venues_Add_Venue = 'venues/add',
  Venues_Edit_Venue_Details_View = 'venues/edit_venue/details/view_info',
  Venues_Edit_Venue_Details_Edit_Info = 'venues/edit_venue/details/edit_info',
  Venues_Edit_Venue_Details_Active = 'venues/edit_venue/details/active',
  Venues_Edit_Venue_Streams_View = 'venues/edit_venue/streams/view',
  Venues_Edit_Venue_Streams_Add = 'venues/edit_venue/streams/add',
  Venues_Edit_Venue_Streams_Edit = 'venues/edit_venue/streams/edit',
  Venues_Edit_Venue_Streams_Active = 'venues/edit_venue/streams/active',
  Venues_Edit_Venue_Teams_View = 'venues/edit_venue/teams/view',
  Venues_Edit_Venue_Teams_Add_Team_To_Venue = 'venues/edit_venue/teams/add_team_to_venue',
  Venues_Edit_Venue_Teams_Add = 'venues/edit_venue/teams/add',
  Venues_Edit_Venue_Teams_Edit_Team = 'venues/edit_venue/teams/edit_team',
  Venues_Edit_Venue_Teams_Remove = 'venues/edit_venue/teams/remove',
  Venues_Edit_Venue_Programs_View = 'venues/edit_venue/programs/view',
  Venues_Edit_Venue_Programs_Program_Uploader = 'venues/edit_venue/programs/program_uploader',
  Venues_Edit_Venue_Programs_Complete = 'venues/edit_venue/programs/complete',
  Venues_Edit_Venue_Programs_Add = 'venues/edit_venue/programs/add',
  Venues_Edit_Venue_Programs_Edit = 'venues/edit_venue/programs/edit',
  Venues_Edit_Venue_Programs_Remove = 'venues/edit_venue/programs/remove',
  // Events section
  Events = 'events',
  Events_MainPage = 'events/main_page',
  Events_Search = 'events/search',
  Events_Add_Event = 'events/add',
  Events_Edit_Event_Details_View = 'events/edit_event/details/view',
  Events_Edit_Event_Details_Edit = 'events/edit_event/details/edit',
  Events_Edit_Event_Details_Active = 'events/edit_event/details/active',
  Events_Edit_Event_Streams_View = 'events/edit_event/streams/view',
  Events_Edit_Event_Streams_Add = 'events/edit_event/streams/add',
  Events_Edit_Event_Streams_Edit = 'events/edit_event/streams/edit',
  Events_Edit_Event_Streams_Active = 'events/edit_event/streams/active',
  Events_Edit_Event_Teams_View = 'events/edit_event/teams/view',
  Events_Edit_Event_Teams_Add_Team_To_Event = 'events/edit_event/teams/add_team_to_event',
  Events_Edit_Event_Teams_Add = 'events/edit_event/teams/add',
  Events_Edit_Event_Teams_Edit_Team = 'events/edit_event/teams/edit_team',
  Events_Edit_Event_Teams_Remove = 'events/edit_event/teams/remove',
  Events_Edit_Event_Venues_View = 'events/edit_event/venues/view',
  Events_Edit_Event_Venues_Add = 'events/edit_event/venues/add',
  Events_Edit_Event_Venues_Remove = 'events/edit_event/venues/remove',
  Events_Edit_Event_Programs_View = 'events/edit_event/programs/view',
  Events_Edit_Event_Programs_Program_Uploader = 'events/edit_event/programs/program_uploader',
  Events_Edit_Event_Programs_Complete = 'events/edit_event/programs/complete',
  Events_Edit_Event_Programs_Add = 'events/edit_event/programs/add',
  Events_Edit_Event_Programs_Edit = 'events/edit_event/programs/edit',
  Events_Edit_Event_Programs_Remove = 'events/edit_event/programs/remove',
  Events_Edit_Event_Shows_View = 'events/edit_event/shows/view',
  Events_Edit_Event_Shows_Add = 'events/edit_event/shows/add',
  Events_Edit_Event_Shows_Edit = 'events/edit_event/shows/edit',
  Events_Edit_Event_Shows_Remove = 'events/edit_event/shows/remove',
  Events_Edit_Event_Banner_Ads_View = 'events/edit_event/banner_ads/view',
  Events_Edit_Event_Banner_Ads_Add = 'events/edit_event/banner_ads/add',
  Events_Edit_Event_Banner_Ads_Edit = 'events/edit_event/banner_ads/edit',
  Events_Edit_Event_Banner_Ads_Remove = 'events/edit_event/banner_ads/remove',
  // Teams section
  Teams = 'teams',
  Teams_MainPage = 'teams/main_page',
  Teams_Search = 'teams/search',
  Teams_Add_Team = 'teams/add',
  Teams_Edit_Team_Details_View = 'teams/edit_team/details/view_info',
  Teams_Edit_Team_Details_Edit = 'teams/edit_team/details/edit_info',
  Teams_Edit_Team_Details_Active = 'teams/edit_team/details/active',
  // Plans section
  Plans = 'plans',
  Plans_MainPage = 'plans/main_page',
  Plans_Search = 'plans/search',
  Plans_Add_Plan = 'plans/add',
  Plans_Edit_Plan_View = 'plans/edit_plan/view',
  Plans_Edit_Plan_Edit = 'plans/edit_plan/edit',
  Plans_Edit_Plan_Active = 'plans/edit_plan/active',
  Plans_Edit_Plan_Plan_Price_View = 'plans/edit_plan/plan_price/view',
  Plans_Edit_Plan_Plan_Price_Edit = 'plans/edit_plan/plan_price/edit',
  Plans_Edit_Plan_Plan_Price_Active = 'plans/edit_plan/plan_price/active',
  // Camera Systems section
  Camera_Systems = 'camera_systems',
  Camera_Systems_MainPage = 'camera_systems/main_page',
  Camera_Systems_Search = 'camera_systems/search',
  Camera_Systems_Add_Camera_System = 'camera_systems/add',
  Camera_Systems_Edit_Camera_System_View = 'camera_systems/edit_camera_system/view',
  Camera_Systems_Edit_Camera_System_Edit = 'camera_systems/edit_camera_system/edit',
  Camera_Systems_Edit_Camera_System_Active = 'camera_systems/edit_camera_system/active',
  // Advertisement section
  Advertisement = 'advertisement',
  Advertisement_MainPage = 'advertisement/main_page',
  Advertisement_Search_Partners = 'advertisement/search_partners',
  Advertisement_Add_Partner = 'advertisement/add_partner',
  Advertisement_View_Advertiser_Table = 'advertisement/View_Advertiser_Table',
  Advertisement_Edit_Advertiser_View = 'advertisement/Edit_Advertiser/view',
  Advertisement_Edit_Advertiser_Edit = 'advertisement/Edit_Advertiser/edit',
  Advertisement_Edit_Advertiser_Active = 'advertisement/Edit_Advertiser/active',
  Advertisement_Add_Advertisement = 'advertisement/add_advertisement',
  Advertisement_Edit_Advertisement_View = 'advertisement/Edit_Advertisement/view',
  Advertisement_Edit_Advertisement_Edit = 'advertisement/Edit_Advertisement/edit',
  Advertisement_Edit_Advertisement_Remove_Media = 'advertisement/Edit_Advertisement/remove_media',
  Advertisement_Edit_Advertisement_Remove_Image = 'advertisement/Edit_Advertisement/remove_image',
  Advertisement_Edit_Advertisement_Active = 'advertisement/Edit_Advertisement/active',
  // Profile section
  Profile_View_And_Edit = 'profile/view_and_edit',
  // Sign Out section
  Sign_Out_Individual_Account = 'sign_out_individual_account',
}
