<app-detail-title i18n>
  Shows
</app-detail-title>
<app-content-container [narrow]="true">
  <app-detail-back-button class="d-block mt-4" i18n>Show Details</app-detail-back-button>
  <div class="position-relative min-height-20">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <div class="mt-4">
      <app-form-group
        *ngIf="formItems?.length > 0"
        [formItems]="formItems"
        [formObject]="formObject"
        [styling]="formStyling"
        [options]="formOptions"
        [hydrateInputObject]="hydrateInputObject"
        (formCancelled)="cancel()"
        (formSubmitted)= formSubmitted($event) >
        <div class="w-100">
          <div class="label-md mb-4" i18n>Video</div>
          <app-upload-asset
            *ngIf="!formObject.videoToUpload && !formObject.existingVideo"
            [allowTypes]="['video']"
            [maxAssets]="1"
            [id]="videoUploadId"
            [parentHandler]="this">
          </app-upload-asset>
          <div *ngIf="formObject.videoToUpload || formObject.existingVideo">
            <div class="video-upload-container">
              <img src="/assets/icons/dark/outline/film.svg"
                   class="video-upload-icon"
                   alt="file-upload">
              <div class="flex-grow-1">
                <div *ngIf="formObject.existingVideo">
                  {{formObject.existingVideo.fileName}}
                </div>
                <div *ngIf="!formObject.existingVideo">
                  <div>{{formObject.videoToUpload.name}}</div>
                </div>
              </div>
              <div class="upload-status-container">
                <div class="color-mid-grey" i18n>Upload Status</div>
                <div class="upload-status"
                     [class.success]="formObject.existingVideo?.uploadStatusId === Types.UploadStatusType.Completed"
                     [class.in-progress]="formObject.existingVideo?.uploadStatusId === Types.UploadStatusType.InProgress">
                  <ng-container *ngIf="formObject.existingVideo">{{formObject.existingVideo.uploadStatus}}</ng-container>
                  <ng-container *ngIf="!formObject.existingVideo" i18n>Ready To Upload</ng-container>
                </div>
              </div>
            </div>
           <div *ngIf="PermissionManagerService.isGranted(Types.AdminSections.Leagues_Edit_League_Shows_Remove ||  Types.AdminSections.Events_Edit_Event_Shows_Remove)">
            <div class="text-link-button remove-upload-button" (click)="removeVideoClicked()" i18n>Remove Video</div>
           </div>
          </div>
          <hr>
          <div class="label-md mb-4" i18n>Image</div>
          <app-upload-asset
            *ngIf="!formObject.imageToUpload && !formObject.existingImageId"
            [allowTypes]="['image']"
            [maxAssets]="1"
            [id]="imageUploadId"
            [parentHandler]="this">
          </app-upload-asset>
          <div class="program-image-container" *ngIf="formObject.imageToUpload || formObject.existingImageId">
            <div class="program-image">
              <img *ngIf="formObject.existingImageId" [src]="(formObject.show.imgSrc$|async)" alt="">
              <img *ngIf="!formObject.existingImageId" [src]="formObject.imageToUpload" alt="">
            </div>
            <div class="text-link-button my-4" (click)="removeImageClicked()" i18n>Remove Image</div>
          </div>
        </div>
      </app-form-group>
    </div>
  </div>
</app-content-container>
