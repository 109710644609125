import {Injectable} from '@angular/core';
import {Cachable} from '../models/protocols/cachable';
import {DeserializeHelper} from '../models/protocols/deserializable';
import {DateUtils} from '../utils/date-utils';
import {DomSanitizer} from '@angular/platform-browser';
import {StringifyUtils} from '../utils/stringify-utils';
import {GenericCacheItem} from '../models/shared/generic-cache-item';
import {SessionContainer} from '../models/shared/session-container';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {CachePolicy} from '../models/enum/shared/cachable-image-policy.enum';
import {ImageApi} from '../api/image-api';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private persistent = localStorage;
  private session = sessionStorage;
  private service = new Map<string, string>();

  constructor(
    private sanitizer: DomSanitizer,
    private imageAPI: ImageApi,
  ) {
  }

  isPersistentEnabled(): boolean {
    return this.persistent === localStorage;
  }

  clearSessionCache() {
    this.session.clear();
  }

  clearPersistentCache() {
    this.persistent.clear();
  }

  // Getters

  public getCachedGeneric<T extends string | number>(key: string, persistentCache: boolean = false): T {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (result) {
      const resp = DeserializeHelper.deserializeToInstance(GenericCacheItem, JSON.parse(result));
      if (resp.isExpired()) {
        // Expired, clear cache
        const imagePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
        this.removeCachedObject(key, imagePolicy);
        return null;
      } else {
        return resp.item as T;
      }
    } else {
      return null;
    }
  }

  public getCachedObject<T extends Cachable>(respObjectType: new () => T, key: string, persistentCache: boolean = false): T {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (result) {
      try {
        const resp = DeserializeHelper.deserializeToInstance(respObjectType, JSON.parse(result));
        const imagePolicy = resp.imageCachePolicy() || CachePolicy.Session;
        // Pull any images from image cache
        if (resp.isExpired()) {
          this.removeCachedObject(key);
          return null;
        } else {
          return resp;
        }
      } catch (e) {
        console.log(`Unable to parse cached object '${key}' to JSON.`);
        return null;
      }
    } else {
      return null;
    }
  }

  public getCachedArray<T extends Cachable>(respObjectType: new () => T, key: string, persistentCache: boolean = false): T[] {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (result) {
      const resp = DeserializeHelper.deserializeArray(respObjectType, JSON.parse(result));
      const imagePolicy = (resp.length > 0 ? resp[0].imageCachePolicy() : CachePolicy.Session) || CachePolicy.Session;
      const containsExpiredObject = resp.filter(o => o.isExpired()).length > 0;
      if (containsExpiredObject) {
        // Expired, clear cache
        this.removeCachedObject(key, imagePolicy);
        return null;
      } else {
        return resp;
      }
    } else {
      return null;
    }
  }


  public cacheGeneric<T extends string | number>(key: string, object: T, persistentCache: boolean = false) {
    const gc = new GenericCacheItem();
    gc.key = key;
    gc.item = object;
    this.setCacheItem(key, gc, persistentCache);
  }

  public cacheObject<T extends Cachable>(key: string, object: T, persistentCache: boolean = false) {
    this.setCacheItem(key, object, persistentCache);
  }

  public removeCachedObject(key: string, cachePolicy: CachePolicy = CachePolicy.Session) {
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        this.persistent.removeItem(key);
        break;
      case CachePolicy.Session:
        this.session.removeItem(key);
        break;
      case CachePolicy.Service:
        this.service.delete(key);
    }
  }

  // Methods to read / write to cache
  private setCacheItem<T extends Cachable>(key: string, object: T, persistentCache: boolean = false) {
    object.cachedTime = DateUtils.currentTimestamp();
    const objString = JSON.stringify(object, StringifyUtils.replacer);
    try {
      if (persistentCache) {
        this.persistent.setItem(key, objString);
      } else {
        this.session.setItem(key, objString);
      }
    } catch (e) {
      this.clearFullCache(persistentCache);
    }
  }

  private setCacheItems<T extends Cachable>(key: string, object: T[], persistentCache: boolean = false) {
    const currTime = DateUtils.currentTimestamp();
    object.map(o => o.cachedTime = currTime);
    try {
      if (persistentCache) {
        this.persistent.setItem(key, JSON.stringify(object, StringifyUtils.replacer));
      } else {
        this.session.setItem(key, JSON.stringify(object, StringifyUtils.replacer));
      }
    } catch (e) {
      this.clearFullCache(persistentCache);
    }
  }

  private getCacheItemString(key: string, cachePolicy: CachePolicy = CachePolicy.Session): string {
    let result: string;
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        result = this.persistent.getItem(key);
        break;
      case CachePolicy.Session:
        result = this.session.getItem(key);
        break;
      case CachePolicy.Service:
        result = this.service.get(key);
        break;
    }
    return result;
  }

  private clearFullCache(persistentCache: boolean) {
    console.log(`${persistentCache ? 'Persistent cache' : 'Session cache'} is full. Performing clear cache.`);
    // Get cached session contain, as it should always be cached
    const sessKey = DefaultCacheKey.SessionContainer;
    const cachedSessContainer = this.getCachedObject<SessionContainer>(SessionContainer, sessKey, persistentCache);
    if (persistentCache) {
      this.clearPersistentCache();
    } else {
      this.clearSessionCache();
    }
    this.setCacheItem(sessKey, cachedSessContainer, persistentCache);
  }

}
