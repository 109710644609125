import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {BaseComponent} from '../../../../models/base/base-component';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: [
    './tab-bar.component.scss',
  ]
})
export class TabBarComponent extends BaseComponent
  implements OnInit, AfterViewInit {

  @Input() public tabs: TabBarItem[] = [];
  @Input() public dynamicHeight: boolean = true;
  public selectedTab: number = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedTab = this.tabs.findIndex(tab => tab.active);
    this.setupViews();

  }

  ngAfterViewInit(): void {
    this.setupBindings();

    this.activatedRoute.fragment.firstNotNull().subscribe(fragment => {
      if (fragment) {
        this.selectedTab = this.tabs.findIndex(t => t.title.toLowerCase() === fragment);
      }
      if (this.selectedTab) {
        this.tabSelected(this.selectedTab);
      }
    });
  }


  tabSelected(index: number): void {
    this.selectedTab = index;
    this.tabs.map(t => t.active = false);
    if (this.tabs.length > this.selectedTab - 1) {
      this.tabs[this.selectedTab].active = true;
      this.appendFragmentToUrl(this.tabs[this.selectedTab]);
    }
    this.cdr.detectChanges();
  }

  setupBindings() {
  }

  setupViews() {
  }

  private appendFragmentToUrl(tab: TabBarItem) {
    const frag = tab.title.toLowerCase();
    const navigationExtras: NavigationExtras = {fragment: frag, relativeTo: this.activatedRoute, replaceUrl: true};
    this.router.navigate(['./'], navigationExtras).then(_ => {
    });
  }
}
