<app-content-container
  *ngIf="(viewModel.isSignedIn$|async)"
  [unsetMinHeight]="true">
  <mat-tab-group class="mt-4"
                 dynamicHeight animationDuration="0ms"
                 (selectedIndexChange)="tabSelected($event)">
    <mat-tab *ngFor="let tab of viewModel.leagueTabs$|async">
      <ng-template mat-tab-label>
        <ng-container>{{tab}}</ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-content-container>
<div   *ngIf="(viewModel.isSignedIn$|async)
&& (viewModel.isSubscribedToLeague$|async)
&& ((viewModel.programContentQuery$|async)
    || (viewModel.programId$|async))">
      <app-program-player
      [programQuery]="viewModel.programContentQuery$|async"
      [programId]="viewModel.programId$|async"
      [isShow]="viewModel.isShow$|async"
      [showBottomDivider]="true"
      [smallVideoPlayer]="true">
    </app-program-player>

</div>
<div  *ngIf="!(viewModel.isSignedIn$|async)">
    <!--Not logged in before featured leagues-->
  <div id="leaderboard_atf_leagues"></div>
  <app-featured-leagues-row
  [hideBottomDivider]="true">
</app-featured-leagues-row>
</div>
<app-sign-up-banner [leagueLandingScreen]="true">
</app-sign-up-banner>
<app-no-content *ngIf="viewModel.showNoResults$|async" [type]="noContentType.League"></app-no-content>
<app-league-subscribe-banner
  [hydratedLeague]="viewModel.selectedHydratedLeague$|async">
</app-league-subscribe-banner>
<div  *ngIf="(viewModel.isSignedIn$|async)" >
  <div id="leaderboard_atf_leagues_members"></div>
</div>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.liveProgramsNoResults.next($event)"
   (cardCountStatus)="onCardCountStatus($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)"
  (cardCountStatus)="onCardCountStatus($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Past"
  [seeAllGamesClicked]="seeAllPastGames"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.pastProgramsNoResults.next($event)"
  (cardCountStatus)="onCardCountStatus($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Shows"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.showsNoResults.next($event)"
  (cardCountStatus)="onCardCountStatus($event)">
</app-program-row>
