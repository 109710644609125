import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../models/base/base-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';
import {ResourceDomainModel} from '../../domainModels/resource-domain-model';
import {ProgramDomainModel} from '../../domainModels/program-domain-model';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {HydratedTeam} from '../../models/resources/hydrated-team';
import {AccountDomainModel} from '../../domainModels/account-domain-model';
import {Favourites} from '../../models/resources/favourites';
import {FavouriteTeams} from '../../models/resources/favourite-teams';
import {FavouriteLeagues} from '../../models/resources/favourite-leagues';
import {ToastService} from '../../services/toast-service';
import {LoadingOptions} from '../../models/shared/loading-options';
import {indicate} from '../../utils/observable.extensions';
import {ContentQuery, ProgramQueryType} from '../../models/program/content-query';
import {SafeResourceUrl} from '@angular/platform-browser';
import {MediaUtils} from '../../utils/media-utils';
import {SessionService} from '../../services/session-service';
import {OpenAuthModalOptions} from '../../models/account/open-auth-modal-options';
import {AuthFlow} from '../../models/account/enum/auth-flow.enum';
// import {FavouriteEvents} from '../../models/resources/favourite-events';

@Injectable()
export class TeamViewModel extends BaseViewModel {

  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
  teamId$ = this.activatedRoute.params.pipe(map(p => Number(p.teamId)));
  team$: BehaviorSubject<HydratedTeam> = new BehaviorSubject<HydratedTeam>(null);
  isTeamFavourite$ = new BehaviorSubject<boolean>(false);

  teamLoadingOpts: LoadingOptions = LoadingOptions.defaultTransparent(false, false);

  fetchHydratedTeam = this.teamId$.pipe(switchMap(id => {
    return this.resourceDomainModel.getHydratedTeam(id).pipe(indicate(this.teamLoadingOpts, ''));
  })).subscribe(this.team$).addTo(this.subscriptions);

  programContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  private fetchProgramContentQuery = this.teamId$.notNull().subscribe(teamId => {
    this.programContentQuery$.next(new ContentQuery(ProgramQueryType.Default, null, [teamId]));
  }).addTo(this.subscriptions);

  fetchTeamIsFavourite = combineLatest([this.accountDomainModel.sessionContainer$, this.teamId$]).pipe(
    filter(x => x.every(y => !!y)),
    map(([sessionContainer, teamId]) => sessionContainer.user.favouriteTeams.some(ft => String(ft.teamId) === String(teamId)))
  ).subscribe(this.isTeamFavourite$).addTo(this.subscriptions);

  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  showNoResults$ = combineLatest([
    this.liveProgramsNoResults,
    this.upcomingProgramsNoResults,
    this.pastProgramsNoResults,
  ]).pipe(
    map(noResults => noResults.every(x => !!x))
  );

  bannerImgSrc$: Observable<string | SafeResourceUrl> = this.team$.notNull().pipe(switchMap(team => {
    if (team?.bannerImages?.length > 0) {
      return team.bannerImgSrc$;
    }
    return of(MediaUtils.getRandomBannerImageSrc());
  }));

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private resourceDomainModel: ResourceDomainModel,
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private sessionService: SessionService,
    private toastService: ToastService
  ) {
    super();
    this.init();
  }

  init() {
    this.setupBindings();
    super.init();
  }

  setupBindings() {
  }

  toggleFavouriteTeam() {
    if (!this.accountDomainModel.sessionContainer$.getValue()) {
      const options = new OpenAuthModalOptions(AuthFlow.SignUp,
        this.router.url, $localize`Sign in to continue`,
        $localize`Sign up to continue`);
      this.sessionService.showAuthModal$.next(options);
      return;
    }

    const userFavTeams = this.accountDomainModel.session.getUser().favouriteTeams;
    const userFavLeagues = this.accountDomainModel.session.getUser().favouriteLeagues;
    const newFavourites = new Favourites(new FavouriteTeams(userFavTeams),
      new FavouriteLeagues(userFavLeagues));
    newFavourites.toggleFavouriteResource(this.team$.value);
    this.accountDomainModel.updateSubscriberFavourites(newFavourites).subscribe(_ => {
      this.toastService.publishSuccessMessage($localize`Your favourites have been updated`, null);
    }, err => {
      this.toastService.publishError(err);
    });
  }
}
