import {SearchListable} from '../../../../models/protocols/search-listable';
import Fuse from 'fuse.js';

export class SearchListItemContainer {
  searchListableValue: SearchListable;
  primarySearchKeyValue: string;
  secondarySearchKeyValue: string;

  constructor(searchListableValue: SearchListable) {
    this.searchListableValue = searchListableValue;
    this.primarySearchKeyValue = searchListableValue.getSearchListablePrimaryKey();
    this.secondarySearchKeyValue = searchListableValue.getSearchListableSecondaryKey();
  }

  static generateFuse(searchListItems: SearchListable[]): Fuse<SearchListItemContainer> {
    const options = {
      isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      ignoreLocation: true,
      threshold: 0.4,
      minMatchCharLength: 2,
      fieldNormWeight: 1,
      keys: [
        {
          name: 'primarySearchKeyValue',
          weight: 2
        },
        {
          name: 'secondarySearchKeyValue',
          weight: 1
        },
      ]
    };

    const searchListContainers = searchListItems.map(s => new SearchListItemContainer(s));
    return new Fuse(searchListContainers, options);
  }
}
