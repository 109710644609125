import {SafeResourceUrl} from '@angular/platform-browser';
import {Image} from '../image/dto/image';
import {Observable} from 'rxjs';

export class CardImageFormObject {
  imageToUpload: string | SafeResourceUrl;
  imageToUploadFormat: string;
  existingImageId: string;
  deleteImageId: string;

  cardImgSrc$: Observable<string | SafeResourceUrl>;

  constructor(cardImage?: Image, imgSrc$?: Observable<string | SafeResourceUrl>) {
    if (!!cardImage) {
      this.existingImageId = cardImage.id;
    }
    if (!!imgSrc$) {
      this.cardImgSrc$ = imgSrc$;
    }
  }
}
