import {Injectable} from '@angular/core';
import { BaseViewModel } from 'src/app/models/base/base-view-model';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {SafeResourceUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from '../../../services/session-service';
import {AuthFlow} from '../../../models/account/enum/auth-flow.enum';
import {OpenAuthModalOptions} from '../../../models/account/open-auth-modal-options';
import {Router} from '@angular/router';

@Injectable()
export class TopNavViewModel extends BaseViewModel {

  sessionContainer$ = this.accountDomainModel.sessionContainer$;
  isSignedIn$ = this.sessionContainer$.pipe(map(s => !!s));
  userImageSrc$: Observable<string | SafeResourceUrl> = this.accountDomainModel.getUserImageSrC();

  constructor(
    private accountDomainModel: AccountDomainModel,
    private sessionService: SessionService,
    private router: Router
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  signInClicked() {
    const options = new OpenAuthModalOptions(AuthFlow.SignIn, this.router.url);
    this.sessionService.showAuthModal$.next(options);
  }
}
