import {Viewable} from '../protocols/viewable';
import {Subscribable} from './subscribable';
import {TypeUtils} from '../../utils/type-utils';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PermissionManagerService} from '../../services/admin-permission/admin-permission-service';

@Component({
  template: ''
})
export abstract class BaseComponent extends Subscribable implements OnInit, AfterViewInit, Viewable {
  Types = TypeUtils;
  PermissionManagerService = PermissionManagerService;
  abstract setupViews();
  abstract setupBindings();
  ngOnInit(): void {
    this.PermissionManagerService.loadPermissionFactoryGetInstance();
    this.setupViews();
  }
  ngAfterViewInit(): void {
    this.setupBindings();
  }

  scrollElementWIthIdIntoView(elementId: string, position: ScrollLogicalPosition = 'nearest') {
    setTimeout(() => {
      document.getElementById(elementId)?.scrollIntoView({behavior: 'smooth', block: position, inline: position});
    });
  }

  scrollToVideoPlayer() {
    this.scrollElementWIthIdIntoView('video-player', 'end');
  }
}
