import {Component} from '@angular/core';
import {OtherVenueStreamCard, WatchVenueStreamViewModel} from './watch-venue-stream-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ProgramRowType} from '../program-row/program-row.component';
import {WatchViewModel} from '../watch/watch-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NoContentType} from '../no-content/no-content.component';
import {Cardable} from '../../../../models/protocols/cardable';
import {CardRowStyle} from '../../../shared/components/card-row/card-row.component';

@Component({
  selector: 'app-watch-venue-stream',
  templateUrl: './watch-venue-stream.component.html',
  styleUrls: ['./watch-venue-stream.component.scss'],
  providers: [WatchVenueStreamViewModel],
})
export class WatchVenueStreamComponent extends BaseComponent {

  programRowType = ProgramRowType;
  noContentType = NoContentType;
  cardRowStyle = CardRowStyle;

  constructor(
    public viewModel: WatchVenueStreamViewModel,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    super();
  }

  setupViews() {

  }

  setupBindings() {
  }

  streamCardClicked(card: Cardable) {
    const venueStreamCard = card as OtherVenueStreamCard;
    this.router.navigate([`/home/venues/stream/${venueStreamCard.venueStream?.id}`]).then();
  }
}
