import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {filter, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';
import {ActivatedRoute, Router} from '@angular/router';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {SessionService} from '../../../../services/session-service';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class SubscriberLeagueDetailsViewModel extends BaseViewModel {

  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));

  leagueId$ = new BehaviorSubject<number>(null);
  leagueAbbreviationParam$ = new BehaviorSubject<string>(null);

  hydratedLeague$ = new BehaviorSubject<HydratedLeague>(null);
  fetchLeagueId = this.activatedRoute.params.subscribe(params => {
    if (!!params.leagueId) {
      this.leagueId$.next(params.leagueId);
    } else if (!!params.leagueAbbreviation) {
      this.leagueAbbreviationParam$.next(params.leagueAbbreviation);
    }
  }).addTo(this.subscriptions);

  private fetchLeagueFromId = this.leagueId$.pipe(
    filter(lId => !!lId),
    switchMap(lId => this.resourceDomainModel.getHydratedLeague(lId)))
    .subscribe(this.hydratedLeague$);

  private fetchLeagueIdFromAbbreviation = this.leagueAbbreviationParam$.pipe(
    filter(abbreviation => !!abbreviation),
    switchMap(abbreviation => this.resourceDomainModel.allHydratedLeagues$.pipe(
      map(leagues => leagues.find(l => l.abbreviation?.toLowerCase() === abbreviation?.toLowerCase())?.id),
      tap(lId => {
        if (!lId) {
          // invalid league abbreviation, navigate to home screen
          this.router.navigate(['']);
        }
      }),
    )),
  ).subscribe(this.leagueId$);

  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  showsNoResults = new BehaviorSubject<boolean>(false);
  showNoResults$ = combineLatest([
    this.liveProgramsNoResults,
    this.upcomingProgramsNoResults,
    this.pastProgramsNoResults,
    this.showsNoResults
  ]).pipe(map(noResults => noResults.every(x => !!x)));

  contentQuery$ = this.leagueId$.notNull().pipe(map(leagueId => {
    return new ContentQuery(ProgramQueryType.Leagues, !!leagueId ? [leagueId] : null);
  }));

  programContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  private fetchSingleProgramContentQuery = this.leagueId$
    .subscribe(leagueId => {
      this.programContentQuery$.next(new ContentQuery(ProgramQueryType.Leagues, [leagueId]));
    }).addTo(this.subscriptions);

  isFreeLeague$ = combineLatest([this.hydratedLeague$]).pipe(
    filter(params => params.every(p => !!p)),
    map(([league]) => {
      return league.subscriptionPlanId === environment.freePlanId;
    }));

  isSubscribedToLeague$ = combineLatest([this.hydratedLeague$, this.accountDomainModel.subscriberSubscriptions$]).pipe(
    filter(params => params.every(p => !!p)),
    map(([league, subscriptions]) => {
      const leaguePlanId = league.subscriptionPlanId;
      return leaguePlanId === environment.freePlanId || subscriptions?.some(s => s.planId === leaguePlanId);
    })
  );

  hydratedLeagueLogo$ = this.hydratedLeague$.notNull().pipe(switchMap(l => l.imgSrc$));
  programId$ = new BehaviorSubject<string>(null);
  isShow$ = new BehaviorSubject<boolean>(null);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
