<div class="card-table-wrapper">

  <!-- Filter Options -->
  <div class="card-table-filters">

    <div class="row">
      <div class="col-sm-12">

        <!-- Search Input -->
        <div *ngIf="tableOptions.enableSearch"
             class="col-sm-12 col-md-6 no-padding table-filter-form">
          <form class="custom-textfield-form pl-0">
            <label class="custom-textfield-label" for="searchQueryString">
              {{ tableOptions.searchLabelText }}
            </label>
            <input (ngModelChange)="onQueryStringChange($event)" [(ngModel)]="searchQueryString"
                   class="custom-textfield search-textfield" id="searchQueryString"
                   name="searchQueryString"
                   placeholder="{{ tableOptions.searchPlaceholderText }}"
                   type="text">
            <button (click)="clearSearch()" class="clear-search" type="reset">
              CLEAR
            </button>
            <span class="custom-textfield-error"></span>
          </form>
        </div>

        <!--   Checkbox Filter     -->
        <div *ngIf="tableOptions.checkBox"
             class="col-sm-6 col-md-3 table-filter-form">
          <app-checkbox [checkbox]="tableOptions.checkBox"
                        (clickEvent)="filterChanged()">
          </app-checkbox>
        </div>

      </div>
    </div>
  </div>

  <!-- Table Results -->
  <div class="row">
    <div class="col-sm-12 mt-16px">

      <!--  CARDS    -->
      <ng-container *ngFor="let card of displayedData; let cardIndex = index;">
        <!-- Cards  -->
        <app-card
          (click)="cardPressed(card)"
          [cardClass]="getCardClass(cardIndex)"
          [card]="card">
        </app-card>
      </ng-container>

      <div [hidden]="displayedData.length > 0 || tableData.length === 0" class="no-results-container">
        <div class="no-results-title">
          {{ getNoResultsTitle() }}
        </div>
        <div class="no-results-body">
          {{ getNoResultsBody() }}
        </div>
      </div>

      <!--   Footer   -->
      <div [hidden]="tableOptions.hideFooter" class="row mt-3">
        <!--   Results Position   -->
        <div class="col-sm-12 col-md-5">
          <div class="position-text">
            Showing
            <span class="position-text-bold">{{beginAmount() + 1}}</span>
            to
            <span
              class="position-text-bold">{{ endAmount() < filteredData.length ? endAmount() : filteredData.length}}</span>
            of
            <span class="position-text-bold">{{filteredData.length}}</span>
            results
          </div>
        </div>
        <!--   Pagination   -->
        <div class="col-sm-12 col-md-7">
          <div class="">
            <ul class="pagination dt_pagination">

              <li [class.disabled]="previousDisabled()" class="paginate_button page-item previous">
                <a (click)="previousClicked()" class="page-link" tabindex="0">
                  <img alt="" class="pagination-arrow" src="../../../../../assets/icons/dark/outline/arrow-left.svg"/>
                </a>
              </li>

              <li *ngFor="let page of this.pages()" [class.active]="isActivePage(page)"
                  class="paginate_button page-item page-item-number">
                <a (click)="pageClicked(page)" class="page-link">
                  {{ page }}
                </a>
              </li>

              <li [class.disabled]="nextDisabled()" class="paginate_button page-item next">
                <a (click)="nextClicked()" class="page-link" data-dt-idx="5"
                   tabindex="0">
                  <img alt="" class="pagination-arrow" src="../../../../../assets/icons/dark/outline/arrow-right.svg"/>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
