import {Component} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {CreateSportTypeRequest} from '../../../../models/lookup/requests/create-sport-type-request';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SportType} from '../../../../models/lookup/sport-type';
import {SportsViewModel} from '../sports-view-model';
import {PermissionManagerService} from '../../../../services/admin-permission/admin-permission-service';
import {AdminSections} from '../../../../models/account/enum/admin-permission.enum';

@Component({
  selector: 'app-add-new-sport-modal',
  templateUrl: './add-new-sport-modal.component.html',
  styleUrls: ['./add-new-sport-modal.component.scss']
})
export class AddNewSportModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: CreateSportTypeRequest = new CreateSportTypeRequest();
  sport: SportType = null;
  sports: SportType[] = [];
  pixellotProductionTypes: string[] = [];
  isEditing: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private viewModel: SportsViewModel,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.pixellotProductionTypes$.notNull().subscribe(s => {
      this.setupFormItems();
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    if (this.sport) {
      this.formObject.name = this.sport.name;
      this.formObject.active = this.sport.active;
      this.formObject.pixellotEnumValue = this.sport.pixellotEnumValue;
    }
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Sport Name`;
    name.bindingProperty = 'name';
    name.required = true;
    items.push(name);

    const sportType = new FormInputItem();
    sportType.inputName = 'pixellotEnumValue';
    sportType.itemType = FormItemType.Dropdown;
    sportType.label = $localize `Calibration Type`;
    sportType.bindingProperty = 'pixellotEnumValue';
    sportType.placeholder = $localize `Sport Type`;
    sportType.dropdownOptions = this.viewModel.getPixellotProductionTypeOptions();
    sportType.required = true;
    items.push(sportType);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    // active.enabled = !PermissionManagerService.isGranted(AdminSections.Sports_Edit_Sport_Active);
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Sport`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Sports_Edit_Sport_Edit_Info,) ?
        '' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Sport` : $localize`Add a New Sport`;
  }
}
