import {BaseLookupType} from '../base/base-lookup-type';
import {Selectable} from '../protocols/selectable';

export class StateType extends BaseLookupType implements Selectable {
  public stateCode: string;
  public id: number;
  public name: string;

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): string {
    return this.name + this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}

