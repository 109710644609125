import {BaseComponent} from '../../../../models/base/base-component';
import {Component} from '@angular/core';

@Component({
  selector: 'app-feedback-landing',
  templateUrl: './feedback-landing.component.html',
  styleUrls: ['./feedback-landing.component.scss']
})
export class FeedbackComponent extends BaseComponent {

  constructor() {
    super();
  }

setupBindings() {
}

setupViews() {
}


}
