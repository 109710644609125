export class Breadcrumb {
  public title: string;
  public targetUrl: string;
  public active: boolean = false;
  public disabled: boolean = false;

  constructor(title: string, targetUrl: string) {
    this.title = title;
    this.targetUrl = targetUrl;
  }
}
