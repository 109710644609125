import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Selectable} from '../../../../models/protocols/selectable';
import {Location} from '../../../../models/location/location';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit {

  public locations: Location[] = [];
  public activeLocation: Location;
  public selectionType = Location;
  public remember: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  cancel() {
    this.activeModal.close(null);
  }

  save() {
    const res: [Location, boolean] = [this.activeLocation, this.remember];
    this.activeModal.close(res);
  }

  selectionChanged(loc: Selectable) {
    this.activeLocation = loc as Location;
  }

  checkRemember(rem: boolean) {
    this.remember = rem;
  }

  setActiveLocation(loc: Location) {
    // Sets active locations to point to object in locations array
    const active = this.locations.find(l => l.id === loc.id);
    if (active) {
      this.activeLocation = active;
    }
  }

}
