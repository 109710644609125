import { CameraSystemTypes } from './../../../../../models/enum/shared/camera-system-type.enum';
import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {CameraSystem} from '../../../../../models/resources/camera-system';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {CameraSystemViewModal} from '../../camera-systems.component-view-model';
import {ToastService} from '../../../../../services/toast-service';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {ActivatedRoute, Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {CameraSystemType} from '../../../../../models/lookup/camera-system-type';
import {SafeResourceUrl} from '@angular/platform-browser';
import {CustomFile} from '../../../../../models/shared/custom-file';
import {BehaviorSubject} from 'rxjs';
import { PermissionsFactory } from 'src/app/models/account/dto/admin-permission/admin-permissions-factory';

@Component({
  selector: 'app-edit-camera-systems',
  templateUrl: './edit-camera-systems.component.html',
  styleUrls: ['./edit-camera-systems.component.scss'],
  providers: [CameraSystemViewModal]
})
export class EditCameraSystemsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formObject = new CameraSystem();
  public formOptions = new FormOptions();
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();
  public systemId: string = '';
  public openUploader = false;
  public systemVersion: string = '';
  public configFileToUpload: string | SafeResourceUrl;
  public viewUploader: boolean = false;
  public CurrentFirmware: string = '';

  constructor(
    public viewModel: CameraSystemViewModal,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupBindings() {
    this.activatedRoute.params.pipe(map(params => params.cameraSystemId))
      .subscribe(cameraSystemId => {
        if (cameraSystemId) {
          this.viewModel.getCameraSystemById(cameraSystemId).notNull().subscribe((cameraSystem) => {
            setTimeout(() => {
              this.formObject = CameraSystem.initWithSystems(cameraSystem);
              this.formObject.itemChanged = true;
              this.systemId = cameraSystem.id;
              this.systemVersion = this.formObject.version;
              this.CurrentFirmware=this.formObject.currentFirmware;
              this.viewUploaderCheck(cameraSystem);
            });
          }, (error) => {
            this.toastService.publishError(error);
          }).addTo(this.subscriptions);
        } else {
          this.formObject = new CameraSystem();
          this.formObject.itemChanged = false;
        }
      });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save System`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Camera_Systems_Edit_Camera_System_Edit) ?
        'preferred-button' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormItems() {
    const items: FormInputItem[] = [];
    const adminRole=PermissionsFactory.getAdminUserLevel();
    const systemName = new FormInputItem();
    systemName.itemType = FormItemType.Input;
    systemName.label = $localize`System Name`;
    systemName.inputName = 'systemName';
    systemName.placeholder = $localize`System Name`;
    systemName.required = true;
    systemName.bindingProperty = 'name';
    items.push(systemName);

    const cameraSystemType = new FormInputItem();
    cameraSystemType.itemType = FormItemType.Dropdown;
    cameraSystemType.label = $localize`Camera System Type`;
    cameraSystemType.inputName = 'cameraSystemType';
    cameraSystemType.placeholder = $localize`Camera System Type`;
    cameraSystemType.required = true;
    cameraSystemType.bindingProperty = 'systemTypeIdToNumber';
    cameraSystemType.dropdownIsObject = true;
    cameraSystemType.dropdownOptions = [new CameraSystemType('bd-p200', 1),
      new CameraSystemType('mevo', 2),
      new CameraSystemType('pix4team', 3),
      new CameraSystemType('pixellot', 4)];
    cameraSystemType.enabled = false;
    items.push(cameraSystemType);


    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      const hardwareSerialNumber = new FormInputItem();
      hardwareSerialNumber.itemType = FormItemType.Input;
      hardwareSerialNumber.label = $localize`Hardware S/N`;
      hardwareSerialNumber.inputName = 'serialNumber';
      hardwareSerialNumber.placeholder = $localize`Serial Number`;
      hardwareSerialNumber.required = true;
      hardwareSerialNumber.bindingProperty = 'serialNumber';
     hardwareSerialNumber.enabled=
      this.PermissionManagerService.isGranted
      (this.Types.AdminSections.Camera_Systems_Edit_Camera_System_Edit) ? true : false;
      items.push(hardwareSerialNumber);
      if (params.cameraSystemId) {
        const cameraSystemId = new FormInputItem();
        cameraSystemId.itemType = FormItemType.Input;
        cameraSystemId.label = $localize`System HTL ID`;
        cameraSystemId.inputName = 'cameraSystemId';
        cameraSystemId.placeholder = $localize`System ID`;
        cameraSystemId.required = true;
        cameraSystemId.bindingProperty = 'id';
        cameraSystemId.enabled = false;
        cameraSystemType.enabled = false;
        items.push(cameraSystemId);

        const projectedContent = new FormInputItem();
        projectedContent.itemType = FormItemType.ProjectedContent;
        items.push(projectedContent);
      } else {
        cameraSystemType.enabled = true;
      }


      const active = new FormInputItem();
      active.itemType = FormItemType.Switch;
      active.inputName = 'active';
      active.label = $localize`Active`;
      active.placeholder = $localize`active`;
      active.bindingProperty = 'statusToBoolean';
      active.customClass = 'mb-4 mt-0';
      items.push(active);
    });
    this.formItems = items;

  }

  systemTypeIdNumberToString(id: number){
    switch(id){
      case 1:{
        return CameraSystemTypes.bdp200;
      }
      case 2:{
        return CameraSystemTypes.mevo;
      }
      case 3:{
        return CameraSystemTypes.pix4team;
      }
      case 4:{
        return CameraSystemTypes.pixellot;
      }
      default:{
        break;
      }
    }
  }

  formSubmitted(formsObject: any) {
    const cameraSystem=formsObject as CameraSystem;
    let formObject = new CameraSystem();
    if (this.formObject.itemChanged) {
      formObject = this.formObject;
    }
    formObject.name = this.formItems.find(item => item.inputName === 'systemName').getValue();
    formObject.statusToBoolean = this.formItems.find(item => item.inputName === 'active').getValue();
    formObject.status = formObject.statusToBoolean ? 1 : 2;
    formObject.systemTypeIdToNumber = this.formItems.find(item => item.inputName === 'cameraSystemType').getValue();
    formObject.systemTypeId = formObject.systemTypeIdToNumber === 1 ? 'bd-p200' : 'pixellot';
    formObject.timeStampVal=this.formObject.timeStampVal;
    formObject.systemTypeId = this.systemTypeIdNumberToString(formObject.systemTypeIdToNumber);
    formObject.serialNumber=this.formItems.find(item => item.inputName === 'serialNumber').getValue();
    console.log(formObject);
    this.viewModel.saveCameraSystem(formObject);
  }

  fileList(f: CustomFile[], id: number) {
    if (f.length > 0) {
      this.formObject.config = f[0].file;
    } else {
      this.formObject.config = undefined;
    }
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  downloadClicked() {
    this.viewModel.getCameraSystemTypeConfigurations(this.systemId).subscribe((config) => {
      const jsonString = JSON.stringify(config);
      const blob = new Blob([jsonString], {type: 'application/json'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'config.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  uploaderClicked() {
    this.openUploader = true;
  }
  viewUploaderCheck(cameraSystem: CameraSystem){
    this.viewUploader= cameraSystem.systemTypeId
    !==CameraSystemTypes.pixellot ? true : false;
  }
}
