<div class="feature-marquee-container" [ngClass]="customClass">
  <div *ngIf="isLive" class="live-badge">
    <span class="live-dot">&nbsp;</span>
    <ng-container i18n>LIVE</ng-container>
  </div>
  <div class="marquee-title-league" *ngIf="getEventName(program$|async) as eventName">{{eventName}}</div>
  <div class="marquee-title-league" *ngIf="getLeagueName(program$|async) as leagueName">{{leagueName}}</div>
  <div class="marquee-title-league short-description">{{getShortDescription(program$|async)}}</div>
  <div class="marquee-title-row" *ngIf="!showScoreAndGameStatus">
    {{programTitle}}
  </div>
  <div class="marquee-score-row" *ngIf="showScoreAndGameStatus">
    <div class="score-name">{{homeTeamName}}</div>
    <div class="score-value">{{homeTeamScore$|async}}</div>
  </div>
  <div class="marquee-score-row leading-score" *ngIf="showScoreAndGameStatus">
    <div class="score-name">{{visitingTeamName}}</div>
    <div class="score-value">{{visitingTeamScore$|async}}</div>
  </div>
  <div class="marquee-score-details" *ngIf="showScoreAndGameStatus">
    <div class="game-period">{{gamePeriod$|async}}</div>
    <div class="game-time-remaining">{{gameTimeRemaining$|async}}</div>
  </div>
  <div class="marquee-description">{{programDescription}}</div>
  <div class="marquee-description">{{getProgramTimeString(program$|async)}}</div>
  <div class="marquee-actions" *ngIf="showMarqueeActions">
    <button class="custom-button preferred-button mr-4" [hidden]="!(program$|async)" (click)="watchNowClicked.emit()">
      <img [src]="'assets/icons/light/outline/play.svg'" alt="">
      <ng-container *ngIf="isLive" i18n>Watch Live</ng-container>
      <ng-container *ngIf="!isLive" i18n>Watch Now</ng-container>
    </button>
  </div>
</div>
