import {environment} from '../../environments/environment';
import {ProgramQueryType} from '../models/program/content-query';
import {AdvertisementPriorityType} from '../models/lookup/advertisement-priority-type';

export class Endpoints {

  // Session

  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  static adminLogin(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/login`;
  }

  static subscriberLogin(email: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/login`;
  }

  static adminChangePassword(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/changePassword`;
  }

  static subscriberChangePassword(email: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/changePassword`;
  }

  static resetForgottenPassword(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/resetforgottenpassword`;
  }

  static adminRefreshSession(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/refreshsession`;
  }

  static subscriberRefreshSession(email: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/refreshsession`;
  }

  static signOut(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/logout`;
  }

  static subscriberSignOut(email: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/logout`;
  }

  static getSubscriberSession(email: string, refreshToken: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/sessions?RefreshToken=${refreshToken}`;
  }

  // Users

  static getAdmins(): string {
    return `${environment.mainServiceUrl}/users`;
  }

  static getAdminById(userId: number): string {
    return `${environment.mainServiceUrl}/users/${userId}`;
  }

  static getDepartment(): string {
    return `${environment.mainServiceUrl}/departments`;
  }

  static createCompanyUser(): string {
    return `${environment.mainServiceUrl}/users`;
  }

  static updateCompanyUser(userId: number): string {
    return `${environment.mainServiceUrl}/users/${userId}`;
  }

  static updateAdminUserProfile(userId: number): string {
    return `${environment.mainServiceUrl}/userProfile/${userId}`;
  }

  static updateCompanyUserPassword(userEmail: string): string {
    return `${environment.mainServiceUrl}/users/${userEmail}/changepassword`;
  }

  static getAdminForgotPasswordCode(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/forgotpasswordcode`;
  }

  static resetForgottenAdminPassword(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/resetforgottenpassword`;
  }

  static respondToNewPasswordChallenge(email: string): string {
    return `${environment.mainServiceUrl}/users/${email}/respondtonewpasswordchallenge`;
  }

  // Subscribers

  static getSubscribers(): string {
    return `${environment.mainServiceUrl}/subscribers`;
  }

  static getHydratedSubscribers(): string {
    return `${environment.mainServiceUrl}/leanhydratedsubscribers`;
  }

  static createSubscriber(): string {
    return `${environment.mainServiceUrl}/subscribers`;
  }

  static updateSubscriber(subscriberId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}`;
  }

  static createSubscriberImage(subscriberId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/images`;
  }

  static deleteSubscriberImage(subscriberId: string, imageId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/images/${imageId}`;
  }

  static getUserImages(userId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${userId}/images`;
  }

  static getSubscriberForgotPasswordCode(email: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/forgotpasswordcode`;
  }

  static resetForgottenSubscriberPassword(email: string): string {
    return `${environment.mainServiceUrl}/subscribers/${email}/resetforgottenpassword`;
  }

  static getSubscriptionPlans(): string {
    return `${environment.mainServiceUrl}/subscriptionplans`;
  }

  static subscriberSubscriptions(subscriberId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/subscriptions`;
  }

  static subscriberSubscription(subscriberId: string, subscriptionId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/subscriptions/${subscriptionId}`;
  }

  static createSubscriberPaymentDetails(subscriberId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/paymentdetails`;
  }

  static deleteSubscriberPaymentDetails(subscriberId: string, paymentDetailId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/paymentdetails/${paymentDetailId}`;
  }

  static cancelSubscription(subscriberId: string, subscriptionId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/subscriptions/${subscriptionId}/cancel`;
  }

  static pauseSubscriberSubscription(subscriberId: string, subscriptionId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/subscriptions/${subscriptionId}/pause`;
  }

  static resumeSubscriberSubscription(subscriberId: string, subscriptionId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/subscriptions/${subscriptionId}/resume`;
  }

  static getCouponWithCode(couponCode: string,isPrivate: boolean): string {
    return `${environment.mainServiceUrl}/chargebeecoupons/${couponCode}/${isPrivate}`;
  }

  static addCouponToSubscription(subscriberId: string, subscriptionId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/subscriptions/${subscriptionId}/addcoupon`;
  }

  static getSubscriberInvoices(subscriberId: string): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/invoices`;
  }

  static getEmailVerification(): string {
    return `${environment.mainServiceUrl}/subscribers/verifyemail`;
  }

  static getReverifyEmail(): string {
    return `${environment.mainServiceUrl}/subscribers/reverifyemail`;
  }

  static validateEmailToken(token: string): string {
    return `${environment.mainServiceUrl}/subscribers/verifytoken?token=${token}`;
  }

  // Roles

  static getCompanyRoles(): string {
    return `${environment.mainServiceUrl}/roles`;
  }

  static getRoleById(roleId: number): string {
    return `${environment.mainServiceUrl}/roles/${roleId}`;
  }

  static updateCompanyRole(roleId: number): string {
    return `${environment.mainServiceUrl}/roles/${roleId}`;
  }

  static createCompanyRole(): string {
    return `${environment.mainServiceUrl}/roles/`;
  }

  // Lookup
  static getSportTypes(): string {
    return `${environment.mainServiceUrl}/sportTypes`;
  }

  static getPixellotProductionTypes(): string {
    return `${environment.mainServiceUrl}/pixelottProductionTypes`;
  }

  static getCountryTypes(): string {
    return `${environment.mainServiceUrl}/countryTypes`;
  }

  static getPermissionTypes(): string {
    return `${environment.mainServiceUrl}/permissionTypes`;
  }

  static getProgramStatusTypes(): string {
    return `${environment.mainServiceUrl}/programStatusTypes`;
  }

  static getProductionTypes(): string {
    return `${environment.mainServiceUrl}/productiontypes`;
  }

  static getAdvertisementPriorityTypes(): string {
    return `${environment.mainServiceUrl}/AdvertisementPriorityTypes`;
  }

  static createSportType(): string {
    return `${environment.mainServiceUrl}/sporttypes`;
  }

  static updateSportType(sportId: string): string {
    return `${environment.mainServiceUrl}/sporttypes/${sportId}`;
  }

  // Leagues

  static getHydratedLeagues(): string {
    return `${environment.mainServiceUrl}/hydratedleagues`;
  }

  static getHydratedLeague(leagueId: number): string {
    return `${environment.mainServiceUrl}/hydratedleagues/${leagueId}`;
  }

  static createLeague(): string {
    return `${environment.mainServiceUrl}/leagues`;
  }

  static getLeagues(): string {
    return `${environment.mainServiceUrl}/leagues`;
  }

  static updateLeague(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}`;
  }

  static completeLeagueProgram(programId: string, leagueId: number): string {
    return `${environment.mainServiceUrl}/programs/${programId}/complete`;
  }

  static createLeagueBannerAdvertisement(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/advertisementbanners`;
  }

  static deleteLeagueBannerAdvertisement(leagueId: number, bannerAdId: string): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/advertisementbanners/${bannerAdId}`;
  }

  static getLeagueImages(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/logos`;
  }

  static deleteLeagueImage(leagueId: number, imageId: string): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/logos/${imageId}`;
  }

  static deleteLeagueProgram(leagueId: number, programId: string): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/programs/${programId}`;
  }

  static createLeagueImage(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/logos`;
  }

  static createLeagueVenueAssociation(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/associatedVenues`;
  }

  static deleteLeagueVenueAssociation(leagueId: number, venueAssociationId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/associatedVenues/${venueAssociationId}`;
  }

  static getLeagueStream(leagueId: number){
    return `${environment.mainServiceUrl}/leagueStream/${leagueId}?fromLeague=true`;
  }

  static updateLeagueStream(streamId: number){
    return `${environment.mainServiceUrl}/leagueStream/${streamId}`;
  }

  static createLeagueStream(){
    return `${environment.mainServiceUrl}/leagueStream`;
  }
  // Events

  static getHydratedEvents(): string {
    return `${environment.mainServiceUrl}/hydratedevents`;
  }

  static getHydratedEvent(eventId: number): string {
    return `${environment.mainServiceUrl}/hydratedevents/${eventId}`;
  }

  static createEvent(): string {
    return `${environment.mainServiceUrl}/events`;
  }

  static updateEvent(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}`;
  }

  static createEventBannerAdvertisement(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/advertisementbanners`;
  }

  static deleteEventBannerAdvertisement(eventId: number, bannerAdId: string): string {
    return `${environment.mainServiceUrl}/events/${eventId}/advertisementbanners/${bannerAdId}`;
  }

  static getEventImages(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/logos`;
  }

  static deleteEventImage(eventId: number, imageId: string): string {
    return `${environment.mainServiceUrl}/events/${eventId}/logos/${imageId}`;
  }

  static deleteEventProgram(eventId: number, programId: string): string {
    return `${environment.mainServiceUrl}/events/${eventId}/programs/${programId}`;
  }

  static createEventImage(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/logos`;
  }

  static createEventVenueAssociation(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/associatedVenues`;
  }

  static deleteEventVenueAssociation(eventId: number, venueAssociationId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/associatedVenues/${venueAssociationId}`;
  }

  static getEventStream(eventId: number){
    return `${environment.mainServiceUrl}/eventStream/${eventId}?fromEvent=true`;
  }

  static updateEventStream(streamId: number){
    return `${environment.mainServiceUrl}/eventStream/${streamId}`;
  }

  static createEventStream(){
    return `${environment.mainServiceUrl}/eventStream`;
  }

  // Seasons

  static getLeagueSeasons(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/seasons`;
  }

  static getLeagueSeasonsById(leagueId: number, seasonId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/seasons/${seasonId}`;
  }

  static updateLeagueSeasons(leagueId: number, seasonId: number) {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/seasons/${seasonId}`;
  }

  static createLeagueSeasons(leagueId: number) {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/seasons`;
  }

  static deleteLeagueSeasons(leagueId: number, seasonId: number) {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/seasons/${seasonId}`;
  }

  // Teams

  static getTeams(): string {
    return `${environment.mainServiceUrl}/teams`;
  }

  static getHydratedTeam(teamId: number): string {
    return `${environment.mainServiceUrl}/hydratedteams/${teamId}`;
  }

  static getHydratedTeams(): string {
    return `${environment.mainServiceUrl}/hydratedteams`;
  }

  static createTeam(): string {
    return `${environment.mainServiceUrl}/teams`;
  }

  static updateTeam(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}`;
  }

  static deleteTeam(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}`;
  }

  static getTeamImages(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/logos`;
  }

  static deleteTeamImage(teamId: number, imageId: string): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/logos/${imageId}`;
  }

  static createTeamImage(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/logos`;
  }

  static getTeamBannerImages(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/bannerimages`;
  }

  static deleteTeamBannerImage(teamId: number, imageId: string): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/bannerimages/${imageId}`;
  }

  static createTeamBannerImage(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/bannerimages`;
  }

  static deleteTeamCardImage(teamId: number, imageId: string): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/cardimages/${imageId}`;
  }

  static createTeamCardImage(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}/cardimages`;
  }

  static createLeagueTeamAssociation(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/associatedTeams`;
  }

  static createEventTeamAssociation(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/associatedTeams`;
  }

  static deleteLeagueTeamAssociation(leagueId: number, teamAssociationId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/associatedTeams/${teamAssociationId}`;
  }

  static deleteEventTeamAssociation(eventId: number, teamAssociationId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/associatedTeams/${teamAssociationId}`;
  }

  static createVenueTeamAssociation(venueId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/associatedTeams`;
  }

  static deleteVenueTeamAssociation(venueId: number, teamAssociationId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/associatedTeams/${teamAssociationId}`;
  }

  // Plans
  static getPlans(): string {
    return `${environment.mainServiceUrl}/subscriptionplans/all`;
  }

  static getPlanById(planId: number): string {
    return `${environment.mainServiceUrl}/subscriptionplans/${planId}`;
  }

  static createPlan(): string {
    return `${environment.mainServiceUrl}/subscriptionplans`;
  }

  static updatePlan(planId: number) {
    return `${environment.mainServiceUrl}/subscriptionplans/${planId}`;
  }

  // Venues
  static getVenues(): string {
    return `${environment.mainServiceUrl}/venues`;
  }

  static createVenue(): string {
    return `${environment.mainServiceUrl}/venues`;
  }

  static getHydratedVenues(): string {
    return `${environment.mainServiceUrl}/hydratedVenues`;
  }

  static getHydratedVenue(id: number): string {
    return `${environment.mainServiceUrl}/hydratedVenues/${id}`;
  }

  static updateVenue(id: string): string {
    return `${environment.mainServiceUrl}/venues/${id}`;
  }

  static updateVenueStream(venueId: number, venueStreamId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/streams/${venueStreamId}`;
  }

  static deleteVenueStream(venueId: number, venueStreamId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/streams/${venueStreamId}`;
  }

  static getVenueStream(venueId: number, venueStreamId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/streams/${venueStreamId}`;
  }

  static createVenueStream(venueId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/streams`;
  }

  static getVenueHydratedPrograms(): string {
    return `${environment.mainServiceUrl}/venues/hydratedPrograms`;
  }

  static createVenueImage(venueId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/images`;
  }

  static deleteVenueImage(venueId: number, imageId: string): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/images/${imageId}`;
  }

  static getPixellotVenues(): string {
    return `${environment.mainServiceUrl}/allcameravenues`;
  }


  // Programs / Shows
  static updateLeagueProgram(programId: string, leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/programs/${programId}`;
  }

  static updateEventProgram(programId: string, eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/programs/${programId}`;
  }

  static createLeagueProgram(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/programs`;
  }

  static createEventProgram(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/programs`;
  }

  static completeEventProgram(programId: string, eventId: number): string {
    return `${environment.mainServiceUrl}/programs/${programId}/complete`;
  }

  static completeVenueProgram(programId: string, venueId: number): string {
    return `${environment.mainServiceUrl}/programs/${programId}/complete`;
  }

  static updateVenueProgram(programId: string, venueId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/programs/${programId}`;
  }

  static createVenueProgram(venueId: number): string {
    return `${environment.mainServiceUrl}/venues/${venueId}/programs`;
  }

  static updateLeagueShow(showId: string, leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/shows/${showId}`;
  }

  static updateEventShow(showId: string, eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/shows/${showId}`;
  }

  static createLeagueShow(leagueId: number): string {
    return `${environment.mainServiceUrl}/leagues/${leagueId}/shows`;
  }

  static createEventShow(eventId: number): string {
    return `${environment.mainServiceUrl}/events/${eventId}/shows`;
  }

  static getHydratedPrograms(): string {
    return `${environment.mainServiceUrl}/hydratedPrograms`;
  }

  static getPreRollAdvertisementsForProgram(programId: string): string {
    return `${environment.mainServiceUrl}/hydratedPrograms/${programId}/advertisements?adtypes=1`;
  }

  static getPreRollAdvertisementsForShow(showId: string): string {
    return `${environment.mainServiceUrl}/hydratedPrograms/shows/${showId}/advertisements/?adtypes=1`;
  }

  static getLeagueHydratedPrograms(): string {
    return `${environment.mainServiceUrl}/leagues/hydratedPrograms`;
  }

  static getEventHydratedPrograms(): string {
    return `${environment.mainServiceUrl}/events/hydratedPrograms`;
  }

  static getHydratedShow(showId: string): string {
    return `${environment.mainServiceUrl}/hydratedPrograms/shows/${showId}`;
  }

  static getHydratedShows(programQueryType: ProgramQueryType): string {
    switch (programQueryType) {
      case ProgramQueryType.Leagues:
        return `${environment.mainServiceUrl}/leagues/hydratedPrograms/shows/`;
      case ProgramQueryType.Events:
        return `${environment.mainServiceUrl}/events/hydratedPrograms/shows/`;
      default:
        return `${environment.mainServiceUrl}/hydratedPrograms/shows/`;
    }
  }

  static getLiveHydratedPrograms(programQueryType: ProgramQueryType): string {
    switch (programQueryType) {
      case ProgramQueryType.Leagues:
        return `${environment.mainServiceUrl}/leagues/hydratedPrograms/liveprograms/`;
      case ProgramQueryType.Venues:
        return `${environment.mainServiceUrl}/venues/hydratedPrograms/liveprograms/`;
      case ProgramQueryType.Events:
        return `${environment.mainServiceUrl}/events/hydratedPrograms/liveprograms/`;
      default:
        return `${environment.mainServiceUrl}/hydratedPrograms/liveprograms/`;
    }
  }

  static getPastHydratedPrograms(programQueryType: ProgramQueryType): string {
    switch (programQueryType) {
      case ProgramQueryType.Leagues:
        return `${environment.mainServiceUrl}/leagues/hydratedPrograms/pastprograms/`;
      case ProgramQueryType.Venues:
        return `${environment.mainServiceUrl}/venues/hydratedPrograms/pastprograms/`;
      case ProgramQueryType.Events:
        return `${environment.mainServiceUrl}/events/hydratedPrograms/pastprograms/`;
      default:
        return `${environment.mainServiceUrl}/hydratedPrograms/pastprograms/`;
    }
  }

  static getUpcomingHydratedPrograms(programQueryType: ProgramQueryType): string {
    switch (programQueryType) {
      case ProgramQueryType.Leagues:
        return `${environment.mainServiceUrl}/leagues/hydratedPrograms/upcomingprograms/`;
      case ProgramQueryType.Venues:
        return `${environment.mainServiceUrl}/venues/hydratedPrograms/upcomingprograms/`;
        case ProgramQueryType.Events:
        return `${environment.mainServiceUrl}/events/hydratedPrograms/upcomingprograms/`;
      default:
        return `${environment.mainServiceUrl}/hydratedPrograms/upcomingprograms/`;
    }
  }

  static getHydratedProgram(programId: string): string {
    return `${environment.mainServiceUrl}/hydratedprograms/${programId}`;
  }

  static getProgramImages(programId: string): string {
    return `${environment.mainServiceUrl}/programs/${programId}/images`;
  }

  static deleteProgramImage(programId: string, imageId: string): string {
    return `${environment.mainServiceUrl}/programs/${programId}/images/${imageId}`;
  }

  static createProgramImage(programId: string): string {
    return `${environment.mainServiceUrl}/programs/${programId}/images`;
  }

  static updateZypeThumbnail(programId: string){
    return `${environment.mainServiceUrl}/programs/${programId}/updateThumbnail`;
  }
  static deleteShowImage(showId: string, imageId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/images/${imageId}`;
  }

  static createShowImage(showId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/images`;
  }

  static createProgramComment(programId: string): string {
    return `${environment.mainServiceUrl}/programs/${programId}/comments`;
  }

  static createShowComment(showId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/comments`;
  }

  static createShowUpload(showId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/uploads`;
  }

  static getShowUploads(showId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/uploads`;
  }

  static deleteShowUpload(showId: string, uploadId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/uploads/${uploadId}`;
  }

  static incrementProgramPlayCount(programId: string): string {
    return `${environment.mainServiceUrl}/programs/${programId}/incrementplaycount`;
  }

  static showsIncrementPlayCount(showId: string): string {
    return `${environment.mainServiceUrl}/shows/${showId}/incrementplaycount`;
  }

  // Camera Systems
  static createCameraSystem(): string {
    return `${environment.mainServiceUrl}/camerasystems`;
  }

  static getAllCameraSystems(): string {
    return `${environment.mainServiceUrl}/camerasystems`;
  }
  static getCameraSystemById(cameraSystemId: string): string {
    return `${environment.mainServiceUrl}/camerasystems/${cameraSystemId}`;
  }

  static getCameraSystemTypeConfigurationsById(cameraSystemTypeId: string): string {
    return `${environment.mainServiceUrl}/camerasystems/${cameraSystemTypeId}/config`;
  }

  static updateCameraSystem(cameraSystemId: string): string {
    return `${environment.mainServiceUrl}/camerasystems/${cameraSystemId}`;
  }

  static updateCameraSystemTypeConfigurations(cameraSystemTypeId: string): string {
    return `${environment.mainServiceUrl}/camerasystems/${cameraSystemTypeId}/config`;
  }

  static deleteCameraSystem(cameraSystemId: number): string {
    return `${environment.mainServiceUrl}/camerasystems/${cameraSystemId}`;
  }

  // Advertising
  static createAdvertisingPartner(): string {
    return `${environment.mainServiceUrl}/advertisingPartners`;
  }

  static getAdvertisingPartners(): string {
    return `${environment.mainServiceUrl}/advertisingPartners`;
  }

  static getAdvertisingPartner(partnerId: number) {
    return `${environment.mainServiceUrl}/advertisingPartners/${partnerId}`;
  }

  static deleteAdvertisingPartner(partnerId: string): string {
    return `${environment.mainServiceUrl}/advertisingPartners/${partnerId}`;
  }

  static updateAdvertisingPartner(partnerId: number): string {
    return `${environment.mainServiceUrl}/advertisingPartners/${partnerId}`;
  }

  static createAdvertisingPartnerAdvertisement(partnerId: number): string {
    return `${environment.mainServiceUrl}/advertisingpartners/${partnerId}/advertisements`;
  }

  static getAdvertisingPartnerAdvertisements(partnerId: number): string {
    return `${environment.mainServiceUrl}/advertisingpartners/${partnerId}/hydratedadvertisements`;
  }

  static getAdvertisingPartnerAdvertisement(partnerId: number, adId: string): string {
    return `${environment.mainServiceUrl}/advertisingpartners/${partnerId}/hydratedadvertisements/${adId}`;
  }

  static updateAdvertisingPartnerAdvertisement(partnerId: number, adId: string): string {
    return `${environment.mainServiceUrl}/advertisingpartners/${partnerId}/advertisements/${adId}`;
  }

  static deleteAdvertisingPartnerAdvertisement(partnerId: number, adId: string): string {
    return `${environment.mainServiceUrl}/advertisingpartners/${partnerId}/advertisements/${adId}`;
  }

  static getAdvertisementUploads(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/uploads`;
  }

  static createAdvertisementUpload(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/uploads`;
  }

  static deleteAdvertisementUpload(adId: string, uploadId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/uploads/${uploadId}`;
  }

  static getAdvertisementRunDates(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/runDates`;
  }

  static createAdvertisementRunDate(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/runDates`;
  }

  static deleteAdvertisementRunDate(adId: string, runDateId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/runDates/${runDateId}`;
  }

  static createAdvertisementVenueAssociation(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedVenues`;
  }

  static deleteAdvertisementVenueAssociation(adId: string, associationId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedVenues/${associationId}`;
  }

  static createAdvertisementTeamAssociation(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedTeams`;
  }

  static deleteAdvertisementTeamAssociation(adId: string, associationId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedTeams/${associationId}`;
  }

  static createAdvertisementLeagueAssociation(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedLeagues`;
  }

  static createAdvertisementEventAssociation(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedEvents`;
  }

  static deleteAdvertisementLeagueAssociation(adId: string, associationId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedLeagues/${associationId}`;
  }

  static deleteAdvertisementEventAssociation(adId: string, associationId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/associatedEvents/${associationId}`;
  }

  static incrementAdvertisementPlayCount(adId: string): string {
    return `${environment.mainServiceUrl}/advertisements/${adId}/IncrementPlayCount`;
  }

  // Unsubscribe-Resubscribe
  static unsubscribeNotification(subscriberId: number): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/marketing/unsubscribe`;
  }

  static resubscribeNotification(subscriberId: number): string {
    return `${environment.mainServiceUrl}/subscribers/${subscriberId}/marketing/resubscribe`;
  }

  static getAllCameraStreamDetails(): string {
    return `${environment.mainServiceUrl}/camerasystems/streams`;
  }
}

