import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Coupon} from './coupon';
import {NumberUtils} from '../../../utils/number-utils';
import {DateUtils} from '../../../utils/date-utils';

export class SubscriberSubscription implements Deserializable {
  public id: string;
  public planId: string;
  public planPriceId: string;
  public plan: string;
  public planPrice: number;
  public status: SubscriptionStatus;
  public billingPeriod: number;
  public billingPeriodUnit: string;
  public nextBillingDateUtc: Date;
  public coupons: Coupon[];
  public expiryDateUtc: Date;

  onDeserialize() {
    this.nextBillingDateUtc = DeserializeHelper.deserializeToDate(this.nextBillingDateUtc);
    this.expiryDateUtc = DeserializeHelper.deserializeToDate(this.expiryDateUtc);
    this.coupons = DeserializeHelper.deserializeArray(Coupon, this.coupons);
  }

  isSubscriptionInactive(): boolean {
    if (!this || !this.status) {
      return false;
    }

    if (this.status === SubscriptionStatus.Cancelled ||
      this.status === SubscriptionStatus.Paused) {

      return this.expiryDateUtc && DateUtils.dateIsBefore(this.expiryDateUtc, new Date()) <= 0;
    }
    return false;
  }

  getSubscriptionStatusString(): string {
    switch (this.status) {
      case SubscriptionStatus.Active:
        return $localize`Active`;
      case SubscriptionStatus.Paused:
        return $localize`Paused`;
      case SubscriptionStatus.Cancelled:
        return $localize`Cancelled`;
      case SubscriptionStatus.NonRenewing:
        return $localize`Non Renewing`;
      case SubscriptionStatus.InTrial:
        return $localize`In Trial`;
      case SubscriptionStatus.Future:
        return $localize`Future`;
    }
    return this.status ?? $localize`N/A`;
  }

  getPriceInDollars() {
    return this.planPrice / 100;
  }

  getPriceDescription(): string {
    return $localize`${NumberUtils.formatNumberAsCurrency(this.getPriceInDollars())} / ${this.billingPeriodUnit}`;
  }
}

export enum SubscriptionStatus {
  Active = 'active',
  Paused = 'paused',
  Cancelled = 'cancelled',
  NonRenewing = 'non_renewing',
  InTrial = 'in_trial',
  Future = 'future',
}
