import {BaseModel} from '../../base/base-model';
import {DepartmentType} from '../../lookup/department-type';
import {DeserializeHelper} from '../../protocols/deserializable';

export class CreateAdminRequest extends BaseModel {
  public id: number;
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public roleId: number = 1;
  public enabled: boolean = true;
  public department: DepartmentType;
  public departmentId: number = 0;
  public designation: string = '';
  public accessLevel: string = '';
  public groupList: string[] = [];
  public active: boolean = true;
  public timeStampVal: Date;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;
  static initWithUser(user: CreateAdminRequest): CreateAdminRequest {
    const formObjects = new CreateAdminRequest();
    formObjects.id = user.id;
    formObjects.firstName = user.firstName;
    formObjects.lastName = user.lastName;
    formObjects.email = user.email;
    formObjects.department = user.department;
    formObjects.departmentId = user.department.id;
    formObjects.designation = user.designation;
    formObjects.accessLevel = user.accessLevel;
    formObjects.active = user.active;
    formObjects.timeStampVal=user.timeStampVal;
    return formObjects;
  }
  public onDeserialize() {
    super.onDeserialize();
    this.department = DeserializeHelper.deserializeToInstance(DepartmentType, this.department ?? new DepartmentType());
  }

}
