import {Component, EventEmitter, OnInit} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NavigationEnd, Router} from '@angular/router';
import {FormInputItem, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {ProgramComment} from '../../../../models/program/program-comment';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent extends BaseModal implements OnInit {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ProgramComment = new ProgramComment();
  public updatedFormObject = new EventEmitter<void>();

  constructor(
    private activeModal: NgbActiveModal,
    public router: Router,
    public modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.bindToBackNav();
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  formSubmitted(result: ProgramComment) {
    this.activeModal.close(result);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const feedbackText = new FormInputItem();
    feedbackText.itemType = FormItemType.Textarea;
    feedbackText.inputName = 'comment';
    feedbackText.label = $localize`Enter Feedback`;
    feedbackText.placeholder = $localize`Type your message here...`;
    feedbackText.bindingProperty = 'comment';
    feedbackText.required = true;
    feedbackText.autoGrowTextArea = true;
    feedbackText.autoGrowMinRows = 6;
    feedbackText.autoGrowMaxRows = 6;
    items.push(feedbackText);

    this.formItems = items;
  }

  setupFormStyling() {
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.primaryButtonContainerClass = 'd-flex justify-content-end mt-2';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Submit`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

}
