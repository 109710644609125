import {Component, EventEmitter} from '@angular/core';
import {AdvertisingPartnerDetailsViewModel} from './advertising-partner-details-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {DatatableOptions} from '../../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../../models/base/base-datatable-data-provider';
import {AdvertisingPartnersViewModel} from '../advertising-partners-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableData} from '../../../../models/protocols/datatable-data';
import {AdvertisingPartner} from '../../../../models/resources/advertising-partner';
import {DatatableColumn, DatatableColumnType} from '../../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../../models/shared/stylesheet/datatable-column-filter';
import {
  EditAdvertisingPartnerModalComponent
} from '../edit-advertising-partner-modal/edit-advertising-partner-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {DateUtils} from '../../../../utils/date-utils';
import {HydratedAdvertisement} from '../../../../models/resources/hydrated-advertisement';

@Component({
  selector: 'app-advertising-partner-details',
  templateUrl: './advertising-partner-details.component.html',
  styleUrls: ['./advertising-partner-details.component.scss'],
  providers: [AdvertisingPartnerDetailsViewModel],
})
export class AdvertisingPartnerDetailsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: AdvertisingPartnerDetailsViewModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.advertisements$.notNull().subscribe(d => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = d;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  rowClicked(data: DatatableData) {
    const ad = data as HydratedAdvertisement;
    this.router.navigate([`./${ad.id}`], {relativeTo: this.activatedRoute}).then();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const advertisementName = new DatatableColumn(
      'advertisementName',
      $localize`Advertisement Name`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return a.name;
      }
    );
    advertisementName.isDefaultSortColumn = true;
    columns.push(advertisementName);

    const category = new DatatableColumn(
      'category',
      $localize`Category`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return a.advertisementType === 1 ? `Pre roll` : `Banner Ad`;
      }
    );
    columns.push(category);

    const venues = new DatatableColumn(
      'venues',
      $localize`Venues`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return `${a.associatedVenues?.length ?? 0}`;
      }
    );
    columns.push(venues);

    const teams = new DatatableColumn(
      'teams',
      $localize`Teams`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return `${a.associatedTeams?.length ?? 0}`;
      }
    );
    columns.push(teams);

    const leagues = new DatatableColumn(
      'leagues',
      $localize`Leagues`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return `${a.associatedLeagues?.length ?? 0}`;
      }
    );
    columns.push(leagues);

    const events = new DatatableColumn(
      'events',
      $localize`Events`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return `${a.associatedEvents?.length ?? 0}`;
      }
    );
    columns.push(events);

    const runDates = new DatatableColumn(
      'runDates',
      $localize`Dates`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        if (a.runDates?.length === 1) {
          return `${DateUtils.formatDateToReadableStringUTC(a.runDates[0].startDateUtc)} -
          ${DateUtils.formatDateToReadableStringUTC(a.runDates[0].endDateUtc)}`;
        } else if (a.runDates?.length > 1) {
          return $localize`Multiple`;
        }
        return $localize`N/A`;
      }
    );
    columns.push(runDates);

    const priority = new DatatableColumn(
      'priority',
      $localize`Priority`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return a.advertisementPriority;
      }
    );
    columns.push(priority);

    const playCount = new DatatableColumn(
      'playCount',
      $localize`Play Count`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return `${a.playCount ?? 0}`;
      }
    );
    columns.push(playCount);

    const active = new DatatableColumn(
      'active',
      $localize`Status`,
      DatatableColumnType.Text,
      (a: HydratedAdvertisement): string => {
        return a.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    active.className = 'custom-datatable-header text-right mr-2';
    active.headerClassName = 'custom-datatable-header text-right mr-2';
    active.columnFilter = this.getStatusColumnFilter();
    columns.push(active);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  createClicked() {
    this.router.navigate([`./add`], {relativeTo: this.activatedRoute}).then();
  }

  editClicked() {
    this.viewModel.advertPartner$.firstNotNull().subscribe(partner => {
      const modalRef = this.modalService.open(
        EditAdvertisingPartnerModalComponent,
        ModalUtils.confirmationModalOptions()
      );
      const modalComponent = modalRef.componentInstance as EditAdvertisingPartnerModalComponent;
      modalComponent.isEditing = true;
      modalComponent.formObject = partner;
      modalRef.result.then((updatedPartner: AdvertisingPartner) => {
        if (updatedPartner) {
          this.viewModel.updateAdvertisingPartner(partner);
        }
      });
    });
  }
}
