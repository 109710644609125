import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {DatatableData} from '../../protocols/datatable-data';

export class SubscriberInvoice implements Deserializable, DatatableData {
  dateUtc: Date;
  dueDateUtc: Date;
  subTotal: number;
  tax: number;
  total: number;
  status: string;
  downloadUrl: string;

  onDeserialize() {
    this.dateUtc = DeserializeHelper.deserializeToDate(this.dateUtc);
    this.dueDateUtc = DeserializeHelper.deserializeToDate(this.dueDateUtc);
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}
