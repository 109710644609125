import {BaseViewModel} from '../../../models/base/base-view-model';
import {Injectable} from '@angular/core';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {ToastService} from '../../../services/toast-service';

@Injectable({
  providedIn: 'root',
})
export class UsersViewModel extends BaseViewModel {
  loadingOpts: LoadingOptions = LoadingOptions.default(false, false);

  constructor(
    private modalService: NgbModal,
    private domainModel: AccountDomainModel,
    private toastService: ToastService
  ) {
    super();
  }

  init() {
    super.init();
  }
}
