<app-search-venues-banner>
</app-search-venues-banner>
<app-sign-up-banner>
</app-sign-up-banner>
<div id="leaderboard_atf_events_members"></div>
<app-program-row
  [navigateOnClick]="true"
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.contentQuery"
  (handleCardClicked)="programCardClicked($event)"
  (noResults)="viewModel.liveProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="true"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.contentQuery"
  (handleCardClicked)="programCardClicked($event)"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="true"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Past"
  [programContentQuery]="viewModel.contentQuery"
  (noResults)="viewModel.pastProgramsNoResults.next($event)">
</app-program-row>
