import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Plan} from '../../../../models/resources/plan';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableData} from '../../../../models/protocols/datatable-data';

@Injectable()
export class PlansTabViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  constructor(
    private domainModel: ResourceDomainModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    ) {
  }

  getPlans(activeOnly: boolean): Observable <Plan[]> {
    return this.domainModel.getPlans(activeOnly);
  };

  addNewPlan() {
    this.router.navigate([`./add`], {relativeTo: this.activatedRoute}).then();
  }

  rowClicked(data: DatatableData) {
    const plan = data as Plan;
    this.router.navigate([`./${plan.id}`], {relativeTo: this.activatedRoute}).then();
  }
}
