<div class="action-container mt-16px"
     [ngClass]="{ 'faded' : action.faded }"
     (click)="clicked.emit(action)">
  <img *ngIf="action.icon"
       alt=""
       [src]="action.icon"
       class="icon mt-4px">
  <div class="flex-fill block ml-8px">
    <div class="title">{{action.title}}</div>
    <div class="desc">{{action.desc}}</div>
  </div>
  <div *ngIf="action.when" class="time">{{action.when}}</div>
</div>
<hr class="mt-12px">


