<app-content-container
  [unsetMinHeight]="true"
  [narrow]="true">
  <div class="py-5">
    <p class="title-sm" i18n>Nothing to watch...yet.</p>
    <p class="paragraph-lg">
      <ng-container *ngIf="type === noContentType.Favourites" i18n>
        There are no programs that match your account preferences.
        Update your preferences and check back here for more curated content.
      </ng-container>
      <ng-container *ngIf="type === noContentType.Team" i18n>
        This team doesn't have any programs to watch right now.
        Check back as more programs are added.
      </ng-container>
      <ng-container *ngIf="type === noContentType.VenueStream" i18n>
        This stream doesn't have any programs to watch right now.
        Check back as more programs are added.
      </ng-container>
      <ng-container *ngIf="type === noContentType.League" i18n>
        This league doesn't have any programs to watch right now.
        Check back as more programs are added.
      </ng-container>
      <ng-container *ngIf="type === noContentType.Event" i18n>
        This event doesn't have any programs to watch right now.
        Check back as more programs are added.
      </ng-container>
    </p>
    <button class="custom-button preferred-button" (click)="ctaClicked()">
      <ng-container *ngIf="type === noContentType.Favourites" i18n>
        Update Preferences
      </ng-container>
      <ng-container *ngIf="type !== noContentType.Favourites" i18n>
        Return to the Home Page
      </ng-container>
    </button>
  </div>
</app-content-container>
