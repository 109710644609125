<app-detail-title
  [hrCustomClass]=" (viewModel.addNewVenue$|async) ? '' : 'd-none'"
  i18n>
  Venues
</app-detail-title>
<app-content-container [narrow]="viewModel.addNewVenue$|async">
  <app-detail-back-button [backRoute]="'..'">
    <ng-container *ngIf="viewModel.addNewVenue$|async" i18n>Add a New Venue</ng-container>
    <ng-container *ngIf="!(viewModel.addNewVenue$|async)" i18n>{{(viewModel.venue$|async)?.name}}</ng-container>
  </app-detail-back-button>
  <app-tab-bar [class.hide-tab-header]="viewModel.addNewVenue$|async" [tabs]="tabs"></app-tab-bar>
</app-content-container>
