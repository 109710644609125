import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Checkbox} from 'src/app/models/shared/stylesheet/checkbox';
import {Radiobutton} from '../../../../../models/shared/stylesheet/radiobutton';

/**
 * @param checkbox: can be used to initialize data, else use parameters below
 * @param label: what is displayed beside the checkmark
 * @param disabled: self explanatory
 */
@Component({
  selector: 'app-radio-button',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadioButtonComponent implements OnInit {

  public cid = Math.random().toString(24);

  @Input() radiobutton: Radiobutton;
  @Input() label: string;
  @Input() title: string;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() refresh: EventEmitter<any>;
  @Input() inline: boolean = false;
  @Input() customCheckboxMargin: string;
  @Input() showDivider: boolean = false;

  @Output() clickEvent = new EventEmitter<any>();

  @Input() isBoxed: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.refresh?.subscribe(() => this.refreshObj());
    this.refreshObj();
  }

  refreshObj() {
    if (this.radiobutton) {
      this.label = this.radiobutton.label;
      this.checked = this.radiobutton.selected;
      this.disabled = this.radiobutton.disabled;
    }
  }

  onClick() {
    this.checked = true;
    if (this.radiobutton) {
      this.radiobutton.selected = this.checked;
    }
    this.clickEvent.emit(this.checked);
  }

  onBoxClick() {
    this.checked = true;
    this.radiobutton.selected = true;
    this.clickEvent.emit(this.radiobutton.selected);
  }
}
