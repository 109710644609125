import {TeamFormObject} from './team-form-object';
import {HydratedVenue} from './hydrated-venue';
import {SafeResourceUrl} from '@angular/platform-browser';

export class VenueFormObject {
  public venue: HydratedVenue;
  public teamFormObjects: TeamFormObject[];

  imageToUpload: string | SafeResourceUrl;
  existingImageId: string;
  deleteImageId: string;

  static initWithVenue(venue: HydratedVenue): VenueFormObject {
    const formObject = new VenueFormObject();

    if (venue.images?.length > 0) {
      formObject.existingImageId = venue.images[0]?.id;
    }
    formObject.venue = venue;
    formObject.teamFormObjects = venue.associatedTeams?.map(t => TeamFormObject.initWithTeam(t)) ?? [];
    return formObject;
  }
}
