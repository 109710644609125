import {TeamId} from './teamId';

export class FavouriteTeams {
  favouriteTeams: TeamId[] = [];

  constructor(favouriteTeams: TeamId[] = null) {
    this.favouriteTeams = favouriteTeams ?? [];
  }

  toggleId(id: number) {
    const i = this.favouriteTeams.findIndex(item => item.teamId === id);
    if (i > -1) {
      this.favouriteTeams.splice(i, 1);
    } else {
      this.favouriteTeams.push(new TeamId(id));
    }
  }
}
