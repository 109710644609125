
export enum AuthFlow {
  SignUp,
  SignIn,
  ForgotPassword,
  ResetPassword,
  SetNewPassword,
}

export enum SubscriberSignUpFlow {
  EmailVerification,
  EmailVerificationLinkExisted,
  EmailVerificationLinkExpired,
  EmailVerificationUnverified,
  EmailVerificationInvalidLink,
  CreateProfile,
  PickPlan,
  PickPlanPackage,
  Review,
  AddPaymentDetails,
  Completed,
  ChooseOrganizationToSupport,
  ProfileDetails,
}

export enum EditSubscriptionFlow {
  EditPlans,
  AddDiscount,
}

export enum GoogleAnalyticsEnum{
  Login='login',
  LogOut='logout',
  LoggedInStatus='logged_in',
  NotLoggedInStatus='not_logged_in',
  UnPaidAccount='unpaid',
  SignupFunnel='signup_funnel',
  FreeSignUp='free_signup',
  EmailVerification='verify_email_clicked ',
  PassWordEntered = 'password_entered',
  PlanSelection='plan_selection',
  PurchaseFunnel='purchase_funnel',
  PlanSelected='plan_selected',
  PackageSelected='package_selected',
  PackageSelection='package_selection',
  ReviewSubmitted='review_submitted',
  PaymentInfoSubmitted='payment_info_submitted',
  HomeTeamStandardLeagueSelection='home_team_standard_league_selection',
  leagueSelected='league_selected',
  AfterEcommercePurchase='after_signup_purchase',
  SignUpPurchase='sign_up_purchase',
  Error='error',
  SignUpStep='sign_up_step',
  Payment='payment',
  PurchaseFunnelError='purchase_funnel_error',
  HomeTeamLive='HomeTeamLive',
  SkipForNowForPayment='skip_for_now_for_payment',
  ApplyForReview='apply_for_review',
  CompleteForPayment='complete_for_payment',
  VerifyEmail='verify_email',
  PlanPriceForPackage='plan_price_for_package',
  LoginStatus='login_status',
  UserId='user_id',
  PlanCancellation='plan_cancellation'
}
