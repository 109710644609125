export class NumberUtils {
  static formFloatCustomParser(numString: string): number {
    return numString ? parseFloat(this.removeNonNumericCharacters(numString)) : null;
  }

  static formIntegerCustomParser(numString: string): number {
    return numString ? parseInt(this.removeNonNumericCharacters(numString), 10) : null;
  }

  static removeNonNumericCharacters(numString): string {
    return numString.replace(/(,|[^\d.-]+)+/g, '');
  }

  static removeNonDigitCharacters(numString): string {
    return numString.replace(/[^\d]/g, '');
  }

  static formatNumberAsPercentString(num: number): string {
    return `${num.toFixed(2)}%`;
  }

  static formatNumberAsCurrency(num: number): string {
    if (num == null) {
      return '';
    }
    if (num >= 0) {
      return `$${num.toFixed(2)}`;
    } else {
      return `-$${(num * -1).toFixed(2)}`;
    }
  }

  static zeroPad(num: number, places: number): string {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }
}
