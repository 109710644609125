<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title" i18n>
        Choose a Stream
      </div>
      <div class="my-4 paragraph-sm">
        Some venues can have multiple streams to choose from. Choose which stream you’d like to view
        at {{(viewModel.hydratedVenue$|async)?.name}}
      </div>
    </div>
  </div>
  <div class="sticky-body-container">
    <div *ngFor="let stream of viewModel.streams$|async">
      <div class="stream-item" (click)="streamClicked(stream)">
        <div class="paragraph-md my-4 flex-grow-1" i18n>
          {{stream.name}}
        </div>
        <img class="" [src]="'assets/icons/dark/outline/chevron-right.svg'" alt="">
      </div>
      <hr class="my-0">
    </div>
  </div>
  <div class="sticky-footer-container modal-footer-flex-end">
    <button class="custom-button neutral-button" (click)="cancel()">
      Back
    </button>
  </div>
</div>
