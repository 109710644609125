<div [ngClass]="{'disabled' : options.disabled}"
     class="range-container">
  <label [hidden]="options.sliderOnly"
         class="control-label custom-textfield-label range-inline-label"
         for="range-input">
    {{ options.label }}
  </label>
  <output [hidden]="options.sliderOnly"
          class="custom-range-output">
    {{ options.value }}
  </output>
  <mat-slider
    (ngModelChange)="updateRangeValue($event)"
    [ngModel]="options.value"
    [disabled]="options.disabled"
    [invert]="options.inverted"
    [max]="options.max"
    [min]="options.min"
    [ngStyle]="{ 'margin' : '-0.5rem' }"
    [step]="options.step"
    [thumbLabel]="options.showThumbLabel"
    [tickInterval]="options.step"
    [vertical]="options.verticalOrientation"
    class="custom-range"
    id="range-input">
  </mat-slider>

</div>
