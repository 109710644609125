import {Component, EventEmitter, OnInit} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NavigationEnd, Router} from '@angular/router';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {ProgramComment} from '../../../../models/program/program-comment';
import {ChangePasswordRequest} from '../../../../models/account/requests/change-password-request';
import {PasswordValidatorDirective} from '../form-group/validators/password-validator.directive';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent extends BaseModal implements OnInit {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ChangePasswordRequest = new ChangePasswordRequest();
  public updatedFormObject = new EventEmitter<void>();

  constructor(
    private activeModal: NgbActiveModal,
    public router: Router,
    public modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.bindToBackNav();
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  formSubmitted(result: ChangePasswordRequest) {
    this.activeModal.close(result);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const currentPassword = new FormInputItem();
    currentPassword.inputName = 'currentPassword';
    currentPassword.inputType = FormInputType.Password;
    currentPassword.label = $localize`Old Password`;
    currentPassword.placeholder = $localize`Old Password`;
    currentPassword.bindingProperty = 'currentPassword';
    currentPassword.required = true;
    items.push(currentPassword);

    const newPassword = new FormInputItem();
    newPassword.inputName = 'newPassword';
    newPassword.inputType = FormInputType.Password;
    newPassword.label = $localize`New Password`;
    newPassword.placeholder = $localize`New Password`;
    newPassword.bindingProperty = 'newPassword';
    newPassword.required = true;
    newPassword.customValidator = new PasswordValidatorDirective();
    items.push(newPassword);

    this.formItems = items;
  }

  setupFormStyling() {
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.primaryButtonContainerClass = 'd-flex justify-content-end';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Changes`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }
}
