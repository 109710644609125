import {AdminSections} from '../../enum/admin-permission.enum';
import {PermissionBase} from './admin-permission-base';

// Super Admin (L3) in the Individual Access Permissions pdf file
export class SuperAdminPermission extends PermissionBase {
  constructor() {
    super();
    this.sections = [
      // Users section
      AdminSections.Users,
      AdminSections.Users_MainPage,
      AdminSections.Users_Search,
      AdminSections.Users_Invite_User,
      AdminSections.Users_Edit_User,
      AdminSections.Users_Edit_User_Active,
      AdminSections.Users_Edit_User_General_Info,
      // Sports section
      AdminSections.Sports,
      AdminSections.Sports_MainPage,
      AdminSections.Sports_Search,
      AdminSections.Sports_Add_Sport,
      AdminSections.Sports_Edit_Sport,
      AdminSections.Sports_Edit_Sport_Edit_Info,
      AdminSections.Sports_Edit_Sport_Active,
      // Leagues section
      AdminSections.Leagues,
      AdminSections.Leagues_MainPage,
      AdminSections.Leagues_Search,
      AdminSections.Leagues_Add_League,
      AdminSections.Leagues_Edit_League_Details_View,
      AdminSections.Leagues_Edit_League_Details_Edit,
      AdminSections.Leagues_Edit_League_Details_Active,
      AdminSections.Leagues_Edit_League_Teams_View,
      AdminSections.Leagues_Edit_League_Teams_Add_Team_To_League,
      AdminSections.Leagues_Edit_League_Teams_Add,
      AdminSections.Leagues_Edit_League_Teams_Edit_Team,
      AdminSections.Leagues_Edit_League_Teams_Active,
      AdminSections.Leagues_Edit_League_Teams_Remove_Team,
      AdminSections.Leagues_Edit_League_Venues_view,
      AdminSections.Leagues_Edit_League_Venues_add,
      AdminSections.Leagues_Edit_League_Venues_remove,
      AdminSections.Leagues_Edit_League_Venues_Remove_Venue,
      AdminSections.Leagues_Edit_League_Seasons_View,
      AdminSections.Leagues_Edit_League_Seasons_Add,
      AdminSections.Leagues_Edit_League_Seasons_Edit,
      AdminSections.Leagues_Edit_League_Seasons_Remove,
      AdminSections.Leagues_Edit_League_Programs_View,
      AdminSections.Leagues_Edit_League_Programs_Program_Uploader,
      AdminSections.Leagues_Edit_League_Programs_Complete,
      AdminSections.Leagues_Edit_League_Programs_Add,
      AdminSections.Leagues_Edit_League_Programs_Edit,
      AdminSections.Leagues_Edit_League_Programs_Remove,
      AdminSections.Leagues_Edit_League_Shows_View,
      AdminSections.Leagues_Edit_League_Shows_Add,
      AdminSections.Leagues_Edit_League_Shows_Edit,
      AdminSections.Leagues_Edit_League_Shows_Remove,
      AdminSections.Leagues_Edit_League_Banner_Ads_View,
      AdminSections.Leagues_Edit_League_Banner_Ads_Add,
      AdminSections.Leagues_Edit_League_Banner_Ads_Edit,
      AdminSections.Leagues_Edit_League_Banner_Ads_Remove,
      // Venues section
      AdminSections.Venues,
      AdminSections.Venues_MainPage,
      AdminSections.Venues_Search,
      AdminSections.Venues_Add_Venue,
      AdminSections.Venues_Edit_Venue_Details_Edit_Info,
      AdminSections.Venues_Edit_Venue_Details_Active,
      AdminSections.Venues_Edit_Venue_Streams_View,
      AdminSections.Venues_Edit_Venue_Streams_Add,
      AdminSections.Venues_Edit_Venue_Streams_Edit,
      AdminSections.Venues_Edit_Venue_Streams_Active,
      AdminSections.Venues_Edit_Venue_Teams_View,
      AdminSections.Venues_Edit_Venue_Teams_Add_Team_To_Venue,
      AdminSections.Venues_Edit_Venue_Teams_Add,
      AdminSections.Venues_Edit_Venue_Teams_Edit_Team,
      AdminSections.Venues_Edit_Venue_Teams_Remove,
      AdminSections.Venues_Edit_Venue_Programs_View,
      AdminSections.Venues_Edit_Venue_Programs_Program_Uploader,
      AdminSections.Venues_Edit_Venue_Programs_Complete,
      AdminSections.Venues_Edit_Venue_Programs_Add,
      AdminSections.Venues_Edit_Venue_Programs_Edit,
      AdminSections.Venues_Edit_Venue_Programs_Remove,
      // Events section
      AdminSections.Events,
      AdminSections.Events_MainPage,
      AdminSections.Events_Search,
      AdminSections.Events_Add_Event,
      AdminSections.Events_Edit_Event_Details_View,
      AdminSections.Events_Edit_Event_Details_Edit,
      AdminSections.Events_Edit_Event_Details_Active,
      AdminSections.Events_Edit_Event_Streams_View,
      AdminSections.Events_Edit_Event_Streams_Add,
      AdminSections.Events_Edit_Event_Streams_Edit,
      AdminSections.Events_Edit_Event_Streams_Active,
      AdminSections.Events_Edit_Event_Teams_View,
      AdminSections.Events_Edit_Event_Teams_Add_Team_To_Event,
      AdminSections.Events_Edit_Event_Teams_Add,
      AdminSections.Events_Edit_Event_Teams_Edit_Team,
      AdminSections.Events_Edit_Event_Teams_Remove,
      AdminSections.Events_Edit_Event_Venues_View,
      AdminSections.Events_Edit_Event_Venues_Add,
      AdminSections.Events_Edit_Event_Venues_Remove,
      AdminSections.Events_Edit_Event_Programs_View,
      AdminSections.Events_Edit_Event_Programs_Program_Uploader,
      AdminSections.Events_Edit_Event_Programs_Complete,
      AdminSections.Events_Edit_Event_Programs_Add,
      AdminSections.Events_Edit_Event_Programs_Edit,
      AdminSections.Events_Edit_Event_Programs_Remove,
      AdminSections.Events_Edit_Event_Shows_View,
      AdminSections.Events_Edit_Event_Shows_Add,
      AdminSections.Events_Edit_Event_Shows_Edit,
      AdminSections.Events_Edit_Event_Shows_Remove,
      AdminSections.Events_Edit_Event_Banner_Ads_View,
      AdminSections.Events_Edit_Event_Banner_Ads_Add,
      AdminSections.Events_Edit_Event_Banner_Ads_Edit,
      AdminSections.Events_Edit_Event_Banner_Ads_Remove,
      // Teams Section
      AdminSections.Teams,
      AdminSections.Teams_MainPage,
      AdminSections.Teams_Search,
      AdminSections.Teams_Add_Team,
      AdminSections.Teams_Edit_Team_Details_View,
      AdminSections.Teams_Edit_Team_Details_Edit,
      AdminSections.Teams_Edit_Team_Details_Active,
      // Plan Section
      AdminSections.Plans,
      AdminSections.Plans_MainPage,
      AdminSections.Plans_Search,
      AdminSections.Plans_Add_Plan,
      AdminSections.Plans_Edit_Plan_View,
      AdminSections.Plans_Edit_Plan_Edit,
      AdminSections.Plans_Edit_Plan_Active,
      AdminSections.Plans_Edit_Plan_Plan_Price_View,
      AdminSections.Plans_Edit_Plan_Plan_Price_Edit,
      AdminSections.Plans_Edit_Plan_Plan_Price_Active,
      // Camera Systems Section
      AdminSections.Camera_Systems,
      AdminSections.Camera_Systems_MainPage,
      AdminSections.Camera_Systems_Search,
      AdminSections.Camera_Systems_Add_Camera_System,
      AdminSections.Camera_Systems_Edit_Camera_System_View,
      AdminSections.Camera_Systems_Edit_Camera_System_Edit,
      AdminSections.Camera_Systems_Edit_Camera_System_Active,
      // Advertisement Section
      AdminSections.Advertisement,
      AdminSections.Advertisement_MainPage,
      AdminSections.Advertisement_Search_Partners,
      AdminSections.Advertisement_Add_Partner,
      AdminSections.Advertisement_View_Advertiser_Table,
      AdminSections.Advertisement_Edit_Advertiser_View,
      AdminSections.Advertisement_Edit_Advertiser_Edit,
      AdminSections.Advertisement_Edit_Advertiser_Active,
      AdminSections.Advertisement_Add_Advertisement,
      AdminSections.Advertisement_Edit_Advertisement_View,
      AdminSections.Advertisement_Edit_Advertisement_Remove_Media,
      AdminSections.Advertisement_Edit_Advertisement_Remove_Image,
      AdminSections.Advertisement_Edit_Advertisement_Active,
      // Profile Section
      AdminSections.Profile_View_And_Edit,
      // Sign_out Section
      AdminSections.Sign_Out_Individual_Account
    ];
  }
}

// Trained Staff (L2) in the Individual Access Permissions pdf file
export class OperationStaffPermission extends PermissionBase {
  constructor() {
    super();
    this.sections = [
      // Sports section
      AdminSections.Sports,
      AdminSections.Sports_MainPage,
      AdminSections.Sports_Search,
      AdminSections.Sports_Add_Sport,
      AdminSections.Sports_Edit_Sport,
      AdminSections.Sports_Edit_Sport_Edit_Info,
      AdminSections.Sports_Edit_Sport_Active,
      // Leagues section
      AdminSections.Leagues,
      AdminSections.Leagues_MainPage,
      AdminSections.Leagues_Search,
      AdminSections.Leagues_Add_League,
      AdminSections.Leagues_Edit_League_Details_View,
      AdminSections.Leagues_Edit_League_Details_Edit,
      AdminSections.Leagues_Edit_League_Details_Active,
      AdminSections.Leagues_Edit_League_Teams_View,
      AdminSections.Leagues_Edit_League_Teams_Add_Team_To_League,
      AdminSections.Leagues_Edit_League_Teams_Add,
      AdminSections.Leagues_Edit_League_Teams_Edit_Team,
      AdminSections.Leagues_Edit_League_Teams_Active,
      AdminSections.Leagues_Edit_League_Teams_Remove_Team,
      AdminSections.Leagues_Edit_League_Venues_view,
      AdminSections.Leagues_Edit_League_Venues_add,
      AdminSections.Leagues_Edit_League_Venues_remove,
      AdminSections.Leagues_Edit_League_Venues_Remove_Venue,
      AdminSections.Leagues_Edit_League_Seasons_View,
      AdminSections.Leagues_Edit_League_Seasons_Add,
      AdminSections.Leagues_Edit_League_Seasons_Edit,
      AdminSections.Leagues_Edit_League_Seasons_Remove,
      AdminSections.Leagues_Edit_League_Programs_View,
      AdminSections.Leagues_Edit_League_Programs_Program_Uploader,
      AdminSections.Leagues_Edit_League_Programs_Add,
      AdminSections.Leagues_Edit_League_Programs_Edit,
      AdminSections.Leagues_Edit_League_Programs_Remove,
      AdminSections.Leagues_Edit_League_Programs_Complete,
      AdminSections.Leagues_Edit_League_Shows_View,
      AdminSections.Leagues_Edit_League_Shows_Add,
      AdminSections.Leagues_Edit_League_Shows_Edit,
      AdminSections.Leagues_Edit_League_Shows_Remove,
      AdminSections.Leagues_Edit_League_Banner_Ads_View,
      AdminSections.Leagues_Edit_League_Banner_Ads_Add,
      AdminSections.Leagues_Edit_League_Banner_Ads_Edit,
      AdminSections.Leagues_Edit_League_Banner_Ads_Remove,
      // Venues section
      AdminSections.Venues,
      AdminSections.Venues_MainPage,
      AdminSections.Venues_Search,
      AdminSections.Venues_Add_Venue,
      AdminSections.Venues_Edit_Venue_Details_View,
      AdminSections.Venues_Edit_Venue_Details_Edit_Info,
      AdminSections.Venues_Edit_Venue_Details_Active,
      AdminSections.Venues_Edit_Venue_Streams_View,
      AdminSections.Venues_Edit_Venue_Streams_Add,
      AdminSections.Venues_Edit_Venue_Streams_Edit,
      AdminSections.Venues_Edit_Venue_Streams_Active,
      AdminSections.Venues_Edit_Venue_Teams_View,
      AdminSections.Venues_Edit_Venue_Teams_Add_Team_To_Venue,
      AdminSections.Venues_Edit_Venue_Teams_Add,
      AdminSections.Venues_Edit_Venue_Teams_Edit_Team,
      AdminSections.Venues_Edit_Venue_Teams_Remove,
      AdminSections.Venues_Edit_Venue_Programs_View,
      AdminSections.Venues_Edit_Venue_Programs_Program_Uploader,
      AdminSections.Venues_Edit_Venue_Programs_Add,
      AdminSections.Venues_Edit_Venue_Programs_Edit,
      AdminSections.Venues_Edit_Venue_Programs_Complete,
      AdminSections.Venues_Edit_Venue_Programs_Remove,
      // Events section
      AdminSections.Events,
      AdminSections.Events_MainPage,
      AdminSections.Events_Search,
      AdminSections.Events_Add_Event,
      AdminSections.Events_Edit_Event_Details_View,
      AdminSections.Events_Edit_Event_Details_Edit,
      AdminSections.Events_Edit_Event_Details_Active,
      AdminSections.Events_Edit_Event_Streams_View,
      AdminSections.Events_Edit_Event_Streams_Add,
      AdminSections.Events_Edit_Event_Streams_Edit,
      AdminSections.Events_Edit_Event_Streams_Active,
      AdminSections.Events_Edit_Event_Teams_View,
      AdminSections.Events_Edit_Event_Teams_Add_Team_To_Event,
      AdminSections.Events_Edit_Event_Teams_Add,
      AdminSections.Events_Edit_Event_Teams_Edit_Team,
      AdminSections.Events_Edit_Event_Teams_Remove,
      AdminSections.Events_Edit_Event_Venues_View,
      AdminSections.Events_Edit_Event_Venues_Add,
      AdminSections.Events_Edit_Event_Venues_Remove,
      AdminSections.Events_Edit_Event_Programs_View,
      AdminSections.Events_Edit_Event_Programs_Program_Uploader,
      AdminSections.Events_Edit_Event_Programs_Add,
      AdminSections.Events_Edit_Event_Programs_Edit,
      AdminSections.Events_Edit_Event_Programs_Remove,
      AdminSections.Events_Edit_Event_Shows_View,
      AdminSections.Events_Edit_Event_Shows_Add,
      AdminSections.Events_Edit_Event_Shows_Edit,
      AdminSections.Events_Edit_Event_Shows_Remove,
      AdminSections.Events_Edit_Event_Banner_Ads_View,
      AdminSections.Events_Edit_Event_Banner_Ads_Add,
      AdminSections.Events_Edit_Event_Banner_Ads_Edit,
      AdminSections.Events_Edit_Event_Banner_Ads_Remove,
      AdminSections.Events_Edit_Event_Programs_Complete,
      // Teams Section
      AdminSections.Teams,
      AdminSections.Teams_MainPage,
      AdminSections.Teams_Search,
      AdminSections.Teams_Add_Team,
      AdminSections.Teams_Edit_Team_Details_View,
      AdminSections.Teams_Edit_Team_Details_Edit,
      AdminSections.Teams_Edit_Team_Details_Active,
      // Plan Section
      AdminSections.Plans,
      AdminSections.Plans_MainPage,
      AdminSections.Plans_Search,
      AdminSections.Plans_Add_Plan,
      AdminSections.Plans_Edit_Plan_View,
      AdminSections.Plans_Edit_Plan_Edit,
      AdminSections.Plans_Edit_Plan_Active,
      AdminSections.Plans_Edit_Plan_Plan_Price_View,
      AdminSections.Plans_Edit_Plan_Plan_Price_Edit,
      AdminSections.Plans_Edit_Plan_Plan_Price_Active,
      // Camera Systems Section
      AdminSections.Camera_Systems_Add_Camera_System,
      AdminSections.Camera_Systems_Edit_Camera_System_Edit,
      // Advertisement Section
      AdminSections.Advertisement,
      AdminSections.Advertisement_MainPage,
      AdminSections.Advertisement_Search_Partners,
      AdminSections.Advertisement_Add_Partner,
      AdminSections.Advertisement_View_Advertiser_Table,
      AdminSections.Advertisement_Edit_Advertiser_View,
      AdminSections.Advertisement_Edit_Advertiser_Edit,
      AdminSections.Advertisement_Edit_Advertiser_Active,
      AdminSections.Advertisement_Add_Advertisement,
      AdminSections.Advertisement_Edit_Advertisement_View,
      AdminSections.Advertisement_Edit_Advertisement_Edit,
      AdminSections.Advertisement_Edit_Advertisement_Remove_Media,
      AdminSections.Advertisement_Edit_Advertisement_Remove_Image,
      AdminSections.Advertisement_Edit_Advertisement_Active,
      // Profile Section
      AdminSections.Profile_View_And_Edit,
      // Sign_out Section
      AdminSections.Sign_Out_Individual_Account
    ];
  }
}

// Contractors (L1) in the Individual Access Permissions pdf file
export class Contractors extends PermissionBase {
  constructor() {
    super();
    this.sections = [
      // Sports section
      AdminSections.Sports,
      AdminSections.Sports_MainPage,
      AdminSections.Sports_Search,
      AdminSections.Sports_Edit_Sport,
      // Leagues section
      AdminSections.Leagues,
      AdminSections.Leagues_MainPage,
      AdminSections.Leagues_Search,
      AdminSections.Leagues_Edit_League_Details_View,
      AdminSections.Leagues_Edit_League_Teams_Add,
      AdminSections.Leagues_Edit_League_Teams_View,
      AdminSections.Leagues_Edit_League_Venues_view,
      AdminSections.Leagues_Edit_League_Venues_add,
      AdminSections.Leagues_Edit_League_Seasons_View,
      AdminSections.Leagues_Edit_League_Seasons_Add,
      AdminSections.Leagues_Edit_League_Programs_View,
      AdminSections.Leagues_Edit_League_Programs_Program_Uploader,
      AdminSections.Leagues_Edit_League_Programs_Add,
      AdminSections.Leagues_Edit_League_Programs_Edit,
      AdminSections.Leagues_Edit_League_Programs_Complete,
      AdminSections.Leagues_Edit_League_Programs_Remove,
      AdminSections.Leagues_Edit_League_Shows_View,
      AdminSections.Leagues_Edit_League_Shows_Add,
      AdminSections.Leagues_Edit_League_Banner_Ads_View,
      // Venues section
      AdminSections.Venues,
      AdminSections.Venues_MainPage,
      AdminSections.Venues_Search,
      AdminSections.Venues_Edit_Venue_Details_View,
      AdminSections.Venues_Edit_Venue_Streams_View,
      AdminSections.Venues_Edit_Venue_Teams_Add,
      AdminSections.Venues_Edit_Venue_Teams_View,
      AdminSections.Venues_Edit_Venue_Programs_View,
      AdminSections.Venues_Edit_Venue_Programs_Program_Uploader,
      AdminSections.Venues_Edit_Venue_Programs_Add,
      AdminSections.Venues_Edit_Venue_Programs_Edit,
      AdminSections.Venues_Edit_Venue_Programs_Complete,
      AdminSections.Venues_Edit_Venue_Programs_Remove,
      // Events section
      AdminSections.Events,
      AdminSections.Events_MainPage,
      AdminSections.Events_Search,
      AdminSections.Events_Edit_Event_Details_View,
      AdminSections.Events_Edit_Event_Venues_Add,
      AdminSections.Events_Edit_Event_Teams_View,
      AdminSections.Events_Edit_Event_Teams_Add,
      AdminSections.Events_Edit_Event_Venues_View,
      AdminSections.Events_Edit_Event_Programs_View,
      AdminSections.Events_Edit_Event_Programs_Program_Uploader,
      AdminSections.Events_Edit_Event_Programs_Add,
      AdminSections.Events_Edit_Event_Programs_Edit,
      AdminSections.Events_Edit_Event_Programs_Remove,
      AdminSections.Events_Edit_Event_Shows_View,
      AdminSections.Events_Edit_Event_Shows_Add,
      AdminSections.Events_Edit_Event_Banner_Ads_View,
      AdminSections.Events_Edit_Event_Programs_Complete,
      // Teams Section
      AdminSections.Teams,
      AdminSections.Teams_Add_Team,
      AdminSections.Teams_Edit_Team_Details_Edit,
      AdminSections.Teams_MainPage,
      AdminSections.Teams_Search,
      // Plans Section
      AdminSections.Plans,
      AdminSections.Plans_MainPage,
      AdminSections.Plans_Search,
      AdminSections.Plans_Edit_Plan_Plan_Price_View,
      // Camera Systems Section
      AdminSections.Camera_Systems,
      AdminSections.Camera_Systems_MainPage,
      AdminSections.Camera_Systems_Search,
      AdminSections.Camera_Systems_Edit_Camera_System_View,
      // Advertisement Section
      AdminSections.Advertisement,
      AdminSections.Advertisement_MainPage,
      AdminSections.Advertisement_Search_Partners,
      AdminSections.Advertisement_View_Advertiser_Table,
      AdminSections.Advertisement_Edit_Advertiser_View,
      // Profile Section
      AdminSections.Profile_View_And_Edit,
      // Sign_out Section
      AdminSections.Sign_Out_Individual_Account
    ];
  }
}

// Trainee Staff (L0) in the Individual Access Permissions pdf file
export class OperationsTrainee extends PermissionBase {
  constructor() {
    super();
    this.sections = [
      // Sport section
      AdminSections.Sports,
      AdminSections.Sports_MainPage,
      AdminSections.Sports_Search,
      AdminSections.Sports_Edit_Sport,
      // Leagues section
      AdminSections.Leagues,
      AdminSections.Leagues_MainPage,
      AdminSections.Leagues_Search,
      AdminSections.Leagues_Edit_League_Details_View,
      AdminSections.Leagues_Edit_League_Teams_View,
      AdminSections.Leagues_Edit_League_Venues_view,
      AdminSections.Leagues_Edit_League_Seasons_View,
      AdminSections.Leagues_Edit_League_Programs_View,
      AdminSections.Leagues_Edit_League_Shows_View,
      AdminSections.Leagues_Edit_League_Banner_Ads_View,
      // Venues section
      AdminSections.Venues,
      AdminSections.Venues_MainPage,
      AdminSections.Venues_Search,
      AdminSections.Venues_Edit_Venue_Details_View,
      AdminSections.Venues_Edit_Venue_Streams_View,
      AdminSections.Venues_Edit_Venue_Teams_View,
      AdminSections.Venues_Edit_Venue_Programs_View,
      // Events section
      AdminSections.Events,
      AdminSections.Events_MainPage,
      AdminSections.Events_Search,
      AdminSections.Events_Edit_Event_Details_View,
      AdminSections.Events_Edit_Event_Teams_View,
      AdminSections.Events_Edit_Event_Venues_View,
      AdminSections.Events_Edit_Event_Programs_View,
      AdminSections.Events_Edit_Event_Shows_View,
      AdminSections.Events_Edit_Event_Banner_Ads_View,
      // Teams section
      AdminSections.Teams,
      AdminSections.Teams_MainPage,
      AdminSections.Teams_Search,
      AdminSections.Teams_Edit_Team_Details_View,
      // Plans Section
      AdminSections.Plans,
      AdminSections.Plans_MainPage,
      AdminSections.Plans_Search,
      AdminSections.Plans_Edit_Plan_Plan_Price_View,
      // Camera Systems Section
      AdminSections.Camera_Systems,
      AdminSections.Camera_Systems_MainPage,
      AdminSections.Camera_Systems_Search,
      AdminSections.Camera_Systems_Edit_Camera_System_View,
      // Advertisement Section
      AdminSections.Advertisement,
      AdminSections.Advertisement_MainPage,
      AdminSections.Advertisement_Search_Partners,
      AdminSections.Advertisement_View_Advertiser_Table,
      AdminSections.Advertisement_Edit_Advertiser_View,
      // Profile Section
      AdminSections.Profile_View_And_Edit,
      // Sign_out Section
      AdminSections.Sign_Out_Individual_Account
    ];
  }
}
