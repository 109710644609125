import {BehaviorSubject, defer, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, take, tap} from 'rxjs/operators';
import {LoadingOptions} from '../models/shared/loading-options';

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    notNull(): Observable<T>;

    firstNotNull(): Observable<T>;
  }
}

Observable.prototype.notNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x != null));
  };

Observable.prototype.firstNotNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x != null), take(1));
  };


export const indicate = <T>(loadingOpts: LoadingOptions, message: string):
  (source: Observable<T>) => Observable<T> => (source: Observable<T>):
  Observable<T> => source.pipe(
  prepare(() => loadingOpts.addRequest(message)),
  finalize(() => loadingOpts.removeRequest(message)),
);

export const indicateOnNext = <T>(loadingOpts: LoadingOptions, message: string): (source: Observable<T>) => Observable<T> => {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => loadingOpts.addRequest(message)),
    tap(() => loadingOpts.removeRequest(message)),
    finalize(() => loadingOpts.removeRequest(message)),
  );
};

export const prepare = <T>(callback: () => void): (source: Observable<T>) => Observable<T> => {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
};
