import {SafeResourceUrl} from '@angular/platform-browser';
import {Show} from './show';
import {HydratedShow} from './hydrated-show';
import {CustomFile} from '../shared/custom-file';
import {VideoUpload} from '../image/dto/video-upload';

export class ShowFormObject {
  show: Show;
  imageToUpload: string | SafeResourceUrl;
  imageToUploadFormat: string;
  existingImageId: string;
  deleteImageId: string;
  leagueId: number = null;
  eventId: number = null;

  videoToUpload: CustomFile;
  existingVideo: VideoUpload;
  deleteVideoId: string;

  static initWithShow(show: HydratedShow, uploads: any[] | VideoUpload[]): ShowFormObject {
    const formObject = new ShowFormObject();
    formObject.show = show;
    formObject.existingVideo = uploads?.find(u => !!u);

    if (show.images?.length > 0) {
      formObject.existingImageId = show.images[0]?.id;
    }

    return formObject;
  }
}
