import {Component, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ProgramPlayerViewModel} from './program-player-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ProgramRowType} from '../program-row/program-row.component';
import {ContentQuery} from '../../../../models/program/content-query';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackModalComponent} from '../../../shared/components/feedback-modal/feedback-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';

@Component({
  selector: 'app-program-player',
  templateUrl: './program-player.component.html',
  styleUrls: ['./program-player.component.scss'],
  providers: [ProgramPlayerViewModel],
})
export class ProgramPlayerComponent extends BaseComponent implements OnChanges {
  programRowType = ProgramRowType;
  @Input() programId: string = null;
  @Input() isShow: boolean = false;
  @Input() programQuery: ContentQuery = null;
  @Input() showBottomDivider: boolean = true;
  @Input() smallVideoPlayer: boolean = false;

  playVideoEventEmitter = new EventEmitter<boolean>();

  constructor(
    public viewModel: ProgramPlayerViewModel,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.isShow) {
      this.viewModel.isShow$.next(this.isShow);
    }
    if (!!changes.programId) {
      this.viewModel.programId$.next(this.programId);
    }
    if (!!changes.programQuery) {
      this.viewModel.programContentQuery$.next(this.programQuery);
    }
  }

  setupViews() {

  }

  setupBindings() {
    this.activatedRoute.params.subscribe((params) => {
      this.scrollElementWIthIdIntoView('video-player', 'end');
    }).addTo(this.subscriptions);
  }

  watchNowClicked() {
    this.playVideoEventEmitter.emit(false);
  }

  startFromBeginningClicked() {
    this.playVideoEventEmitter.emit(true);
  }

  showFeedbackModal() {
    const modalRef = this.modalService.open(
      FeedbackModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    modalRef.result.then((programComment) => {
      if (programComment) {
        this.viewModel.submitProgramComment(programComment);
      }
    });
  }

  subscribeClicked() {
    this.viewModel.subscribeClicked();
  }
}
