import {BaseModel} from '../base/base-model';
import {FormListable} from '../protocols/form-listable';
import {Selectable} from '../protocols/selectable';

export class PixellotVenues extends BaseModel implements  FormListable, Selectable {
  id: number;
  name: string = '';

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  onDeserialize() {
    super.onDeserialize();
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.name;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}
