<div class="container-fluid p-0 marquee-container">
  <img class="marquee-background-image"
       *ngIf="featureProgramHomeTeamImgSrc$|async"
       [src]="featureProgramHomeTeamImgSrc$|async" alt="">
  <div class="marquee-background-image-overlay"></div>
  <app-content-container customClass="d-flex flex-column-reverse pb-5">

    <app-feature-marquee
      [isLive]="isLive()"
      [program$]="hydratedProgram$"
      (watchNowClicked)="featureWatchNow()"
      class="marquee-content"></app-feature-marquee>
  </app-content-container>
</div>



