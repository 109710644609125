import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageApi} from '../../../../api/image-api';
import {AddLeagueViewModel} from './add-league-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import AddressUtils from '../../../../utils/address-utils';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';

@Component({
  selector: 'app-add-league-modal',
  templateUrl: './add-league-modal.component.html',
  styleUrls: ['./add-league-modal.component.scss'],
  providers: [AddLeagueViewModel]
})
export class AddLeagueModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject = {league: null};

  constructor(
    public viewModel: AddLeagueViewModel,
    public modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private imageApi: ImageApi,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.leagues$.notNull().subscribe(v => {
      setTimeout(() => {
        this.updateLeagueSearchValues(v);
      });
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const leagueSearch = new FormInputItem();
    leagueSearch.itemType = FormItemType.Input;
    leagueSearch.inputType = FormInputType.Search;
    leagueSearch.inputName = 'leagueSearch';
    leagueSearch.label = 'Search For League';
    leagueSearch.placeholder = 'Search';
    leagueSearch.bindingProperty = 'league';
    leagueSearch.required = true;
    leagueSearch.inlineLabel = true;
    leagueSearch.customError = new Map<string, string>([
      ['searchFor', 'League Not Found'],
    ]);
    leagueSearch.searchable = [];
    items.push(leagueSearch);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Add`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject.league);
  }

  private updateLeagueSearchValues(leagues: HydratedLeague[]) {
    const leagueSearchFormItem = this.formItems.find(f => f.inputName === 'leagueSearch');
    leagueSearchFormItem.searchable.splice(0);
    leagueSearchFormItem.searchable.push(...leagues.map(l => {
      return {
        title: l.name,
        subtitle: l.abbreviation,
        lookupKey: l.name,
        value: l,
      };
    }));
  }
}
