import {Component} from '@angular/core';
import {BaseComponent} from '../../../models/base/base-component';
import {UsersViewModel} from './users-view-model';
import {TabBarItem} from '../../../models/shared/stylesheet/tab-bar-item';

import {AdminUsersComponent} from './admin-users/admin-users.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends BaseComponent{

  tabs: TabBarItem[] = [];

  constructor(
    public viewModel: UsersViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
    this.setupTabBar();
  }

  setupTabBar() {
    this.tabs = [];
    const admins = new TabBarItem(AdminUsersComponent, 2, $localize`Admins`, '');
    this.tabs.push(...[ admins]);
  }
}
