import {Component, EventEmitter, Input, ViewChild} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {PasswordValidatorDirective} from '../../../components/form-group/validators/password-validator.directive';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {ResetPasswordFormObject} from '../../../../../models/account/requests/reset-password-form-object';
import {AuthModalViewModel} from '../auth-modal-view-model';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {FormGroupComponent} from '../../../components/form-group/form-group.component';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent extends BaseComponent {

  @Input() primaryButtonClicked: EventEmitter<void>;
  @Input() secondaryButtonClicked: EventEmitter<void>;
  @ViewChild('resetPasswordForm') resetPasswordForm: FormGroupComponent;

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ResetPasswordFormObject = new ResetPasswordFormObject();

  constructor(
    public viewModel: AuthModalViewModel,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.errorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

    this.primaryButtonClicked.subscribe(() => {
      this.resetPasswordForm.submitForm();
    }).addTo(this.subscriptions);

    this.secondaryButtonClicked.subscribe(() => {
      this.formCancelled();
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.viewModel.clearErrorMessage();
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const password = new FormInputItem();
    password.inputName = 'password';
    password.inputType = FormInputType.Password;
    password.customValidator = new PasswordValidatorDirective();
    password.label = $localize`New Password`;
    password.placeholder = $localize`Enter your new password`;
    password.bindingProperty = 'newPassword';
    password.required = true;
    items.push(password);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formCancelled() {
    this.viewModel.authFlow = AuthFlow.SignIn;
  }

  formSubmitted() {
    this.viewModel.respondToNewPassChallenge(this.formObject);
  }

}
