/// <reference types="@types/googlemaps" />

import {BaseModel} from '../base/base-model';
import {DatatableData} from '../protocols/datatable-data';
import {Deserializable} from '../protocols/deserializable';
import AddressUtils from '../../utils/address-utils';

export class Address extends BaseModel implements DatatableData, Deserializable {
  public id: string;
  public addressLine1: string = null;
  public addressLine2: string = null;
  public apartment: string = null;
  public street: string = null;
  public city: string = null;
  public state: string = null;
  public stateId: number = null;
  public stateCode: string = null;
  public country: string = null;
  public countryId: number = null;
  public countryCode: string = null;

  public postalCode: string = null;
  public notes: string = null;
  public latitude: number = null;
  public longitude: number = null;

  static fromPlaceResult(place: google.maps.places.PlaceResult): Address {
    if (place == null) {
      return;
    }
    const address = new Address();
    const streetNumber = place.address_components.find(c => c.types.includes('street_number'))?.long_name;
    const route = place.address_components.find(c => c.types.includes('route'))?.long_name;
    address.street = route;
    address.city = place.address_components.find(c => c.types.includes('locality'))?.long_name;
    address.postalCode = place.address_components.find(c => c.types.includes('postal_code'))?.long_name;
    const state = place.address_components.find(c => c.types.includes('administrative_area_level_1'));
    address.state = state?.short_name;
    const country = place.address_components.find(c => c.types.includes('country'));
    address.country = country?.short_name;
    address.latitude = place.geometry.location.lat();
    address.longitude = place.geometry.location.lng();
    return address;
  }

  getAddress(): string {
    return AddressUtils.streetAddressString(this);
  }

  public onDeserialize() {
    super.onDeserialize();
    this.city = this.city ?? '';
    this.state = this.state ?? '';
    this.postalCode = this.postalCode ?? '';
    this.country = this.country ?? '';
    this.addressLine1 = this.addressLine1 ?? '';
    this.addressLine2 = this.addressLine2 ?? '';
    this.countryId = this.countryId ?? null;
    this.stateId = this.stateId ?? null;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  removeNullValues(): void {
    if (!this.city) {
      this.city = undefined;
    }
    if (!this.state) {
      this.state = undefined;
    }
    if (!this.postalCode) {
      this.postalCode = undefined;
    }
    if (!this.country) {
      this.country = undefined;
    }
    if (!this.addressLine1) {
      this.addressLine1 = undefined;
    }
    if (!this.addressLine2) {
      this.addressLine2 = undefined;
    }
    if (!this.countryId) {
      this.countryId = undefined;
    }
    if (!this.stateId) {
      this.stateId = undefined;
    }
  }
}

