<div class="container-fluid p-0 marquee-container">
  <app-loading
    [hidden]="!viewModel.featureProgramLoadingOpts.isLoading"
    [options]="viewModel.featureProgramLoadingOpts">
  </app-loading>
  <app-featured-program-slider [hydratedPrograms]="viewModel.featurePrograms$|async">
  </app-featured-program-slider>
</div>
<app-home-tab-bar
  *ngIf="(viewModel.isSignedIn$|async)"
  (favouritesTabSelected)="viewModel.fetchFavouriteContent$.next($event)">
</app-home-tab-bar>
<app-no-content *ngIf="viewModel.hasNoPreferences$|async" [type]="noContentType.Favourites"></app-no-content>
<app-sign-up-banner>
</app-sign-up-banner>
  <!-- Before live or upcoming program-->
  <div  *ngIf="(viewModel.isSignedIn$|async)" >
    <div id="leaderboard_atf_home_members"></div>
  </div>
  <div  *ngIf="!(viewModel.isSignedIn$|async)" >
    <div id="leaderboard_atf_home"></div>
  </div>
  <app-program-row *ngIf="!(viewModel.hasNoPreferences$|async)"
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.programContentQuery$|async"
  (noResults)="viewModel.liveProgramsNoResults.next($event)">
</app-program-row>
<app-program-row *ngIf="!(viewModel.hasNoPreferences$|async)"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.programContentQuery$|async"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)">
</app-program-row>
<app-program-row *ngIf="!(viewModel.hasNoPreferences$|async)"
  [programRowType]="programRowType.Past"
  [programContentQuery]="viewModel.programContentQuery$|async"
  (noResults)="viewModel.pastProgramsNoResults.next($event)">
</app-program-row>
<div *ngIf="!(viewModel.isSignedIn$|async)">
  <app-featured-leagues-row> </app-featured-leagues-row>
</div>
<!-- between featured and shows-->
<div *ngIf="!(viewModel.isSignedIn$|async)">
  <div id="leaderboard_btf_home"></div>
</div>
<app-program-row *ngIf="!(viewModel.hasNoPreferences$|async)"
  [programRowType]="programRowType.Shows"
  [programContentQuery]="viewModel.programContentQuery$|async"
  (noResults)="viewModel.showsNoResults.next($event)">
</app-program-row>
