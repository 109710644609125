import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, EMPTY, of, Subject} from 'rxjs';
import {filter, map, shareReplay, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {indicate, indicateOnNext} from '../../../../utils/observable.extensions';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {HydratedShow} from '../../../../models/program/hydrated-show';
import {ShowFormObject} from '../../../../models/program/show-form-object';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {SubscriptionPlan} from '../../../../models/account/dto/subscription-plan';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';

@Injectable()
export class ShowDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  formErrorMessage = new BehaviorSubject<string>(null);

  loadingShowMessage = $localize`Loading Show Details`;
  show$: BehaviorSubject<HydratedShow> = new BehaviorSubject<HydratedShow>(null);
  addNew: boolean = false;
  leagueId$ = new BehaviorSubject<number>(null);
  eventId$ = new BehaviorSubject<number>(null);
  fetchShowSubject = new BehaviorSubject<void>(null);
  fetchShow = this.fetchShowSubject
    .pipe(
      tap(() => this.loadingOpts.addRequest(this.loadingShowMessage)),
      switchMapTo(this.activatedRoute.params),
      switchMap(params => {
      console.log('this is params saying hi', params);
        this.addNew = !params.showId;
        if (!!params.leagueId) {
          this.leagueId$.next(params.leagueId);
          return this.addNew ? of(new HydratedShow()) : this.domainModel.getHydratedShow(params.showId);
        } else if (!!params.eventId) {
          this.eventId$.next(params.eventId);
          return this.addNew ? of(new HydratedShow()) : this.domainModel.getHydratedShow(params.showId);
      }else {
          return EMPTY;
        }
      }),
      indicateOnNext(this.loadingOpts, this.loadingShowMessage),
    ).subscribe(this.show$);

  updateFormItemStatesSubject$ = new Subject<void>();

  showUploads$ = this.activatedRoute.params.pipe(
    map(params => params?.showId),
    switchMap(sId => {
      if (!sId) {
        return of([]);
      } else {
        return this.domainModel.getShowUploads(sId);
      }
    }),
  );

  hydratedLeague$ = new BehaviorSubject<HydratedLeague>(null);
  fetchLeague = this.leagueId$.notNull().pipe(switchMap(lId => {
    return this.resourceDomainModel.getHydratedLeague(lId);
  })).subscribe(this.hydratedLeague$);

  hydratedEvent$ = new BehaviorSubject<HydratedEvent>(null);
  fetchEvent = this.eventId$.notNull().pipe(switchMap(lId => {
    return this.resourceDomainModel.getHydratedEvent(lId);
  })).subscribe(this.hydratedEvent$);

  constructor(
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainModel: AdminProgramDomainModel,
    private lookupDomainModel: LookupDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  clearFormErrors() {
    this.formErrorMessage.next(null);
  }

  saveShow(formObject: ShowFormObject) {
    formObject.leagueId = this.leagueId$.getValue();
    if (this.hydratedLeague$.getValue()) {
      formObject.show.subscriptionPlanId = this.hydratedLeague$.getValue().subscriptionPlanId;
    }
    formObject.eventId = this.eventId$.getValue();
    if (this.hydratedEvent$.getValue()) {
      formObject.show.subscriptionPlanId = this.hydratedEvent$.getValue().subscriptionPlanId;
    }
    this.domainModel.saveShow(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Show`))
      .subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Show Saved`, null);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'shows'}).then();
      }, error => {
        this.toastService.publishError(error);
      });
  }
}
