import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Tabbable} from '../../../../models/protocols/tabbable';
import {ActivatedRoute, Router} from '@angular/router';
import {PlansDetailsViewModel} from './plans-details-view-model';
import {BaseDatatableDataProvider} from '../../../../models/base/base-datatable-data-provider';
import {Plan} from '../../../../models/resources/plan';
import {PlanFormObject} from '../../../../models/resources/plan-form-object';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {DatatableOptions} from '../../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../../models/shared/datatable-filter';
import {ToastService} from '../../../../services/toast-service';
import {PricingOptions} from '../../../../models/resources/pricing-options';
import {map} from 'rxjs/operators';
import {PlanType} from '../../../../models/lookup/plan-type';
import {ConfirmationModalComponent} from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {ConfirmationOptions} from '../../../../models/shared/stylesheet/confirmation-options';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'app-plans-details',
  templateUrl: './plans-details.component.html',
  styleUrls: ['./plans-details.component.scss'],
  providers: [PlansDetailsViewModel],
})
export class PlansDetailsComponent extends BaseComponent implements Tabbable {

  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();

  tabs: TabBarItem[] = [];
  public plans: Plan[] = [];

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: PlanFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  public tableFilterChanged = new EventEmitter();
  public isEditing = true;
  public plan: Plan;

  public defaultFrequencies: string[] = ['day', 'week', 'month', 'year'];
  public defaultCurrencies: string[] = ['USD', 'CAD'];
  public pricingRows$: Observable<any[]>;


  constructor(
    public viewModel: PlansDetailsViewModel,
    private router: Router,
    private modalService: NgbModal,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.enableSearch = false;
  }


  setupBindings() {
    this.activatedRoute.params.pipe(map(params => params.planId))
      .subscribe(planId => {
        if (planId) {
          this.viewModel.getPlanById(planId).notNull().subscribe((plan) => {
            setTimeout(() => {
              this.isEditing = true;
              this.initWithFormObject(PlanFormObject.initWithPlan(plan));
              this.populatePricesForEditing(plan);
            });
          }, er => {
            this.toastService.publishError(er);
          }).addTo(this.subscriptions);
        } else {
          this.isEditing = false;
          this.setupFormItems();
          this.initializeDefaultPricingRows();
        }
      });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  initWithNewFormObject(formObject: PlanFormObject) {
    this.isEditing = false;
    this.formObject = PlanFormObject.initWithPlan(new Plan());
    this.formObject.itemCreated = true;
  }

  initWithFormObject(planFormObject: PlanFormObject) {
    this.isEditing = true;
    this.formObject = planFormObject.getCopy();
    this.formObject.itemChanged = true;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
  }

  planStatusValue() {
    const activeInput = this.formItems.find(i => i.inputName === 'active');
    return activeInput.getValue();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const planExternalName = new FormInputItem();
    planExternalName.itemType = FormItemType.Input;
    planExternalName.label = $localize`External Name`;
    planExternalName.inputName = 'externalName';
    planExternalName.placeholder = $localize`External Name`;
    planExternalName.required = true;
    planExternalName.bindingProperty = 'planExternalName';
    planExternalName.overrideFullWidth = false;
    planExternalName.customClass = 'ml-9';
    items.push(planExternalName);

    const planInternalName = new FormInputItem();
    planInternalName.itemType = FormItemType.Input;
    planInternalName.label = $localize`Internal Name`;
    planInternalName.inputName = 'internalName';
    planInternalName.placeholder = $localize`InternalName`;
    planInternalName.required = true;
    planInternalName.bindingProperty = 'planInternalName';
    items.push(planInternalName);

    const planDescription = new FormInputItem();
    planDescription.itemType = FormItemType.Input;
    planDescription.label = $localize`Description`;
    planDescription.inputName = 'description';
    planDescription.placeholder = $localize`Description`;
    planDescription.bindingProperty = 'plan.description';
    planDescription.required = false;
    items.push(planDescription);

    if (this.isEditing) {
      const planId = new FormInputItem();
      planId.itemType = FormItemType.Input;
      planId.label = $localize`Plan Id`;
      planId.inputName = 'planId';
      planId.placeholder = $localize`Plan Id`;
      planId.required = false;
      planId.bindingProperty = 'planId';
      planId.setFormEnabledStatus(false);
      items.push(planId);
    }


    const subscriptionCategory = new FormInputItem();
    subscriptionCategory.itemType = FormItemType.Dropdown;
    subscriptionCategory.label = $localize`Subscription Category`;
    subscriptionCategory.inputName = 'subscriptionCategory';
    subscriptionCategory.placeholder = $localize`Subscription Category`;
    subscriptionCategory.required = false;
    subscriptionCategory.bindingProperty = 'planCategory';
    subscriptionCategory.dropdownIsObject = true;
    subscriptionCategory.dropdownOptions = [new PlanType('League', 1), new PlanType('Event', 2)];
    if (this.isEditing) {
      subscriptionCategory.setFormEnabledStatus(false);
    }
    items.push(subscriptionCategory);


    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'planStatus';
    active.customClass = 'mb-4 mt-0';
    active.valueChanged.subscribe(v => {
      if (v[0]) {
        this.openPlanActivationModal();
      }
    }).addTo(this.subscriptions);
    items.push(active);


    if (!this.isEditing) {
      const hiddenInput = new FormInputItem();
      hiddenInput.inputName = 'hiddenInput';
      hiddenInput.inputType = FormInputType.Text;
      hiddenInput.label = $localize``;
      hiddenInput.bindingProperty = 'null';
      hiddenInput.hideInput = true;
      items.push(hiddenInput);
    }

    items.push(FormInputItem.generateDivider());

    const planPriceTitle = new FormInputItem();
    planPriceTitle.itemType = FormItemType.Title;
    planPriceTitle.label = $localize`Pricing`;
    items.push(planPriceTitle);

    this.formItems = items;
    this.setupBindings();
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  savePlan() {
    this.pricingRows$.subscribe(pricingRows => {
      const invalidPriceRows = pricingRows.filter(row => {
        const decimalPart = (row.price * 100) % 100;
        return decimalPart >= 100;
      });
      if (invalidPriceRows.length > 0) {
        this.toastService.publishErrorMessage
        ('One or more price values are invalid. Please enter valid numbers with up to two decimal places.', null);
        return;
      }
      const validPricingRows = pricingRows.filter(row => row.currency && row.frequency && row.price);
      let formObject = new PlanFormObject();
      formObject.plan = new Plan();
      if (this.isEditing) {
        formObject = this.formObject;
      }
      const rowsWithEmptyPrice = pricingRows.filter(row => row.status && (!row.price || false));
      rowsWithEmptyPrice.forEach(row => row.hasError = true);
      if (rowsWithEmptyPrice.length > 0) {
        this.toastService
          .publishErrorMessage('One or more active plans have an empty price. Please enter a price for all active plans.', null);
        return;
      }
      formObject.planExternalName = this.formItems.find(item => item.inputName === 'externalName').getValue();
      formObject.planInternalName = this.formItems.find(item => item.inputName === 'internalName').getValue();
      formObject.planDescription = this.formItems.find(item => item.inputName === 'description').getValue();
      formObject.planCategory = this.formItems.find(item => item.inputName === 'subscriptionCategory').getValue();
      formObject.planStatus = this.formItems.find(item => item.inputName === 'active').getValue();
      formObject.pricingOptions = validPricingRows?.map
      (row => new PricingOptions(
        row?.currency,
        row?.frequency,
        Math.round(Number(row?.price) * 100),
        '1',
        this.isEditing ? (row?.status ? 1 : 2) : (row?.price ? 1 : 2),
        'name'));

      this.viewModel.savePlan(formObject).subscribe(
        (response) => {
          this.toastService.publishSuccessMessage('Plan Saved Successfully', null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute});
        },
        (e) => {
          this.toastService.publishErrorMessage('Error saving the plan. Please try again.', null);
        });
    });
  }


initializeDefaultPricingRows(): void {
  const pricingRows = [];
  this.defaultCurrencies.forEach(currency => {
    this.defaultFrequencies.forEach(frequency => {
      let frequencyLabel = frequency;
      if (frequency === 'day') {
        frequencyLabel = $localize`Daily`;
      }
      if (frequency === 'week') {
        frequencyLabel = $localize`Weekly`;
      }
      if (frequency === 'month') {
        frequencyLabel = $localize`Monthly`;
      }
      if (frequency === 'year') {
        frequencyLabel = $localize`Yearly`;
      }
      pricingRows.push({
        currency,
        frequencyLabel,
        frequency,
        price: null,
        status: false,
        hasError: false
      });
    });
  });
  this.pricingRows$ = of(pricingRows);
}

  populatePricesForEditing(plan: Plan): void {
    this.initializeDefaultPricingRows();
    try {
      if (!plan || !plan.pricingOptions) {
        return;
      }
      this.pricingRows$.subscribe(rows => {
        rows.forEach(row => {
          const matchedOption = plan.pricingOptions.find(option =>
            option.currencyCode === row.currency && option.periodUnit === row.frequency
          );
          if (matchedOption) {
            row.price = matchedOption.price / 100;
            row.status = matchedOption.status === 1;
          }
          row.hasError = false;
        });
      });
    } catch (e) {
      console.log('Error in populatePricesForEditing:', e);
    }
  };

  openActivationModal(row): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Activate Plan Pricing Option`;
    opts.bodyText = $localize`Are you sure you want to activate the plan pricing option?`;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Activate Pricing Option`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((activate) => {
      if (!activate) {
        row.status = false;
      }
    });
  }

  openDeactivateModal(row): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Archive Plan Pricing Option`;
    opts.bodyText = $localize`Are you sure you want to archive the plan pricing option?`;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Archive Pricing Option`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((activate) => {
      if (!activate) {
        row.status = true;
      }
    });
  }

  openPlanActivationModal(): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Activate Plan`;
    opts.bodyText = $localize`Are you sure you want to activate the plan?`;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Activate Plan`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((activate) => {
      if (!activate) {
        const activeInput = this.formItems.find(i => i.inputName === 'active');
        activeInput?.setInputFormControlValue(false);
      }
    });
  }
}
