import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/models/base/base-component';
import { TabBarItem } from 'src/app/models/shared/stylesheet/tab-bar-item';
import { CameraSystemsComponent } from '../camera-systems.component';
import { LiveCameraStreamComponent } from '../live-camera-stream/live-camera-stream.component';
import { UpcomingCameraStreamComponent } from '../upcoming-camera-stream/upcoming-camera-stream.component';

@Component({
  selector: 'app-camera-system-landing',
  templateUrl: './camera-system-landing.component.html',
  styleUrls: ['./camera-system-landing.component.scss']
})
export class CameraSystemLandingComponent extends BaseComponent {
tabs: TabBarItem[]= [];
  constructor( private router: Router) {
    super();
  }



  setupViews() {
    this.setupTabBar();
  }
  setupBindings() {
  }

  setupTabBar() {
    this.tabs = [];
    let id = 1;
    const details = new TabBarItem(CameraSystemsComponent, id++, $localize`Inventory`, '');
    details.active = true;
    const liveStreamDetails=new TabBarItem(LiveCameraStreamComponent,id++,$localize`Live`,'');
    const upcomingStreamDetails=new TabBarItem(UpcomingCameraStreamComponent,id++,$localize`Upcoming`,'');
    this.tabs.push(...[details,liveStreamDetails,upcomingStreamDetails]);
}

}
