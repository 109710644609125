import {Component, Input} from '@angular/core';
import {FeaturedEventsRowViewModel} from './featured-events-row-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';
import {Router} from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-featured-events-row',
  templateUrl: './featured-events-row.component.html',
  styleUrls: ['./featured-events-row.component.scss'],
  providers: [FeaturedEventsRowViewModel],
})
export class FeaturedEventsRowComponent extends BaseComponent {

  @Input() hideBottomDivider: boolean = false;
  columnsPerRow: number;

  constructor(
    public viewModel: FeaturedEventsRowViewModel,
    public router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    this.calculateColumnsPerRow();
  }

  eventClicked(l: HydratedEvent) {
    this.router.navigate([`/home/events/${l.id}`]).then();
  }

  calculateColumnsPerRow() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      this.columnsPerRow = 6; // Extra-large screens
    } else if (screenWidth >= 992) {
      this.columnsPerRow = 4; // Large screens
    } else if (screenWidth >= 768) {
      this.columnsPerRow = 3; // Medium screens
    } else {
      this.columnsPerRow = 2; // Small screens
    }
  }

}
