import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

export class ModalUtils {

  public static confirmationModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  public static defaultModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static authModalOptions(blurBackground: boolean = false): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false,
      windowClass: blurBackground ? 'ht-modal-window blur-bg-modal-window' : 'ht-modal-window',
      backdropClass: 'ht-modal-backdrop',
    };
  }

  static addLeagueModalOptions(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false,
      windowClass: 'overflow-visible-modal',
    };
  }

  static addEventModalOptions(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false,
      windowClass: 'overflow-visible-modal',
    };
  }

  static addVenueModalOptions(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false,
      windowClass: 'overflow-visible-modal',
    };
  }

  static addTeamModalOptions(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false,
      windowClass: 'overflow-visible-modal',
    };
  }

  public static mediumStatic(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false,
      windowClass: 'ht-modal-window',
      backdropClass: 'ht-modal-backdrop',
    };
  }

  private static largeStatic(): NgbModalOptions {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      windowClass: 'ht-modal-window',
      backdropClass: 'ht-modal-backdrop',
    };
  }

  static defaultMedium(): NgbModalOptions {
    return {
      size: 'md',
      centered: true,
      windowClass: 'ht-modal-window',
      backdropClass: 'ht-modal-backdrop',
    };
  }


}
