<app-detail-title i18n>
  Profile
</app-detail-title>
<app-content-container [narrow]="true">
  <app-detail-back-button i18n>
    Change Password
  </app-detail-back-button>
  <app-loading
    [hidden]="!(viewModel.loadingOpts?.isLoading)"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (formSubmitted)="formSubmitted($event)">
  </app-form-group>
</app-content-container>
