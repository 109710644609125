import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { CameraSystemViewModal } from '../camera-systems.component-view-model';
import { BaseDatatableDataProvider } from 'src/app/models/base/base-datatable-data-provider';
import { DatatableFilter } from 'src/app/models/shared/datatable-filter';
import { DatatableOptions } from 'src/app/models/shared/stylesheet/datatable-options';
import { CameraStream } from 'src/app/models/resources/camera-stream';
import { DatatableAction } from 'src/app/models/shared/stylesheet/datatable-action';
import { DatatableColumn, DatatableColumnType } from 'src/app/models/shared/stylesheet/datatable-column';
import { DatatableColumnFilterListItem, DatatableColumnFilter } from 'src/app/models/shared/stylesheet/datatable-column-filter';
import { indicateOnNext } from 'src/app/utils/observable.extensions';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DateUtils } from 'src/app/utils/date-utils';

@Component({
  selector: 'app-upcoming-camera-stream',
  templateUrl: './upcoming-camera-stream.component.html',
  styleUrls: ['./upcoming-camera-stream.component.scss'],
  providers: [CameraSystemViewModal]
})
export class UpcomingCameraStreamComponent extends BaseComponent implements OnDestroy {
  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public cameraStreamsDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  isReload: boolean=true;


  constructor(
    public  viewModel: CameraSystemViewModal
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.isReload=false;
  }

  setupBindings() {
    this.viewModel.getCameraStreams(false)
      .pipe(
        indicateOnNext(this.viewModel.loadingOpts, $localize`Loading Live Camera Streams`)
      )
      .subscribe((cs) => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = cs;
        this.updateTableData.next(dataProvider);
        //three hundered thousand seconds for 5 minutes
        setTimeout(()=>this.isReload ? this.setupBindings() : null ,300000);
      }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDataTable();
  }
  setupDataTable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }
  setDatatableFilter() {
        // Basic filter setup
        this.datatableFilter.enableFilterCheckbox = false;
        this.datatableFilter.enableResultsDisplayed = false;
        this.datatableFilter.searchPlaceholderText = $localize`Search by Camera System name,Client,Venue`;
        // Clear query text
        this.datatableFilter.searchQueryString = '';
        // Set custom filters
        this.datatableFilter.customFilters = [];
  }
  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const cameraName  = new DatatableColumn(
      'CameraName',
      $localize` Name`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.cameraName;
      }
    );
    cameraName.isDefaultSortColumn = true;
    columns.push(cameraName);

    const client  = new DatatableColumn(
      'client',
      $localize` Client`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.client;
      }
    );
    columns.push(client);

    const venue  = new DatatableColumn(
      'Venue',
      $localize` Venue`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.venue;
      }
    );
    columns.push(venue);

    const date  = new DatatableColumn(
      'date',
      $localize` Date `,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.startDate;
      }
    );
    columns.push(date);

    const startTime  = new DatatableColumn(
      'StartTime',
      $localize` Start Time`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.startDateUtc ?
		  $localize`${DateUtils.formatTimeToReadableString(cameraStream.startDateUtc)}` : $localize`N/A`;
      }
    );
    columns.push(startTime);

    const endTime  = new DatatableColumn(
      'EndTime',
      $localize`End Time`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.endDateUtc ?
		  $localize`${DateUtils.formatTimeToReadableString(cameraStream.endDateUtc)}` : $localize`N/A`;
      }
    );

    columns.push(endTime);

    const productionType  = new DatatableColumn(
      'productionType',
      $localize`Production Type`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return cameraStream.productionType;
      }
    );
    columns.push(productionType);
    productionType.className = 'custom-datatable-header text-left mr-2';
    productionType.headerClassName = 'custom-datatable-header text-left mr-2';
    productionType.columnFilter = this.getProductionColumnFilter();


    const status  = new DatatableColumn(
      'status',
      $localize`Status`,
      DatatableColumnType.Text,
      (cameraStream: CameraStream): string => {
        return this.viewModel.getStreamStatus(cameraStream);
      }
    );
    columns.push(status);
    status.className = 'custom-datatable-header text-left mr-2';
    status.headerClassName = 'custom-datatable-header text-left mr-2';
    status.columnFilter = this.getStatusColumnFilter();
    return columns;
  }
  getProductionColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Premium`, $localize`Premium`),
      new DatatableColumnFilterListItem($localize`Standard`, $localize`Standard`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Online`, $localize`Online`),
      new DatatableColumnFilterListItem($localize`Offline`, $localize`Offline`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

}
