<li class="custom-upload-preview"
    [ngClass]="{
        'success': file?.success,
        'error': file?.success
    }">
  <img *ngIf="file?.isImage()" class="custom-upload-preview-image"
       src="{{file?.url}}"
       alt="/assets/placeholder/list-image-dark.jpg">
  <video *ngIf="file?.isVideo()"
         class="custom-upload-preview-image"
         [style.max-height]="'100px'"
         [controls]="false"
         [src]="file?.url"
         [hidden]="false"> <!-- (click)="viewModel?.assetClicked()" -->
  </video>
  <div class="custom-upload-preview-text-container">
    <span class="custom-upload-preview-text-name">
      {{file?.name}}
    </span>
    <span *ngIf="file?.size && !file?.failureError"
          class="custom-upload-preview-text-size">
      {{formatBytes(file?.size)}}
    </span>
    <span *ngIf="file?.failureError"
          class="custom-upload-preview-error">
      {{file?.failureError}}
    </span>
  </div>
  <div class="custom-upload-button-group">
    <button *ngIf="file?.success"
            class="custom-upload-button custom-remove-upload-button">
      <img class="custom-upload-button-icon"
           src="/assets/icons/dark/solid/remove.svg"
           alt="star"
           (click)="removeMe()">
    </button>
    <button *ngIf="!file?.success"
            class="custom-upload-button custom-refresh-upload-button">
      <img class="custom-upload-button-icon"
           src="/assets/icons/dark/solid/retry.svg"
           alt="retry">
    </button>
  </div>
</li>
