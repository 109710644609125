import {Component} from '@angular/core';
import {ProgramDetailsViewModel} from '../program-details-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {ToastService} from '../../../../../services/toast-service';
import {Clipboard} from '@angular/cdk/clipboard';
import { AdminRole } from 'src/app/models/account/enum/admin-permission.enum';
import { ProductionType } from 'src/app/models/lookup/production-type';

@Component({
  selector: 'app-program-details-stream',
  templateUrl: './program-details-stream.component.html',
  styleUrls: ['./program-details-stream.component.scss'],
})
export class ProgramDetailsStreamComponent extends BaseComponent implements Tabbable {

  hideIngestServerKey = true;
  cameraType: string='Pixellot';
  AdminUser: string;
  isAdminL1User: boolean;
  isPremiumStream: boolean;

  constructor(
    public viewModel: ProgramDetailsViewModel,
    private clipboard: Clipboard,
    private toastService: ToastService,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    this.viewModel.program$.subscribe((program)=>{
      if(!program.isPixellotSystem){
        this.cameraType='HTL Camera System';
      }
      this.isPremiumStream=program.productionTypeId===ProductionType.PremiumId;
    });
    this.AdminUser=this.PermissionManagerService.getUserAdminLevel();
    this.isAdminL1User=(this.AdminUser===AdminRole.Level0)||(this.AdminUser===AdminRole.Level1);
  }

  copyIngestKeyToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.amazonIngestStreamKey ?? '');
    this.toastService.publishSuccessMessage($localize`Ingest server key copied to clipboard`, null);
  }

  copyIngestUrlToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.amazonIngestStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Ingest server copied to clipboard`, null);
  }

  copyPixellotStreamUrlToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.pixellotHDStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Pixellot Stream Url copied to clipboard`, null);
  }

  copyChannelArnToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.channelArn ?? '');
    this.toastService.publishSuccessMessage($localize`Channel Arn copied to clipboard`, null);
  }

  copyPlaybackStreamUrlToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.playbackStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Stream Url copied to clipboard`, null);
  }

  copyPermanentUrlToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.permanentIvsUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Camera Permanent IVS Stream Url copied to clipboard`, null);
  }

  pixellotPanoStreamUrl() {
    this.clipboard.copy(this.viewModel.program$.value?.pixellotPANOStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Pixellot Pano Stream Url copied to clipboard`, null);
  }
}
