import {Deserializable} from '../../protocols/deserializable';

export class CouponConstraint implements Deserializable {
    public itemType: ConstraintItemType;
    public constraint: ConstraintType;
    public planIds: string[];

    onDeserialize() {
        this.planIds = Array.from(this.planIds || []);
    }
}

export enum ConstraintItemType {
  Plan = 'plan',
  Addon = 'addon',
  Charge = 'charge',
}

export enum ConstraintType {
  Specific = 'specific',
  None = 'none',
}
