import {HydratedUser} from '../account/dto/hydrated-user';
import {DateUtils} from '../../utils/date-utils';
import {CodeDeliveryDetails} from '../account/dto/code-delivery-details';
import {Cachable} from '../protocols/cachable';
import {DefaultCacheKey} from '../enum/shared/default-cache-key.enum';
import {DeserializeHelper} from '../protocols/deserializable';
import {CachePolicy} from '../enum/shared/cachable-image-policy.enum';
import {Role} from '../account/dto/role';

export class SessionContainer implements Cachable {
  // Shared properties
  public user: HydratedUser;
  public userRole: Role;
  // Session Settings
  public sessionStartTime: number;
  public rememberSession: boolean;
  public cachedTime: number;

  constructor(user?: HydratedUser) {
    this.user = user;
  }

  public validSession(): boolean {
    return this.user?.session?.validSession();
  }

  public sessionNeedsRefresh(): boolean {
    return this.user?.session?.sessionNeedsRefresh();
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionContainer;
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  onDeserialize() {
    this.user = DeserializeHelper.deserializeToInstance(HydratedUser, this.user);
    this.userRole = DeserializeHelper.deserializeToInstance(Role, this.userRole);
  }
}
