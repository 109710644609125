import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth.component';
import {AuthGuard} from '../../services/guards/auth.guard';
import {CanDeactivateGuard} from '../../services/guards/can-deactivate.guard';
import {RefreshSessionGuard} from '../../services/guards/refresh-session.guard';
import {AuthModalComponent} from '../shared/modals/auth-modal/auth-modal.component';
import {HomeComponent} from '../home/components/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    data: {
      title: 'Auth'
    },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'auth/sign-in',
    component: AuthComponent,
    data: {
      title: 'Sign In',
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'auth/sign-in/admin',
    component: AuthComponent,
    data: {
      title: 'Sign In',
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'auth/sign-up',
    component: AuthComponent,
    data: {
      title: 'Sign Up'
    },
    canActivate: [RefreshSessionGuard]

  },
  {
    path: 'auth/sign-up/expired',
    component: AuthComponent,
    data: {
      title: 'Sign Up Expired'
    },
    canActivate: [RefreshSessionGuard]

  },
  {
    path: 'auth/forgot-password',
    component: AuthComponent,
    data: {
      title: 'Forgot Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'auth/reset-password',
    component: AuthComponent,
    data: {
      title: 'Reset Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'auth/new-password',
    component: AuthComponent,
    data: {
      title: 'New Password'
    },
    canActivate: [RefreshSessionGuard]
  },
];



@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
