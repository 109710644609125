import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../models/base/base-component';
import {AuthFlow, SubscriberSignUpFlow} from '../../models/account/enum/auth-flow.enum';
import {ScreenService} from '../../services/screen-service.service';
import {ModalUtils} from '../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthModalComponent} from '../shared/modals/auth-modal/auth-modal.component';
import {LoginType} from '../shared/modals/auth-modal/auth-modal-view-model';
import {OpenAuthModalOptions} from '../../models/account/open-auth-modal-options';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent {

  constructor(
    private router: Router,
    private screenService: ScreenService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  parseAuthFlow() {
    this.activatedRoute.url.firstNotNull().subscribe((url) => {
      let authFlow: AuthFlow = null;
      let subAuthFlow: SubscriberSignUpFlow = null;
      let loginType: LoginType = LoginType.Subscriber;
      switch (url.last().path) {
        case 'admin': {
          loginType = LoginType.Admin;
          authFlow = AuthFlow.SignIn;
          break;
        }
        case 'sign-in': {
          authFlow = AuthFlow.SignIn;
          break;
        }
        case 'sign-up': {
          authFlow = AuthFlow.SignUp;
          break;
        }
        case 'forgot-password': {
          authFlow = AuthFlow.ForgotPassword;
          break;
        }
        case 'reset-password': {
          authFlow = AuthFlow.ResetPassword;
          break;
        }
        case 'new-password': {
          authFlow = AuthFlow.SetNewPassword;
          break;
        }
        case 'expired': {
          authFlow = AuthFlow.SignUp;
          subAuthFlow = SubscriberSignUpFlow.EmailVerificationLinkExpired;
          break;
        }
      }

      if (!!authFlow || !!subAuthFlow) {
        this.openAuthModal(authFlow, loginType, subAuthFlow);
      }
    }).addTo(this.subscriptions);
  }

  setupBindings() {
    this.parseAuthFlow();
  }

  setupViews() {
  }

  openAuthModal(initialAuthFlow: AuthFlow, loginType: LoginType, subAuthFlow: SubscriberSignUpFlow): void {
    const modalRef = this.modalService.open(AuthModalComponent, ModalUtils.authModalOptions(true));
    const compInstance = modalRef.componentInstance as AuthModalComponent;
    const options = new OpenAuthModalOptions(initialAuthFlow,
      null,
      $localize`Sign in to continue`,
      $localize`Sign up to continue`,
      null,
      subAuthFlow);
    options.loginType = loginType;
    compInstance.initWithOptions(options);
    modalRef.result.then(() => {
    }, () => {
      this.router.navigate(['']);
    });
  }
}
