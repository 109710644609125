<div class="h-100">
  <div class="search-field-container">
    <div class="my-3 d-flex justify-content-between align-items-end">
      <div class="title-md mt-3">Search</div>
      <div class="text-link-button"
           (click)="cancel()"
           i18n>
        Cancel
      </div>
    </div>
    <form>
      <label class="custom-textfield-label" for="textfieldSearch" i18n>Search</label>
      <input class="custom-textfield"
             #searchField
             [ngModel]="viewModel.searchText$|async"
             (ngModelChange)="viewModel.searchText$.next($event)"
             id="textfieldSearch"
             name="textfieldSearch"
             autocomplete="off"
             i18n-placeholder
             placeholder="Search for events, leagues, venues and teams"
             type="text">
    </form>
  </div>
  <div class="position-relative h-100">
    <app-loading
      [hidden]="!(viewModel.loadingOpts?.isLoading)"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <div *ngFor="let searchResult of viewModel.searchResults$|async">
      <div class="search-result-row" (click)="resultClicked(searchResult)">
        <div class="search-result-logo" [class.no-img]="!(searchResult.getSearchListableImgSrc$()|async)">
          <img *ngIf="searchResult.getSearchListableImgSrc$()|async" [src]="searchResult.getSearchListableImgSrc$()|async" alt="logo">
        </div>
        <div class="flex-grow-1">
          <div class="label-sm">
            {{searchResult.getSearchListableTitle()}}
          </div>
          <div class="paragraph-xs color-mid-grey">
            {{searchResult.getSearchListableSubTitle()}}
          </div>
        </div>
        <img class="chevron" alt="chevron" [src]="'assets/icons/dark/outline/chevron-right.svg'">
      </div>
      <hr>
    </div>
    <div *ngIf="!((viewModel.searchResults$|async)?.length > 0) && ((viewModel.searchText$|async)?.length > 0)">
      <div class="label-md mb-2">{{this.getNoResultsText()}}</div>
      <div class="paragraph-sm color-mid-grey" i18n>The term you entered did not bring up any results.</div>
    </div>
  </div>
</div>
