import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { AccountDomainModel } from 'src/app/domainModels/account-domain-model';
import {map} from 'rxjs/operators';
import {SessionService} from '../session-service';
import {AuthFlow} from '../../models/account/enum/auth-flow.enum';
import {OpenAuthModalOptions} from '../../models/account/open-auth-modal-options';

@Injectable({
  providedIn: 'root'
})

export class AdminAuthGuard {

  constructor(
    public accountDomainModel: AccountDomainModel,
    public sessionService: SessionService,
    public router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const observer = this.accountDomainModel.isAuthenticated().pipe(map(r => r != null));
    observer.subscribe(res => {
      if (!res) {
        this.router.navigate(['/admin']).then();
      }
    });
    return observer;
  }
}
