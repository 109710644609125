import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {LeagueDetailsViewModel} from '../league-details-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseDatatableDataProvider} from '../../../../../models/base/base-datatable-data-provider';
import {DatatableFilter} from '../../../../../models/shared/datatable-filter';
import {DatatableOptions} from '../../../../../models/shared/stylesheet/datatable-options';
import {DatatableData} from '../../../../../models/protocols/datatable-data';
import {HydratedProgram} from '../../../../../models/program/hydrated-program';
import {DatatableColumn, DatatableColumnType} from '../../../../../models/shared/stylesheet/datatable-column';
import {Season} from '../../../../../models/resources/season';
import {DateUtils} from '../../../../../utils/date-utils';
import {
  DatatableColumnFilter,
  DatatableColumnFilterType
} from '../../../../../models/shared/stylesheet/datatable-column-filter';
import {Placements} from 'ngx-popper';
import {map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {SeasonFormObject} from '../../../../../models/resources/season-form-object';
import {EditSeasonModalComponent} from '../../../shared/edit-season-modal/edit-season-modal.component';
import {ToastService} from '../../../../../services/toast-service';
import {
  ConfirmationModalComponent
} from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../../../models/shared/stylesheet/confirmation-options';

@Component({
  selector: 'app-league-edit-season-modal',
  templateUrl: './league-seasons.component.html',
  styleUrls: ['./league-seasons.component.scss'],
})
export class LeagueSeasonsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public formObject: SeasonFormObject;
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  leagueId$ = this.activatedRoute.params.pipe(map(p => p.leagueId));
  private performSilentUpdate = false;
  constructor(
    public viewModel: LeagueDetailsViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super();
    this.datatableFilter.enableSearch = true;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.noResultsForFiltersTitle = '';
    this.datatableFilter.noResultsForFilterSubtitle = '';

  }
  setupViews() {
    this.setupDatatable();
  }

  setupBindings() {
    this.viewModel.league$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s.seasons;
        this.updateTableData.next(dataProvider);
      });
    });
  }

  seasonClicked(data: DatatableData) {
    const season = data as Season;
    this.editSeason(SeasonFormObject.initWithSeason(season));
  }

  public addNewSeasonClicked() {
    const modalRef = this.modalService.open(
      EditSeasonModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    const instance = (modalRef.componentInstance as EditSeasonModalComponent);
    instance.initWithNewFormObject();
    modalRef.result.then((seasonFormObject: SeasonFormObject) => {
      if(seasonFormObject) {
        this.formObject = seasonFormObject;
        this.saveSeason(seasonFormObject);
      }
    }, reason => {});
  }

  private editSeason(seasonFormObject: SeasonFormObject): void {
    const modalRef = this.modalService.open(
      EditSeasonModalComponent,
      ModalUtils.mediumStatic()
    );
    const instance = (modalRef.componentInstance as EditSeasonModalComponent);
    instance.initWithFormObject(seasonFormObject);
    modalRef.componentInstance.title = seasonFormObject.seasonName;
    modalRef.componentInstance.isEditing = true;
    modalRef.result.then((updatedSeason: SeasonFormObject) => {
      updatedSeason.timeStampVal=seasonFormObject.timeStampVal;
      if (updatedSeason && updatedSeason.itemDeleted) {
        const confirmationModalRef = this.modalService.open(
          ConfirmationModalComponent,
          ModalUtils.confirmationModalOptions()
        );
        const comInstance = confirmationModalRef.componentInstance as ConfirmationModalComponent;
        const opts = new ConfirmationOptions();
        opts.title = $localize`Delete Season`;
        opts.bodyText = $localize`Are you sure you want to delete this season?`;
        opts.cancelText = $localize`Cancel`;
        opts.continueText = $localize`Delete`;
        opts.continueButtonClass = $localize `preferred-button`;
        comInstance.setConfirmationOptions(opts);
        confirmationModalRef.result.then((confirmed) => {
          if (confirmed) {
            this.leagueId$.subscribe((leagueId) => {
              this.deleteSeason(updatedSeason, leagueId);
            });
          }
        });
      } else {
        this.saveSeason(updatedSeason);
      }

    });
  }

  private deleteSeason(seasonFormObject: SeasonFormObject, leagueId: number): void {
    this.viewModel.deleteLeagueSeason(seasonFormObject, leagueId);
  }

  saveSeason(formObject: SeasonFormObject) {
    this.leagueId$.subscribe((leagueId) => {
      formObject.leagueId = leagueId;
    });
    this.viewModel.saveLeagueSeason(formObject).subscribe(() => {
      this.toastService.publishSuccessMessage($localize`Season saved successfully`, null);
      this.viewModel.refreshLeagueSubject$.next();
    }, error => {
      if(!!formObject.season?.id){
        this.toastService.publishError(error, $localize`Error saving season`);
      }
      else{
        this.toastService.publishErrorMessage(error, $localize`Error saving season`);
      }

    });
  }

  public setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.datatableOptions.ctaButtonText = $localize`Add New Season`;
    this.datatableOptions.ctaButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Leagues_Edit_League_Seasons_Add) ?
        'preferred-button' : 'preferred-button disabled';
  }

  getDatatableColumns() {
    const columns: DatatableColumn[] = [];

    const seasonName = new DatatableColumn(
      'seasonName',
      $localize`Season Name`,
      DatatableColumnType.Text,
      (s: Season): string => {
        return s.name;
    }
    );
    columns.push(seasonName);

    const startDate = new DatatableColumn(
    'startDate',
      $localize`Start Date`,
      DatatableColumnType.Text,
      (s: Season): string => {
      return s.startDate ?
        $localize`${DateUtils.formatDateWithWeekdayToReadableString(s.startDate)}` :
        $localize`N/A`;
      }
    );
    startDate.customSortingMethod = ((a: Season, b: Season) => {
      return DateUtils.dateIsBefore(b.startDate, a.startDate);
    });
      startDate.columnFilter = this.getDateColumnFilter();
      startDate.isDefaultSortColumn = true;
      columns.push(startDate);

    const endDate = new DatatableColumn(
      'endDate',
      $localize`End Date`,
      DatatableColumnType.Text,
      (s: Season): string => {
        return s.endDate ?
          $localize`${DateUtils.formatDateWithWeekdayToReadableString(s.endDate)}` :
          $localize`N/A`;
      }
    );
    endDate.customSortingMethod = ((a: Season, b: Season) => {
      return DateUtils.dateIsBefore(b.endDate, a.endDate);
    });
    endDate.columnFilter = this. getDateColumnFilter();
    endDate.isDefaultSortColumn = false;
    columns.push(endDate);

    return columns;
  }

  getDateColumnFilter(): DatatableColumnFilter {
    const dateRangeFilter = new DatatableColumnFilter();
    dateRangeFilter.filterType = DatatableColumnFilterType.DateRangeFilter;
    dateRangeFilter.popperPlacement = Placements.BottomStart;
    dateRangeFilter.dateRangeFilterMethod = (p: HydratedProgram, dateRange) => {
      return DateUtils.dateRangeContains(dateRange.start, dateRange.end, p.startDateUtc);
    };
    return dateRangeFilter;
  }
}
