<div class="modal-body">
    <div class="modal-title">
      Download Error File
    </div>
    <div class="my-4 paragraph-md">
      Uploader CSV Program failed to create an event in Pixellot. Please download the csv file to review the errors and re-upload programs after resolving the errors.
    </div>
      <button class="custom-button preferred-button ml-auto" (click)="downloadCSV()">
          Download
      </button>
</div>
 
  