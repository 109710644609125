import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {VenueDetailsViewModel} from '../venue-details-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {VenueFormObject} from '../../../../../models/resources/venue-form-object';
import {VenueStream} from '../../../../../models/resources/venue-stream';
import {TeamFormObject} from '../../../../../models/resources/team-form-object';
import {CountryType} from '../../../../../models/lookup/country-type';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {combineLatest} from 'rxjs';
import {debounceTime, first, take} from 'rxjs/operators';
import {EditVenueStreamModalComponent} from '../../edit-venue-stream-modal/edit-venue-stream-modal.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {SubscriptionPlan} from '../../../../../models/account/dto/subscription-plan';
import {EditTeamModalComponent} from '../../../shared/edit-team-modal/edit-team-modal.component';
import {UploadImageModalComponent} from '../../../../shared/components/upload-image-modal/upload-image-modal.component';
import {
  ConfirmationModalComponent
} from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../../../models/shared/stylesheet/confirmation-options';
import {
  AlphanumericValidatorDirective
} from '../../../../shared/components/form-group/validators/alphanumeric-validator.directive';
import {
  AlphanumericWithSpaceValidatorDirective
} from '../../../../shared/components/form-group/validators/alphanumeric-with-space-validator.directive';

@Component({
  selector: 'app-edit-venue',
  templateUrl: './edit-venue.component.html',
  styleUrls: ['./edit-venue.component.scss'],
})
export class EditVenueComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: VenueFormObject;

  countries: CountryType[] = [];

  constructor(
    public viewModel: VenueDetailsViewModel,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
    this.setupFormStyling();

    combineLatest([this.viewModel.venue$, this.viewModel.countryTypes$.notNull()])
      .pipe(debounceTime(100))
      .subscribe(([venue, countryTypes]) => {
        this.formObject = VenueFormObject.initWithVenue(venue);
        this.countries = countryTypes;
        this.setupFormItems();
      }).addTo(this.subscriptions);
  }

  setupFormBindings() {
    this.viewModel.subscriptionPlans$.notNull().pipe(debounceTime(100)).subscribe(sp => {
      this.setSubscriptionPlanOptions(sp);
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupFormOptions();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const venueName = new FormInputItem();
    venueName.inputName = 'venueName';
    venueName.inputType = FormInputType.Text;
    venueName.label = $localize`Venue Name`;
    venueName.placeholder = $localize`Enter Venue Name`;
    venueName.bindingProperty = 'venue.name';
    venueName.required = true;
    venueName.overrideFullWidth = true;
    venueName.customValidator = new AlphanumericWithSpaceValidatorDirective();
    items.push(venueName);

    const address1 = new FormInputItem();
    address1.inputName = 'addressLine1';
    address1.inputType = FormInputType.Text;
    address1.label = $localize`Address Line 1`;
    address1.placeholder = $localize`Address`;
    address1.bindingProperty = 'venue.address.addressLine1';
    address1.required = true;
    items.push(address1);

    const address2 = new FormInputItem();
    address2.inputName = 'address2';
    address2.inputType = FormInputType.Text;
    address2.label = $localize`Address Line 2`;
    address2.placeholder = $localize`Apartment, building, floor (optional)`;
    address2.bindingProperty = 'venue.address.addressLine2';
    items.push(address2);

    const venueLocation = new FormInputItem();
    venueLocation.inputName = 'venueLocation';
    venueLocation.inputType = FormInputType.Text;
    venueLocation.label = $localize`Location`;
    venueLocation.placeholder = $localize`Enter city or town name`;
    venueLocation.bindingProperty = 'venue.address.city';
    venueLocation.required = true;
    items.push(venueLocation);

    const venueCountry = new FormInputItem();
    venueCountry.itemType = FormItemType.Dropdown;
    venueCountry.inputName = 'venueCountry';
    venueCountry.label = $localize`Country`;
    venueCountry.placeholder = $localize`Choose a country`;
    venueCountry.bindingProperty = 'venue.address.countryId';
    venueCountry.required = true;
    venueCountry.dropdownIsObject = true;
    venueCountry.dropdownOptions = this.countries;
    venueCountry.valueChanged.subscribe(([countryId]) => {
      this.setProvinceOptions(countryId);
    });
    items.push(venueCountry);

    const venueProvince = new FormInputItem();
    venueProvince.itemType = FormItemType.Dropdown;
    venueProvince.inputName = 'venueProvince';
    venueProvince.label = $localize`Province`;
    venueProvince.placeholder = $localize`Choose a province`;
    venueProvince.bindingProperty = 'venue.address.stateId';
    venueProvince.required = true;
    venueProvince.dropdownIsObject = true;
    // Country Code 1 = Canada
    venueProvince.dropdownOptions = this.countries?.find(
      c => c.id === CountryType.getCanadaCountryCode())?.states.sort((a, b) => a.name.localeCompare(b.name));
    items.push(venueProvince);

    const venuePostalCode = new FormInputItem();
    venuePostalCode.inputName = 'venuePostalCode';
    venuePostalCode.inputType = FormInputType.Text;
    venuePostalCode.label = $localize`Postal Code`;
    venuePostalCode.bindingProperty = 'venue.address.postalCode';
    venuePostalCode.required = true;
    items.push(venuePostalCode);

    const subscriptionPlan = new FormInputItem();
    subscriptionPlan.itemType = FormItemType.Dropdown;
    subscriptionPlan.inputName = 'subscriptionPlanId';
    subscriptionPlan.label = $localize`Subscription Plan`;
    subscriptionPlan.placeholder = $localize`Choose a Subscription Plan`;
    subscriptionPlan.bindingProperty = 'venue.subscriptionPlanId';
    subscriptionPlan.dropdownIsObject = true;
    subscriptionPlan.required = true;
    subscriptionPlan.dropdownOptions = [];
    items.push(subscriptionPlan);

    const hiddenInput = new FormInputItem();
    hiddenInput.inputName = 'hiddenInput';
    hiddenInput.inputType = FormInputType.Text;
    hiddenInput.label = $localize``;
    hiddenInput.bindingProperty = 'null';
    hiddenInput.hideInput = true;
    items.push(hiddenInput);


    const enableTransmitAds = new FormInputItem();
    enableTransmitAds.itemType = FormItemType.Switch;
    enableTransmitAds.inputName = 'enableTransmitads';
    enableTransmitAds.label = $localize`Enable Transmit Ads`;
    enableTransmitAds.placeholder = $localize`EnableTransmitAds`;
    enableTransmitAds.bindingProperty = 'venue.enableTransmitads';
    enableTransmitAds.overrideFullWidth=true;
    items.push(enableTransmitAds);

    const venueIsActive = new FormInputItem();
    venueIsActive.itemType = FormItemType.Switch;
    venueIsActive.inputName = 'active';
    venueIsActive.label = $localize`Active`;
    venueIsActive.bindingProperty = 'venue.active';
    venueIsActive.valueChanged.subscribe(v => {
      if (!v[0]) {
        this.openDeactivateVenueModal();
      }
    }).addTo(this.subscriptions);
    items.push(venueIsActive);


    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'forgot-password-button-container';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Venue`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Details_Edit_Info) ?
        '' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueFormObject) {
    result.venue.subscriptionPlanId = this.formItems.find(f => f.inputName === 'subscriptionPlanId').getValue();
    result.venue.enableTransmitads=this.formItems.find(f=>f.inputName==='enableTransmitads').getValue();
    this.viewModel.saveVenue(result);
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  setSubscriptionPlanOptions(subscriptionPlans: SubscriptionPlan[]) {
    const subscriptionPlanInput = this.formItems.find(f => f.inputName === 'subscriptionPlanId');
    subscriptionPlanInput.dropdownOptions = subscriptionPlans.map(plan => {
      return {
        getSelectionTitle: () => plan.internalName,
        getSelectionValue: () => plan.id,
        getSelectionUniqueIdentifier: () => plan.id
      };
    });
  }

  showEditPhotoModal() {
    this.viewModel.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Add Venue Image`,
        $localize`Crop Venue Image`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  public setProvinceOptions(countryId: number) {
    const province = this.formItems?.find(f => f.inputName === 'venueProvince');
    province.dropdownOptions = this.countries?.find(
      c => c.id === countryId)?.states.sort((a, b) => a.name.localeCompare(b.name));
  }

  openDeactivateVenueModal(): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Deactivate Venue`;
    // eslint-disable-next-line max-len
    opts.bodyText = $localize`Deactivating a venue will limit actions that admins can take with that venue. Are you sure you want to deactivate this venue?\n\nThis action can be reversed from the Venues tab. `;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Deactivate Venue`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deactivate) => {
      if (!deactivate) {
        const activeInput = this.formItems.find(i => i.inputName === 'active');
        activeInput?.setInputFormControlValue(true);
      }
    });
  }
}
