<div class="app-body-container">
<!--    <button class="invalidate-session-button" (click)="invalidateSession()">Invalidate Session</button>-->
  <app-loading
    [hidden]="!loadingOpts?.isLoading"
    [options]="loadingOpts">
  </app-loading>
  <app-top-nav class="top-bar" [hidden]="showAdminLayout"></app-top-nav>
  <div class="d-flex h100p" [class.top-bar-padding]="!showAdminLayout">
    <!-- Nav Bar -->
    <app-sidebar [fixed]="true"
                 [hidden]="!showAdminLayout"
                 [ngClass]="{
                    'sidebar-show': showAdminLayout,
                    'sidebar-hide': showAdminLayout,
                    'dev-color': dev
                  }"
                 class="sidebar">
      <app-sidebar-navigation>
      </app-sidebar-navigation>
    </app-sidebar>
    <!-- Main content -->
    <main id="main-content" class="main-content" [class.main-content-padding]="showAdminLayout"
          [ngClass]="{
        'admin-layout': showAdminLayout,
        'subscriber-layout': !showAdminLayout
                      }">
        <router-outlet></router-outlet>
      <app-footer *ngIf="!showAdminLayout"></app-footer>
    </main>
  </div>
</div>

