import {Component} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {VenueStream} from '../../../../models/resources/venue-stream';
import {DeserializeHelper} from '../../../../models/protocols/deserializable';
import {SportType} from '../../../../models/lookup/sport-type';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {VenueDetailsViewModel} from '../venue-details/venue-details-view-model';
import {PixellotVenues} from '../../../../models/resources/pixellot-venues';
import {BehaviorSubject} from 'rxjs';
import {ToastService} from '../../../../services/toast-service';
import {
  AlphanumericValidatorDirective
} from '../../../shared/components/form-group/validators/alphanumeric-validator.directive';
import {
  AlphanumericWithSpaceValidatorDirective
} from '../../../shared/components/form-group/validators/alphanumeric-with-space-validator.directive';

@Component({
  selector: 'app-edit-venue-stream-modal',
  templateUrl: './edit-venue-stream-modal.component.html',
  styleUrls: ['./edit-venue-stream-modal.component.scss'],
  providers: [VenueDetailsViewModel],
})
export class EditVenueStreamModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: VenueStream;
  venueStream: VenueStream = null;
  venueStreamForPixellotCamera = new VenueStream();
  isEditing: boolean = false;
  pixellotVenues = new BehaviorSubject<PixellotVenues[]>(null);

  constructor(
    private viewModel: VenueDetailsViewModel,
    private activeModal: NgbActiveModal,
    private lookupDomainModel: LookupDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.lookupDomainModel.sportTypes.notNull().subscribe(s => {
      setTimeout(() => {
        this.setSportsOptions(s);
      });
    }).addTo(this.subscriptions);
    this.viewModel.getPixellotVenues().notNull().subscribe((pv) => {
      setTimeout(() => {
        this.pixellotVenues.next(pv);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    if (this.venueStream) {
      this.formObject = DeserializeHelper.deserializeToInstance(VenueStream, this.venueStream);
      this.formObject.itemChanged = true;
      this.formObject.itemCreated = false;
    } else {
      this.formObject = new VenueStream();
      this.formObject.itemCreated = true;
    }
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Stream Name`;
    name.bindingProperty = 'name';
    name.required = true;
    name.overrideFullWidth = true;
    name.customValidator = new AlphanumericWithSpaceValidatorDirective();
    items.push(name);

    const cameraName = new FormInputItem();
    cameraName.inputName = 'cameraName';
    cameraName.inputType = FormInputType.Text;
    cameraName.label = $localize`Camera Name`;
    cameraName.placeholder = $localize`Camera Name`;
    cameraName.bindingProperty = 'cameraName';
    cameraName.overrideFullWidth = true;
    if(this.isEditing){
      cameraName.keyUpValueChanged.subscribe((value) => {
        cameraId.setInputFormControlValue('');
        const pixellotCameraId=this.findPixellotIdByName(value[0]);
        if(!pixellotCameraId){
          this.toastService.publishErrorMessage($localize`Camera Id not found, please check camera name.`, null);
          cameraId.setInputFormControlValue('');
        }
        else{
          cameraId.setInputFormControlValue(pixellotCameraId);
        }
      });
      cameraName.required = true;
      items.push(cameraName);
    }
    else{
      cameraName.valueChanged.subscribe((value) => {
        cameraId.setInputFormControlValue('');
        const pixellotCameraId=this.findPixellotIdByName(value[0]);
        if(!pixellotCameraId){
          this.toastService.publishErrorMessage($localize`Camera Id not found, please check camera name.`, null);
          cameraId.setInputFormControlValue('');
        }
        else{
          cameraId.setInputFormControlValue(pixellotCameraId);
        }
      });
      cameraName.required = true;
      items.push(cameraName);
    }

    const cameraId = new FormInputItem();
    cameraId.inputName = 'cameraId';
    cameraId.inputType = FormInputType.Text;
    cameraId.label = $localize`Camera Id`;
    cameraId.placeholder = this.isEditing ?
     $localize`Enter valid Camera Name to Get the Camera Id`:
    $localize`Click here after entering Camera Name to Get the Camera Id`;
    cameraId.bindingProperty = 'cameraId';
    cameraId.setFormEnabledStatus(false);
    cameraId.overrideFullWidth = true;
    items.push(cameraId);

    const hiddenCameraId = new FormInputItem();
    hiddenCameraId.inputName = 'hiddenCameraId';
    hiddenCameraId.inputType = FormInputType.Text;
    hiddenCameraId.itemType = FormItemType.Hidden;
    hiddenCameraId.bindingProperty = '';
    hiddenCameraId.setFormEnabledStatus(true);
    items.push(hiddenCameraId);

    const sportType = new FormInputItem();
    sportType.itemType = FormItemType.Dropdown;
    sportType.inputName = 'sportId';
    sportType.label = $localize`Sport`;
    sportType.placeholder = $localize`Choose a Sport`;
    sportType.bindingProperty = 'sportId';
    sportType.dropdownIsObject = true;
    sportType.required = true;
    sportType.overrideFullWidth = true;
    sportType.dropdownOptions = [];
    items.push(sportType);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    active.overrideFullWidth = true;
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueStream) {
    if( result.cameraId !==''){
      this.activeModal.close(result);
    }
    else{
      this.toastService.publishErrorMessage($localize`Please fix the errors,
      Camera Id is required`, null);
    }
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Stream` : $localize`Add New Stream`;
  }

  deleteStreamClicked() {
    this.formObject.itemDeleted = true;
    this.activeModal.close(this.formObject);
  }

  setSportsOptions(sports: SportType[]) {
    setTimeout(() => {
      const sportInput = this.formItems.find(f => f.inputName === 'sportId');
      sportInput.dropdownOptions = sports;
    });
  }

  findPixellotIdByName(name: string): string {
    return this.pixellotVenues.getValue().find((pv) => {
      return pv.name === name;
    })?.id.toString();
  }
}
