import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {BehaviorSubject, combineLatest, EMPTY, Observable, of} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {Program} from 'src/app/models/program/program';
import {ProgramStatusType} from '../../../../models/lookup/program-status-type';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {filter, map, switchMap} from 'rxjs/operators';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {MediaUtils} from '../../../../utils/media-utils';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {indicate} from '../../../../utils/observable.extensions';
import {NoContentComponent, NoContentType} from '../no-content/no-content.component';

@Injectable()
export class HomeViewModel extends BaseViewModel {

  featureProgram$ = new BehaviorSubject<HydratedProgram>(null);
  featurePrograms$ = new BehaviorSubject<HydratedProgram[]>(null);
  featureProgramLoadingOpts: LoadingOptions = LoadingOptions.defaultTransparent(false, false);
  isLive$ = new BehaviorSubject<boolean>(null);
  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
  fetchFeatureProgram = this.accountDomainModel.subscriberSubscriptions$.pipe(
    switchMap(subscriptions => {
      const privatePlansExist = subscriptions?.some(plan =>
        plan.planId.startsWith('Private-')
      );
      const subscriptionPlanIds = subscriptions?.map(s => s.planId);

      if (this.isSignedIn$ && privatePlansExist) {
        // If signed in, fetch all programs and private plan exist
        const contentQuery = new ContentQuery(ProgramQueryType.Default, null, null, null, null, null, null, null, subscriptionPlanIds);
        return this.programDomainModel.getFeaturedHydratedPrograms(contentQuery)
          .pipe(indicate(this.featureProgramLoadingOpts, ''));
      } else if(!privatePlansExist || !this.isSignedIn$){
        // If not signed in or there is no private plan exist then , restricting all the private content
        const contentQuery = new ContentQuery
        (ProgramQueryType.Default, null, null, null, null, null, null, null,
           privatePlansExist ? subscriptionPlanIds : []);
        return this.programDomainModel.getFeaturedHydratedPrograms(contentQuery).pipe(
          map(featuredPrograms => featuredPrograms.filter(program => !program.isPrivateAccess)),
          indicate(this.featureProgramLoadingOpts, '')
        );
      }
    })
  ).subscribe((p) => {
    this.featurePrograms$.next(p);
    // this.isLive$.next(p.programStatusId === ProgramStatusType.InProgressId);
  }).addTo(this.subscriptions);

featureImageUrl$ = this.featureProgram$.notNull().pipe(switchMap(program => {
    return program.images?.length > 0 ? program.imgSrc$ : this.featureProgramHomeTeamImgSrc$;
  }));

  featureProgramHomeTeamImgSrc$ = combineLatest(
    [this.featureProgram$, this.resourceDomainModel.activeHydratedTeams$]
  ).pipe(
    filter(values => values.every(v => !!v)),
    filter(([p, _]) => !!p?.homeTeamId),
    switchMap(([program, teams]) => {
      const hydrateHomeTeam = teams.find(t => t.id === program.homeTeamId);
      if (hydrateHomeTeam?.bannerImages?.length > 0) {
        return hydrateHomeTeam.bannerImgSrc$;
      } else if (program.images?.length > 0) {
        return program.imgSrc$;
      } else if (hydrateHomeTeam?.cardImages.length > 0) {
        const randomCardImageId = hydrateHomeTeam.cardImages.random()?.id;
        return !!randomCardImageId ? hydrateHomeTeam.cardImgSrcMap.get(randomCardImageId) : EMPTY;
        } else {
        return of(MediaUtils.getRandomBannerImageSrc());
      }
    }),
  );


  fetchFavouriteContent$ = new BehaviorSubject<boolean>(false);

  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  showsNoResults = new BehaviorSubject<boolean>(false);

  showNoResults$ = combineLatest([
    this.liveProgramsNoResults,
    this.upcomingProgramsNoResults,
    this.pastProgramsNoResults,
    this.showsNoResults
  ]).pipe(map(noResults => noResults.every(x => !!x)));

  hasNoPreferences$ = this.fetchFavouriteContent$.pipe(
    switchMap(favouritesTabOnly => this.accountDomainModel.sessionContainer$.pipe(map(s => {
      if (!favouritesTabOnly) {
        return false;
      } else {
        return s.user.favouriteTeams.length === 0 && s.user.favouriteLeagues.length === 0;
      }
    })))
  );


  programContentQuery$ = this.fetchFavouriteContent$.pipe(
    switchMap(favouritesOnly => this.accountDomainModel.sessionContainer$.pipe(map(s => {
      // s.user.favouriteTeams.length > 0 s.user.favouriteTeams?.map(t => t.teamId) : -1;
      if (!favouritesOnly) {
        return new ContentQuery(ProgramQueryType.Default);
      } else {
        return new ContentQuery(ProgramQueryType.Default,
          s.user.favouriteLeagues?.map(l => l.leagueId),
          s.user.favouriteTeams?.map(t => t.teamId));
      }
    })))
  );

  constructor(private programDomainModel: ProgramDomainModel,
              private accountDomainModel: AccountDomainModel,
              private resourceDomainModel: ResourceDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
