<div class="mt-3">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="planDataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="viewModel.rowClicked($event)"
    (ctaButtonClicked)="PermissionManagerService.isGranted(Types.AdminSections.Plans_Add_Plan) ? viewModel.addNewPlan() : null"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
