import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {map} from 'rxjs/operators';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class VenueLandingViewModel extends BaseViewModel {

  leagues$ = this.resourceDomainModel.activeHydratedLeagues$;
  events$ = this.resourceDomainModel.activeHydratedEvents$;
  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  isShow$ = new BehaviorSubject<boolean>(null);
  programId$ = new BehaviorSubject<string>(null);
  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
  contentQuery = new ContentQuery(ProgramQueryType.Venues);

  constructor(
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
