import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {FormValidator} from '../../../../../models/protocols/form-validator';

export const alphanumericValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    const expression = {
      pattern: '^[a-zA-Z0-9]+$',
      message: 'Letters and numbers only, no spaces or special characters.'
    };
    const regexp = new RegExp(expression.pattern);
    return !regexp.test(value) ? {alphanumeric: expression.message} : null;
  };
};

@Directive({
  selector: '[appAlphanumericDirective]',
  providers: [{provide: NG_VALIDATORS, useExisting: AlphanumericValidatorDirective, multi: true}]
})
export class AlphanumericValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } {
    return alphanumericValidator()(control);
  }

  errorName(): string {
    return 'alphanumeric';
  }
}
