/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError, map} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {User} from '../models/account/dto/user';
import {ChangePasswordRequest} from '../models/account/requests/change-password-request';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Role} from '../models/account/dto/role';
import {Image} from '../models/image/dto/image';
import {SignUpRequest} from '../models/account/requests/sign-up-request';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {SignInPortal} from '../models/account/requests/sign-in-request';
import {CreateAdminRequest} from '../models/account/requests/create-admin-request';
import {Program} from '../models/program/program';
import {ResetPasswordFormObject} from '../models/account/requests/reset-password-form-object';
import {
  SubscriberSubscriptionRequest,
  SubscriptionCouponRequest,
  SubscriptionPlan
} from '../models/account/dto/subscription-plan';
import {SubscriberSubscription} from '../models/account/dto/subscriber-subscription';
import {CreateSubscriberPaymentDetails, SubscriberPaymentDetails} from '../models/account/dto/subscriber-payment-details';
import {HydratedSubscriber} from '../models/account/dto/hydrated-subscriber';
import {Coupon} from '../models/account/dto/coupon';
import {SubscriberInvoice} from '../models/account/dto/subscriber-invoice';
import {DepartmentType} from '../models/lookup/department-type';

@Injectable({
  providedIn: 'root',
})
export class UserApi implements LoggableAPI {
  // Variables
  public serviceName = 'Users';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public getAdminById(id: number): Observable<CreateAdminRequest> {
    const url = Endpoints.getAdminById(id);
    return this.apiClient.getObj(CreateAdminRequest, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getUserById', err));
        return throwError(err);
      })
    );
  }

  public getDepartmentList(): Observable<DepartmentType[]> {
    const url = Endpoints.getDepartment();
    return this.apiClient.getArr(DepartmentType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getDepartmentList', err));
        console.log(err, 'error');
        return throwError(err);
      })
    );
  }


  public updateAdmin(user: User): Observable<User> {
    const url = Endpoints.updateCompanyUser(user.id as number);
    return this.apiClient.putObj(User, url, user).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateAdmin', err));
        return throwError(err);
      })
    );
  }

  public updateAdminUserProfile(user: User): Observable<User> {
    const url = Endpoints.updateAdminUserProfile(user.id as number);
    return this.apiClient.putObj(User, url, user).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateAdmin', err));
        return throwError(err);
      })
    );
  }

  public updateAdminRequest(req: CreateAdminRequest): Observable<CreateAdminRequest> {
    const url = Endpoints.updateCompanyUser(req.id as number);
    return this.apiClient.putObj(CreateAdminRequest, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createUser', err));
        return throwError(err);
      })
    );
  }

  public createAdmin(req: CreateAdminRequest): Observable<User> {
    const url = Endpoints.createCompanyUser();
    return this.apiClient.postObj(User, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createUser', err));
        return throwError(err);
      })
    );
  }

  public changeUserPassword(email: string, changePasswordRequest: ChangePasswordRequest, type: SignInPortal): Observable<ChangePasswordRequest> {
    let url = '';
    if (type === 'admin') {
      url = Endpoints.adminChangePassword(Endpoints.encodeParam(email));
    } else if (type === 'subscriber') {
      url = Endpoints.subscriberChangePassword(Endpoints.encodeParam(email));
    }
    return this.apiClient.postObj(ChangePasswordRequest, url, changePasswordRequest).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updatePassword', err));
        return throwError(err);
      })
    );
  }

  public getRoleForSignIn(roleId: number, authToken: string): Observable<Role> {
    const url = Endpoints.getRoleById(roleId);
    const authHeader = new HttpHeaders({authorization: `Bearer ${authToken}`});
    return this.apiClient.getObj(Role, url, authHeader).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getRole', err));
        return throwError(err);
      })
    );
  }

  public respondToNewPasswordChallenge(req: ResetPasswordFormObject, email: string): Observable<HydratedUser> {
    const url = Endpoints.respondToNewPasswordChallenge(email);
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'RespondToNewPasswordChallenge', err));
        return throwError(err);
      })
    );
  }

  // Subscriber Methods
  public createSubscriber(signUpRequest: SignUpRequest): Observable<HydratedUser> {
    const url = Endpoints.createSubscriber();
    return this.apiClient.postObj(HydratedUser, url, signUpRequest).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriber', err));
        return throwError(err);
      })
    );
  }

  public updateSubscriber(user: User): Observable<User> {
    const url = Endpoints.updateSubscriber(user.id as string);
    return this.apiClient.putObj(User, url, user).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateSubscriber', err));
        return throwError(err);
      })
    );
  }

  public deleteSubscriberImage(subscriberId: string, deleteImageId: string): Observable<string> {
    const url = Endpoints.deleteSubscriberImage(subscriberId, deleteImageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteSubscriberImage', err));
        return throwError(err);
      })
    );
  }

  public getSubscriberImages(userId: string): Observable<Image[]> {
    const url = Endpoints.getUserImages(userId);
    return this.apiClient.getArr(Image, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getUserImages', err));
        return throwError(err);
      })
    );
  }

  public getSubscribers(): Observable<User[]> {
    const url = Endpoints.getSubscribers();
    return this.apiClient.recursiveGetArr(User, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSubscribers', err));
        return throwError(err);
      })
    );
  }

  public getHydratedSubscribers(): Observable<HydratedSubscriber[]> {
    const url = Endpoints.getHydratedSubscribers();
    return this.apiClient.recursiveGetArr(HydratedSubscriber, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedSubscribers', err));
        return throwError(err);
      })
    );
  }

  public getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.apiClient.getArr(SubscriptionPlan, Endpoints.getSubscriptionPlans()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSubscriptionPlans', err));
        return throwError(err);
      })
    );
  }

  public createSubscriberSubscription(req: SubscriberSubscriptionRequest, subscriberId: string): Observable<SubscriberSubscription> {
    return this.apiClient.postObj<SubscriberSubscription>(SubscriberSubscription, Endpoints.subscriberSubscriptions(subscriberId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriberSubscription', err));
        return throwError(err);
      })
    );
  }

  public getSubscriberSubscriptions(subscriberId: string): Observable<SubscriberSubscription[]> {
    return this.apiClient.getArr<SubscriberSubscription>(SubscriberSubscription, Endpoints.subscriberSubscriptions(subscriberId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriberSubscription', err));
        return throwError(err);
      })
    );
  }

  public cancelSubscription(req: SubscriberSubscriptionRequest, subscriberId: string, subscriptionId: string): Observable<void> {
    return this.apiClient.postVoid(Endpoints.cancelSubscription(subscriberId, subscriptionId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'cancelSubscription', err));
        return throwError(err);
      })
    );
  }

  public createSubscriberPaymentDetails(req: CreateSubscriberPaymentDetails, subscriberId: string): Observable<SubscriberPaymentDetails> {
    return this.apiClient.postObj<any>(SubscriberSubscription, Endpoints.createSubscriberPaymentDetails(subscriberId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriberPaymentDetails', err));
        return throwError(err);
      })
    );
  }

  public deleteSubscriberPaymentDetails(req: HydratedUser, subscriberId: string, paymentDetailsId: string): Observable<any> {
    return this.apiClient.deleteObj<any>(null, Endpoints.deleteSubscriberPaymentDetails(subscriberId, paymentDetailsId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteSubscriberPaymentDetails', err));
        return throwError(err);
      })
    );
  }

   public pauseSubscriberSubscription(req: SubscriberSubscriptionRequest, subscriberId: string, subscriptionId: string): Observable<SubscriberSubscription> {
    return this.apiClient.postObj<SubscriberSubscription>(SubscriberSubscription, Endpoints.pauseSubscriberSubscription(subscriberId, subscriptionId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'pauseSubscription', err));
        return throwError(err);
      })
    );
  }

  public  resumeSubscriberSubscription(req: SubscriberSubscriptionRequest, subscriberId: string, subscriptionId: string): Observable<SubscriberSubscription> {
    return this.apiClient.postObj<SubscriberSubscription>(SubscriberSubscription, Endpoints.resumeSubscriberSubscription(subscriberId, subscriptionId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'resumeSubscription', err));
        return throwError(err);
      })
    );
  }

  public getSubscriberPaymentDetails(subscriberId: string): Observable<SubscriberPaymentDetails[]> {
    return this.apiClient.getArr<SubscriberPaymentDetails>(SubscriberPaymentDetails, Endpoints.createSubscriberPaymentDetails(subscriberId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriberPaymentDetails', err));
        return throwError(err);
      })
    );
  }

  public getCouponFromCode(code: string,isPrivate: boolean): Observable<Coupon> {
    return this.apiClient.getObj<Coupon>(Coupon, Endpoints.getCouponWithCode(code,isPrivate)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriberPaymentDetails', err));
        return throwError(err);
      })
    );
  }

  public addCouponToSubscription(req: SubscriptionCouponRequest, subscriptionId: string, subscriberId: string): Observable<SubscriberSubscription> {
    return this.apiClient.postObj<any>(SubscriberSubscription, Endpoints.addCouponToSubscription(subscriberId, subscriptionId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addCouponToSubscription', err));
        return throwError(err);
      })
    );
  }

  public getSubscriberInvoices(subscriberId: string): Observable<SubscriberInvoice[]> {
    const url = Endpoints.getSubscriberInvoices(subscriberId);
    return this.apiClient.getArr(SubscriberInvoice, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSubscriberInvoices', err));
        return throwError(err);
      })
    );
  }

  public getAdmins(): Observable<User[]> {
    const url = Endpoints.getAdmins();
    return this.apiClient.recursiveGetArr(User, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdmins', err));
        return throwError(err);
      })
    );
  }
}
