import {Injectable} from '@angular/core';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {BehaviorSubject} from 'rxjs';
import {AdvertisementRunDate} from '../../../../models/resources/advertisement-run-date';

@Injectable()
export class AddRunDatesViewModel extends BaseViewModel {

  formErrorMessage = new BehaviorSubject<string>(null);

  constructor() {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  isFormValid(formObject: AdvertisementRunDate): boolean {
    if (formObject.startDateUtc > formObject.endDateUtc) {
      this.formErrorMessage.next($localize`Start date must be earlier than end date.`);
      return false;
    }
    return true;
  }
}
