import {CustomFile} from '../shared/custom-file';
import {VideoUpload} from '../image/dto/video-upload';
import {HydratedAdvertisement} from './hydrated-advertisement';
import {Image} from '../image/dto/image';
import {SafeResourceUrl} from '@angular/platform-browser';
import {BannerAdvertisement} from './banner-advertisement';

export class AdvertisementFormObject {

  public advertisement: HydratedAdvertisement;

  fileToUpload: CustomFile;
  existingFile: VideoUpload;
  deleteFileId: string;

  static initWith(ad: HydratedAdvertisement, uploads: any[] | VideoUpload[]): AdvertisementFormObject {
    const formObject = new AdvertisementFormObject();
    formObject.existingFile = uploads?.find(u => !!u);
    formObject.advertisement = ad;
    return formObject;
  }
}
