<div class="favourite-picker-container">
  <app-loading
    [hidden]="!(loadingOpts?.isLoading)"
    [options]="loadingOpts">
  </app-loading>
  <app-form-group
    class="d-block pt-3 flex-shrink-0"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formSubmitted)="formSubmitted()">
  </app-form-group>
  <mat-tab-group dynamicHeight animationDuration="0ms" class="overflow-auto">
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>All</ng-container>
      </ng-template>
      <div class="fave-container">
        <app-favourite-picker-row
          *ngFor="let f of filteredAll$|async"
          [favourite]="f"
          [selected]="isSelected(f)"
          (click)="itemClicked(f)">
        </app-favourite-picker-row>
        <div class="no-results" *ngIf="!((filteredAll$|async)?.length > 0) && (searchText$|async)?.length > 2">
          <div class="label-md mb-2 mt-4">
            {{getNoResultsText()}}
          </div>
          <div class="paragraph-sm color-mid-grey" i18n>
            No teams or leagues were found. Please try a different search term.
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Leagues</ng-container>
      </ng-template>
      <div class="fave-container">
        <app-favourite-picker-row
          *ngFor="let f of filteredLeagues$|async"
          [favourite]="f"
          [selected]="isSelected(f)"
          (click)="itemClicked(f)">
        </app-favourite-picker-row>
        <div class="no-results" *ngIf="!((filteredLeagues$|async)?.length > 0) && (searchText$|async)?.length > 2">
          <div class="label-md mb-2 mt-4">
            {{getNoResultsText()}}
          </div>
          <div class="paragraph-sm color-mid-grey" i18n>
            No leagues were found. Please try a different search term.
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Teams</ng-container>
      </ng-template>
      <div class="fave-container">
        <app-favourite-picker-row
          *ngFor="let f of filteredTeams$|async"
          [favourite]="f"
          [selected]="isSelected(f)"
          (click)="itemClicked(f)">
        </app-favourite-picker-row>
        <div class="no-results" *ngIf="!((filteredTeams$|async)?.length > 0) && (searchText$|async)?.length > 2">
          <div class="label-md mb-2 mt-4">
            {{getNoResultsText()}}
          </div>
          <div class="paragraph-sm color-mid-grey" i18n>
            No teams were found. Please try a different search term.
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

