import {BaseLookupType} from '../base/base-lookup-type';
import {DatatableData} from '../protocols/datatable-data';
import {Selectable} from '../protocols/selectable';

export class SportType extends BaseLookupType implements DatatableData, Selectable {
  public name: string = '';
  public id: number;
  public active: boolean = false;
  public pixellotEnumValue: string = '';
  timeStampVal: Date;

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}

