<app-detail-title >
  <div class="d-flex justify-content-between">
    <ng-container i18n>Sports</ng-container>
    <button [disabled]="!PermissionManagerService.isGranted(Types.AdminSections.Sports_Add_Sport)"
            [ngClass]="{'preferred-button': true, 'disabled': !PermissionManagerService.isGranted(Types.AdminSections.Sports_Add_Sport)}"
            class="custom-button preferred-button"
            (click)="viewModel.addNewSport()"
            i18n>Add a New Sport
    </button>
  </div>
</app-detail-title>
<div class="mt-3 position-relative">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
  <app-data-table
    [tableData]="userDataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetUsersTable"
    [updateTableData]="updateSportsTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="PermissionManagerService.isGranted(Types.AdminSections.Sports_Edit_Sport) ? sportClicked($event) : null"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
