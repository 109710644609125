import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from '../../base/base-model';
import {Selectable} from '../../protocols/selectable';
import {NumberUtils} from '../../../utils/number-utils';
import {SubscriptionStatus} from './subscriber-subscription';
import {Coupon} from './coupon';

export class SubscriptionPlan extends BaseModel implements Deserializable, Selectable {
  id: string;
  externalName: string;
  internalName: string;
  name: string;
  category: number;
  status: number;
  description: string;
  pricingOptions: SubscriptionPricingOption[];

  onDeserialize() {
    super.onDeserialize();
    this.pricingOptions = DeserializeHelper.deserializeArray(SubscriptionPricingOption, this.pricingOptions);
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  isActive(): boolean {
    return this.status === 2;
  }

}

export class SubscriptionPricingOption implements Deserializable {
  id: string;
  name: string;
  status: SubscriptionStatus;
  price: number;
  currencyCode: string;
  trialPeriod: number;
  trialPeriodUnit: string;
  period: string;
  periodUnit: string;

  getPriceInDollars() {
    return this.price / 100;
  }

  getPriceDescription(): string {
    return $localize`${NumberUtils.formatNumberAsCurrency(this.getPriceInDollars())}
     ${this.currencyCode} / ${this.periodUnit}`;
  }

  onDeserialize() {
  }
}

export class SubscriberSubscriptionRequest implements Deserializable {
  subscriptionId: string;
  planPriceId: string;
  coupons: SubscriptionCouponRequest[];

  constructor(planPriceId: string, couponIds?: string[], subscriptionId?: string) {
    this.planPriceId = planPriceId;
    this.coupons = couponIds?.map(id => new SubscriptionCouponRequest(id)) ?? undefined;
    this.subscriptionId = subscriptionId ?? undefined;
  }

  onDeserialize() {
  }
}

export class SubscriptionCouponRequest {
  couponId: string;

  constructor(couponId: string) {
    this.couponId = couponId;
  }
}



