<div class="mt-3 position-relative">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <app-data-table
      [tableData]="cameraStreamsDataProvider"
      [tableFilter]="datatableFilter"
      [resetTable]="resetTable"
      [updateTableData]="updateTableData"
      [filterApplied]="tableFilterChanged"
      [tableOptions]="datatableOptions">
    </app-data-table>
  </div>