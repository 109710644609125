import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';

@Injectable()
export class EventsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  events$ = this.domainModel.allHydratedEvents$.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Events`));

  constructor(private domainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
