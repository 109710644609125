import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {ProgramComment} from '../../../../models/program/program-comment';
import {DateUtils} from '../../../../utils/date-utils';
import {HydratedShow} from '../../../../models/program/hydrated-show';

@Component({
  selector: 'app-program-feedback',
  templateUrl: './program-feedback.component.html',
  styleUrls: ['./program-feedback.component.scss'],
})
export class ProgramFeedbackComponent extends BaseComponent {

  @Input() program: HydratedProgram | HydratedShow;
  @Output() closeButtonClicked = new EventEmitter<void>();

  constructor(
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  sortedComments(): ProgramComment[] {
    return this.program?.comments?.sort((a: ProgramComment, b: ProgramComment) => {
      return DateUtils.dateIsBefore(b.createdDate, a.createdDate);
    });
  }
}
