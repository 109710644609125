<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Resubscribe Page</title>
  <link rel="stylesheet" href="resubscribe-landing.component.scss">
</head>
<body>
<div class="unsubscribe-container">
  <div class="logo-container">
    <img src="assets/logo/dark/logo-stroke.svg" alt="Company Logo" style="padding: 10px 0;">
  </div>
  <h1 class="resubscribe-message">Welcome Back!</h1>
  <p class="resubscribe-message">{{userEmail}} has been resubscribed to notifications.</p>
  <button class="watch-now-button"  (click)="navigateToRoot()">Watch Now</button>
</div>


