import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {Venue} from '../models/resources/venue';
import {HydratedVenue} from '../models/resources/hydrated-venue';
import {VenueStream} from '../models/resources/venue-stream';
import {TeamId} from '../models/resources/teamId';

@Injectable({
  providedIn: 'root',
})
export class VenueApi implements LoggableAPI {
  // Variables
  public serviceName = 'Venue';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }


  public getHydratedVenues(): Observable<HydratedVenue[]> {
    return this.apiClient.getArr(HydratedVenue, Endpoints.getHydratedVenues()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getVenues', err));
        return throwError(err);
      })
    );
  }
  public getHydratedVenue(venueId: number): Observable<HydratedVenue> {
    return this.apiClient.getObj(HydratedVenue, Endpoints.getHydratedVenue(venueId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getVenues', err));
        return throwError(err);
      })
    );
  }

  public createVenue(req: Venue): Observable<Venue> {
    return this.apiClient.postObj<Venue>(Venue, Endpoints.createVenue(), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createVenue', err));
        return throwError(err);
      })
    );
  }

  public updateVenue(req: Venue): Observable<Venue> {
    return this.apiClient.putObj<Venue>(Venue, Endpoints.updateVenue(String(req.id)), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateVenue', err));
        return throwError(err);
      })
    );
  }

  public createVenueStream(venueId: number, req: VenueStream): Observable<VenueStream> {
    return this.apiClient.postObj<VenueStream>(VenueStream, Endpoints.createVenueStream(venueId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createVenueStream', err));
        return throwError(err);
      })
    );
  }

  public updateVenueStream(venueId: number, req: VenueStream): Observable<VenueStream> {
    return this.apiClient.putObj<VenueStream>(VenueStream, Endpoints.updateVenueStream(venueId, req.id), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateVenueStream', err));
        return throwError(err);
      })
    );
  }

  public deleteVenueStream(venueId: number, streamId: number): Observable<any> {
    return this.apiClient.deleteObj<any>(null, Endpoints.deleteVenueStream(venueId, streamId), null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteVenueStream', err));
        return throwError(err);
      })
    );
  }

  addVenueTeamAssociation(venueId: number, teamId: number): Observable<TeamId> {
    const teamIdBody = new TeamId();
    teamIdBody.teamId = teamId;
    return this.apiClient.postObj<TeamId>(TeamId, Endpoints.createVenueTeamAssociation(venueId), teamIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addVenueTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteVenueTeamAssociation(venueId: number, teamId: number): Observable<string> {
    const url = Endpoints.deleteVenueTeamAssociation(venueId, teamId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteVenueTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  public deleteVenueImage(venueId: number, imageId: string): Observable<string> {
    const url = Endpoints.deleteVenueImage(venueId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteVenueImage', err));
        return throwError(err);
      })
    );
  }
}
