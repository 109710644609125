import {SafeResourceUrl} from '@angular/platform-browser';
import {FormListable} from '../protocols/form-listable';
import {DeserializeHelper} from '../protocols/deserializable';
import {BannerAdvertisement} from './banner-advertisement';

export class BannerAdvertisementFormObject implements FormListable {
  public bannerAd: BannerAdvertisement;
  imageToUpload: string | SafeResourceUrl;
  existingImageId: string;
  deleteImageId: string;
  imageToUploadFormat: string;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  static initWithBannerAdvertisement(bannerAd: BannerAdvertisement): BannerAdvertisementFormObject {
    const formObject = new BannerAdvertisementFormObject();
    formObject.setBannerAdvertisement(bannerAd);
    return formObject;
  }

  setBannerAdvertisement(t: BannerAdvertisement) {
    this.bannerAd = t;
    this.existingImageId = t.id;
  }

  getCopy(): BannerAdvertisementFormObject {
    const bannerAdCopy = DeserializeHelper.deserializeToInstance(BannerAdvertisement, this.bannerAd);
    const formObjectCopy = Object.assign(new BannerAdvertisementFormObject(), this);
    formObjectCopy.setBannerAdvertisement(bannerAdCopy);
    return formObjectCopy;
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    if (this.bannerAd?.advertisementUrl?.length >= 50) {
      return this.bannerAd?.advertisementUrl.slice(0, 50).concat('...');
    } else {
      return this.bannerAd?.advertisementUrl;
    }
  }

  getListItemTitle(): string {
    return this.bannerAd?.advertisementName;
  }
}

