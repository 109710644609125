import {Component, EventEmitter} from '@angular/core';
import {AdvertisingPartnersViewModel} from './advertising-partners-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';
import {AdvertisingPartner} from '../../../models/resources/advertising-partner';
import {ModalUtils} from '../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  EditAdvertisingPartnerModalComponent
} from './edit-advertising-partner-modal/edit-advertising-partner-modal.component';

@Component({
  selector: 'app-advertising-partners',
  templateUrl: './advertising-partners.component.html',
  styleUrls: ['./advertising-partners.component.scss'],
  providers: [AdvertisingPartnersViewModel],
})
export class AdvertisingPartnersComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: AdvertisingPartnersViewModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.advertPartners$.notNull().subscribe(d => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = d;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  rowClicked(data: DatatableData) {
    const partner = data as AdvertisingPartner;
    this.router.navigate([`./${partner.id}`], {relativeTo: this.activatedRoute}).then();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const advertisingPartner = new DatatableColumn(
      'advertisingPartner',
      $localize`Advertising Partner`,
      DatatableColumnType.Text,
      (a: AdvertisingPartner): string => {
        return a.name;
      }
    );
    advertisingPartner.isDefaultSortColumn = true;
    columns.push(advertisingPartner);

    const preRolls = new DatatableColumn(
      'preRoll',
      $localize`Pre-Roll`,
      DatatableColumnType.Text,
    (a: AdvertisingPartner): number => {
      return a.preRolls;
    }
    );
    columns.push(preRolls);

    const bannerAds = new DatatableColumn(
      'bannerAds',
      $localize`Banner Ads`,
      DatatableColumnType.Text,
      (a: AdvertisingPartner): number => {
        return a.bannerAds;
      }
    );
    columns.push(bannerAds);


    const active = new DatatableColumn(
      'active',
      $localize`Status`,
      DatatableColumnType.Text,
      (a: AdvertisingPartner): string => {
        return a.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    active.className = 'custom-datatable-header text-right mr-2';
    active.headerClassName = 'custom-datatable-header text-right mr-2';
    active.columnFilter = this.getStatusColumnFilter();
    columns.push(active);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  createAdvertPartnerClicked() {
    const modalRef = this.modalService.open(
      EditAdvertisingPartnerModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    modalRef.result.then((partner: AdvertisingPartner) => {
      if (partner) {
       this.viewModel.createAdvertisingPartner(partner);
      }
    });
  }
}
