import {Component, Input} from '@angular/core';
import {HydratedProgram} from '../../../../../models/program/hydrated-program';


@Component({
  selector: 'app-featured-program-slider',
  templateUrl: './featured-program-slider.component.html',
  styleUrls: ['./featured-program-slider.component.scss'],
})

export class ImageSliderComponent {

  @Input() hydratedPrograms: HydratedProgram[] = [];

  constructor() {
  }

}
