import * as buffer from 'buffer';

export class CsvUtils {
  static downloadCsvFile(data: string, fileName: string) {
    const downloadLink = document.createElement('a');
    const blob = new Blob([data], { type: 'text/csv' });
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  static escapeCsvDataString(dataString: string): string {
    if (!dataString) {
      return '';
    }
    // If the value contains a comma, newline or double quote, then the String value should be returned enclosed in double quotes.
    // Any double quote characters in the value should be escaped with another double quote.
    // If the value does not contain a comma, newline or double quote, then the String value should be returned unchanged.
    if (dataString.includes(',') || dataString.includes('\n') || dataString.includes('"')) {
      return `"${dataString.replace('"', '""')}"`;
    } else {
      return dataString;
    }
  }

  static csvFileURLToString(dataUrl: string) {
    let b64string = '';

    if (dataUrl.includes('data:text/csv;base64,')) {
      b64string = dataUrl.replace('data:text/csv;base64,', '');
    }
    else if (dataUrl.includes('data:application/vnd.ms-excel;base64,')) {
      b64string = dataUrl.replace('data:application/vnd.ms-excel;base64,', '');
    }

    return buffer.Buffer.from(b64string, 'base64').toString('binary');
  }
}
