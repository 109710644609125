import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TeamComponent} from './team.component';
import {IsSubscriberGuard} from '../../services/guards/is-subscriber.guard';

const routes: Routes = [
  {
    path: ':teamId',
    component: TeamComponent,
    data: {
      title: 'Team'
    },
    canActivate: [IsSubscriberGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamRoutingModule { }
