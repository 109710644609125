import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-resubscribe-landing',
  templateUrl: './resubscribe-landing.component.html',
  styleUrls: ['./resubscribe-landing.component.scss']
})
export class ResubscribeComponent extends BaseComponent {

  userEmail: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
              ) {
    super();
  }

  setupBindings() {
    this.route.queryParams.subscribe(params => {
      this.userEmail = params.email;
    });
  }

  setupViews() {
  }

  navigateToRoot() {
    this.router.navigate(['/']);
  }


}
