import {Component, EventEmitter} from '@angular/core';
import {EventsViewModel} from './events-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {HydratedEvent} from '../../../models/resources/hydrated-event';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [EventsViewModel],
})
export class EventsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: EventsViewModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.events$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  rowClicked(data: DatatableData) {
    const event = data as HydratedEvent;
    this.router.navigate([`./${event.id}`], {relativeTo: this.activatedRoute}).then();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const eventName = new DatatableColumn(
      'eventName',
      $localize`Name`,
      DatatableColumnType.Text,
      (l: HydratedEvent): string => {
        return l.name;
      }
    );
    eventName.isDefaultSortColumn = true;
    columns.push(eventName);

    const teams = new DatatableColumn(
      'teams',
      $localize`Teams`,
      DatatableColumnType.Text,
      (l: HydratedEvent): string => {
        return `${l.associatedTeams?.length ?? 0}`;
      }
    );
    columns.push(teams);

    const activeEvent = new DatatableColumn(
      'activeEvent',
      $localize`Status`,
      DatatableColumnType.Text,
      (l: HydratedEvent): string => {
        return l.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    activeEvent.className = 'custom-datatable-header text-right mr-2';
    activeEvent.headerClassName = 'custom-datatable-header text-right mr-2';
    activeEvent.columnFilter = this.getStatusColumnFilter();
    columns.push(activeEvent);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  addNewEvent() {
    this.router.navigate([`./add`], {relativeTo: this.activatedRoute}).then();
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
}
