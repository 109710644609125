import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {map, switchMap, switchMapTo} from 'rxjs/operators';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {AdvertisingDomainModel} from '../../../../domainModels/advertising-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute} from '@angular/router';
import {AdvertisingPartner} from '../../../../models/resources/advertising-partner';

@Injectable()
export class AdvertisingPartnerDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);

  partnerId$ = this.activatedRoute.params.pipe(
    map(params => params?.partnerId),
  );

  refreshPartner$ = new BehaviorSubject<void>(undefined);
  advertPartner$ = combineLatest([this.partnerId$, this.refreshPartner$]).pipe(
    switchMap(([id, _]) => {
      return this.domainModel.getAdvertisingPartner(id).pipe(indicateOnNext(this.loadingOpts, $localize`Loading Advertising Partner`));
    })
  );

  advertisements$ = combineLatest([this.partnerId$, this.refreshPartner$]).pipe(
    switchMap(([id, _]) => {
      return this.domainModel.getAdvertisingPartnerAdvertisements(id).pipe(
        indicateOnNext(this.loadingOpts, $localize`Loading Advertisements`));
    })
  );

  constructor(private domainModel: AdvertisingDomainModel,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastService) {
    super();
    this.init();
  }

  init() {
    super.init();
  }


  updateAdvertisingPartner(updatedAdvertisingPartner: AdvertisingPartner) {
    this.domainModel.updateAdvertisingPartner(updatedAdvertisingPartner).subscribe(() => {
      this.toastService.publishSuccessMessage(null, $localize`Advertising Partner Saved`);
      this.refreshPartner$.next();
    }, error => {
      this.toastService.publishError(error, $localize`Update Advertising Partner Failed`);
    });
  }
}
