import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {VenueDetailsViewModel} from '../venue-details-view-model';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {VenueFormObject} from '../../../../../models/resources/venue-form-object';
import {TeamFormObject} from '../../../../../models/resources/team-form-object';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime} from 'rxjs/operators';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {EditTeamModalComponent} from '../../../shared/edit-team-modal/edit-team-modal.component';
import {ToastService} from '../../../../../services/toast-service';
import {AddExistingTeamModalComponent} from '../../../shared/add-existing-team-modal/add-existing-team-modal.component';
import {HydratedTeam} from '../../../../../models/resources/hydrated-team';

@Component({
  selector: 'app-venue-teams',
  templateUrl: './venue-teams.component.html',
  styleUrls: ['./venue-teams.component.scss'],
})
export class VenueTeamsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: VenueFormObject;
  private addTeamClicked = new EventEmitter();
  private editTeamClicked = new EventEmitter<TeamFormObject>();

  constructor(
    public viewModel: VenueDetailsViewModel,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
    this.setupFormStyling();

    this.viewModel.venue$
      .pipe(debounceTime(100))
      .subscribe(venue => {
        this.formObject = VenueFormObject.initWithVenue(venue);
        this.setupFormItems();
      }).addTo(this.subscriptions);

    this.addTeamClicked.subscribe(() => {
      this.addNewTeam();
    }).addTo(this.subscriptions);

    this.editTeamClicked.subscribe((t) => {
      this.editTeam(t);
    }).addTo(this.subscriptions);
  }

  setupFormBindings() {
  }

  setupViews() {
    this.PermissionManagerService.loadPermissionFactoryGetInstance();
    this.setupFormOptions();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const teamsTitle = new FormInputItem();
    teamsTitle.itemType = FormItemType.Title;
    teamsTitle.label = $localize`Teams`;
    teamsTitle.titleButtonText = $localize`Add a Team`;
    teamsTitle.titleButtonClicked = this.addTeamClicked;
    teamsTitle.enabled = this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Teams_Add);
    teamsTitle.overrideFullWidth = true;
    items.push(teamsTitle);

    const teams = new FormInputItem();
    teams.inputName = 'teamFormObjects';
    teams.itemType = FormItemType.List;
    teams.bindingProperty = 'teamFormObjects';
    teams.listItemButtonText = $localize`Edit`;
    teams.listItemClicked = this.editTeamClicked;
    teams.listItemClicked = this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Teams_Edit_Team) ?
      this.editTeamClicked : null;
    teams.listItemButtonClass = this.PermissionManagerService.isGranted(this.Types.AdminSections.Venues_Edit_Venue_Teams_Edit_Team) ?
      '' : 'text-link disabled';
    items.push(teams);

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueFormObject) {
  }

  cancel() {
  }

  private addNewTeam() {
    const modalRef = this.modalService.open(
      AddExistingTeamModalComponent,
      ModalUtils.addTeamModalOptions(),
    );
    const compInstance = modalRef.componentInstance as AddExistingTeamModalComponent;
    let assocaitedTeamIds: number[];
    let venueId: number;
    this.viewModel.venue$.notNull().subscribe((venue)=>{
       assocaitedTeamIds=venue.associatedTeams.map(venueSelected=>venueSelected.id);
       venueId=venue.id;
    });

    compInstance.intialSetup(assocaitedTeamIds);
    modalRef.result.then((existingTeam: HydratedTeam) => {
      if (existingTeam) {
        const tfo = TeamFormObject.initWithTeam(existingTeam);
        this.formObject.teamFormObjects.push(tfo);
        tfo.team.itemCreated = true;
        this.saveVenueTeamAssociation(tfo,venueId);
      } else {
        this.createNewTeam();
      }
    }, reason => {});

  }

  private createNewTeam() {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.initWithNewFormObject();
    modalRef.result.then((teamFormObject: TeamFormObject) => {
      if (teamFormObject) {
        this.formObject.teamFormObjects.push(teamFormObject);
        this.saveVenueTeam(teamFormObject);
      }
    }, reason => {
    });
  }

  private editTeam(teamFormObject: TeamFormObject) {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.initWithFormObject(teamFormObject);
    let venueId: number;
    this.viewModel.venue$.notNull().subscribe((venue)=>{
      venueId=venue.id;
   });
    modalRef.result.then((updatedTeamFormObject: TeamFormObject) => {
      if (updatedTeamFormObject) {
        const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
        this.formObject.teamFormObjects[index] = updatedTeamFormObject;
        if (updatedTeamFormObject.team.itemDeleted) {
          this.saveVenueTeamAssociation(updatedTeamFormObject,venueId);
          this.formObject.teamFormObjects.splice(index, 1);
        } else {
          this.saveVenueTeam(updatedTeamFormObject);
        }
      }

    }, reason => {
    });
  }

  saveVenueTeam(teamFormObject: TeamFormObject) {
    this.viewModel.saveVenueTeam(teamFormObject).subscribe((updatedTeam) => {
      this.toastService.publishSuccessMessage($localize`Venue Team Saved`, null);
      const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
      this.formObject.teamFormObjects[index].team.id = updatedTeam.id;
      this.formObject.teamFormObjects[index].itemCreated = false;
      this.formObject.teamFormObjects[index].itemChanged = false;
      this.triggerRefresh();
    }, error => {
      this.toastService.publishErrorMessage(error, $localize`Venue Team Save Failed`);
    });
  }

  saveVenueTeamAssociation(teamFormObject: TeamFormObject, venueId: number) {
    this.viewModel.saveVenueTeamAssociation(teamFormObject.team,venueId).subscribe((updatedTeamId) => {
      this.toastService.publishSuccessMessage($localize`Venue Team Saved`, null);
      this.triggerRefresh();
      const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
      this.formObject.teamFormObjects[index].team.id = updatedTeamId.teamId;
      this.formObject.teamFormObjects[index].itemCreated = false;
      this.formObject.teamFormObjects[index].itemChanged = false;
    }, error => {
      this.toastService.publishErrorMessage(error, $localize`Venue Team Save Failed`);
    });

  }
  triggerRefresh() {
    this.viewModel.refreshVenueSubject$.next();
  }
}
