import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EventSubscribeBannerViewModel} from './event-subscribe-banner-view-model.service';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';

@Component({
  selector: 'app-event-subscribe-banner',
  templateUrl: './event-subscribe-banner.component.html',
  styleUrls: ['./event-subscribe-banner.component.scss'],
  providers: [EventSubscribeBannerViewModel],
})
export class EventSubscribeBannerComponent extends BaseComponent implements OnChanges{

  @Input() hydratedEvent: HydratedEvent;

  constructor(
    public viewModel: EventSubscribeBannerViewModel,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.hydratedEvent) {
      this.viewModel.hydratedEvent$.next(this.hydratedEvent);
    }
  }

  public subscribeClicked() {
    this.viewModel.subscribeClicked();
  }
}
