export class FormOptions {
  public clearOnSubmit: boolean = false;
  public submitOnEnter: boolean = true;
  public includeEndFormCheckbox: boolean = false;
  public includeEndFormRadiobutton: boolean = false;
  public checkboxBindingProperty: [string, boolean] = ['', false];
  public radiobuttonBindingProperty: [string, boolean] = ['', false];
  public performNonEmptyInitialValidation: boolean = true;
  public emitInitialValuesAfterSetup: boolean = true;
}
