import {VenueStream} from './venue-stream';
import {DeserializeHelper} from '../protocols/deserializable';
import {Venue} from './venue';
import {HydratedTeam} from './hydrated-team';
import {Image} from '../image/dto/image';
import AddressUtils from '../../utils/address-utils';

export class HydratedVenue extends Venue {
  streams: VenueStream[] = [];
  associatedTeams: HydratedTeam[];
  images: Image[];

  onDeserialize() {
    super.onDeserialize();
    this.associatedTeams = DeserializeHelper.deserializeArray(HydratedTeam, this.associatedTeams ?? []);
    this.streams = DeserializeHelper.deserializeArray(VenueStream, this.streams ?? []);
    this.images = DeserializeHelper.deserializeArray(Image, this.images);
  }
}
