<div class="feedback-container">
  <div class="feedback-title-container">
    <div class="title-tiny" i18n>Error</div>
    <div class="text-link-button"
         (click)="closeButtonClicked.emit()"
         i18n>Close
    </div>
  </div>
  <div class="comments-container" *ngIf="error?.length > 0">
    <div class="error-text">{{error}}</div>
  </div>
</div>
