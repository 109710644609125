<app-loading
  [hidden]="!loadingOpts.isLoading"
  [options]="loadingOpts">
</app-loading>
<img (click)="navigateToRoot()" [src]="'assets/logo/light/logo-filled.svg'" alt="logo"
     class="nav-logo">
<ul class="nav-list custom-menu-nav">
  <li (click)="navItemSelected(navItem)"
      *ngFor="let navItem of navItems"
      [ngClass]="{ 'passive-selection' : navItem.locationPicker,
       'active' : isNavItemActive(navItem),
        'animating' : navItem.animating,
        'dev-color': dev}"
      class="nav-item active custom-nav-item">
    <div class="nav-item-icon"
    [ngClass]="{'dev-color': dev}">
      <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon">
      <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled">
    </div>
    <div class="nav-item-label"
         [ngClass]="{
         'multi-line-label': multilineNavLabel(navItem),
         'dev-color': dev
         }">
      {{navItem.name}}
    </div>
    <div *ngIf="getBadgeContentForNavItem(navItem)"
         class="nav-item-notification-pill">
      {{getBadgeContentForNavItem(navItem)}}
    </div>
    <div *ngIf="navItem.locationPicker"
         class="location-picker">
      <img alt="icon" class="nav-bar-icon" src="../../../../assets/icons/light/outline/selector.svg">
    </div>
  </li>
</ul>
