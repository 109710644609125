<div class="mt-3">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="userDataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetUsersTable"
    [updateTableData]="updateUsersTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="userClicked($event)"
    (ctaButtonClicked)="openInviteUserPage()"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
