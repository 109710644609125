<label [hidden]="checkBoxContainerOptions.hideBulkCheckbox" class="custom-checkbox-container mb-4"
       for="checkbox-all-{{cid}}">
  <input class="custom-checkbox" id="checkbox-all-{{cid}}" name="checkbox-all"
         type="checkbox"
         [ngClass]="{ 'indeterminate': percentageChecked > 0 && percentageChecked < 1 }"
         [checked]="percentageChecked === 1"
         (change)="selectAllClicked($event)">
  <span class="custom-checkbox-label">
    {{ checkBoxContainerOptions.checkAllText }}
  </span>
  <span class="custom-checkmark"></span>
</label>
<app-checkbox
  *ngFor="let chbx of checkBoxes"
  [checkbox]="chbx"
  [refresh]="refresh"
  [inline]="checkBoxContainerOptions.inlineOptions"
  [customCheckboxMargin]="checkBoxContainerOptions.customCheckboxMargin"
  (clickEvent)="selectSingleOptionClicked()"
  [showDivider]="checkBoxContainerOptions.showDivider">
</app-checkbox>
