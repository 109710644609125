import {Component, EventEmitter, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../../models/base/base-component';
import {CameraSystemViewModal} from './camera-systems.component-view-model';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';
import {CameraSystem} from '../../../models/resources/camera-system';
import { StringUtils } from 'src/app/utils/string-utils';

@Component({
  selector: 'app-camera-systems',
  templateUrl: './camera-systems.component.html',
  styleUrls: ['./camera-systems.component.scss'],
  providers: [CameraSystemViewModal]
})
export class CameraSystemsComponent extends BaseComponent implements OnDestroy {


  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public cameraSystemsDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  isReload: boolean=true;



  constructor(
    public  viewModel: CameraSystemViewModal
  ) {
    super();
  }


  ngOnDestroy(): void {
    this.isReload=false;
  }

  setupBindings() {
    this.viewModel.getCameraSystems().notNull().subscribe((cameraSystem) => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = cameraSystem;
        this.updateTableData.next(dataProvider);
        setTimeout(()=>this.isReload ? this.setupBindings() : null ,300000);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDataTable();
  }

  setupDataTable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.datatableOptions.ctaButtonText = $localize`Add New System`;
    this.datatableOptions.ctaButtonClass = this.PermissionManagerService
    .isGranted(this.Types.AdminSections.Camera_Systems_Add_Camera_System)
     ? 'preferred-button' : 'preferred-button disabled';
    this.setDatatableFilter();
  }

  ctaButtonClicked(){
    this.viewModel.addNewSystem();
  }

  private getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const cameraName  = new DatatableColumn(
      'camera',
      $localize`Camera Name`,
      DatatableColumnType.Text,
      (camera: CameraSystem): string => {
        return camera.name;
      }
    );
    cameraName.isDefaultSortColumn = true;
    columns.push(cameraName);

    const systemId = new DatatableColumn(
      'systemType',
      $localize`System Type`,
      DatatableColumnType.Text,
      (camera: CameraSystem): string => {
        return camera.systemTypeId;
      }
    );
    columns.push(systemId);
    systemId.columnFilter = this.getDefaultColumnFilter();

    const camerSystemId = new DatatableColumn(
      'systemTypeId',
      $localize`System HTL Id`,
      DatatableColumnType.Text,
      (camera: CameraSystem): string => {
        return camera.id;
      }
    );
    columns.push(camerSystemId);


    const currentFirmware = new DatatableColumn(
      'currentFirmware',
      $localize`Current FirmWare`,
      DatatableColumnType.Text,
      (camera: CameraSystem): string => {
        return StringUtils.checkStringNotNullOrEmpty(camera.currentFirmware) ? camera.currentFirmware : '';
      }
    );
    columns.push(currentFirmware);

    const serialNumber = new DatatableColumn(
      'serialNumber',
      $localize`Hardware Serial Number`,
      DatatableColumnType.Text,
      (camera: CameraSystem): string => {
        return StringUtils.checkStringNotNullOrEmpty(camera.serialNumber) ? camera.serialNumber : '';
      }
    );
    columns.push(serialNumber);

    const status = new DatatableColumn(
      'cameraStatus',
      $localize`Status`,
      DatatableColumnType.Text,
      (camera: CameraSystem): string => {
        return this.viewModel.getCameraStatus(camera);
      }
    );
    columns.push(status);
    status.className = 'custom-datatable-header text-right mr-2';
    status.headerClassName = 'custom-datatable-header text-right mr-2';
    status.columnFilter = this.getStatusColumnFilter();

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by Camera System name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Online`, $localize`Online`),
      new DatatableColumnFilterListItem($localize`Offline`, $localize`Offline`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }


  getDefaultColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
}

