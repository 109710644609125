import {EventEmitter} from '@angular/core';

export class LabelGroupItem {
  public itemType: LabelItemType = LabelItemType.Text;
  public id: string;
  public label: string;
  public value: string;
  public color: string;
  public backgroundColor: string;
  public tooltipText: string;
  public inlineLabel: boolean = false;
  public hideLabel: boolean = false;
  public equalInlineLabel: boolean = false;
  public actionButtonText: string;
  public actionButtonClass: string;
  public iconSrc: string;
  public actionEmitter: EventEmitter<any> = new EventEmitter<any>();
}

export enum LabelItemType {
  Text = 'text',
  SmallText = 'smalltext',
  Label = 'label',
  Divider = 'divider',
  Title = 'title',
  ButtonListItem = 'buttonlistitem',
  IconListItem = 'iconlistitem'
}
