<div class="modal-body">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <div class="modal-title">
    Add League
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (formSubmitted)="formSubmitted()">
  </app-form-group>
</div>
