import {BaseLookupType} from '../base/base-lookup-type';
import {StateType} from './state-type';
import {DeserializeHelper} from '../protocols/deserializable';
import {Selectable} from '../protocols/selectable';

export class DepartmentType extends BaseLookupType implements Selectable{
  public name: string;
  public id: number;

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): string {
    return this.name + this.id;
  }


  getSelectionValue(): any {
    return this.id;
  }
}

