import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {BaseDomainModel} from '../models/base/base-domain-model';
import '../utils/observable.extensions';
import {AdvertisingPartner} from '../models/resources/advertising-partner';
import {AdvertisingApi} from '../api/advertising-api';
import {Advertisement} from '../models/resources/advertisement';
import {VideoUpload} from '../models/image/dto/video-upload';
import {AdvertisementFormObject} from '../models/resources/advertisement-form-object';
import {map, switchMap} from 'rxjs/operators';
import {HydratedAdvertisement} from '../models/resources/hydrated-advertisement';
import {VenueId} from '../models/resources/venue-id';
import {LeagueId} from '../models/resources/leagueId';
import {TeamId} from '../models/resources/teamId';
import * as moment from 'moment';
import {DateUtils} from '../utils/date-utils';
import {EventId} from '../models/resources/eventId';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingDomainModel extends BaseDomainModel {

  constructor(
    private advertisingApi: AdvertisingApi
  ) {
    super();
    this.init();
  }

  public init() {
  }

  getAdvertisingPartners(): Observable<AdvertisingPartner[]> {
    return this.advertisingApi.getAdvertisingPartners();
  }

  getAdvertisingPartner(partnerId: number): Observable<AdvertisingPartner> {
    return this.advertisingApi.getAdvertisingPartner(partnerId);
  }

  createAdvertisingPartner(partner: AdvertisingPartner): Observable<AdvertisingPartner> {
    return this.advertisingApi.createAdvertisingPartner(partner);
  }

  updateAdvertisingPartner(partner: AdvertisingPartner): Observable<AdvertisingPartner> {
    return this.advertisingApi.updateAdvertisingPartner(partner);
  }

  getAdvertisingPartnerAdvertisements(partnerId: number): Observable<HydratedAdvertisement[]> {
    return this.advertisingApi.getAdvertisingPartnerAdvertisements(partnerId);
  }

  getAdvertisingPartnerAdvertisement(partnerId: number, adId: string): Observable<HydratedAdvertisement> {
    return this.advertisingApi.getAdvertisingPartnerAdvertisement(partnerId, adId);
  }

  createAdvertisingPartnerAdvertisement(partnerId: number, ad: Advertisement): Observable<Advertisement> {
    return this.advertisingApi.createAdvertisingPartnerAdvertisement(partnerId, ad);
  }

  updateAdvertisingPartnerAdvertisement(partnerId: number, ad: Advertisement): Observable<Advertisement> {
    return this.advertisingApi.updateAdvertisingPartnerAdvertisement(partnerId, ad);
  }

  getAdvertisementUploads(adId: string): Observable<VideoUpload[]> {
    return this.advertisingApi.getAdvertisementUploads(adId);
  }

  saveAdvertisement(advertisementFormObject: AdvertisementFormObject): Observable<any> {
    console.log(advertisementFormObject, 'advertisementFormObject');
    const ad = advertisementFormObject.advertisement;
    const partnerId = ad.advertisingPartnerId;
    const saveReq: Observable<Advertisement> = !advertisementFormObject.advertisement.id ?
      this.advertisingApi.createAdvertisingPartnerAdvertisement(partnerId, ad) :
      this.advertisingApi.updateAdvertisingPartnerAdvertisement(partnerId, ad);
    return saveReq.pipe(
      switchMap(updatedAdvertisement =>
        forkJoin([
          this.saveAdvertisingRunDates(advertisementFormObject, updatedAdvertisement),
          this.saveAdvertisingAssociations(advertisementFormObject, updatedAdvertisement),
          this.uploadAdvertisementVideoRequest(advertisementFormObject, updatedAdvertisement),
          this.deleteAdvertisementVideoRequest(advertisementFormObject, updatedAdvertisement),
        ])
      )
    );
  }

  uploadAdvertisementVideoRequest(advertisementFormObject: AdvertisementFormObject, updatedAdvertisement: Advertisement): Observable<any> {
    if (!advertisementFormObject.fileToUpload) {
      return of(null);
    }
    return this.advertisingApi.uploadAdvertisementVideo(updatedAdvertisement.id, advertisementFormObject.fileToUpload.file);
  }

  deleteAdvertisementVideoRequest(advertisementFormObject: AdvertisementFormObject, updatedAdvertisement: Advertisement): Observable<any> {
    if (!advertisementFormObject.deleteFileId) {
      return of(null);
    }
    return this.advertisingApi.deleteAdvertisementUpload(updatedAdvertisement.id, advertisementFormObject.deleteFileId);
  }

  saveAdvertisingAssociations(advertisementFormObject: AdvertisementFormObject, updatedAdvertisement: Advertisement): Observable<any> {
    const obs: Observable<any>[] = [];
    advertisementFormObject.advertisement.associatedLeagues
      .forEach(l => obs.push(this.saveAdvertisementLeagueAssociation(updatedAdvertisement.id, l)));
    advertisementFormObject.advertisement.associatedEvents
      .forEach(e => obs.push(this.saveAdvertisementEventAssociation(updatedAdvertisement.id, e)));
    advertisementFormObject.advertisement.associatedVenues
      .forEach(v => obs.push(this.saveAdvertisementVenueAssociation(updatedAdvertisement.id, v)));
    advertisementFormObject.advertisement.associatedTeams
      .forEach(t => obs.push(this.saveAdvertisementTeamAssociation(updatedAdvertisement.id, t)));
    return obs?.length > 0 ? forkJoin(obs) : of(null);
  }

  saveAdvertisingRunDates(advertisementFormObject: AdvertisementFormObject, updatedAdvertisement: Advertisement): Observable<any> {
    const obs: Observable<any>[] = advertisementFormObject.advertisement.runDates
      ?.map(runDate => {
        if (runDate.itemCreated && !runDate.itemDeleted) {
          runDate.startDateUtc = DateUtils.startOfDayUTC(runDate.startDateUtc);
          runDate.endDateUtc = DateUtils.endOfDayUTC(runDate.endDateUtc);
          return this.advertisingApi.createAdvertisementRunDate(updatedAdvertisement.id, runDate);
        } else if (runDate.itemDeleted) {
          return this.advertisingApi.deleteAdvertisementRunDate(updatedAdvertisement.id, runDate.id).pipe(map(() => null));
        }
        return of(null);
      });
    return obs?.length > 0 ? forkJoin(obs) : of(null);
  }

  saveAdvertisementVenueAssociation(advertisementId: string, venueAssociation: VenueId): Observable<VenueId> {
    if (venueAssociation.itemCreated && !venueAssociation.itemDeleted) {
      return this.advertisingApi.addAdvertisementVenueAssociation(advertisementId, venueAssociation.venueId);
    } else if (venueAssociation.itemDeleted) {
      return this.advertisingApi.deleteAdvertisementVenueAssociation(advertisementId, venueAssociation.id).pipe(map(() => null));
    }
    return of(null);
  }

  saveAdvertisementLeagueAssociation(advertisementId: string, leagueAssociation: LeagueId): Observable<LeagueId> {
    if (leagueAssociation.itemCreated && !leagueAssociation.itemDeleted) {
      return this.advertisingApi.addAdvertisementLeagueAssociation(advertisementId, leagueAssociation.leagueId);
    } else if (leagueAssociation.itemDeleted) {
      return this.advertisingApi.deleteAdvertisementLeagueAssociation(advertisementId, leagueAssociation.id).pipe(map(() => null));
    }
    return of(null);
  }

saveAdvertisementEventAssociation(advertisementId: string, eventAssociation: EventId): Observable<EventId> {
    if (eventAssociation.itemCreated && !eventAssociation.itemDeleted) {
      return this.advertisingApi.addAdvertisementEventAssociation(advertisementId, eventAssociation.eventId);
    } else if (eventAssociation.itemDeleted) {
      return this.advertisingApi.deleteAdvertisementEventAssociation(advertisementId, eventAssociation.id).pipe(map(() => null));
    }
    return of(null);
}

  saveAdvertisementTeamAssociation(advertisementId: string, teamAssociation: TeamId): Observable<TeamId> {
    if (teamAssociation.itemCreated && !teamAssociation.itemDeleted) {
      return this.advertisingApi.addAdvertisementTeamAssociation(advertisementId, teamAssociation.teamId);
    } else if (teamAssociation.itemDeleted) {
      return this.advertisingApi.deleteAdvertisementTeamAssociation(advertisementId, teamAssociation.id).pipe(map(() => null));
    }
    return of(null);
  }
}
