import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {FormListable} from '../protocols/form-listable';
import {DateUtils} from '../../utils/date-utils';

export class AdvertisementRunDate implements Deserializable, FormListable {
  id: string;
  startDateUtc: Date = null; // format: 2022-02-22T00:00:00
  endDateUtc: Date = null;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  onDeserialize() {
    this.startDateUtc = DeserializeHelper.deserializeToDate(this.startDateUtc);
    this.endDateUtc = DeserializeHelper.deserializeToDate(this.endDateUtc);
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return null;
  }

  getListItemTitle(): string {
    return `${DateUtils.formatDateToReadableStringUTC(this.startDateUtc)} - ${DateUtils.formatDateToReadableStringUTC(this.endDateUtc)}`;
  }
}
