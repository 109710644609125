<app-content-container [narrow]="true">
  <div class="py-4 h-100 position-relative min-height-20">
    <app-form-group
      *ngIf="formItems?.length > 0 && !!formObject"
      [hidden]="viewModel.loadingOpts?.isLoading"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="PermissionManagerService.isGranted(Types.AdminSections.Leagues_Edit_League_Details_Edit) ? formSubmitted($event) : null">
      <div class="d-block w-100">
        <div class="league-photo-container" (click)="showEditPhotoModal()">
          <div class="league-photo mr-3">
            <img [src]="formObject?.imageToUpload" alt=""
                 *ngIf="formObject.imageToUpload">
            <img [src]="(formObject?.league?.imgSrc$|async) || ''" alt=""
                 *ngIf="formObject.existingImageId && !formObject.imageToUpload && !formObject.deleteImageId">
          </div>
          <div class="photo-text">
            <ng-container
              *ngIf="!(formObject.imageToUpload || formObject.existingImageId)"
              i18n>Add a League Logo
            </ng-container>
            <ng-container
              *ngIf="formObject.imageToUpload || formObject.existingImageId"
              i18n>Change League Logo
            </ng-container>
          </div>
        </div>
      </div>
    </app-form-group>
    <app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
                 [options]="viewModel.loadingOpts">
    </app-loading>
  </div>
</app-content-container>
