import {Component, EventEmitter, Input, ViewChild} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AuthModalViewModel} from '../auth-modal-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {ResetPasswordRequest} from '../../../../../models/account/requests/reset-password-request';
import {PasswordValidatorDirective} from '../../../components/form-group/validators/password-validator.directive';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {ChangePasswordFormObject} from '../../../../../models/account/requests/change-password-form-object';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {FormGroupComponent} from '../../../components/form-group/form-group.component';

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.scss']
})
export class NewPasswordFormComponent extends BaseComponent {

  @Input() primaryButtonClicked: EventEmitter<void>;
  @Input() secondaryButtonClicked: EventEmitter<void>;
  @ViewChild('newPasswordForm') newPasswordForm: FormGroupComponent;

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ChangePasswordFormObject = new ChangePasswordFormObject();

  constructor(
    public viewModel: AuthModalViewModel,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.errorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

    this.primaryButtonClicked.subscribe(() => {
      this.newPasswordForm.submitForm();
    }).addTo(this.subscriptions);

    this.secondaryButtonClicked.subscribe(() => {
      this.formCancelled();
    }).addTo(this.subscriptions);

    this.setupFormBindings();
  }

  setupViews() {
    this.viewModel.clearErrorMessage();
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Enter your email`;
    email.bindingProperty = 'email';
    email.required = true;
    items.push(email);

    const code = new FormInputItem();
    code.inputName = 'code';
    code.inputType = FormInputType.Text;
    code.label = $localize`Reset Code`;
    code.placeholder = $localize`Enter code from your email`;
    code.bindingProperty = 'code';
    code.required = true;
    items.push(code);

    const password = new FormInputItem();
    password.inputName = 'password';
    password.inputType = FormInputType.Password;
    password.customValidator = new PasswordValidatorDirective();
    password.label = $localize`New Password`;
    password.placeholder = $localize`Enter your new password`;
    password.bindingProperty = 'newPassword';
    password.required = true;
    items.push(password);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formCancelled() {
    this.viewModel.authFlow = AuthFlow.ForgotPassword;
  }

  formSubmitted() {
    const changePasswordReq = new ResetPasswordRequest();
    changePasswordReq.newPassword = this.formObject.newPassword;
    changePasswordReq.code = this.formObject.code;
    this.viewModel.resetForgottenPassword(this.formObject.email, changePasswordReq);
  }

  setupFormBindings() {
    this.activatedRoute.queryParams.subscribe(params => {
      const email = params.email;
      const emailInput = this.formItems.find(i => i.inputName === 'email');
      if (!!email) {
        setTimeout(() => {
          emailInput.setInputFormControlValue(email);
          emailInput.markAsTouched();
        });
      }
      const code = params.code;
      const codeInput = this.formItems.find(i => i.inputName === 'code');
      if (!!code) {
        setTimeout(() => {
          codeInput.setInputFormControlValue(code);
          codeInput.markAsTouched();
        });
      }
    }).addTo(this.subscriptions);
  }
}
