import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import { map } from 'rxjs/operators';


@Injectable()
export class FeaturedLeaguesRowViewModel extends BaseViewModel {

  leagues$ = this.resourceDomainModel.activeHydratedLeagues$.pipe(
    map(leagues => leagues.filter(league => !league.privateAccess))
  );
  constructor(private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
