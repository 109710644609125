<div class="d-flex mt-3 h-100 min-height-15">
  <app-loading
    [hidden]="!viewModel.programLoadingOpts?.isLoading"
    [options]="viewModel.programLoadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="dataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="PermissionManagerService.isGranted(Types.AdminSections.Venues_Edit_Venue_Programs_Edit) ? programClicked($event) : null"
    (secondaryButtonClicked)="PermissionManagerService.isGranted(Types.AdminSections.Venues_Edit_Venue_Programs_Program_Uploader) ? programUploaderClicked() : null"
    (ctaButtonClicked)="PermissionManagerService.isGranted(Types.AdminSections.Venues_Edit_Venue_Programs_Add) ? addNewProgramClicked() : null"
    [tableOptions]="datatableOptions">
  </app-data-table>
  <app-program-feedback
    [hidden]="!viewModel.selectedFeedbackProgram"
    (closeButtonClicked)="viewModel.selectedFeedbackProgram = null"
    [program]="viewModel.selectedFeedbackProgram">
  </app-program-feedback>
</div>
