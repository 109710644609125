<div *ngIf="viewModel.reLoginErrorMessage|async"
     class="form-input-container d-block w-100">
  <div class="custom-alert mb-4 custom-alert-danger alert-container">
    <div class=" custom-alert-danger">
      <img class="custom-alert-icon"
           [src]="'assets/icons/light/solid/x-circle.svg'" alt="">
        {{viewModel.reLoginErrorMessage|async}}
      <span style="margin-left: 43px">
        Continuing will sign out of any previous sessions.
      </span>
    </div>
    <div class="prompt-button-container">
      <button class=" custom-button neutral-button red-text"
              (click)="viewModel.cancelClicked()">
        Cancel
      </button>
      <button class=" custom-button neutral-button ml-3"
              (click)="viewModel.logOutOtherSession()">
        Continue
      </button>
    </div>
  </div>
</div>
<div [hidden]="viewModel.reLoginErrorMessage|async">
  <app-form-group
    #signInForm
    class="d-block pt-4"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formSubmitted)="formSubmitted($event)"
    (checkboxRowClicked)="forgotPasswordClicked()">
  </app-form-group>
</div>
