/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {User} from '../models/account/dto/user';
import {ChangePasswordRequest} from '../models/account/requests/change-password-request';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Role} from '../models/account/dto/role';
import {Image} from '../models/image/dto/image';
import {SignUpRequest} from '../models/account/requests/sign-up-request';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {SignInPortal} from '../models/account/requests/sign-in-request';
import {CreateAdminRequest} from '../models/account/requests/create-admin-request';
import {Program} from '../models/program/program';
import {ResetPasswordFormObject} from '../models/account/requests/reset-password-form-object';
import {
  SubscriberSubscriptionRequest,
  SubscriptionCouponRequest,
  SubscriptionPlan
} from '../models/account/dto/subscription-plan';
import {SubscriberSubscription} from '../models/account/dto/subscriber-subscription';
import {CreateSubscriberPaymentDetails, SubscriberPaymentDetails} from '../models/account/dto/subscriber-payment-details';
import {HydratedSubscriber} from '../models/account/dto/hydrated-subscriber';
import {Coupon} from '../models/account/dto/coupon';
import {SubscriberInvoice} from '../models/account/dto/subscriber-invoice';
import {AdvertisingPartner} from '../models/resources/advertising-partner';
import {Advertisement} from '../models/resources/advertisement';
import {VideoUpload} from '../models/image/dto/video-upload';
import {HydratedAdvertisement} from '../models/resources/hydrated-advertisement';
import {VenueId} from '../models/resources/venue-id';
import {TeamId} from '../models/resources/teamId';
import {LeagueId} from '../models/resources/leagueId';
import {AdvertisementRunDate} from '../models/resources/advertisement-run-date';
import {EventId} from '../models/resources/eventId';

@Injectable({
  providedIn: 'root',
})
export class AdvertisingApi implements LoggableAPI {

  public serviceName = 'Advertising';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public getAdvertisingPartners(): Observable<AdvertisingPartner[]> {
    const url = Endpoints.getAdvertisingPartners();
    return this.apiClient.getArr(AdvertisingPartner, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdvertisingPartners', err));
        return throwError(err);
      })
    );
  }

  getAdvertisingPartner(partnerId: number): Observable<AdvertisingPartner> {
    return this.apiClient.getObj(AdvertisingPartner, Endpoints.getAdvertisingPartner(partnerId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdvertisingPartner', err));
        return throwError(err);
      })
    );
  }

  public createAdvertisingPartner(req: AdvertisingPartner): Observable<AdvertisingPartner> {
    const url = Endpoints.createAdvertisingPartner();
    return this.apiClient.postObj(AdvertisingPartner, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createAdvertisingPartner', err));
        return throwError(err);
      })
    );
  }

  public updateAdvertisingPartner(req: AdvertisingPartner): Observable<AdvertisingPartner> {
    const url = Endpoints.updateAdvertisingPartner(req.id);
    return this.apiClient.putObj(AdvertisingPartner, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateAdvertisingPartner', err));
        return throwError(err);
      })
    );
  }

  public getAdvertisingPartnerAdvertisements(partnerId: number): Observable<HydratedAdvertisement[]> {
    const url = Endpoints.getAdvertisingPartnerAdvertisements(partnerId);
    return this.apiClient.getArr(HydratedAdvertisement, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdvertisingPartnerAdvertisements', err));
        return throwError(err);
      })
    );
  }

  getAdvertisingPartnerAdvertisement(partnerId: number, adId: string): Observable<HydratedAdvertisement> {
    return this.apiClient.getObj(HydratedAdvertisement, Endpoints.getAdvertisingPartnerAdvertisement(partnerId, adId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdvertisingPartnerAdvertisement', err));
        return throwError(err);
      })
    );
  }

  public createAdvertisingPartnerAdvertisement(partnerId: number, req: Advertisement): Observable<Advertisement> {
    const url = Endpoints.createAdvertisingPartnerAdvertisement(partnerId);
    return this.apiClient.postObj(Advertisement, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createAdvertisingPartnerAdvertisement', err));
        return throwError(err);
      })
    );
  }

  public updateAdvertisingPartnerAdvertisement(partnerId: number, req: Advertisement): Observable<Advertisement> {
    const url = Endpoints.updateAdvertisingPartnerAdvertisement(partnerId, req.id);
    return this.apiClient.putObj(Advertisement, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateAdvertisingPartner', err));
        return throwError(err);
      })
    );
  }

  public getAdvertisementUploads(adId: string): Observable<VideoUpload[]> {
    const url = Endpoints.getAdvertisementUploads(adId);
    return this.apiClient.getArr(VideoUpload, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdvertisementUploads', err));
        return throwError(err);
      })
    );
  }

  public uploadAdvertisementVideo(adId: string, video: File): Observable<VideoUpload> {
    const formData = new FormData();
    formData.append('InputFile', video);
    const url = Endpoints.createAdvertisementUpload(adId);
    return this.apiClient.postFormData(VideoUpload, url, formData).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'uploadAdvertisementVideo', err));
        return throwError(err);
      })
    );
  }

  public deleteAdvertisementUpload(adId: string, uploadId: string): Observable<string> {
    const url = Endpoints.deleteAdvertisementUpload(adId, uploadId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteAdvertisementUpload', err));
        return throwError(err);
      })
    );
  }

  addAdvertisementVenueAssociation(adId: string, venueId: number): Observable<VenueId> {
    const venueIdBody = new VenueId();
    venueIdBody.venueId = venueId;
    return this.apiClient.postObj<VenueId>(VenueId, Endpoints.createAdvertisementVenueAssociation(adId), venueIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addAdvertisementVenueAssociation', err));
        return throwError(err);
      })
    );
  }

  addAdvertisementTeamAssociation(adId: string, teamId: number): Observable<TeamId> {
    const teamIdBody = new TeamId();
    teamIdBody.teamId = teamId;
    return this.apiClient.postObj<TeamId>(TeamId, Endpoints.createAdvertisementTeamAssociation(adId), teamIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addAdvertisementTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  addAdvertisementLeagueAssociation(adId: string, leagueId: number): Observable<LeagueId> {
    const leagueIdBody = new LeagueId();
    leagueIdBody.leagueId = leagueId;
    return this.apiClient.postObj<LeagueId>(LeagueId, Endpoints.createAdvertisementLeagueAssociation(adId), leagueIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addAdvertisementLeagueAssociation', err));
        return throwError(err);
      })
    );
  }

  addAdvertisementEventAssociation(adId: string, eventId: number): Observable<EventId> {
    const eventIdBody = new EventId();
    eventIdBody.eventId = eventId;
    return this.apiClient.postObj<EventId>(EventId, Endpoints.createAdvertisementEventAssociation(adId), eventIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addAdvertisementEventAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteAdvertisementVenueAssociation(adId: string, associationId: string): Observable<string> {
    const url = Endpoints.deleteAdvertisementVenueAssociation(adId, associationId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteAdvertisementVenueAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteAdvertisementTeamAssociation(adId: string, associationId: string): Observable<string> {
    const url = Endpoints.deleteAdvertisementTeamAssociation(adId, associationId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteAdvertisementTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteAdvertisementLeagueAssociation(adId: string, associationId: string): Observable<string> {
    const url = Endpoints.deleteAdvertisementLeagueAssociation(adId, associationId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteAdvertisementLeagueAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteAdvertisementEventAssociation(adId: string, associationId: string): Observable<string> {
    const url = Endpoints.deleteAdvertisementEventAssociation(adId, associationId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteAdvertisementEventAssociation', err));
        return throwError(err);
      })
    );
  }

  createAdvertisementRunDate(adId: string, runDate: AdvertisementRunDate): Observable<LeagueId> {
    return this.apiClient.postObj<LeagueId>(LeagueId, Endpoints.createAdvertisementRunDate(adId), runDate).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addAdvertisementRunDate', err));
        return throwError(err);
      })
    );
  }

  deleteAdvertisementRunDate(adId: string, runDateId: string): Observable<string> {
    const url = Endpoints.deleteAdvertisementRunDate(adId, runDateId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteAdvertisementRunDate', err));
        return throwError(err);
      })
    );
  }
}
