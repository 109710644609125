import {CardStyle} from './card-style.enum';

export class Card {
  public id: string;
  public label: string;
  public text: string;
  public subtext: string;
  public imageSrc: string;
  public filterValue: any[];
  public cardStyle: CardStyle = CardStyle.Default;
  public data: any;
  public customClass: string;
  public selected: boolean = false;

  constructor(label, text: string) {
    this.label = label;
    this.text = text;
  }

  getBackgroundUrl(): string {
    if (this.imageSrc) {
      return `url(${this.imageSrc})`;
    } else {
      return 'url(assets/icons/light/outline/photograph-grey.svg)';
    }
  }
}
