import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FavouriteResource} from '../../../../../models/protocols/favourite-resource';

@Component({
  selector: 'app-favourite-picker-row',
  templateUrl: './favourite-picker-row.component.html',
  styleUrls: ['./favourite-picker-row.component.scss'],
})
export class FavouritePickerRowComponent extends BaseComponent {

  @Input() favourite: FavouriteResource;
  @Input() selected: boolean;

  constructor(
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }
}
