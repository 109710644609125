import {FavouriteTeams} from './favourite-teams';
import {FavouriteLeagues} from './favourite-leagues';
import {FavouriteResource} from '../protocols/favourite-resource';

export class Favourites {
  favouriteTeams: FavouriteTeams;
  favouriteLeagues: FavouriteLeagues;

  constructor(favouriteTeams: FavouriteTeams = null, favouriteLeagues: FavouriteLeagues = null) {
    this.favouriteTeams = favouriteTeams ?? new FavouriteTeams();
    this.favouriteLeagues = favouriteLeagues ??  new FavouriteLeagues();
  }

  toggleFavouriteResource(f: FavouriteResource) {
    if (f.getFavouriteResourceType() === 'league') {
      this.favouriteLeagues.toggleId(f.getFavouriteResourceId());
    } else if (f.getFavouriteResourceType() === 'team') {
      this.favouriteTeams.toggleId(f.getFavouriteResourceId());
    }
  }
}
