import {FormValidator} from '../../protocols/form-validator';
import {FormGroup, Validators} from '@angular/forms';
import {Validatable} from '../../protocols/validatable';
import {Selectable} from '../../protocols/selectable';
import {Searchable} from '../../protocols/searchable';
import {EventEmitter, TemplateRef} from '@angular/core';
import {Checkbox} from './checkbox';
import {CheckboxContainerOptions} from './checkbox-container-options';
import {AutoCapitalize} from '../../enum/shared/input-options.enum';
import {FormListable} from '../../protocols/form-listable';
import * as IMask from 'imask';
import {Radiobutton} from './radiobutton';
import {RadiobuttonContainerOptions} from './radiobutton-container-options';

export enum FormInputType {
  Date = 'date',
  Email = 'email',
  Color = 'color',
  NumberInput = 'number',
  Password = 'password',
  ConfirmPassword = 'password',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
  AddressAutoComplete = 'addressAutoComplete',
}

export enum FormNumberInputType {
  Integer = 'integer',
  Decimal = 'decimal',
}

export enum FormItemType {
  Input = 'input',
  Dropdown = 'dropdown',
  Textarea = 'textarea',
  CheckboxItem = 'checkbox',
  Button = 'button',
  Switch = 'switch',
  Divider = 'divider',
  Hidden = 'hidden',
  Title = 'title',
  CheckboxGroup = 'checkboxgroup',
  RadioGroup = 'radiogroup',
  List = 'list',
  AlertBanner = 'alertBanner',
  ProjectedContent = 'ProjectedContent',
}

export class FormInputItem implements Validatable {
  // Properties
  public itemType: FormItemType = FormItemType.Input;
  public inputName: string;
  public label: string;
  public hint: string;
  public hidden: boolean = false;
  public hideErrorMessage: boolean = false;
  public placeholder: string;
  public editable: boolean = true;
  public bindingProperty: string;
  public chipInput: boolean = false;
  public chipInputValues: string[] = [];
  public inputType: FormInputType;
  public numberInputType: FormNumberInputType;
  public mustMatchInputName: string;
  public mustMatchInputLabel: string;
  public inputMask: any = null;
  public dropdownOptions: Selectable[] = [];
  public dropdownIsObject: boolean = false;
  public tooltipText: string;
  public valueChanged: EventEmitter<any> = new EventEmitter<any>();
  public keyUpValueChanged: EventEmitter<any> = new EventEmitter<any>();
  public enabled: boolean = true;
  public customValueParser: (s: any) => any;
  public boundInputs: FormInputItem[];
  public customClass: string;
  public inputStep: string = '1';
  public hideInput: boolean = false;
  // Grouped Checkbox
  public groupCheckboxes: Checkbox[] = [];
  public groupCheckboxesContainerClass: string = '';
  public groupCheckboxOptions: CheckboxContainerOptions = new CheckboxContainerOptions();
  public groupCheckboxesChanged: (checkboxes: Checkbox[]) => void;
  // Grouped Radio
  public groupRadios: Radiobutton[] = [];
  public groupRadiosContainerClass: string = '';
  public groupRadioOptions: RadiobuttonContainerOptions = new RadiobuttonContainerOptions(false);
  public groupRadiosChanged: (radios: Radiobutton[]) => void;
  // Title Button
  public titleButtonText: string;
  public titleButtonClass: string;
  public titleButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  // Input Button
  public inputHasButton: boolean = false;
  public inputButtonText: string;
  public inputButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  public buttonDisabled: boolean = true;
  // Layout Properties
  public showRequiredAsterisk: boolean = false;
  public inlineLabel: boolean = false;
  public inlineLabelWidthPercent: number = 50;
  public hideLabel: boolean = false;
  public overrideFullWidth: boolean = false;
  public autoCapitalize: AutoCapitalize = AutoCapitalize.On;
  public autoComplete: boolean = false;
  public forceDisableAutoFill: boolean = false;
  // Validation Properties
  public required: boolean = false;
  public minLength: number;
  public maxLength: number;
  public minValue: number = -1;
  public maxValue: number = -1;
  public customValidator: FormValidator;
  // Custom error messages
  public customError: Map<string, string>; // Options: required, minlength, maxlength, and custom FormValidator.errorName()
  // Form Delegate
  public formDelegate: FormGroup;
  // Searchable
  public searchable: Searchable[] = [];
  public useInputValueIfNoSearchableSelected: boolean = false;
  public searchableItemSelected: EventEmitter<Searchable> = new EventEmitter<Searchable>();

  // Text Area
  public autoGrowTextArea: boolean = false;
  public autoGrowMinRows: number = 2.5;
  public autoGrowMaxRows: number = 4;
  // List
  listItemButtonText: string;
  listItemButtonClass: string;
  public listItemClicked: EventEmitter<FormListable> = new EventEmitter<FormListable>();
  // Alert Banner
  alertBannerId: string;
  alertBannerMessage: string;
  alertBannerStyle: '' | 'success' | 'error';

  dividerId: string;

  static generateCurrencyMask(allowNegative: boolean = false): IMask.Masked<any> {
    return IMask.createMask({
      mask: '$num',
      blocks: {
        num: {
          mask: IMask.MaskedNumber,
          thousandsSeparator: ',', // any single char
          radix: '.', // fractional delimiter
          padFractionalZeros: true,  // if true, then pads zeros at end to the length of scale
          normalizeZeros: true,  // appends or removes zeros at ends
          scale: 2,  // digits after point, 0 for integers
          signed: allowNegative,  // disallow negative
        }
      }
    });
  }

  static generatePhoneNumberMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '{1} (000) 000-0000',
    });
  }

  static generateCreditCardNumberMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '0000 0000 0000 0000',
    });
  }


  static generateCreditCardLastFourMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '{*}{*}{*}{*} {*}{*}{*}{*} {*}{*}{*}{*} 0000',
    });
  }

  static generateEditCreditCardMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '**** **** **** 0000',
    });
  }

  static generateCreditCardExpiryMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '00{/}00',
    });
  }

  static generateCreditCardSecurityCodeMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '000',
    });
  }

  static generatePercentageMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: [
        {
          mask: '',
        },
        {
          mask: 'num%',
          lazy: false,
          placeholderChar: '^',
          blocks: {
            num: {
              mask: Number,
              scale: 2,
              min: 0,
              max: 100,
              radix: '.',
              mapToRadix: [','],
            },
          },
        }
      ]
    });
  }

  static generateDivider(id?: string): FormInputItem {
    const divider = new FormInputItem();
    divider.itemType = FormItemType.Divider;
    if (id) {
      divider.dividerId = id;
    }
    return divider;
  }

  static getItemValue(items: FormInputItem[], inputName: string): any {
    let val: any = null;
    items.forEach((i) => {
      if (i.inputName === inputName) {
        val = i.getValue();
      }
    });
    return val;
  }

  // static fromCheckbox(checkbox: Checkbox) {
  //   const item = new FormInputItem();
  //   item.inputName = checkbox.id.toString();
  //   item.label = checkbox.label;
  //   item.placeholder = checkbox.description;
  //   item.itemType = FormItemType.CheckboxItem;
  //   item.bindingProperty = checkbox.id.toString();
  //   item.required = false;
  //
  //   return item;
  // }

  getCustomErrorMessage(key: string): string {
    let customErr;
    if (this.customError) {
      this.customError.forEach((val, k) => {
        if (key === k) {
          customErr = val;
        }
      });
    }
    return customErr ? customErr : null;
  }

  clearValue() {
    if (!this.hasFormEditableValue()) {
      return;
    }
    const formControl = this.formDelegate?.get(this.inputName);
    if (formControl) {
      return formControl.reset(this.dropdownIsObject ? null : '');
    }
    return;
  }

  selectFirstDropdown() {
    if (this.itemType === FormItemType.Dropdown) {
      this.getInputFormControl().setValue(this.dropdownOptions[0]?.getSelectionValue());
      this.getInputFormControl().markAsTouched();
    }
  }

  selectDropDown(s: Selectable) {
    if (this.itemType === FormItemType.Dropdown) {
      const formControl = this.getInputFormControl();
      if (s == null) {
        formControl.setValue(null);
      } else if (formControl.value !== s.getSelectionValue()) {
        formControl.setValue(s.getSelectionValue());
      }
      formControl.markAsTouched();
    }
  }

  getValue(): any {
    if (!this.hasFormEditableValue()) {
      return null;
    }

    if (this.formDelegate?.get(this.inputName)) {
      const val = this.formDelegate?.get(this.inputName).value;
      if (this.itemType === FormItemType.Dropdown) {
        return this.dropdownOptions?.find(o => o.getSelectionValue() === val)?.getSelectionValue() || (this.dropdownIsObject ? null : '');
      } else if (this.inputType === FormInputType.Search) {
        return this.searchable.find(i =>
          i.lookupKey === val
        )?.value;
      }
      return val;
    }
    return null;
  }

  handleKeyUpValueChanged() {
    // Handle changes to input if necessary
    if (this.formDelegate?.get(this.inputName)) {
      const val = this.formDelegate?.get(this.inputName).value;
      this.keyUpValueChanged.emit([val, this.boundInputs]);
    }
  }

  handleValueChanged() {
    // Handle changes to input if necessary
    this.valueChanged.emit([this.getValue(), this.boundInputs]);
  }

  handleRadiobuttonChanged() {
    this.valueChanged.emit([this.groupRadios.find(r => r.selected), this.boundInputs]);
  }

  handleSearchableItemSelected(item: Searchable) {
    this.getInputFormControl().setValue(item.lookupKey);
    this.handleValueChanged();
    this.searchableItemSelected.emit(item);
  }

  // Validatable Methods
  hasFormEditableValue(): boolean {
    return this.itemType !== FormItemType.Divider &&
      this.itemType !== FormItemType.Title &&
      this.itemType !== FormItemType.List &&
      this.itemType !== FormItemType.AlertBanner &&
      this.itemType !== FormItemType.ProjectedContent;
      // && this.itemType !== FormItemType.Hidden;
  }

  hasErrors(): boolean {
    if (!this.enabled || !this.hasFormEditableValue() || !this.formDelegate) {
      return false;
    } else if (this.itemType === FormItemType.CheckboxGroup) {
      if (this.groupCheckboxOptions.requireMinimumSelection > 0) {
        return this.groupCheckboxes?.map(ch => ch.checked).filter(ch => ch).length < this.groupCheckboxOptions.requireMinimumSelection;
      } else {
        return false;
      }
    } else if (this.getInputFormControl().touched) {
      // check for error on touched field
      if (this.mustMatchInputName) {
        if (this.getInputFormControl().value !== '' &&
          this.getInputFormControl().value !== this.formDelegate.get(this.mustMatchInputName).value) {
          return true;
        }
      }
      return (this.getInputFormControl().invalid && this.getInputFormControl().errors !== null);
    } else {
      return false;
    }
  }

  private getInputFormControl() {
    return this.formDelegate.get(this.inputName);
  }

  public setInputFormControlValue(value: any) {
    return this.formDelegate.get(this.inputName).setValue(value);
  }

  public markAsTouched() {
    return this.formDelegate.get(this.inputName).markAsTouched();
  }

  public setRequired(isRequired: boolean) {
    const f = this.getInputFormControl();
    this.required = isRequired;
    if (isRequired) {
      f.addValidators(Validators.required);
    } else {
      f.removeValidators(Validators.required);
    }
    f.updateValueAndValidity();
  }

  getErrorMessage(): string {
    if (!this.enabled || !this.formDelegate || !this.hasFormEditableValue()) {
      // dont validate disabled fields
      return '';
    } else if (this.itemType === FormItemType.CheckboxGroup) {
      if (this.groupCheckboxOptions.requireMinimumSelection > 0 &&
        this.groupCheckboxOptions.touched &&
        this.groupCheckboxes.map(ch => ch.checked).filter(ch => ch).length < this.groupCheckboxOptions.requireMinimumSelection) {
        return `At least ${this.groupCheckboxOptions.requireMinimumSelection} option must be selected.`;
      } else {
        return '';
      }
    } else {
      const errors = this.getInputFormControl().errors;
      if (errors === null) {
        if (this.mustMatchInputName &&
          this.getInputFormControl().value !== this.formDelegate.get(this.mustMatchInputName).value) {
          return `Does not match ${this.mustMatchInputLabel}`;
        } else {
          return null;
        }
      } else {
        let returnError: string = '';
        Object.keys(errors).forEach(keyError => {
          if (keyError === 'required') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} is required.`;
          } else if (keyError === 'minlength') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} must be more than ${this.minLength} characters.`;
          } else if (keyError === 'maxlength') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} must be at less than ${this.maxLength} characters.`;
          } else if (keyError === 'min') {
            // If min value is 0.01 then show error message as 0 since it is inclusive
            returnError = this.getCustomErrorMessage(keyError) ||
            (this.minValue === 0.01) ?
              `${this.label} must be greater than 0.` :
              `${this.label} must be greater than or equal to ${this.minValue}.`;
          } else if (keyError === 'max') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} must be less than ${this.maxValue}.`;
          } else if (keyError === 'email') {
            returnError = this.getCustomErrorMessage(keyError) || `Must be a valid email address.`;
          } else if (keyError === 'searchFor') {
            returnError = this.getCustomErrorMessage(keyError) || `Search list must contain selection.`;
          } else if (keyError === 'phoneNumber') {
            returnError = this.getCustomErrorMessage(keyError) || `Invalid phone number.`;
          } else if (keyError === this.customValidator?.errorName()) {
            returnError = this.getCustomErrorMessage(keyError) || errors[keyError];
          }
        });
        return returnError;
      }
    }
  }

  canSubmit(): boolean {
    if (!this.formDelegate) {
      return false;
    }
    if (!this.hasFormEditableValue()) {
      return true;
    }
    if (this.required) {
      // can submit if has no errors and the items has either been touched/dirty or is disabled
      return !this.hasErrors() && (this.getInputFormControl().touched ||
        this.getInputFormControl().dirty || !this.enabled);
    } else {
      return !this.hasErrors();
    }
  }

  filteredSearchableItems(): Searchable[] {
    const val = (this.formDelegate?.get(this.inputName).value ?? '') as string;
    if (val.length === 0) {
      return [];
    }
    return this.searchable?.filter(s => {
      const searchComponents: string[] = [s.lookupKey];
      if (s.title?.length > 0) {
        searchComponents.push(s.title);
      }
      if (s.subtitle?.length > 0) {
        searchComponents.push(s.subtitle);
      }
      const searchString = searchComponents?.join('\x00')?.toLowerCase() ?? '';
      return s.alwaysShow === true || searchString.toLowerCase().includes(val.toLowerCase());
    });
  }

  setFormEnabledStatus(enabled: boolean) {
    this.enabled = enabled;
    const i = this.formDelegate?.get(this.inputName);
    if (i) {
      if (enabled) {
        i.enable();
      } else {
        i.disable();
      }
    }
  }

}
