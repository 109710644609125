import {Component, ViewEncapsulation} from '@angular/core';
import {EventLandingViewModel} from './event-landing-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ProgramRowType} from '../program-row/program-row.component';
import {NoContentType} from '../no-content/no-content.component';
import {Cardable} from '../../../../models/protocols/cardable';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-event-landing',
  templateUrl: './event-landing.component.html',
  styleUrls: ['./event-landing.component.scss'],
  providers: [EventLandingViewModel],
  encapsulation: ViewEncapsulation.None
})
export class EventLandingComponent extends BaseComponent {

  noContentType = NoContentType;
  programRowType = ProgramRowType;

  constructor(
    public viewModel: EventLandingViewModel,
    private router: Router
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  tabSelected(index: number): void {
    this.viewModel.selectedTabIndex(index);
  }

  programCardClicked(card: Cardable) {
    if (card instanceof Program) {
      this.viewModel.isShow$.next(false);
      this.viewModel.programId$.next(card.id);
    } else if (card instanceof Show) {
      this.viewModel.isShow$.next(true);
      this.viewModel.programId$.next(card.id);
    }
    this.scrollToVideoPlayer();
  }

  seeAllPastGames = () => {
    this.viewModel.selectedEventId$.pipe(take(1)).subscribe(eventId => {
      this.router.navigate([`/see-all`], {queryParams: eventId ? {eventId} : null}).then();
    });
  };
}
