<div [ngStyle]="{'top': options.topMarginRem + 'rem',
'border-radius' : options.cornerRadius,
'background-color' : options.backgroundColor,
'z-index': options.zIndex}"
     [class.fullscreen-indeterminate-spinner]="options.fullscreen && !options.determinateLoading"
     class="loading-container">

  <div *ngIf="!options.determinateLoading">
    <ul>
      <li id="a" [style.background-color]="options.spinnerColor"></li>
      <li id="b" [style.background-color]="options.spinnerColor"></li>
      <li id="c" [style.background-color]="options.spinnerColor"></li>
      <li id="d" [style.background-color]="options.spinnerColor"></li>
      <li id="e" [style.background-color]="options.spinnerColor"></li>
      <li id="f" [style.background-color]="options.spinnerColor"></li>
      <li id="g" [style.background-color]="options.spinnerColor"></li>
      <li id="h" [style.background-color]="options.spinnerColor"></li>
      <li id="i" [style.background-color]="options.spinnerColor"></li>
    </ul>
      <p *ngIf="options.showLoadingText && options.loadingText && options.loadingText !== ''"
         [style]="{ 'color':options.color }"
         class="loading-text">
        {{ options.loadingText }}
      </p>
  </div>

  <div *ngIf="options.determinateLoading"
       [ngClass]="{'fullscreen':options.fullscreen}"
       [ngStyle]="{'background-color':options.backgroundColor, 'color':options.color}"
       class="determinate-spinner">
    <div class="progress-bar-container">
      <app-progress-bar
        [barColor]="options.spinnerColor"
        [progress]="options.progress">
      </app-progress-bar>
      <span *ngIf="options.showLoadingText && options.loadingText && options.loadingText !== ''"
            class="determinate-spinner-loading-text">
                {{ options.loadingText }}
            </span>
    </div>
  </div>

</div>
