import { BaseModel } from './../base/base-model';

export class LeagueStream extends BaseModel{
  id: number;
  leagueId: number;
  mp4ClientEmail1: string;
  mp4ClientEmail2: string;
  mp4ClientEmail3: string;
  isMp4Required: boolean = false;

  static initWithStream(leagueStream: LeagueStream): LeagueStream {
    const formObject = new LeagueStream();
    formObject.id=leagueStream.id;
    formObject.leagueId=leagueStream.leagueId;
    formObject.mp4ClientEmail1=leagueStream.mp4ClientEmail1;
    formObject.mp4ClientEmail2=leagueStream.mp4ClientEmail2;
    formObject.mp4ClientEmail3=leagueStream.mp4ClientEmail3;
    formObject.isMp4Required=leagueStream.isMp4Required;
    return formObject;
  }

  onDeserialize(){
    super.onDeserialize();
  }


}
