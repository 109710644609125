import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {PixellotVenues} from '../models/resources/pixellot-venues';
import {Endpoints} from './endpoints';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {ApiClient} from './api-client';
import {catchError} from 'rxjs/operators';
import {LoggingService} from '../services/logging-service';
import {LoggableAPI} from '../models/protocols/loggable-api';

@Injectable({
  providedIn: 'root',
})
export class PixellotVenuesApi implements LoggableAPI {
  // Variables
  public serviceName = 'PixellotVenues';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public getPixellotVenues(): Observable<PixellotVenues[]> {
    return this.apiClient.getArr(PixellotVenues, Endpoints.getPixellotVenues()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getVenues', err));
        return throwError(err);
      })
    );
  }
}
