<app-detail-title
  [hrCustomClass]=" (viewModel.addNewSystem$|async) ? '' : 'd-none'"
  i18n>
  Camera Systems
</app-detail-title>
<app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
             [options]="viewModel.loadingOpts">
</app-loading>
<app-content-container [narrow]="true">
  <div *ngIf="formItems?.length > 0 && !!formObject" class="py-4 h-100 position-relative min-height-20">
    <app-detail-back-button [backRoute]="'..'" class="d-block mt-4">
      <ng-container *ngIf="viewModel.addNewSystem$|async" i18n>Add a New Camera System</ng-container>
      <ng-container *ngIf="!(viewModel.addNewSystem$|async)" i18n>{{(viewModel.system$|async)?.name}}</ng-container>
    </app-detail-back-button>
    <app-form-group
      class="d-block pt-3"
      [hidden]="viewModel.loadingOpts?.isLoading"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      [hydrateInputObject]="hydrateInputObject"
      (formCancelled)="cancel()"
      (formSubmitted)="PermissionManagerService.isGranted(Types.AdminSections.Camera_Systems_Edit_Camera_System_Edit) ? formSubmitted($event) : null">
      <div class="d-block w-100" *ngIf="viewUploader">
        <div class="label-md mb-4" i18n>Config File</div>
        <div class="banner-image-container pb-4 d-flex justify-content-between">
          <div class="text-link-button"  i18n>{{systemVersion}}</div>
          <!-- <div *ngIf="!openUploader" class="text-link-button" (click)="uploaderClicked()">Upload File</div> -->
        </div>
      </div>
      <div class="d-block w-100">
        <div class="label-md mb-4" i18n>Current Firmware</div>
        <div class="banner-image-container pb-4 d-flex justify-content-between">
          <div class="text-link-button"  i18n>{{CurrentFirmware}}</div>
          <!-- <div *ngIf="!openUploader" class="text-link-button" (click)="uploaderClicked()">Upload File</div> -->
        </div>
      </div>

    </app-form-group>
  </div>
</app-content-container>
