import {Component, EventEmitter, Output} from '@angular/core';
import {HomeTabBarViewModel} from './home-tab-bar-view-model';
import {BaseComponent} from '../../../../models/base/base-component';

@Component({
  selector: 'app-home-tab-bar',
  templateUrl: './home-tab-bar.component.html',
  styleUrls: ['./home-tab-bar.component.scss'],
  providers: [HomeTabBarViewModel],
})
export class HomeTabBarComponent extends BaseComponent {

  @Output() favouritesTabSelected = new EventEmitter<boolean>();

  favouritesTabIndex = 1;

  constructor(
    public viewModel: HomeTabBarViewModel,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  tabSelected(index: number): void {
    this.favouritesTabSelected.next(index === this.favouritesTabIndex);
  }
}
