import {DatatableData} from '../protocols/datatable-data';
import {Advertisement} from './advertisement';
import {DeserializeHelper} from '../protocols/deserializable';
import {LeagueId} from './leagueId';
import {TeamId} from './teamId';
import {VenueId} from './venue-id';
import {AdvertisementRunDate} from './advertisement-run-date';
import {EventId} from './eventId';
import {EventEmitter} from '@angular/core';

export class HydratedAdvertisement extends Advertisement implements DatatableData {
  associatedLeagues: LeagueId[] = [];
  associatedEvents: EventId[] = [];
  associatedTeams: TeamId[] = [];
  associatedVenues: VenueId[] = [];
  runDates: AdvertisementRunDate[] = [];
  playCount: number = 0;

  onDeserialize() {
    this.associatedTeams = DeserializeHelper.deserializeArray(TeamId, this.associatedTeams ?? []);
    this.associatedLeagues = DeserializeHelper.deserializeArray(LeagueId, this.associatedLeagues ?? []);
    this.associatedEvents = DeserializeHelper.deserializeArray(EventId, this.associatedEvents ?? []);
    this.associatedVenues = DeserializeHelper.deserializeArray(VenueId, this.associatedVenues ?? []);
    this.runDates = DeserializeHelper.deserializeArray(AdvertisementRunDate, this.runDates ?? []);
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}
