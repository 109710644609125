import {Injectable} from '@angular/core';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {Cardable} from '../../../../models/protocols/cardable';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import {SafeResourceUrl} from '@angular/platform-browser';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {VenueStream} from '../../../../models/resources/venue-stream';

@Injectable()
export class WatchVenueStreamViewModel extends BaseViewModel {

  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
  venueStreamId$ = this.activatedRoute.params.pipe(map(params => Number(params?.venueStreamId)));
  paramProgramId$ = this.activatedRoute.params.pipe(map(params => params?.programId));
  otherStreamsLoadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);

  programId$ = new BehaviorSubject<string>(null);
  contentQuery$ = new BehaviorSubject<ContentQuery>(null);
  fetchContentQuery = combineLatest([this.venueStreamId$, this.resourceDomainModel.activeHydratedVenues$]).pipe(
    filter(params => params.every(p => !!p)),
  ).subscribe(([venueStreamId, activeHydratedVenues]) => {
    const c = new ContentQuery(ProgramQueryType.Venues, null, null, null, null, null, null, [venueStreamId]);
    this.contentQuery$.next(c);
  }).addTo(this.subscriptions);

  setProgramIdFromParam = this.paramProgramId$.notNull().subscribe(programId => {
    this.programId$.next(programId);
  }).addTo(this.subscriptions);

  playerContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  fetchPlayerContentQuery = combineLatest([
    this.paramProgramId$.pipe(filter(p => !p)),
    this.contentQuery$
  ]).pipe(
    filter(([programId, contentQuery]) => !programId && !!contentQuery),
    map(([_, contentQuery]) => {
      return contentQuery;
    }),
  ).subscribe(playerContentQuery => {
    this.playerContentQuery$.next(playerContentQuery);
  }).addTo(this.subscriptions);

  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  showNoResults$ = combineLatest([
    this.liveProgramsNoResults,
    this.upcomingProgramsNoResults,
    this.pastProgramsNoResults,
  ]).pipe(
    map(noResults => noResults.every(x => !!x))
  );

  otherStreamsCards$ =  new BehaviorSubject<Cardable[]>(null);
  fetchOtherStreams = combineLatest([this.venueStreamId$, this.resourceDomainModel.activeHydratedVenues$]).pipe(
    filter(params => params.every(p => !!p)),
    indicateOnNext(this.otherStreamsLoadingOpts, ''),
  ).subscribe(([venueStreamId, activeHydratedVenues]) => {
    const hydratedVenue = activeHydratedVenues?.find(venue => !!venue.streams?.find(s => s.id === venueStreamId));
    this.otherStreamsCards$.next(this.getVenueStreamCards(venueStreamId, hydratedVenue));
  }).addTo(this.subscriptions);

  constructor(
    private activatedRoute: ActivatedRoute,
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  getVenueStreamCards(currentVenueStreamId: number, hydratedVenue: HydratedVenue): Cardable[] {
    const otherStreams = hydratedVenue?.streams?.filter(s => s.id !== currentVenueStreamId);
    return otherStreams?.map(s => new OtherVenueStreamCard(s, hydratedVenue));
  }
}

export class OtherVenueStreamCard implements Cardable {
  venueStream: VenueStream;
  hydratedVenue: HydratedVenue;

  constructor(venueStream: VenueStream, hydratedVenue: HydratedVenue) {
    this.venueStream = venueStream;
    this.hydratedVenue = hydratedVenue;
  }

  getCardTitle(): string {
    return this.venueStream.name;
  }
  getCardCategory(): string {
    return this.hydratedVenue.name;
  }

  getCardMetadata(): string {
    return null;
  }

  getCardDescription(): string {
    return null;
  }

  getCardShortDescription(): string {
    return '';
  }

  getCardImageSrc(): Observable<SafeResourceUrl | string> {
    return this.hydratedVenue.imgSrc$;
  }

  getCardHomeTeamId(): number {
    return null;
  }

  getCardAwayTeamId(): number {
    return null;
  }
}
