import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Card} from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-theme-carousel-image',
  templateUrl: './theme-carousel-image.component.html',
  styleUrls: ['./theme-carousel-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeCarouselImageComponent implements OnInit {

  @Input() card: Card;
  @Input() cardClass: string;

  @Output() cardButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  getCardClasses() {
    if (this.card?.customClass) {
      return `${this.cardClass} ${this.card.customClass}`;
    } else {
      return this.cardClass;
    }
  }

}
