<body>
<div class="unsubscribe-container">
  <a class="logo-container"><img src="assets/logo/dark/logo-stroke.svg" style="padding: 10px 0 10px 0 "></a>
  <h1 class="left-align">We're sorry to see you go.</h1>
  <p class="left-align">Your email has been successfully unsubscribed from our mailing list.</p>
  <button class="resubscribe-button" (click)="resubscribeForNotifications(userId)">Resubscribe</button>
  <div class="feedback-form">
    <p class="left-align">Please take a moment to tell us why you chose to unsubscribe. (Optional)</p>
    <input type="radio" id="never-signed-up" name="reason" value="never-signed-up" (click)="checkRadioButton($event.target); checkOtherReason(otherReasonRadio)">
    <label for="never-signed-up">I never signed up for your emails</label><br>
    <input type="radio" id="not-relevant" name="reason" value="not-relevant" (click)="checkRadioButton($event.target); checkOtherReason(otherReasonRadio)">
    <label for="not-relevant">The content is no longer relevant to me</label><br>
    <input type="radio" id="too-many-email" name="reason" value="too-many-email" (click)="checkRadioButton($event.target); checkOtherReason(otherReasonRadio)">
    <label for="too-many-email">I get too many emails from you</label><br>
    <input #otherReasonRadio type="radio" id="other" name="reason" value="other" (click)="checkRadioButton($event.target); checkOtherReason(otherReasonRadio)">
    <label for="other">Other reason</label><br>
    <textarea #commentArea [disabled]="!isOtherReasonSelected" placeholder="Please enter your reason here (Max 250 words)" type="text" name="comment" id="comment"></textarea>
    <button type="submit" class="submit-button" [disabled]="!isRadioButtonSelected" (click)="unsubscribeOnClick()">Submit Feedback</button>
  </div>
</div>
</body>
