import {Component, ViewEncapsulation} from '@angular/core';
import {VenueLandingViewModel} from './venue-landing-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ProgramRowType} from '../program-row/program-row.component';
import {Cardable} from '../../../../models/protocols/cardable';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';

@Component({
  selector: 'app-venue-landing',
  templateUrl: './venue-landing.component.html',
  styleUrls: ['./venue-landing.component.scss'],
  providers: [VenueLandingViewModel],
  encapsulation: ViewEncapsulation.None
})
export class VenueLandingComponent extends BaseComponent {

  programRowType = ProgramRowType;

  constructor(
    public viewModel: VenueLandingViewModel,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  programCardClicked(card: Cardable) {
    if (card instanceof Program) {
      this.viewModel.isShow$.next(false);
      this.viewModel.programId$.next(card.id);
    } else if (card instanceof Show) {
      this.viewModel.isShow$.next(true);
      this.viewModel.programId$.next(card.id);
    }
    this.scrollToVideoPlayer();
  }
}
