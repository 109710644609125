<app-program-player
  [programId]="viewModel.programId$|async"
  [programQuery]="viewModel.playerContentQuery$|async"
  [showBottomDivider]="true">
</app-program-player>
<app-sign-up-banner></app-sign-up-banner>
<app-program-row
  *ngIf="(viewModel.contentQuery$|async)"
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.liveProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  *ngIf="(viewModel.contentQuery$|async)"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  *ngIf="(viewModel.contentQuery$|async)"
  [programRowType]="programRowType.Past"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.pastProgramsNoResults.next($event)">
</app-program-row>
<app-no-content *ngIf="viewModel.showNoResults$|async" [type]="noContentType.VenueStream"></app-no-content>
<app-content-container [unsetMinHeight]="true">
  <app-card-row
    [loadingOpts]="viewModel.otherStreamsLoadingOpts"
    [cards]="viewModel.otherStreamsCards$|async"
    (cardClicked)="streamCardClicked($event)"
    [title]="'Other Streams at this Venue'">
  </app-card-row>
</app-content-container>
