export class StringUtils {
  static lowercaseFirstCharacter(s: string): string {
    return (s.length > 1) ? s.charAt(0).toLowerCase() + s.slice(1) : s;
  }

  static uppercaseFirstCharacter(s: string): string {
    return (s.length > 1) ? s.charAt(0).toUpperCase() + s.slice(1) : s;
  }

  static checkStringNotNullOrEmpty(s: string): boolean{
    return s!==null && s!=='';
  }
}
