import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {BehaviorSubject, Observable} from 'rxjs';
import {Program} from '../../../../models/program/program';
import '../../../../utils/subscription.extensions';
import {DateUtils} from '../../../../utils/date-utils';
import {ProgramStatusType} from '../../../../models/lookup/program-status-type';
import {Show} from '../../../../models/program/show';

@Component({
  selector: 'app-feature-marquee',
  templateUrl: './feature-marquee.component.html',
  styleUrls: ['./feature-marquee.component.scss'],
})
export class FeatureMarqueeComponent extends BaseComponent {

  @Input() public customClass: string = '';
  @Input() public showScoreAndGameStatus: boolean = false;
  @Input() isLive: boolean = false;
  @Input() showMarqueeActions: boolean = true;
  @Input() program$: Observable<Program | Show>;

  @Output() watchNowClicked = new EventEmitter<void>();
  @Output() startFromBeginningClicked = new EventEmitter<void>();

  homeTeamName = '';
  visitingTeamName = '';
  programTitle = '';
  programDescription = '';
  homeTeamScore$ = new BehaviorSubject<number>(1);
  visitingTeamScore$ = new BehaviorSubject<number>(3);
  gamePeriod$ = new BehaviorSubject<string>('2nd');
  gameTimeRemaining$ = new BehaviorSubject<string>('12:21 Remaining');


  constructor() {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    this.program$.subscribe((p) => {
      this.setProgramText(p);
    }).addTo(this.subscriptions);
  }

  setProgramText(p: Program | Show) {
    if (p instanceof Show) {
      this.programTitle = p.name ?? '';
      this.programDescription = p?.description ?? '';
      return;
    }

    this.homeTeamName = p?.homeTeam?.toUpperCase();
    this.visitingTeamName = p?.visitingTeam?.toUpperCase();
    if (p?.visitingTeam?.length > 0 && p?.visitingTeam?.length > 0) {
      this.programTitle = $localize`${p?.homeTeam} Vs. ${p?.visitingTeam}`;
      if (p?.venue === undefined) {
        this.programDescription = p?.description ?? $localize`Watch as the ${p?.visitingTeam} visit the ${p?.homeTeam}`;
      } else {
        this.programDescription = p?.description ?? $localize`Watch as the ${p?.visitingTeam} visit the ${p?.homeTeam} at ${p?.venue}.`;
      }
    } else if (p?.venue?.length > 0) {
      this.programTitle = p?.venue;
      this.programDescription = p?.description ?? '';
    } else {
      this.programTitle = '';
      this.programDescription = p?.description ?? '';
    }
  }

  getProgramTimeString(program: Program | Show): string {
    if (program instanceof Show) {
      return '';
    }

    const startDateTime = program?.startDateUtc;
    switch (program?.programStatusId) {
      case ProgramStatusType.ScheduledId:
        return $localize`This game will take place on
         ${DateUtils.formatDateWithWeekdayToReadableString(startDateTime)} at
          ${DateUtils.formatTimeToReadableString(startDateTime)}.`;
      case ProgramStatusType.InProgressId:
        return $localize`This game is currently live.`;
      case ProgramStatusType.PastId:
        return $localize`This game was played on
        ${DateUtils.formatDateWithWeekdayToReadableString(startDateTime)} at
         ${DateUtils.formatTimeToReadableString(startDateTime)}.`;
      case ProgramStatusType.CompletedId:
          return $localize`This game was played on
          ${DateUtils.formatDateWithWeekdayToReadableString(startDateTime)} at
           ${DateUtils.formatTimeToReadableString(startDateTime)}.`;
    }
  }

  getLeagueName(program: Program | Show): string {
    return (program instanceof Show) ? '' : program?.league ?? '';
  }

  getEventName(program: Program | Show): string {
    return (program instanceof Show) ? '' : program?.event ?? '';
  }

  getShortDescription(program: Program | Show): string {
    if (program instanceof Show) {
      return '';
    }
    return program?.shortDescription ?? '';
  }
}
