import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../services/session-service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../models/base/base-component';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SearchDomainModel} from '../../../domainModels/search-domain-model';
import {SearchModalComponent} from '../../shared/components/search-modal/search-modal.component';
import {environment} from '../../../../environments/environment';
import {DateUtils} from '../../../utils/date-utils';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent extends BaseComponent implements OnInit {

  public loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, true);
  public showAdminLayout = false;
  public dev = !environment.production;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private modalService: NgbModal,
    private searchDomainModel: SearchDomainModel,
  ) {
    super();
  }

  setupViews() {
    this.setupLoadingOpts();
  }

  setupBindings() {
    this.sessionService.refreshingSession.notNull().subscribe((ref) => {
      const lm = $localize`Refreshing Session`;
      if (!this.loadingOpts.containsRequest(lm) && ref) {
        this.loadingOpts.addRequest(lm);
      } else if (this.loadingOpts.containsRequest(lm) && !ref) {
        this.loadingOpts.removeRequest(lm);
      }
    }).addTo(this.subscriptions);
    this.sessionService.userIsSubscriber.subscribe((userIsSub) => {
      this.showAdminLayout = !userIsSub;
    }).addTo(this.subscriptions);

    this.searchDomainModel.showSearchSubject$.subscribe(() => {
      this.showSearchModal();
    }).addTo(this.subscriptions);
  }

  setupLoadingOpts() {
    this.loadingOpts.zIndex = 100;
  }

  toggleMinimize(e) {
    this.showAdminLayout = e;
  }

  showSearchModal() {
    if (this.modalService.hasOpenModals()) {
      return;
    }

    const modalRef = this.modalService.open(SearchModalComponent, {
      windowClass: 'right-side-modal-window',
      backdropClass: 'right-side-modal-backdrop',
      container: '#main-content',
    });
    modalRef.result.then(() => {
    }, () => {
    });
  }

  invalidateSession() {
    const session = this.sessionService.sessionContainer.getValue()?.user?.session;
    if (!!session) {
      session.accessToken = 'invalid-token';
      session.expiresAt = DateUtils.currentTimestamp();
      session.refreshToken = 'invalid-token';
      console.log(session);
    }
  }
}
