export class CardBreakpoints {
  static defaultBreakpoints = {
    0: {
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    720: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
    960: {
      slidesPerGroup: 4,
      slidesPerView: 4,
    }
  };

  static showsBreakpoints = {
    0: {
      slidesPerGroup: 1,
      slidesPerView: 1,
    },
    720: {
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    960: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    }
  };

  static upcomingBreakpoints = {
    0: {
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    540: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
    720: {
      slidesPerGroup: 4,
      slidesPerView: 4,
    },
    960: {
      slidesPerGroup: 5,
      slidesPerView: 5,
    },
    1140: {
      slidesPerGroup: 6,
      slidesPerView: 6,
    }
  };
}
