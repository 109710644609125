import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FeaturedLeaguesRowViewModel} from './featured-leagues-row-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {Router} from '@angular/router';

@Component({
  selector: 'app-featured-leagues-row',
  templateUrl: './featured-leagues-row.component.html',
  styleUrls: ['./featured-leagues-row.component.scss'],
  providers: [FeaturedLeaguesRowViewModel],
  encapsulation: ViewEncapsulation.None
})
export class FeaturedLeaguesRowComponent extends BaseComponent {

  @Input() hideBottomDivider: boolean = false;
  columnsPerRow: number;

  constructor(
    public viewModel: FeaturedLeaguesRowViewModel,
    public router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    this.calculateColumnsPerRow();
    window.addEventListener('resize', () => this.calculateColumnsPerRow());
  }
  calculateColumnsPerRow() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      this.columnsPerRow = 6; // Extra-large screens
    } else if (screenWidth >= 992) {
      this.columnsPerRow = 4; // Large screens
    } else if (screenWidth >= 768) {
      this.columnsPerRow = 3; // Medium screens
    } else {
      this.columnsPerRow = 2; // Small screens
    }
  }

  leagueClicked(l: HydratedLeague) {
    this.router.navigate([`/home/leagues/${l.id}`]).then();
  }

}
