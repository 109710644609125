<div class="container-fluid p-0 marquee-container mb-4">
  <img class="marquee-background-image"
       [hidden]="!(viewModel.bannerImgSrc$|async)"
       [src]="viewModel.bannerImgSrc$|async" alt="hero image">
  <div class="marquee-background-image-overlay"></div>
  <app-content-container class="d-block h-100" customClass="h-100">
    <app-loading
      [hidden]="!viewModel.teamLoadingOpts.isLoading"
      [options]="viewModel.teamLoadingOpts">
    </app-loading>
    <div class="team-info" *ngIf="!viewModel.teamLoadingOpts.isLoading">
      <img class="team-logo" *ngIf="!!(viewModel?.team$?.value?.imgSrc$ | async)"
           [src]="viewModel?.team$?.value?.imgSrc$ | async" alt="">
      <div class="team-name mt-2">{{(viewModel.team$|async)?.fullTeamName()?.toUpperCase()}}</div>
      <div class="d-flex flex-wrap-content justify-content-between">
        <button class="custom-button favourite-button mr-3 mt-2"
                [ngClass]="{'orange-text': !viewModel.isTeamFavourite$.value}"
                (click)="viewModel.toggleFavouriteTeam()">
          <img class="icon-button-icon" [ngClass]="{'orange-filter' : !viewModel.isTeamFavourite$.value}"
               [src]="!viewModel.isTeamFavourite$.value ? 'assets/icons/dark/outline/star.svg' : 'assets/icons/dark/solid/star.svg'"
               alt="star-icon">
          {{viewModel.isTeamFavourite$.value ? 'Remove from Favourites' : 'Add to Favourites'}}
        </button>
      </div>
    </div>
  </app-content-container>
</div>
<ng-container>
  <app-program-player
    *ngIf="(viewModel.isSignedIn$|async) && (viewModel.programContentQuery$|async)"
    [programQuery]="viewModel.programContentQuery$|async"
    [showBottomDivider]="true">
  </app-program-player>
</ng-container>
<app-program-row
  *ngIf="(viewModel.programContentQuery$|async)"
  [programRowType]="programRowType.Live"
  [programContentQuery]="(viewModel.programContentQuery$|async)"
  (noResults)="viewModel.liveProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  *ngIf="(viewModel.programContentQuery$|async)"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="(viewModel.programContentQuery$|async)"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  *ngIf="(viewModel.programContentQuery$|async)"
  [programRowType]="programRowType.Past"
  [seeAllGamesClicked]="this.seeAllPastGames"
  [programContentQuery]="(viewModel.programContentQuery$|async)"
  (noResults)="viewModel.pastProgramsNoResults.next($event)">
</app-program-row>
<app-no-content *ngIf="viewModel.showNoResults$|async" [type]="noContentType.Team"></app-no-content>
