<app-detail-title>
  <div class="d-flex justify-content-between">
    <ng-container i18n>Advertisements</ng-container>
    <button
      [disabled]="!PermissionManagerService.isGranted(Types.AdminSections.Advertisement_Add_Advertisement)"
      [ngClass]="{'preferred-button': true, 'disabled': !PermissionManagerService.isGranted(Types.AdminSections.Advertisement_Add_Advertisement)}"
      class="custom-button preferred-button"
      (click)="createClicked()" i18n>
      Create Advertisement
    </button>
  </div>
</app-detail-title>
<div class="mt-3 position-relative">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <div class="d-flex">
    <app-detail-back-button [backRoute]="'..'" class="flex-grow-1">
      {{ (viewModel.advertPartner$|async)?.name }}
    </app-detail-back-button>
    <div
      [ngClass]="{'text-link-button': true, 'disabled': !PermissionManagerService.isGranted(Types.AdminSections.Advertisement_Edit_Advertiser_Edit)}"
      (click)="PermissionManagerService.isGranted(Types.AdminSections.Advertisement_Edit_Advertiser_Edit) ? editClicked() : null"
      i18n>Edit Advertiser
    </div>
  </div>
  <app-data-table
    [tableData]="dataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="rowClicked($event)"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
