import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from '../session-service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    public sessionService: SessionService,
    public router: Router
  ) {
  }

  public findTerm = (href, term) => {
    if (href.includes(term)){
      return href;
    }
  };

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
    // return new Observable<boolean>(subscriber => {
    //   this.sessionService.sessionContainer.firstNotNull().subscribe((_) => {
    //     subscriber.next(this.routeIsPermitted(state));
    //     subscriber.complete();
    //   });
    // });
  }

  routeIsPermitted(state: RouterStateSnapshot): boolean {
    let isPermitted = true;
    const sess = this.sessionService.sessionContainer.getValue();
    if (sess && sess.user) {
      switch (state.url) {
        case this.findTerm(state.url, '/forbidden-route'):
          isPermitted = false;
          break;
      }
    }
    if (!isPermitted) {
      this.router.navigate(['']).then(() => {
      });
    }
    return isPermitted;
  }

}
