import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';

@Component({
  selector: 'app-program-uploader-error',
  templateUrl: './program-uploader-error.component.html',
  styleUrls: ['./program-uploader-error.component.scss'],
})
export class ProgramUploaderErrorComponent extends BaseComponent {

  @Input() error: string;
  @Output() closeButtonClicked = new EventEmitter<void>();

  constructor(
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }
}
