import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ChangePasswordRequest} from '../../../../models/account/requests/change-password-request';
import {indicate} from '../../../../utils/observable.extensions';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {SessionService} from '../../../../services/session-service';

@Injectable()
export class AdminChangePasswordViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);

  constructor(private domainModel: AccountDomainModel,
              private toastService: ToastService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  changePassword(changePasswordRequest: ChangePasswordRequest) {
    this.domainModel.changePassword(changePasswordRequest, 'admin')
      .pipe(indicate(this.loadingOpts, $localize`Updating Password`))
      .subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Password Updated`, null);
        this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
      }, error => {
        this.toastService.publishError(error);
      });
  }
}
