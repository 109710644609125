import {Component, EventEmitter, Input, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
import {AuthModalViewModel, LoginType} from '../auth-modal-view-model';
import {SignInRequest} from '../../../../../models/account/requests/sign-in-request';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {FormGroupComponent} from '../../../components/form-group/form-group.component';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent extends BaseComponent {

  @Input() primaryButtonClicked: EventEmitter<void>;
  @Input() secondaryButtonClicked: EventEmitter<void>;
  @Input() tertiaryButtonClicked: EventEmitter<void>;
  @ViewChild('signInForm') signInForm: FormGroupComponent;

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: SignInRequest = new SignInRequest();
  public updatedFormObject = new EventEmitter<void>();

  constructor(public viewModel: AuthModalViewModel,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    super();
  }

  setupViews() {
    this.viewModel.clearErrorMessage();
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.viewModel.errorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

    this.primaryButtonClicked.subscribe(() => {
      this.signInForm.submitForm();
    }).addTo(this.subscriptions);

    this.secondaryButtonClicked.subscribe(() => {
      this.viewModel.dismissModal();
    }).addTo(this.subscriptions);

    this.tertiaryButtonClicked.subscribe(() => {
      this.needAccountClicked();
    }).addTo(this.subscriptions);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Enter your email`;
    email.bindingProperty = 'email';
    email.required = true;
    items.push(email);

    const password = new FormInputItem();
    password.inputName = 'password';
    password.inputType = FormInputType.Password;
    password.label = $localize`Password`;
    password.placeholder = $localize`Password`;
    password.bindingProperty = 'password';
    password.customClass = 'm-0';
    password.required = true;
    items.push(password);

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormBindings() {
    this.activatedRoute.queryParams.subscribe(params => {
      const email = params.email;
      const emailInput = this.formItems.find(i => i.inputName === 'email');
      if (!!email && !!emailInput) {
        setTimeout(() => {
          emailInput.setInputFormControlValue(email);
          emailInput.markAsTouched();
        });
      }
      const password = params.code;
      const passwordInput = this.formItems.find(i => i.inputName === 'password');
      if (!!password) {
        setTimeout(() => {
          passwordInput.setInputFormControlValue(password);
          passwordInput.markAsTouched();
        });
      }
    }).addTo(this.subscriptions);
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
    this.formStyling.checkboxLabel = $localize`Keep Me Signed In`;
    this.formStyling.checkboxRowButtonLabel = $localize`Forgot Password?`;
    this.formStyling.checkboxClass = 'flex-row-reverse';
  }

  setupFormOptions() {
    this.formOptions.includeEndFormCheckbox = true;
    this.formOptions.checkboxBindingProperty = ['rememberMe', this.formObject.rememberMe];
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: SignInRequest) {
    this.viewModel.signIn(result);
  }

  forgotPasswordClicked() {
    this.viewModel.authFlow = AuthFlow.ForgotPassword;
  }

  needAccountClicked() {
    this.viewModel.authFlow = AuthFlow.SignUp;
  }
}


