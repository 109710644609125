
export class FormGroupStyling {
  public includePadding: boolean = false;
  // 2 === two-column, 69 === two-column-expand-last
  public numberColumns: number = 1;
  // Primary button container
  public primaryButtonFloat: string = 'none';
  public primaryButtonContainerClass: string = '';
  public primaryButtonClass: string = '';
  public resetButtonText: string = '';
  public cancelButtonText: string = '';
  public submitButtonText: string = '';
  public submitButtonClass: string = '';
  public submitButtonDisabledClass: string = 'preferred-button disabled';
  public completeProgramButtonText: string = '';
  // Secondary button container
  public secondaryButtonFloat: string = 'none';
  public secondaryButtonText: string = '';
  public secondaryButtonClass: string = '';
  // Tertiary button container
  public tertiaryButtonContainerClass: string = '';
  public tertiaryButtonFloat: string = 'none';
  public tertiaryButtonText: string = '';
  public tertiaryButtonClass: string = '';
  // Checkbox
  public checkboxLabel: string = '';
  public checkboxClass: string = '';
  public checkboxRowButtonLabel: string = '';
  // Tooltip
  public tooltipPosition: string = '';


  public getButtonMargin(secondary: boolean = false, tertiary: boolean = false): string {
    let buttonFloat;
    if (secondary) {
      buttonFloat = this.secondaryButtonFloat;
    } else if (tertiary) {
      buttonFloat = this.tertiaryButtonFloat;
    } else {
      buttonFloat = this.primaryButtonFloat;
    }
    switch (buttonFloat) {
      case 'right':
        return '0 0 0 1rem';
      case 'left':
        return '0 1rem 0 0';
      default:
        return '0 0.5rem';
    }
  }

}
