import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError, flatMap, map} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {HydratedTeam} from '../models/resources/hydrated-team';
import {Team} from '../models/resources/team';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TeamApi implements LoggableAPI {
  // Variables
  public serviceName = 'Team';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }


  public getTeamsWithSportId(sportId: number): Observable<HydratedTeam[]> {
    const params = new HttpParams().set('sportId', sportId);
    return this.apiClient.recursiveGetArr(HydratedTeam, Endpoints.getTeams(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getTeamsWithSportId', err));
        return throwError(err);
      })
    );
  }

  public getHydratedTeams(activeOnly: boolean): Observable<HydratedTeam[]> {
    let params = new HttpParams();
    if (activeOnly) {
      params = params.set('active', true);
    }
    // Limit total number of API calls for getHydratedTeams by using page size of 500 since each page is very small already
    return this.apiClient.recursiveGetArr(HydratedTeam, Endpoints.getHydratedTeams(), null, params, 0, 500).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedTeams', err));
        return throwError(err);
      })
    );
  }

  public getHydratedTeam(teamId: number): Observable<HydratedTeam> {
    return this.apiClient.getObj(HydratedTeam, Endpoints.getHydratedTeam(teamId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedTeam', err));
        return throwError(err);
      })
    );
  }

  public deleteTeamImage(teamId: number, imageId: string): Observable<string> {
    const url = Endpoints.deleteTeamImage(teamId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteTeamImage', err));
        return throwError(err);
      })
    );
  }

  public deleteTeamBannerImage(teamId: number, imageId: string): Observable<string> {
    const url = Endpoints.deleteTeamBannerImage(teamId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteTeamBannerImage', err));
        return throwError(err);
      })
    );
  }

  public deleteTeamCardImage(teamId: number, imageId: string): Observable<string> {
    const url = Endpoints.deleteTeamCardImage(teamId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteTeamCardImage', err));
        return throwError(err);
      })
    );
  }


  public createTeam(req: Team): Observable<Team> {
    return this.apiClient.postObj<Team>(Team, Endpoints.createTeam(), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createTeam', err));
        return throwError(err);
      })
    );
  }

  public updateTeam(req: Team): Observable<Team> {
    return this.apiClient.putObj<Team>(Team, Endpoints.updateTeam(req.id), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateTeam', err));
        return throwError(err);
      })
    );
  }

  public deleteTeam(teamId: number): Observable<any> {
    return this.apiClient.deleteObj<any>(null, Endpoints.deleteTeam(teamId), null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueTeam', err));
        return throwError(err);
      })
    );
  }
}
