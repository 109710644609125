export enum streamStatus {
    Offline = 'OFFLINE',
    Missing = 'MISSING',
    Failed = 'FAILED',
    Online = 'ONLINE',
    Pending = 'PENDING',
    Ready = 'READY',
    Live = 'LIVE',
    Unhealthy = 'UNHEALTHY',
    Dropped = 'DROPPED',
    Error = 'ERROR',
    Defunct = 'DEFUNCT',
    NotLive = 'NOT LIVE',
    IVSHealthy = 'HEALTHY',
    IVSStarvation = 'STARVING',
    IVSUnknown = 'UNKNOWN'

  }

