import {CardFilter} from './card-filter';
import {Checkbox} from './checkbox';

export class CardTableOptions {
  // Search Input
  public enableSearch: boolean = true;
  public searchLabelText: string = 'Search';
  public searchPlaceholderText: string = 'Search to filter results';
  public checkBox: Checkbox;
  public checkBoxFilter: CardFilter;
  // Footer
  public hideFooter: boolean = false;
}
