import {Component, OnInit} from '@angular/core';
import {Tabbable} from '../../../../../models/protocols/tabbable';

@Component({
  selector: 'app-test-tab',
  templateUrl: './test-tab.component.html',
  styleUrls: ['./test-tab.component.scss']
})
export class TestTabComponent implements OnInit, Tabbable {

  testName: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  setupViews() {
  }

  setupBindings() {
  }


}
