import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {WatchComponent} from './components/watch/watch.component';
import {IsSubscriberGuard} from '../../services/guards/is-subscriber.guard';
import {LeagueLandingComponent} from './components/league-landing/league-landing.component';
import {
  SubscriberLeagueDetailsComponent
} from './components/subscriber-league-details/subscriber-league-details.component';
import {VenueLandingComponent} from './components/venue-landing/venue-landing.component';
import {WatchVenueStreamComponent} from './components/watch-venue-stream/watch-venue-stream.component';
import {EventLandingComponent} from './components/event-landing/event-landing.component';
import {
  SubscriberEventDetailsComponent
} from './components/subscriber-event-details/subscriber-event-details.component';
import {AuthComponent} from '../auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'leagues',
    component: LeagueLandingComponent,
    data: {
      title: 'Leagues'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'league/:leagueAbbreviation',
    component: SubscriberLeagueDetailsComponent,
    data: {
      title: 'Leagues'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'leagues/:leagueId',
    component: SubscriberLeagueDetailsComponent,
    data: {
      title: 'Leagues'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'venues',
    component: VenueLandingComponent,
    data: {
      title: 'Venues'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'venues/stream/:venueStreamId/:programId',
    component: WatchVenueStreamComponent,
    data: {
      title: 'Venue Stream'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'venues/stream/:venueStreamId',
    component: WatchVenueStreamComponent,
    data: {
      title: 'Venue Stream'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'events',
    component: EventLandingComponent,
    data: {
      title: 'Events'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'event/:eventAbbreviation',
    component: SubscriberEventDetailsComponent,
    data: {
      title: 'Events'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'events/:eventId',
    component: SubscriberEventDetailsComponent,
    data: {
      title: 'Events'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'show/:programId',
    component: WatchComponent,
    data: {
      title: 'Watch',
      isShow: true,
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: ':programId',
    component: WatchComponent,
    data: {
      title: 'Watch'
    },
    canActivate: [IsSubscriberGuard]
  },
  {
    path: 'auth/sign-up/verification/verifytoken',
    component: HomeComponent,
    data: {
      title: 'Sign Up Verification Successful'
    },
    canActivate: [IsSubscriberGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
