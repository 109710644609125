import { BaseModel } from './../base/base-model';

export class EventStream extends BaseModel{
  id: number;
  eventId: number;
  mp4ClientEmail1: string;
  mp4ClientEmail2: string;
  mp4ClientEmail3: string;
  isMp4Required: boolean = false;


  static initWithStream(eventStream: EventStream): EventStream {
    const formObject = new EventStream();
    formObject.id=eventStream.id;
    formObject.eventId=eventStream.eventId;
    formObject.mp4ClientEmail1=eventStream.mp4ClientEmail1;
    formObject.mp4ClientEmail2=eventStream.mp4ClientEmail2;
    formObject.mp4ClientEmail3=eventStream.mp4ClientEmail3;
    formObject.isMp4Required=eventStream.isMp4Required;
    return formObject;
  }

  onDeserialize(){
    super.onDeserialize();
  }

}
