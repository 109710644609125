import {Checkbox} from './stylesheet/checkbox';
import {DatatableFilterOption} from './stylesheet/datatable-filter-option';

export class DatatableFilter {
  // Search Input
  public enableSearch: boolean = true;
  public searchLabelText: string = $localize`Search`;
  public searchPlaceholderText: string = $localize`Search to filter results`;
  // Checkbox
  public enableFilterCheckbox: boolean = false;
  public checkbox: Checkbox = new Checkbox('');
  // Other
  public enableResultsDisplayed: boolean = true;
  public filterContainerClass: string = '';
  public noResultsForFiltersTitle: string = $localize`No results matching the active filters.`;
  public noResultsForSearchTermSubtitle: string = $localize`Try a different search term.`;
  public noResultsForFilterSubtitle: string = $localize`Try modifying your filters.`;
  // Custom Filters
  public customFilters: DatatableFilterOption[] = [];
  public searchQueryString: string = '';

  public filtersAreActive(): boolean {
    return this.customFilters.map(cf => cf.selectedOption && cf.selectedOption.getSelectionValue() !== '').filter(cf => cf).length > 0;
  }

  public clearFilters(): void {
    this.customFilters.map(cf => cf.selectedOption = cf.hintOptions);
  }
}
