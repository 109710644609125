<label class="custom-checkbox-container"
       [ngClass]="{ 'inline-checkbox' : inline }"
       [ngStyle]="{ 'margin': customCheckboxMargin ? customCheckboxMargin : null }"
       [class.disabled]="disabled"
       for="{{cid}}">
  <input type="checkbox"
         class="custom-checkbox"
         id="{{cid}}"
         name="custom-cb"
         [checked]="checked"
         [disabled]="disabled"
         (change)="onClick()">
  <span class="custom-checkbox-label" [class.with-description]="checkbox?.description">
    <ng-container *ngIf="label">{{label}}</ng-container>
  </span>
  <span class="custom-checkbox-description mt-1">
    <ng-container *ngIf="checkbox?.description">{{checkbox.description}}</ng-container>
  </span>
  <span class="custom-checkbox-description mt-2">
    <ng-container *ngIf="checkbox?.subDescription">{{checkbox.subDescription}}</ng-container>
  </span>
  <span class="custom-checkmark">
    <img [src]="'/assets/icons/light/solid/check.svg'" alt="">
  </span>
</label>
<hr *ngIf="showDivider" class="pb-3">
