import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {AdvertisingDomainModel} from '../../../domainModels/advertising-domain-model';
import {AdvertisingPartner} from '../../../models/resources/advertising-partner';
import {BehaviorSubject} from 'rxjs';
import {switchMapTo} from 'rxjs/operators';
import {ToastService} from '../../../services/toast-service';

@Injectable()
export class AdvertisingPartnersViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  refreshPartners$ = new BehaviorSubject<void>(undefined);
  advertPartners$ = this.refreshPartners$.pipe(
    switchMapTo(this.domainModel.getAdvertisingPartners().pipe(indicateOnNext(this.loadingOpts, $localize`Loading Advertising Partners`)))
  );

  constructor(private domainModel: AdvertisingDomainModel,
              private toastService: ToastService) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  createAdvertisingPartner(advertisingPartner: AdvertisingPartner) {
    this.domainModel.createAdvertisingPartner(advertisingPartner).subscribe(s => {
      this.toastService.publishSuccessMessage(null, $localize`Advertising Partner Created`);
      this.refreshPartners$.next();
    }, error => {
      this.toastService.publishErrorMessage(error, $localize`Creating Advertising Partner Failed`);
    });
  }
}
