<div class="card custom-card"
     [ngClass]="cardClass"
     [ngStyle]="{ 'background-image': card.getBackgroundUrl() }">
  <div class="custom-card-label">
    <div class="custom-card-label-title">
      {{ card.label }}
    </div>
    <div class="custom-card-label-text">
      {{ card.text }}
    </div>
  </div>
</div>
