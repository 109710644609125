import {Deserializable} from '../../protocols/deserializable';
import {Address} from '../../location/address';

export class SubscriberPaymentDetails implements Deserializable {
  public firstName: string = '';
  public lastName: string = '';
  public cardNumber: string = null;
  public maskedCardNumber: string = '';
  public expiryDate: string = '';
  public cardExpiryMonth: number = null;
  public cardExpiryYear: number = null;
  public cardCVV: string = '';
  public id: string = null;
  public address: Address = new Address();

  onDeserialize() {
    // CardCVV is never returned from the API
    this.cardCVV = '';
    this.setupExpiryDateString();
  }

  setupExpiryDateString() {
    let expiryMonthString = this.cardExpiryMonth?.toString();
    if (expiryMonthString?.length === 1) {
      expiryMonthString = `0${expiryMonthString}`;
    }
    let expiryYearString = this.cardExpiryYear?.toString();
    if (expiryYearString?.length === 4) {
      expiryYearString = expiryYearString.slice(2);
    }
    this.expiryDate = `${expiryMonthString}/${expiryYearString}`;
  }

  formatPaymentRequest() {
    const splitExpiry = this.expiryDate.split('/');
    this.cardExpiryMonth = Number(splitExpiry[0]);
    this.cardExpiryYear = Number('20' + splitExpiry[1]);
    this.cardNumber = this.cardNumber.replace(/\s+/g, '');
  }
}

export class CreateSubscriberPaymentDetails {
  public firstName: string = '';
  public lastName: string = '';
  public cardNumber: string = null;
  public cardExpiryMonth: number = null;
  public cardExpiryYear: number = null;
  public cardCVV: string = '';

  createPaymentDetailRequestFromHydrated(pd: SubscriberPaymentDetails) {
    this.firstName = pd.firstName;
    this.lastName = pd.lastName;
    this.cardNumber = pd.cardNumber;
    this.cardExpiryMonth = pd.cardExpiryMonth;
    this.cardExpiryYear = pd.cardExpiryYear;
    this.cardCVV = pd.cardCVV;
  }
}
