<app-content-container [unsetMinHeight]="true">
  <app-card-row
    #cardRowComponent
    [loadingOpts]="viewModel.loadingOpts"
    [cards]="viewModel.cards$|async"
    (cardClicked)="cardClicked($event)"
    (buttonClicked)="seeAllGamesClicked()"
    (lastLoadedPageReached)="loadMorePrograms()"
    [fetchingMoreData]="viewModel.fetchingMoreData"
    [title]="viewModel.rowTitle$|async"
    [buttonTitle]="viewModel.rowCTA$|async"
    [cardCtaText]="viewModel.cardCTA$|async"
    [cardStyle]="viewModel.cardStyle$|async"
    [breakpoints]="viewModel.cardBreakpoints$|async">
  </app-card-row>
  <div *ngIf="(getResult() | async) && (isSignedIn$ | async)">
    <div *ngIf="getType()==='leagues'">
      <div id="leaderboard_btf_leagues_members">
        <script>
          googletag.cmd.push(function() { googletag.display('leaderboard_btf_leagues_members'); });
        </script>
      </div>
    </div>
    <div *ngIf="getType()==='home'">
      <div id="leaderboard_btf_home_members"></div>
    </div>
    <div *ngIf="getType()==='events'">
      <div id="leaderboard_atf_events_members"></div>
    </div>
</div>
<div *ngIf="(getResult() | async) && !(isSignedIn$ | async)">
  <div *ngIf="getType()==='leagues'">
    <div id="leaderboard_btf_leagues"></div>
  </div>
  <div *ngIf="getType()==='home'">
    <div id="leaderboard_btf_home"></div>
  </div>
</div>
</app-content-container>
