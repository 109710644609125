<app-detail-title
  [hrCustomClass]=" (viewModel.addNewUser$|async) ? '' : 'd-none'"
  i18n>
  Users
</app-detail-title>
<app-content-container [narrow]="true">
  <div *ngIf="formItems?.length > 0 && !!formObject" class="py-4 h-100 position-relative min-height-20">
    <app-detail-back-button [backRoute]="'..'" class="d-block mt-4">
      <ng-container *ngIf="viewModel.addNewUser$|async" i18n>Add a New User</ng-container>
      <ng-container *ngIf="!(viewModel.addNewUser$|async)" i18n>{{(formObject.firstName)}}</ng-container>
    </app-detail-back-button>
    <app-form-group
      [hidden]="viewModel.loadingOpts?.isLoading"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="formSubmitted()">
    </app-form-group>
    <app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
                 [options]="viewModel.loadingOpts">
    </app-loading>
  </div>
</app-content-container>
