/* eslint-disable @angular-eslint/directive-selector */
import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[tab-content-container]'
})
export class TabContentContainerDirective {
  constructor(public viewContainerRef: ViewContainerRef) {
  }
}
