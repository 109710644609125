import {Injectable} from '@angular/core';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {BaseViewModel} from '../../../../models/base/base-view-model';

@Injectable()
export class AddLeagueViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);

  leagues$ = this.domainModel.allHydratedLeaguesForAdminModules$.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Leagues`));

  constructor(private domainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
