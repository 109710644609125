import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {SessionService} from '../../../../services/session-service';
import {map} from 'rxjs/operators';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';
import {OpenAuthModalOptions} from '../../../../models/account/open-auth-modal-options';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class SignUpBannerViewModel extends BaseViewModel {

  showSignUpBanner$ = this.sessionService.sessionContainer.pipe(map(s => !s));

  constructor(private sessionService: SessionService, private router: Router) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  signUpClicked(forLeagues: boolean) {
    const signUpTitle = forLeagues ? $localize`Sign up to access premium league content` : $localize`Sign up to watch now`;
    const options = new OpenAuthModalOptions(AuthFlow.SignUp, this.router.url, $localize`Sign in to continue`, signUpTitle);
    this.sessionService.showAuthModal$.next(options);
  }

}
