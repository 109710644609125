import {Component} from '@angular/core';
import {UsersViewModel} from '../users-view-model';
import {FormInputItem, FormInputType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateAdminRequest} from '../../../../models/account/requests/create-admin-request';

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss']
})
export class InviteUserModalComponent extends BaseModal {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: CreateAdminRequest = new CreateAdminRequest();

  constructor(
    public viewModel: UsersViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }


  setupBindings() {
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    items.push(lastName);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Email`;
    email.bindingProperty = 'email';
    email.overrideFullWidth = true;
    email.required = true;
    items.push(email);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Send Invite`;
  }

  setupFormOptions() {
  }

  formSubmitted() {
    this.activeModal.close(this.formObject);
  }

}
