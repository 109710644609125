import {Deserializable} from '../protocols/deserializable';
import {FormListable} from '../protocols/form-listable';
import {Team} from './team';

export class TeamId  implements Deserializable, FormListable {

  id: string;
  teamId: number;
  team: string;
  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  constructor(id: number = null) {
    this.teamId = id;
  }
  static initWithTeam(team: Team): TeamId {
    const t = new TeamId();
    t.teamId = team.id;
    t.team = team.name;
    return t;
  }

  onDeserialize() {
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.team;
  }
}


