import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SessionService} from '../services/session-service';
import {switchMap} from 'rxjs/operators';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {SportType} from '../models/lookup/sport-type';
import {CountryType} from '../models/lookup/country-type';
import {PermissionType} from '../models/lookup/permission-type';
import {ProgramStatusType} from '../models/lookup/program-status-type';
import {LookupApi} from '../api/lookup-api';
import '../utils/observable.extensions';
import {CreateSportTypeRequest} from '../models/lookup/requests/create-sport-type-request';
import {ProductionType} from '../models/lookup/production-type';
import {AdvertisementPriorityType} from '../models/lookup/advertisement-priority-type';
import {PixellotVenues} from '../models/resources/pixellot-venues';
import {DepartmentType} from '../models/lookup/department-type';

@Injectable({
  providedIn: 'root'
})
export class LookupDomainModel extends BaseDomainModel {

  public sportTypes = new BehaviorSubject<SportType[]>(null);
  public pixellotProductionTypes = new BehaviorSubject<string[]>(null);
  public countryTypes = new BehaviorSubject<CountryType[]>(null);
  public departmentTypes = new BehaviorSubject<DepartmentType[]>(null);
  public permissionTypes = new BehaviorSubject<PermissionType[]>(null);
  public programStatusTypes = new BehaviorSubject<ProgramStatusType[]>(null);
  public productionTypes = new BehaviorSubject<ProductionType[]>(null);
  public advertisementPriorityTypes = new BehaviorSubject<AdvertisementPriorityType[]>(null);

  private fetchSportTypes = this.sessionService.sessionContainer.firstNotNull()
    .pipe(switchMap(() => this.lookupApi.getSportTypes()))
    .subscribe(v => this.sportTypes.next(v)).addTo(this.subscriptions);

  private fetchPixellotProductionTypes = this.sessionService.sessionContainer.firstNotNull()
    .pipe(switchMap(() => this.lookupApi.getPixellotProductionTypes()))
    .subscribe(v => this.pixellotProductionTypes.next(v)).addTo(this.subscriptions);

  private fetchCountryTypes = this.lookupApi.getCountryTypes()
    .subscribe(v => this.countryTypes.next(v)).addTo(this.subscriptions);

  private fetchDepartmentTypes = this.lookupApi.getDepartmentTypes()
    .subscribe(v => this.departmentTypes.next(v)).addTo(this.subscriptions);

  private fetchPermissionTypes = this.sessionService.sessionContainer.firstNotNull()
    .pipe(switchMap(() => this.lookupApi.getPermissionTypes()))
    .subscribe(v => this.permissionTypes.next(v)).addTo(this.subscriptions);

  private fetchProgramStatusTypes = this.sessionService.sessionContainer.firstNotNull()
    .pipe(switchMap(() => this.lookupApi.getProgramStatusTypes()))
    .subscribe(v => this.programStatusTypes.next(v)).addTo(this.subscriptions);

  private fetchProductionTypes = this.sessionService.sessionContainer.firstNotNull()
    .pipe(switchMap(() => this.lookupApi.getProductionTypes()))
    .subscribe(v => this.productionTypes.next(v)).addTo(this.subscriptions);

  private fetchAdvertisementPriorityTypes = this.sessionService.sessionContainer.firstNotNull()
    .pipe(switchMap(() => this.lookupApi.getAdvertisementPriorityTypes()))
    .subscribe(v => this.advertisementPriorityTypes.next(v)).addTo(this.subscriptions);

  constructor(
    private sessionService: SessionService,
    private lookupApi: LookupApi,
  ) {
    super();
    this.init();
  }

  public init() {
  }

  // Sport Types
  createSportType(req: CreateSportTypeRequest): Observable<SportType> {
    return this.lookupApi.createSportType(req);
  }

  updateSportType(req: CreateSportTypeRequest, id: string): Observable<SportType> {
    return this.lookupApi.updateSportType(req, id);
  }

  refreshSportTypes() {
    this.lookupApi.getSportTypes().subscribe(s => {
      this.sportTypes.next(s);
    });
  }

}
