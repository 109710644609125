<app-detail-title
  [hrCustomClass]=" (viewModel.addNewEvent$|async) ? '' : 'd-none'"
  i18n>
  Events
</app-detail-title>
<app-content-container [narrow]="viewModel.addNewEvent$|async">
  <app-detail-back-button [backRoute]="'..'">
    <ng-container *ngIf="viewModel.addNewEvent$|async" i18n>Add a New Event</ng-container>
    <ng-container *ngIf="!(viewModel.addNewEvent$|async)" i18n>{{(viewModel.event$|async)?.name}}</ng-container>
  </app-detail-back-button>
  <app-tab-bar [class.hide-tab-header]="viewModel.addNewEvent$|async" [tabs]="tabs"></app-tab-bar>
</app-content-container>
