import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SearchVenuesBannerViewModel} from './search-venues-banner-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import AddressUtils from '../../../../utils/address-utils';
import {FormControl} from '@angular/forms';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  VenueStreamPickerModalComponent
} from '../../../shared/modals/venue-stream-picker-modal/venue-stream-picker-modal.component';
import {VenueStream} from '../../../../models/resources/venue-stream';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-search-venues-banner',
  templateUrl: './search-venues-banner.component.html',
  styleUrls: ['./search-venues-banner.component.scss'],
  providers: [SearchVenuesBannerViewModel],
})
export class SearchVenuesBannerComponent extends BaseComponent implements OnChanges{

  @Input() hydratedLeague: HydratedLeague;
  addressUtils = AddressUtils;

  searchFormControl = new FormControl();

  constructor(
    public viewModel: SearchVenuesBannerViewModel,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    this.searchFormControl.valueChanges.subscribe(v => {
      this.viewModel.searchText$.next(v);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.hydratedLeague) {
      this.viewModel.hydratedLeague$.next(this.hydratedLeague);
    }
  }

  searchResultClicked(result: HydratedVenue) {
    if (result.streams?.length === 1) {
      this.navigateToStream(result.streams[0]);
    } else {
      this.showVenueStreamPickerModal(result);
    }
  }

  showVenueStreamPickerModal(hydratedVenue: HydratedVenue) {
    const modalRef = this.modalService.open(
      VenueStreamPickerModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    (modalRef.componentInstance as VenueStreamPickerModalComponent).initWithHydratedVenue(hydratedVenue);
    modalRef.result.then(stream => {
      this.navigateToStream(stream);
    }, () => {

    });
  }

  navigateToStream(stream: VenueStream) {
    this.router.navigate([`/home/venues/stream/${stream.id}`]).then();
  }

  selectFirstResult() {
    this.viewModel.searchResults$.pipe((take(1))).subscribe(results => {
      if (results?.length > 0) {
        this.searchResultClicked(results[0]);
      }
    });
  }
}
