import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {TeamComponent} from './team.component';
import {TeamRoutingModule} from './team-routing.module';
import {HomeModule} from '../home/home.module';

@NgModule({
  declarations: [
    TeamComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TeamRoutingModule,
    HomeModule
  ]
})
export class TeamModule { }
