import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/models/base/base-modal';
import { Observable } from 'rxjs';
import { DateUtils } from 'src/app/utils/date-utils';
import { CsvUtils } from 'src/app/utils/csv-utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-program-uploader-modal',
  templateUrl: './program-uploader-modal.component.html',
  styleUrls: ['./program-uploader-modal.component.scss'],
})
export class ProgramUploaderModalComponent extends BaseModal  {

  public csvData$: Observable<string>;
  public leagueName: string;
  constructor(private activeModal: NgbActiveModal) {
    super();
  }
  setupViews() {
  }
  setupBindings() {
  }
  cancel() {
  }

  submit(){
  }
  initWithProgramDetails(csvData$: Observable<string>,leagueName: string){
     this.csvData$=csvData$;
     this.leagueName=leagueName;
  }
downloadCSV(){
    this.csvData$.subscribe(csvData=>{
      const fileNamePrefix=DateUtils.currentTimestamp().toString();
      CsvUtils.downloadCsvFile(csvData,`${this.leagueName}_ProgramErrorFile_${fileNamePrefix}.csv`);
    });
    this.activeModal.close();
  }


}
