import { Pipe, PipeTransform } from '@angular/core';
import {FavouriteResource} from '../../../models/protocols/favourite-resource';

@Pipe({
  name: 'favouriteTeam'
})
export class FavouriteTeamPipe implements PipeTransform {

  transform(items: FavouriteResource[], filterVal: string = ''): FavouriteResource[] {
    if (!items || !filterVal || filterVal?.length < 3 ) {
      return items;
    } else {
      return items.filter(item => item.getFavouriteResourceName().toLowerCase().includes(filterVal.toLowerCase()));
    }
  }

}
