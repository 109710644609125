import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Action} from '../../../../../models/shared/stylesheet/action';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent extends BaseComponent
  implements OnInit, AfterViewInit {

  @Input() action: Action;
  @Output() clicked = new EventEmitter<Action>();

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupViews() {
  }

  setupBindings() {
  }

}
