<label for="dropdown" class="custom-dropdown-label">{{labelText}}</label>
<select
  [disabled]="disabled"
  class="custom-dropdown"
  name="dropdown"
  id="dropdown"
  [(ngModel)]="currentSelection"
  (ngModelChange)="changed()">
  <option [hidden]="!hint" [ngValue]="hint" disabled hidden selected>{{ hint?.getSelectionTitle() }}</option>
  <option *ngFor="let drop of dropdowns" [ngValue]="drop">{{ drop.getSelectionTitle() }}</option>
</select>
