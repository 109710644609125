import { Component, OnInit } from '@angular/core';
import {TeamViewModel} from './team-view-model';
import {CardRowStyle} from '../shared/components/card-row/card-row.component';
import {Cardable} from '../../models/protocols/cardable';
import {Program} from '../../models/program/program';
import {Router} from '@angular/router';
import {CardBreakpoints} from '../../models/shared/card-breakpoints';
import {ProgramRowType} from '../home/components/program-row/program-row.component';
import {NoContentType} from '../home/components/no-content/no-content.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [TeamViewModel]
})
export class TeamComponent implements OnInit {

  noContentType = NoContentType;
  programRowType = ProgramRowType;

  constructor(
    public viewModel: TeamViewModel,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  seeAllPastGames = () => {
    this.router.navigate([`/see-all`], {queryParams: {teamId: this.viewModel.team$?.getValue()?.id}}).then();
  };

  goHome() {
    this.router.navigate([`/home`]).then();
  }
}
