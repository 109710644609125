import {BaseModel} from '../base/base-model';
import {Selectable} from '../protocols/selectable';
import {DatatableData} from '../protocols/datatable-data';
import {SearchListable} from '../protocols/search-listable';
import {SafeResourceUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {PricingOptions} from './pricing-options';

export class Plan extends BaseModel implements Selectable, DatatableData, SearchListable {
  id: number;
  name: string;
  internalName: string;
  externalName: string;
  description: string;
  status: number;
  category: number;
  isChargebeeUpdated: boolean;
  trialPeriod: number;
  pricingOptions: PricingOptions[] = [];

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  onDeserialize() {
    super.onDeserialize();
  }

  getSelectionTitle(): string {
    return this.name;
  }


  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getSearchListablePrimaryKey(): string {
    return this.name;
  }

  getSearchListableSecondaryKey(): string {
    return null;
  }

  getListItemSubtitle(): string {
    return null;
  }

  getListItemTitle(): string {
    return this.name;
  }

  getEnabledStatus(): number {
    return this.status;
  }

  getSearchListableTitle(): string {
    return this.name;
  }

  getSearchListableSubTitle(): string {
    return $localize`Plan`;
  }

  getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
    return null;
  }
}
