export class ItemPayment  {
    public item_id: string;
    public item_name: string;
    public currency: string;
    public item_brand: string;
    public item_category: string;
    public item_category2: string;
    public item_variant: string;
    public price: number;
    public quantity: number;
    public index: number;
}
