import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';

@Injectable()
export class PlansViewModel extends BaseViewModel {

  loadingOpts = LoadingOptions.defaultLight(false, false);

  constructor(private domainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
