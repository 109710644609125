import {Deserializable} from '../../protocols/deserializable';

export class EmailVerification implements Deserializable{
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';

  onDeserialize() {

  }
}
