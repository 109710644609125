import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AccountDomainModel} from '../../domainModels/account-domain-model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefreshSessionGuard implements CanActivate {

  constructor(
    private accountDomainModel: AccountDomainModel,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const observer = this.accountDomainModel.isAuthenticated().pipe(map(r => r == null));
    observer.subscribe(res => {
      if (!res) {
        this.router.navigate(['']).then();
      }
    });
    return observer;
  }
}
