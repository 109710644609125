import {Component, ViewChild} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HydratedTeam} from '../../../../models/resources/hydrated-team';
import {TeamFormObject} from '../../../../models/resources/team-form-object';
import {catchError, first, take} from 'rxjs/operators';
import {UploadImageModalComponent} from '../../../shared/components/upload-image-modal/upload-image-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {Observable, of} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ImageSize} from '../../../../models/enum/dto/image-size.enum';
import {ImageApi} from '../../../../api/image-api';
import {CustomFile} from '../../../../models/shared/custom-file';
import {CardImageFormObject} from '../../../../models/resources/card-image-form-object';
import {FormGroupComponent} from '../../../shared/components/form-group/form-group.component';
import {
  AlphanumericValidatorDirective
} from '../../../shared/components/form-group/validators/alphanumeric-validator.directive';
import {
  AlphanumericWithSpaceValidatorDirective
} from '../../../shared/components/form-group/validators/alphanumeric-with-space-validator.directive';

@Component({
  selector: 'app-edit-team-modal',
  templateUrl: './edit-team-modal.component.html',
  styleUrls: ['./edit-team-modal.component.scss']
})
export class EditTeamModalComponent extends BaseModal {

  @ViewChild('teamFormGroup') teamFormGroup: FormGroupComponent;
  public readonly bannerImageInputId = 1;
  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: TeamFormObject;
  isEditing: boolean = false;
  canRemoveTeamOnEdit: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private imageApi: ImageApi,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
  }

  initWithNewFormObject() {
    this.formObject = TeamFormObject.initWithTeam(new HydratedTeam());
    this.formObject.itemCreated = true;
  }

  initWithFormObject(teamFormObject: TeamFormObject) {
    this.isEditing = true;
    this.formObject = teamFormObject.getCopy();
    this.formObject.itemChanged = true;
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Team Name`;
    name.bindingProperty = 'team.name';
    name.required = true;
    name.customValidator = new AlphanumericWithSpaceValidatorDirective();
    items.push(name);

    const location = new FormInputItem();
    location.inputName = 'location';
    location.inputType = FormInputType.Text;
    location.label = $localize`Location`;
    location.placeholder = $localize`Team Location`;
    location.bindingProperty = 'team.location';
    location.required = false;
    items.push(location);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'team.active';
    active.customClass = 'mb-4 mt-0';
    active.overrideFullWidth = true;
    items.push(active);

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.tertiaryButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Teams_Edit_Team_Details_Edit) ?
        '' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Team` : $localize`Add New Team`;
  }

  deleteClicked() {
    this.formObject.team.itemDeleted = true;
    this.activeModal.close(this.formObject);
  }

  showEditPhotoModal() {
    this.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Crop Team Logo`,
        $localize`Crop Team Logo`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  getLogo(teamFormObject: TeamFormObject): Observable<string | SafeResourceUrl> {
    if (teamFormObject.imageToUpload) {
      return of(teamFormObject.imageToUpload);
    } else if (teamFormObject.existingImageId) {
      return this.imageApi.getHydratedTeamImage(teamFormObject.team, ImageSize.Original, true).pipe(catchError(err => {
        return of(null);
      }));
    } else {
      return of(null);
    }
  }

  removeBannerImageClicked() {
    if (this.formObject.existingBannerImageId) {
      this.formObject.deleteBannerImageId = this.formObject.existingBannerImageId;
      this.formObject.existingBannerImageId = null;
    }
    this.formObject.bannerImageToUpload = null;
  }

  fileList(f: CustomFile[], id: number) {
    if (id === this.bannerImageInputId) {
      this.handleBannerImageFiles(f);
    } else {
      this.handleCardImageFiles(f);
    }
  }

  handleBannerImageFiles(files: CustomFile[]) {
    if (files.length > 0) {
      this.formObject.bannerImageToUpload = files[0].url;
      this.formObject.bannerImageToUploadFormat = files[0].type;
    } else {
      this.formObject.bannerImageToUpload = undefined;
      this.formObject.bannerImageToUploadFormat = undefined;
    }
  }

  handleCardImageFiles(files: CustomFile[]) {
    files.forEach(f => {
      const cardImageFormObject = new CardImageFormObject();
      cardImageFormObject.imageToUpload = f.url;
      cardImageFormObject.imageToUploadFormat = f.type;
      this.formObject.cardImageFormObjects.push(cardImageFormObject);
    });
  }

  removeCardImgClicked(cardImageFormObject: CardImageFormObject) {
    if (cardImageFormObject.existingImageId) {
      cardImageFormObject.deleteImageId = cardImageFormObject.existingImageId;
      cardImageFormObject.existingImageId = null;
    }
    cardImageFormObject.imageToUpload = null;
    cardImageFormObject.imageToUploadFormat = null;
  }
}
