import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {ToastService} from '../../../services/toast-service';
import {Router} from '@angular/router';
import {indicate, indicateOnNext} from '../../../utils/observable.extensions';
import {SessionService} from '../../../services/session-service';
import {HydratedUser} from '../../../models/account/dto/hydrated-user';
import {DepartmentType} from '../../../models/lookup/department-type';
import {UsersDomainModel} from '../../../domainModels/users-domain-model';

@Injectable()
export class ProfileViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 200);
  fetchUserSubject = new BehaviorSubject<void>(undefined);
  user$ = combineLatest(
    [this.fetchUserSubject,
      this.domainModel.sessionContainer$.pipe(map(s => s?.user))
    ]).pipe(map(([, u]) => u));
  admins$ = this.userDomainModel.admins$.notNull().pipe(indicateOnNext(this.loadingOpts, $localize`Loading Admins`));

  constructor(private domainModel: AccountDomainModel,
              private toastService: ToastService,
              private sessionService: SessionService,
              private router: Router,
              private userDomainModel: UsersDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.userDomainModel.getAdmins();
  }

  updateUser(user: HydratedUser, department: DepartmentType) {
    user.department = department;
    this.domainModel.updateAdminUserProfile(user)
      .pipe(indicate(this.loadingOpts, $localize`Saving User`))
      .subscribe(() => {
        const remember = this.sessionService.sessionContainer.getValue()?.rememberSession;
        const userRole = this.sessionService.getRole();
        this.sessionService.setUser(user, userRole, false, remember);
        this.fetchUserSubject.next();
        this.toastService.publishSuccessMessage($localize`User Updated`, null);
      }, error => {
        this.toastService.publishError(error);
      });
  }

  getAdminUserById(id: number) {
    return this.domainModel.getAdminById(id);
  }
}
