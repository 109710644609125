<div class="w-100 position-relative" [hidden]="!(cards?.length > 0) && !loadingOpts.isLoading">
  <hr *ngIf="showTopDivider && cards?.length > 0 && !loadingOpts.isLoading">
  <div class="card-row-header">
    <div class="card-row-title">{{title}}</div>
    <div *ngIf="buttonTitle?.length" class="pb-4 pt-4 pr-4">
      <button [ngClass]="buttonClass" (click)="buttonClicked.emit()">{{buttonTitle}}</button>
    </div>
    <div class="card-pagination-container" *ngIf="cards?.length > 0">
      <img class="card-pagination-button mr-3"
           [src]="'assets/icons/dark/outline/arrow-left.svg'"
           [class.disabled]="backButtonDisabled$|async"
           alt=""
           (click)="previousPagClicked()">
      <img class="card-pagination-button"
           [src]="'assets/icons/dark/outline/arrow-right.svg'"
           [class.disabled]="nextButtonDisabled$|async"
           alt=""
           (click)="nextPageClicked()">
    </div>
  </div>
  <div class="position-relative min-height-10">
    <swiper
      #swiper
      [virtual]="true"
      [parallax]="true"
      [slidesPerView]="4"
      [slidesPerGroup]="4"

      [spaceBetween]="24"
      [allowTouchMove]="true"
      [class.text-below-card-swiper]="cardStyle === cardRowStyles.textBelowCard"
      [breakpoints]="breakpoints">
      <ng-template swiperSlide *ngFor="let card of cards">
        <div class="text-below-card-container"
             *ngIf="cardStyle === cardRowStyles.textBelowCard"
             (click)="cardClicked.emit(card); $event.stopPropagation()"
             mat-ripple>
          <div class="text-below-card">
            <img class="program-image" *ngIf="card.getCardImageSrc()|async; else noCardImg; let cardImg" [src]="cardImg"
                 alt="">
            <ng-template #noCardImg>
              <img class="program-image" [src]="'./assets/img/seats.jpg'" alt="">
            </ng-template>
          </div>
          <div class="text-below-card-content">
            <div class="card-category" *ngIf="card.getCardCategory()">{{card.getCardCategory()}}</div>
            <div class="card-short-description-light-background" *ngIf="card.getCardShortDescription()">{{card.getCardShortDescription()}} </div>
            <div class="card-title" *ngIf="card.getCardTitle()">{{card.getCardTitle()}}</div>
            <div class="card-description" *ngIf="card.getCardDescription()">{{card.getCardDescription()}}</div>
            <div class="card-metadata" *ngIf="card.getCardMetadata()">{{card.getCardMetadata()}}</div>
            <div class="card-cta" *ngIf="cardCtaText">{{cardCtaText}}</div>
          </div>
        </div>
        <div class="text-inside-card-container"
             *ngIf="cardStyle === cardRowStyles.textInsideCard"
             (click)="cardClicked.emit(card); $event.stopPropagation()"
             mat-ripple>
          <div class="text-inside-card">
            <img class="program-image" *ngIf="card.getCardImageSrc()|async; else noCardImg; let cardImg" [src]="cardImg"
                 alt="">
            <ng-template #noCardImg>
              <img class="program-image" [src]="'./assets/img/seats.jpg'" alt="">
            </ng-template>
            <div class="img-overlay">&nbsp;</div>
            <div class="text-inside-card-content">
              <div class="card-category">{{card.getCardCategory()}}</div>
              <div class="card-short-description">{{card.getCardShortDescription()}}</div>
              <div class="card-title">{{card.getCardTitle()}}</div>
              <div class="card-metadata">{{card.getCardMetadata()}}</div>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
    <app-loading class="row-loading" [options]="loadingOpts" [hidden]="!(loadingOpts?.isLoading) || !(fetchingMoreData$|async) || ((snapIndex$|async) + 1 < (snapGridLength$|async))">
    </app-loading>
  </div>
  <hr class="mt-4">
</div>
