import {ProgramFormObject} from './program-form-object';
import {DatatableData} from '../protocols/datatable-data';

export class ViewableProgramFormObject extends ProgramFormObject implements DatatableData{
  public homeTeamName: string;
  public visitingTeamName: string;
  public venueName: string;
  public productionType: string;
  public error: string;

  constructor(programFormObject: ProgramFormObject) {
    super();
    this.program = programFormObject.program;
    this.programDate = programFormObject.programDate;
    this.programTime = programFormObject.programTime;
    this.programTimeZone = programFormObject.programTimeZone;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return 'white';
  }

  getTextColor(): string {
    return 'black';
  }

}
