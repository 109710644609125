import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiClient} from './api-client';
import {Observable, throwError} from 'rxjs';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {LoggingService} from '../services/logging-service';
import {CameraSystem} from '../models/resources/camera-system';
import {VideoUpload} from '../models/image/dto/video-upload';
import { CameraStream } from '../models/resources/camera-stream';

@Injectable({
  providedIn: 'root'
})
export class CameraSystemApi {

  public serviceName = 'CameraSystem';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }


  public createCameraSystem(cameraSystem: CameraSystem): Observable<CameraSystem> {
    const url = Endpoints.createCameraSystem();
    return this.apiClient.postObj(CameraSystem, url, cameraSystem).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createCameraSystem', err));
        return throwError(err);
      })
    );
  }

  public updateCameraSystem(cameraSystemId: string, req: CameraSystem): Observable<CameraSystem> {
    const url = Endpoints.updateCameraSystem(cameraSystemId);
    return this.apiClient.putObj(CameraSystem, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateCameraSystem', err));
        return throwError(err);
      })
    );
  }

  public getCameraSystem(): Observable<CameraSystem[]> {
    const url = Endpoints.getAllCameraSystems();
    return this.apiClient.getArr(CameraSystem, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getCameraSystem', err));
        return throwError(err);
      })
    );
  }

  public getCameraSystemById(cameraSystemId: string): Observable<CameraSystem> {
    return this.apiClient.getObj(CameraSystem, Endpoints.getCameraSystemById(cameraSystemId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getCameraSystemById', err));
        return throwError(err);
      })
    );
  }

  public getCameraSystemTypeConfigurationsById(cameraSystemTypeId: string): Observable<CameraSystem> {
    return this.apiClient.getObj(CameraSystem, Endpoints.getCameraSystemTypeConfigurationsById(cameraSystemTypeId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getCameraSystemTypeConfigurationsById', err));
        return throwError(err);
      })
    );
  }

  public updateCameraSystemTypeConfigurations(cameraSystemTypeId: string, req: any): Observable<CameraSystem> {
    const formData = new FormData();
    formData.append('InputFile', req);
    const url = Endpoints.updateCameraSystemTypeConfigurations(cameraSystemTypeId);
    return this.apiClient.putFormData(CameraSystem, url, formData).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'uploadConfigFile', err));
        return throwError(err);
      })
    );
  }

  public getCameraStreams(isLive: boolean): Observable<CameraStream[]> {
    const url = Endpoints.getAllCameraStreamDetails();
    let params = new HttpParams();
    if (isLive) {
      params = params.set('isLive', true);
    }
    else{
      params=params.set('isLive',false);
    }
    return this.apiClient.recursiveGetArr(CameraStream, url, null,params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getCameraSystem', err));
        return throwError(err);
      })
    );
  }
}
