import {Component, Input, OnInit} from '@angular/core';
import {Cardable} from '../../../../../../models/protocols/cardable';
import {HydratedProgram} from '../../../../../../models/program/hydrated-program';
import {filter, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, EMPTY, of} from 'rxjs';
import {MediaUtils} from '../../../../../../utils/media-utils';
import {ResourceDomainModel} from '../../../../../../domainModels/resource-domain-model';
import {Router} from '@angular/router';
import {ProgramStatusType} from '../../../../../../models/lookup/program-status-type';

@Component({
  selector: 'app-featured-program-slide',
  templateUrl: './featured-program-slide.component.html',
  styleUrls: ['./featured-program-slide.component.scss']
})
export class FeaturedProgramSlideComponent implements OnInit {
  @Input() hydratedProgram: HydratedProgram = null;
  hydratedProgram$ = new BehaviorSubject<HydratedProgram>(null);

  featureProgramHomeTeamImgSrc$ = combineLatest(
    [this.hydratedProgram$, this.resourceDomainModel.activeHydratedTeams$]
  ).pipe(
    filter(values => values.every(v => !!v)),
    switchMap(([program, teams]) => {
      const hydrateHomeTeam = teams.find(t => t.id === program.homeTeamId);
      if (hydrateHomeTeam?.bannerImages?.length > 0) {
        return hydrateHomeTeam.bannerImgSrc$;
      } else if (program.images?.length > 0) {
        return program.imgSrc$;
      } else if (hydrateHomeTeam?.cardImages.length > 0) {
        const randomCardImageId = hydrateHomeTeam.cardImages.random()?.id;
        return !!randomCardImageId ? hydrateHomeTeam.cardImgSrcMap.get(randomCardImageId) : EMPTY;
      } else {
        return of(MediaUtils.getRandomBannerImageSrc());
      }
    }),
  );

  constructor(private resourceDomainModel: ResourceDomainModel,
              public router: Router,) {
  }

  featureWatchNow() {
    this.router.navigate([`/home/${this.hydratedProgram.id}`]).then();
  }

  isLive() {
    return this.hydratedProgram.programStatusId === ProgramStatusType.InProgressId;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.hydratedProgram$.next(this.hydratedProgram);
    });
  }
}
