import {AuthFlow, SubscriberSignUpFlow} from './enum/auth-flow.enum';
import {LoginType} from '../../views/shared/modals/auth-modal/auth-modal-view-model';

export class OpenAuthModalOptions {
  authFlow: AuthFlow;
  completionRoute: string;
  signInTitle: string;
  signUpTitle: string;
  loginType: LoginType = LoginType.Subscriber;
  subscriptionPlanId: string;
  subAuthFlow: SubscriberSignUpFlow = null;

  constructor(
    authFlow: AuthFlow,
    completionRoute?: string,
    signInTitle?: string,
    signUpTitle?: string,
    subscriptionPlanId?: string,
    subAuthFlow?: SubscriberSignUpFlow) {
    this.authFlow = authFlow;
    this.completionRoute = completionRoute;
    this.signInTitle = signInTitle;
    this.signUpTitle = signUpTitle;
    this.subscriptionPlanId = subscriptionPlanId;
    this.subAuthFlow = subAuthFlow;
  }
}
