import {Type} from '@angular/core';
import {Tabbable} from '../../protocols/tabbable';

export class TabBarItem {
  public id: number;
  public active: boolean = false;
  public title: string;
  public iconSrc: string;
  public componentType: Type<Tabbable>;

  constructor(componentType: Type<Tabbable>, id: number, title, iconSrc: string) {
    this.id = id;
    this.title = title;
    this.iconSrc = iconSrc;
    this.componentType = componentType;
  }
}
