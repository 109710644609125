<div [ngClass]="{ 'selected' : card.selected }"
     class="card custom-card custom-theme-full-preview-card {{ getCardClasses() }}">
  <div class="custom-theme-carousel-preview-card-wrapper">
    <!--  Theme Preview  -->
    <div [ngClass]="{ 'placeholder': !card.imageSrc }"
         [ngStyle]="{'background-image': card.getBackgroundUrl() }"
         class="custom-theme-preview-image">
    </div>
  </div>
</div>
