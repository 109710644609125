import {Component} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageApi} from '../../../../api/image-api';
import {CustomFile} from '../../../../models/shared/custom-file';
import {BannerAdvertisementFormObject} from '../../../../models/resources/banner-ad-form-object';
import {debounceTime} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {BannerAdvertisement} from '../../../../models/resources/banner-advertisement';

@Component({
  selector: 'app-edit-banner-advertisement-modal',
  templateUrl: './edit-banner-advertisement-modal.component.html',
  styleUrls: ['./edit-banner-advertisement-modal.component.scss']
})
export class EditBannerAdvertisementModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: BannerAdvertisementFormObject;
  isEditing: boolean = false;
  formErrorMessage = new BehaviorSubject<string>(null);

  constructor(
    private activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private imageApi: ImageApi,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.formErrorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

  }

  initWithNewFormObject() {
    this.formObject = BannerAdvertisementFormObject.initWithBannerAdvertisement(new BannerAdvertisement());
    this.formObject.itemCreated = true;
  }

  initWithFormObject(bannerAdFormObject: BannerAdvertisementFormObject) {
    this.isEditing = true;
    this.formObject = bannerAdFormObject.getCopy();
    this.formObject.itemChanged = true;
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Banner Name`;
    name.bindingProperty = 'bannerAd.advertisementName';
    name.required = true;
    name.enabled = !this.isEditing;
    items.push(name);

    const adUrl = new FormInputItem();
    adUrl.inputName = 'bannerAdUrl';
    adUrl.inputType = FormInputType.Text;
    adUrl.label = $localize`Destination URL`;
    adUrl.placeholder = $localize`https://www.example.com`;
    adUrl.bindingProperty = 'bannerAd.advertisementUrl';
    adUrl.required = true;
    adUrl.enabled = !this.isEditing;
    items.push(adUrl);

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    if (this.isEditing) {
      this.formStyling.secondaryButtonClass = 'secondary-button text-link-button ml-0 mr-auto px-0';
      this.formStyling.secondaryButtonText = $localize`Remove Banner Ad`;
    } else {
      this.formStyling.submitButtonText = $localize`Save`;
    }
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    if (!this.formObject.imageToUpload && !this.formObject.existingImageId) {
      this.formErrorMessage.next($localize`Banner image is required.`);
      return;
    }
    this.activeModal.close(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Banner Advertisement` : $localize`Create New Banner Advertisement`;
  }

  deleteClicked() {
    this.formObject.itemDeleted = true;
    this.formObject.deleteImageId = this.formObject.existingImageId;
    this.activeModal.close(this.formObject);
  }

  removeImageClicked() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  fileList(f: CustomFile[], id: number) {
    if (f.length > 0) {
      this.formObject.imageToUpload = f[0].url;
      this.formObject.imageToUploadFormat = f[0].type;
    } else {
      this.formObject.imageToUpload = undefined;
      this.formObject.imageToUploadFormat = undefined;
    }
  }
}
