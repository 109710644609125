<div class="modal-body">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <div class="d-flex justify-content-between">
    <div class="modal-title" i18n>Add Team</div>
    <div
      class="text-link-button"
      [hidden]="!PermissionManagerService.isGranted(Types.AdminSections.Leagues_Edit_League_Teams_Add_Team_To_League || Types.AdminSections.Venues_Edit_Venue_Teams_Add_Team_To_Venue || Types.AdminSections.Events_Edit_Event_Teams_Add_Team_To_Event)"
      (click)="createNewTeamClicked()"
      i18n>
      Create New Team
    </div>
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (formSubmitted)="formSubmitted()">
  </app-form-group>
</div>
