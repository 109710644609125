import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {UsersDomainModel} from '../../../../domainModels/users-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {User} from '../../../../models/account/dto/user';
import {CreateAdminRequest} from '../../../../models/account/requests/create-admin-request';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';


@Injectable()
export class AdminUsersViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 400);
  admins$ = this.domainModel.admins$.notNull().pipe(indicateOnNext(this.loadingOpts, $localize`Loading Admins`));
  addNewUser$ = this.activatedRoute.data.pipe(map(data => data.addUser as boolean));
  public departmentTypes$ = this.lookupDomainModel.departmentTypes;

  constructor(private domainModel: UsersDomainModel,
              private accountDomainModel: AccountDomainModel,
              private toastService: ToastService,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              private lookupDomainModel: LookupDomainModel,) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
    this.domainModel.getAdmins();
  }

  setupBindings() {

  }

  updateAdmin(req: User) {
    this.accountDomainModel.updateAdmin(req).subscribe(() => {
      this.toastService.publishSuccessMessage($localize`User Updated Successfully`, null);
    }, error => {
      this.toastService.publishError(error);
    });
  }

  updateAdminRequest(req: CreateAdminRequest) {
    this.accountDomainModel.updateAdminRequest(req).subscribe(() => {
      this.toastService.publishSuccessMessage($localize`User Updated Successfully`, null);
      this.router.navigate(['admin/users']);
    }, error => {
      this.toastService.publishError(error);
    });
  }

  createAdmin(req: CreateAdminRequest) {
    this.accountDomainModel.createAdmin(req).subscribe(u => {
      this.toastService.publishSuccessMessage($localize`An email has been sent to ${req.email}`, $localize`Admin Account Created`);
    }, error => {
      this.toastService.publishError(error);
    });
  }

  getAdminById(id: number): Observable<CreateAdminRequest> {
    return this.accountDomainModel.getAdminById(id).
    pipe(indicateOnNext(this.loadingOpts, $localize`Loading Users`));
  }
}
