import {Deserializable} from '../protocols/deserializable';
import {Selectable} from '../protocols/selectable';
import {FormListable} from '../protocols/form-listable';
import {Venue} from './venue';
import {Team} from './team';

export class VenueId implements Deserializable, Selectable, FormListable {
  id: string;
  venueId: number;
  venue: string;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  constructor(venue: Venue = null) {
    this.venueId = venue?.id;
    this.venue = venue?.name;
  }

  static initWithVenue(venue: Venue): VenueId {
    const v = new VenueId();
    v.venueId = venue.id;
    v.venue = venue.name;
    return v;
  }

  onDeserialize() {
  }

  // Selectable
  getSelectionTitle(): string {
    return this.venue;
  }

  getSelectionUniqueIdentifier(): any {
    return this.venueId;
  }

  getSelectionValue(): any {
    return this.venueId;
  }

  // FormListable
  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return '';
  }

  getListItemTitle(): string {
    return this.venue;
  }
}
