import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LeagueSubscribeBannerViewModel} from './league-subscribe-banner-view-model.service';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';

@Component({
  selector: 'app-league-subscribe-banner',
  templateUrl: './league-subscribe-banner.component.html',
  styleUrls: ['./league-subscribe-banner.component.scss'],
  providers: [LeagueSubscribeBannerViewModel],
})
export class LeagueSubscribeBannerComponent extends BaseComponent implements OnChanges{

  @Input() hydratedLeague: HydratedLeague;

  constructor(
    public viewModel: LeagueSubscribeBannerViewModel,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.hydratedLeague) {
      this.viewModel.hydratedLeague$.next(this.hydratedLeague);
    }
  }

  public subscribeClicked() {
    this.viewModel.subscribeClicked();
  }
}
