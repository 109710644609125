import {Injectable} from '@angular/core';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {Plan} from '../models/resources/plan';
import {Endpoints} from './endpoints';
import {catchError, tap} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {HttpParams} from '@angular/common/http';
import {HydratedTeam} from '../models/resources/hydrated-team';

@Injectable({
  providedIn: 'root',
})
export class PlanApi implements LoggableAPI {
  // Variables
  public serviceName = 'Plan';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public getPlans(activeOnly: boolean): Observable<Plan[]> {
    let params = new HttpParams();
    if (activeOnly) {
      params = params.set('active', true);
    }
    return this.apiClient.getArr(Plan, Endpoints.getPlans()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPlans', err));
        return throwError(err);
      })
    );
  }

  public getPlanById(planId: number): Observable<Plan> {
    return this.apiClient.getObj(Plan, Endpoints.getPlanById(planId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPlanById', err));
        return throwError(err);
      })
    );
  }

  public createPlan(req: Plan): Observable<Plan> {
    return this.apiClient.postObj(Plan, Endpoints.createPlan(), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createPlan', err));
        return throwError(err);
      })
    );
  }

  public updatePlan(planId: number, req: Plan): Observable<Plan> {
    return this.apiClient.putObj<Plan>(Plan, Endpoints.updatePlan(planId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updatePlan', err));
        return throwError(err);
      })
    );
  }
}
