import {LeagueId} from '../../resources/leagueId';
import {TeamId} from '../../resources/teamId';
import {Address} from '../../location/address';

export class SignUpRequest {
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public enabled: boolean = true;
  public password: string = '';
  public confirmPassword: string = '';
  public favouriteLeagues: LeagueId[];
  public favouriteTeams: TeamId[];
  public address: Address = new Address();
  public countryOfResidence: number = null;
}
