import {Component, Input} from '@angular/core';
import {NoContentViewModel} from './no-content-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
  providers: [NoContentViewModel],
})
export class NoContentComponent extends BaseComponent {
  noContentType = NoContentType;
  @Input() type: NoContentType;

  constructor(
    public viewModel: NoContentViewModel,
    public router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  ctaClicked() {
    switch (this.type) {
      case NoContentType.Favourites:
        this.router.navigate(['/account'], {fragment: 'preferences'});
        break;
      case NoContentType.Team:
      case NoContentType.VenueStream:
        this.router.navigate(['/home']);
        break;
    }
  }
}

export enum NoContentType {
  Favourites,
  Team,
  VenueStream,
  League,
  Event
}
