import {Directive} from '@angular/core';
import {FormValidator} from '../../../../../models/protocols/form-validator';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';

export const confirmationCodeValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (!value || value === '') {
      return null;
    }
    // validate length
    if (value.length < 6 || value.length > 6) {
      return {confirmationCode: 'Codes are 6 characters long.'};
    }
    return null;
  };
};


@Directive({
  selector: '[appConfirmationCodeValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: ConfirmationCodeValidatorDirective, multi: true}]
})
export class ConfirmationCodeValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } {
    return confirmationCodeValidator()(control);
  }

  errorName(): string {
    return 'confirmationCode';
  }
}
