import { DataTableComponent } from 'src/app/views/shared/components/data-table/data-table.component';
import { BaseModal } from '../base/base-modal';
import { DatatableData } from '../protocols/datatable-data';
import { SearchListable } from '../protocols/search-listable';
import { Selectable } from '../protocols/selectable';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseModel } from '../base/base-model';

export class CameraStream extends BaseModel implements DatatableData, SearchListable, Selectable{
    cameraName: string;
    client: string;
    venue: string;
    status: string;
    amazonPlaybackStreamUrl: string;
    startDate: string;
    productionType: string;
    startDateUtc: Date;
    endDateUtc: Date;

    lastUpdatedUtc: Date;
    errorText: string;
    lastStreamEnded: Date;



    getChildrenUniqueIds(): string[] {
        return [];
    }
    getColor(): string {
        return '';
    }
    getTextColor(): string {
        return '';
    }
    getSearchListableTitle(): string {
       return this.cameraName;
    }
    getSearchListableSubTitle(): string {
        return $localize`Camera Stream`;
    }
    getSearchListablePrimaryKey(): string {
       return this.cameraName;
    }
    getSearchListableSecondaryKey(): string {
        return '';
    }
    getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
        return null;
    }
    getSelectionTitle(): string {
        return this.cameraName;
    }
    getSelectionValue() {
        return this.cameraName;
    }
    getSelectionUniqueIdentifier() {
        throw this.cameraName;
    }

}
