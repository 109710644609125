import {BaseLookupType} from '../base/base-lookup-type';
import {Selectable} from '../protocols/selectable';

export class PlanType extends BaseLookupType implements Selectable {

    constructor(public name: string, public id: number) {
        super();
    }

    getSelectionTitle(): string {
        return this.name;
    }

    getSelectionUniqueIdentifier(): any {
        return this.id;
    }

    getSelectionValue(): any {
        return this.id;
    }
}
