<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Feedback Page</title>
  <link rel="stylesheet" href="feedback-landing.component.scss">
</head>
<body>
<div class="feedback-container">
  <div class="logo-container">
    <img src="assets/logo/dark/logo-stroke.svg" alt="Company Logo" style="padding: 10px 0;">
  </div>
  <h1 class="left-align">Feedback Submitted</h1>
  <p class="left-align">Thank you! Your comments have been recorded.</p>
</div>
</body>
