<div class="feedback-container">
  <div class="feedback-title-container">
    <div class="title-tiny" i18n>Feedback</div>
    <div class="text-link-button"
         (click)="closeButtonClicked.emit()"
         i18n>Close
    </div>
  </div>
  <div class="comments-container" *ngIf="program?.comments?.length > 0">
    <div *ngFor="let comment of sortedComments()">
      <div class="feedback-author">{{comment.createdBy}}</div>
      <div class="feedback-comment">{{comment.comment}}</div>
      <hr>
    </div>
  </div>
  <div class="no-comments-container"
       [hidden]="program?.comments?.length > 0"
       i18n>
    There is currently no feedback for this program.
  </div>
</div>
