import {Component, EventEmitter} from '@angular/core';
import {LeaguesViewModel} from './leagues-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {HydratedLeague} from '../../../models/resources/hydrated-league';

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss'],
  providers: [LeaguesViewModel],
})
export class LeaguesComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: LeaguesViewModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.leagues$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  rowClicked(data: DatatableData) {
    const league = data as HydratedLeague;
    this.router.navigate([`./${league.id}`], {relativeTo: this.activatedRoute}).then();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const leagueName = new DatatableColumn(
      'leagueName',
      $localize`Name`,
      DatatableColumnType.Text,
      (l: HydratedLeague): string => {
        return l.name;
      }
    );
    leagueName.isDefaultSortColumn = true;
    columns.push(leagueName);

    const teams = new DatatableColumn(
      'teams',
      $localize`Teams`,
      DatatableColumnType.Text,
      (l: HydratedLeague): string => {
        return `${l.associatedTeams?.length ?? 0}`;
      }
    );
    columns.push(teams);

    const activeLeague = new DatatableColumn(
      'activeLeague',
      $localize`Status`,
      DatatableColumnType.Text,
      (l: HydratedLeague): string => {
        return l.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    activeLeague.className = 'custom-datatable-header text-right mr-2';
    activeLeague.headerClassName = 'custom-datatable-header text-right mr-2';
    activeLeague.columnFilter = this.getStatusColumnFilter();
    columns.push(activeLeague);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  addNewLeague() {
    this.router.navigate([`./add`], {relativeTo: this.activatedRoute}).then();
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
}
