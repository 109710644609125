import {BaseComponent} from './base-component';
import {HostListener, Injectable} from '@angular/core';

@Injectable()
export abstract class BaseModal extends BaseComponent {

  @HostListener('document:keydown', ['$event'])

  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  abstract cancel();

}
