<div class="search-banner-container">
  <app-content-container [unsetMinHeight]="true">
    <div class="row">
      <div class="col-12 col-lg-8">
        <input
          [formControl]="searchFormControl"
          (keyup.enter)="selectFirstResult()"
          class="search-venues-input"
          type="text"
          placeholder="Search Venues...">
        <div class="position-relative">
          <div class="search-venues-results-container"
               [hidden]="!(viewModel.noResults$|async) && !((viewModel.searchResults$|async)?.length > 0)">
            <div class="search-venue-no-results"
                 *ngIf="(viewModel.noResults$|async)">
              <div class="result-title" i18n>
                No Results
              </div>
            </div>
            <div class="search-venue-result"
                 *ngFor="let result of viewModel.searchResults$|async"
                 (click)="searchResultClicked(result)">
              <div class="result-title">
                {{result.getSearchListableTitle()}}
              </div>
              <div class="result-subtitle">
                {{addressUtils.cityStateString(result.address)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-content-container>
</div>
