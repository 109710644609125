import {Component} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {VenueDetailsViewModel} from './venue-details-view-model';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {EditVenueComponent} from './edit-venue/edit-venue.component';
import {VenueProgramsComponent} from './venue-programs/venue-programs.component';
import {VenueStreamsComponent} from './venue-streams/venue-streams.component';
import {VenueTeamsComponent} from './venue-teams/venue-teams.component';
import {ActivationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-venue-details',
  templateUrl: './venue-details.component.html',
  styleUrls: ['./venue-details.component.scss'],
  providers: [VenueDetailsViewModel]
})
export class VenueDetailsComponent extends BaseComponent {

  tabs: TabBarItem[] = [];

  constructor(
    public viewModel: VenueDetailsViewModel,
    private router: Router,
  ) {
    super();
  }

  setupBindings() {
    this.router.events.subscribe(ev => {
      if (ev instanceof ActivationEnd && Object.is(ev?.snapshot?.component, VenueDetailsComponent)) {
        this.viewModel.refreshVenuePrograms$.next();
      }
    });
  }

  setupViews() {
    this.setupTabBar();
  }

  setupTabBar() {
    this.tabs = [];
    let id = 1;
    const details = new TabBarItem(EditVenueComponent, id++, $localize`Details`, '');
    details.active = true;
    const streams = new TabBarItem(VenueStreamsComponent, id++, $localize`Streams`, '');
    const teams = new TabBarItem(VenueTeamsComponent, id++, $localize`Teams`, '');
    const programs = new TabBarItem(VenueProgramsComponent, id++, $localize`Programs`, '');
    this.tabs.push(...[details, streams, teams, programs]);
  }
}
