import {User} from './user';
import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {SubscriberSubscription} from './subscriber-subscription';

export class HydratedSubscriber extends User implements Deserializable {
  public subscriptions: SubscriberSubscription[];

  public onDeserialize() {
    super.onDeserialize();
    this.subscriptions = DeserializeHelper.deserializeArray(SubscriberSubscription, this.subscriptions);
  }
}
