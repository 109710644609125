import {Component, ViewEncapsulation} from '@angular/core';
import {HomeViewModel} from './home-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ActivatedRoute, Router} from '@angular/router';
import {ProgramRowType} from '../program-row/program-row.component';
import {NoContentType} from '../no-content/no-content.component';
import {AuthFlow, SubscriberSignUpFlow} from '../../../../models/account/enum/auth-flow.enum';
import {LoginType} from '../../../shared/modals/auth-modal/auth-modal-view-model';
import {AuthModalComponent} from '../../../shared/modals/auth-modal/auth-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {OpenAuthModalOptions} from '../../../../models/account/open-auth-modal-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeViewModel],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends BaseComponent {

  noContentType = NoContentType;
  programRowType = ProgramRowType;

  constructor(
    public viewModel: HomeViewModel,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    this.parseAuthFlow();
  }

  featureWatchNow() {
    this.router.navigate([`/home/${this.viewModel.featureProgram$.value.id}`]).then();
  }

  featureStartFromBeginning() {
    this.router.navigate([`/home/${this.viewModel.featureProgram$.value.id}`], {queryParams: {startFromBeginning: true}}).then();
  }


  parseAuthFlow() {
    this.activatedRoute.url.firstNotNull().subscribe((url) => {
      let authFlow: AuthFlow = null;
      let subAuthFlow: SubscriberSignUpFlow = null;
      const loginType: LoginType = LoginType.Subscriber;
      switch (url.last().path) {
        case 'verifytoken': {
          authFlow = AuthFlow.SignUp;
          subAuthFlow = SubscriberSignUpFlow.CreateProfile;
          break;
        }
      }

      if (!!authFlow || !!subAuthFlow) {
        this.openAuthModal(authFlow, loginType, subAuthFlow);
      }
    }).addTo(this.subscriptions);
  }

  openAuthModal(initialAuthFlow: AuthFlow, loginType: LoginType, subAuthFlow: SubscriberSignUpFlow): void {
    const modalRef = this.modalService.open(AuthModalComponent, ModalUtils.authModalOptions(true));
    const compInstance = modalRef.componentInstance as AuthModalComponent;
    const options = new OpenAuthModalOptions(initialAuthFlow,
      null,
      $localize`Sign in to continue`,
      $localize`Sign up to continue`,
      null,
      subAuthFlow);
    options.loginType = loginType;
    compInstance.initWithOptions(options);
    modalRef.result.then(() => {
    }, () => {
      this.router.navigate(['']);
    });
  }
}
