<div class="single-selection-container">
  <div (click)="handleSelection(item)"
       *ngFor="let item of items; let i = index;"
       [ngClass]="{ 'disabled' : item.disabled,
        'selected': item.selected}"
       class="single-selection-item">

    <div class="single-selection-icon">
      <img [src]="item.iconSrc" alt="icon">
    </div>

    <div class="single-selection-text-container">
      <div class="single-selection-title ellipsis-nowrap-text">
        {{ item.title }}
      </div>
      <div class="single-selection-body">
        {{ item.body }}
      </div>
    </div>

    <div class="single-selection-radio">
      <div [ngClass]="{'disabled' : item.disabled}"
           class="custom-radio-option">
        <input [checked]="item.selected" [disabled]="item.disabled" class="custom-radio" id="single-selection-{{sid}}-{{i}}"
               name="single-selection-{{sid}}-{{i}}"
               type="radio">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="single-selection-{{sid}}-{{i}}" style="display: none"></label>
      </div>
    </div>
  </div>
</div>
