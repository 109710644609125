import {Component, EventEmitter, OnInit} from '@angular/core';
import {VenuesViewModel} from './venues-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {Venue} from '../../../models/resources/venue';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
  providers: [VenuesViewModel]
})
export class VenuesComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetUsersTable = new EventEmitter();
  public updateVenuesTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: VenuesViewModel,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.venues$.notNull().subscribe(v => {
      const dataProvider = new BaseDatatableDataProvider();
      dataProvider.data = v;
      this.updateVenuesTableData.next(dataProvider);
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];

    const venueName = new DatatableColumn(
      'venueName',
      $localize`Venue Name`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.name;
      }
    );
    venueName.isDefaultSortColumn = true;
    columns.push(venueName);

    const venueAddress = new DatatableColumn(
      'venueAddress',
      $localize`Address`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.address.getAddress();
      }
    );
    columns.push(venueAddress);

    const venueLocation = new DatatableColumn(
      'venueLocation',
      $localize`Location`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.address.city;
      }
    );
    columns.push(venueLocation);

    const venuePostalCode = new DatatableColumn(
      'venuePostalCode',
      $localize`Postal Code`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.address.postalCode;
      }
    );
    columns.push(venuePostalCode);

    const venueProvince = new DatatableColumn(
      'venueProvince',
      $localize`Province`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.address.state;
      }
    );
    columns.push(venueProvince);

    const venueCountry = new DatatableColumn(
      'venueCountry',
      $localize`Country`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.address.country;
      }
    );
    columns.push(venueCountry);

    const venueActive = new DatatableColumn(
      'venueActive',
      $localize`Status`,
      DatatableColumnType.Text,
      (v: Venue): string => {
        return v.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    venueActive.className = 'custom-datatable-header text-right mr-2';
    venueActive.headerClassName = 'custom-datatable-header text-right mr-2';
    venueActive.columnFilter = this.getStatusColumnFilter();
    columns.push(venueActive);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  venueClicked(data: DatatableData) {
    this.viewModel.venueClicked(data as Venue);
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }
}
