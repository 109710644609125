import {Component} from '@angular/core';
import {EditAdvertisingPartnerModalViewModel} from './edit-advertising-partner-modal-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdvertisingPartner} from '../../../../models/resources/advertising-partner';

@Component({
  selector: 'app-edit-advertising-partner-modal',
  templateUrl: './edit-advertising-partner-modal.component.html',
  styleUrls: ['./edit-advertising-partner-modal.component.scss'],
  providers: [EditAdvertisingPartnerModalViewModel],
})
export class EditAdvertisingPartnerModalComponent extends BaseComponent {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: AdvertisingPartner = new AdvertisingPartner();

  isEditing: boolean = false;

  constructor(
    public viewModel: EditAdvertisingPartnerModalViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Name`;
    name.bindingProperty = 'name';
    name.required = true;
    items.push(name);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Advertising Partner` : $localize`Create Advertising Partner`;
  }
}
