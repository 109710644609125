<div>
    <swiper
      [pagination]="{ clickable: true }"
      [virtual]="true"
      [allowTouchMove]="true"
      [autoplay]="{delay: 10000, disableOnInteraction: false}">
      <ng-template swiperSlide *ngFor="let p of hydratedPrograms">
        <app-featured-program-slide [hydratedProgram]="p">
        </app-featured-program-slide>
      </ng-template>
    </swiper>
</div>
