import {Component, EventEmitter, Output} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {ProgramFormObject} from '../../../../models/program/program-form-object';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, filter, map, skip, take} from 'rxjs/operators';
import {CustomFile} from '../../../../models/shared/custom-file';
import {ProductionType} from '../../../../models/lookup/production-type';
import {HydratedTeam} from '../../../../models/resources/hydrated-team';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProgramUploaderViewModel} from './program-uploader-view-model';
import {Program} from '../../../../models/program/program';
import {CsvUtils} from '../../../../utils/csv-utils';
import {UploadImageInterface} from '../../../shared/components/upload-asset/upload-image-interface';
import {combineLatest, forkJoin, Observable} from 'rxjs';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {VenueStream} from '../../../../models/resources/venue-stream';
import {ToastService} from '../../../../services/toast-service';
import {ViewableProgramFormObject} from '../../../../models/program/viewable-program-form-object';
import {BaseDatatableDataProvider} from '../../../../models/base/base-datatable-data-provider';
import {DatatableOptions} from '../../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../../models/shared/datatable-filter';
import {DatatableColumn, DatatableColumnType} from '../../../../models/shared/stylesheet/datatable-column';
import {DateUtils} from '../../../../utils/date-utils';
import { ProgramUploaderModalComponent } from '../program-uploader-modal/program-uploader-modal.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-program-uploader',
  templateUrl: './program-uploader.component.html',
  styleUrls: ['./program-uploader.component.scss'],
  providers: [ProgramUploaderViewModel],
})
export class ProgramUploaderComponent extends BaseComponent implements UploadImageInterface {

  public formItems: FormInputItem[] = [];
  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public resetTable = new EventEmitter();
  public tableFilterChanged = new EventEmitter();
  public formStyling = new FormGroupStyling();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public formOptions = new FormOptions();
  public formObject: ProgramFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  public errorMessage;
  private canBeSubmitted: boolean;
  private performSilentUpdate = false;
  public viewingError: string = '';
  public leagueAbbrevation: string='';
programFormObjectsClone: any[] = [];


  constructor(
    public viewModel: ProgramUploaderViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super();
    this.datatableFilter.enableSearch = true;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.noResultsForFiltersTitle = '';
    this.datatableFilter.noResultsForFilterSubtitle = '';
  }

  setupViews() {
    this.setupDatatable();
  }

  setupBindings() {

  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
  }

  formSubmitted(formatData: ProgramFormObject) {
    this.viewModel.saveProgram(formatData,0).subscribe(result=>{
      if(Array.isArray(result)){
        this.programSuccess();
      }
      else{
        this.toastService.publishErrorMessage($localize`Program saving failed`, result?.error.message);
      }

    });
  }

  cancel() {
    this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
  }

  uploadProgram() {
    if(this.viewModel.programFormObjects.length===0 && this.canBeSubmitted){
      this.downloadCSV(this.programFormObjectsClone[0]);
    }
    else{
      if (this.canBeSubmitted) {
        const saveRequests$ = this.viewModel.programFormObjects.map((programFormObject ,index)=> {
          return this.viewModel.saveProgram(programFormObject,index+1);//adding +1 to start from 1 instead of 0
        });
        forkJoin(saveRequests$).subscribe(results => {
          const hasErrors=results.some(result => !result.success);
          if(hasErrors){
            this.downloadCSV(results.filter(result => !Array.isArray(result)));
            this.programFormObjectsClone.push(results.filter(result => !Array.isArray(result)));
          }
          this.viewModel.programFormObjects = [];
        });
      }
      else {
        this.toastService.publishErrorMessage($localize`Please fix the errors before uploading.`, null);
      }
    }
  }
  downloadCSV(programData: any[]) {
        if(programData.length>0)
        {
          this.viewModel.hydratedLeague$.subscribe((league=>{
              this.leagueAbbrevation= league.abbreviation;
          }));
          const csvString$=this.convertToCSVData(programData);
          const modalRef = this.modalService.open(ProgramUploaderModalComponent, {size: 'lg'});
          const compInstance = modalRef.componentInstance as ProgramUploaderModalComponent;
          compInstance.initWithProgramDetails(csvString$,this.leagueAbbrevation);
      }
      else
      {
        this.programSuccess();
      }
}
programSuccess(){
      this.toastService.publishSuccessMessage($localize`Program Saved`, null);
      this.router.navigate(['../..'], { relativeTo: this.activatedRoute, fragment: 'programs' }).then();
    }
  convertToCSVData(programData: any[]): Observable<string> {
    return new Observable<string>(subscriber => {
      const header = [
        'start date',
        'start time',
        'duration in minutes',
        'home team',
        'away team',
        'venue',
        'production type',
        'offset',
        'timezone',
        'short description',
        'failure row',
        'error message'
      ].join(',');
        combineLatest([
        this.viewModel.filteredTeams$,
        this.viewModel.venueStreams$,
        this.viewModel.productionTypes$
      ]).subscribe(([filteredTeams, venueStreams, productionTypes]) => {
        const rows = programData.map(data => {
          const program = data?.formObject;
          return [
            program?.programDate,
            program?.programTime,
            program?.program.durationInMinutes,
            this.getTeamNameFromTeamId(filteredTeams,program?.program.homeTeamId),
            this.getTeamNameFromTeamId(filteredTeams,program?.program.visitingTeamId),
            this.getVenueStreamNameFromVenueStreamId(venueStreams,program?.program.venueStreamId),
            this.getProductionNameFromProductionTypeId(productionTypes,program?.program.productionTypeId),
            program?.program.pixellotEventStartTimeOffsetInMinutes,
            program?.programTimeZone,
            program?.program.shortDescription,
            program?.index,
            data?.error.message
          ].join(',');
        });
        const csvData = `${header}\n${rows.join('\n')}`;
        subscriber.next(csvData);
        subscriber.complete();
      });
    });
  }


  getCsvIndexForColumnName(columnName: string): number {
    const csvIndex = this.viewModel.csvData[0].findIndex(item => item.toLowerCase() === columnName.toLowerCase());
    if (csvIndex === -1) {
      this.errorMessage = this.errorMessage + $localize`The CSV file is missing the ${columnName} column. \n`;
    }
    return csvIndex;
  }

  fileList(files: CustomFile[], id: number) {
    const csvString = CsvUtils.csvFileURLToString(files[0].url as string);
    this.viewModel.csvData = this.viewModel.parseCsvString(csvString);
    combineLatest([this.viewModel.filteredTeams$,
      this.viewModel.venueStreams$,
      this.viewModel.leagueId$,
      this.viewModel.eventId$,
      this.viewModel.productionTypes$])
      .pipe(debounceTime(100), take(1), indicateOnNext(this.viewModel.loadingOpts, 'loading'))
      .subscribe(([teams, streams, leagueId, eventId, productionTypes]) => {
        this.errorMessage = '';
        const dateIndex = this.getCsvIndexForColumnName('start date');
        const timeIndex = this.getCsvIndexForColumnName('start time');
        const durationIndex = this.getCsvIndexForColumnName('duration in minutes');
        const homeTeamIndex = this.getCsvIndexForColumnName('home team');
        const visitingTeamIndex = this.getCsvIndexForColumnName('away team');
        const venueIndex = this.getCsvIndexForColumnName('venue');
        const productionTypeIndex = this.getCsvIndexForColumnName('production type');
        const pixellotTimeOffsetIndex = this.getCsvIndexForColumnName('offset');
        const timeZoneIndex = this.getCsvIndexForColumnName('timezone');
        const shortDescriptionIndex = this.getCsvIndexForColumnName('short description');
        console.log(this.viewModel.csvData);

        if (this.errorMessage.length === 0) {
          this.viewModel.csvData.forEach((row, i) => {
            if (i === 0) {
              return;
            }
            const programFormObject = new ProgramFormObject();
            programFormObject.program = new Program();
            programFormObject.program.venueStreamId = this.getVenueStreamIdFromVenueStreamName(streams, row[venueIndex].trim());
            programFormObject.program.homeTeamId = this.getTeamFromTeamName(teams, row[homeTeamIndex].trim()) ?
              this.getTeamFromTeamName(teams, row[homeTeamIndex].trim()).id :
              null;
            programFormObject.program.visitingTeamId = this.getTeamFromTeamName(teams, row[visitingTeamIndex].trim()) ?
              this.getTeamFromTeamName(teams, row[visitingTeamIndex].trim()).id :
              null;
            programFormObject.program.productionTypeId = this.getProductionTypeIdFromProductionTypeName(productionTypes,
              row[productionTypeIndex].trim());
            programFormObject.programDate = new Date(row[dateIndex].trim() + ' ' + '00:00:00').toISOString();
            programFormObject.programTime = row[timeIndex].trim();
            programFormObject.program.startDateUtc = row[dateIndex].trim().length > 0 &&
            row[timeIndex].length > 0 ? new Date(row[dateIndex].trim() + ' ' + row[timeIndex].trim()) : null;
            programFormObject.program.durationInMinutes = parseInt(row[durationIndex].trim(), 10);
            programFormObject.program.pixellotEventStartTimeOffsetInMinutes = parseInt(row[pixellotTimeOffsetIndex].trim(), 10);
            programFormObject.programTimeZone = row[timeZoneIndex].trim();
            programFormObject.program.shortDescription = row[shortDescriptionIndex].trim();
            this.viewModel.programFormObjects.push(programFormObject);
            this.canBeSubmitted = true;

            const viewableFormObject = new ViewableProgramFormObject(programFormObject);
            viewableFormObject.homeTeamName = row[homeTeamIndex];
            viewableFormObject.visitingTeamName = row[visitingTeamIndex];
            viewableFormObject.venueName = row[venueIndex];
            viewableFormObject.productionType = row[productionTypeIndex];
            this.viewModel.viewableFormObjects.push(viewableFormObject);
          });
          const newDataProvider = new BaseDatatableDataProvider();
          newDataProvider.data = this.viewModel.viewableFormObjects;
          this.updateTableData.next(newDataProvider);
        }
      }).addTo(this.subscriptions);
  }

  getDatatableColumns() {
    const columns: DatatableColumn[] = [];

    const programDateColumn = new DatatableColumn(
      'programDate',
      $localize`Start Date`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.programDate ?
          `${DateUtils.formatDateToReadableString(new Date(csvData.programDate))}` : $localize`N/A`;
      }
    );
    columns.push(programDateColumn);

    const startDateTime = new DatatableColumn(
      'programTime',
      $localize`Start Time`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.programTime ? csvData.programTime : $localize`N/A`;
      }
    );
    columns.push(startDateTime);

    const durationColumn = new DatatableColumn(
      'durationInMinutes',
      $localize`Duration`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return `${csvData.program.durationInMinutes}`;
      }
    );
    columns.push(durationColumn);

    const homeTeamColumn = new DatatableColumn(
      'homeTeamName',
      $localize`Home Team`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.homeTeamName;
      }
    );
    columns.push(homeTeamColumn);

    const visitingTeamColumn = new DatatableColumn(
      'visitingTeamName',
      $localize`Away Team`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.visitingTeamName;
      }
    );
    columns.push(visitingTeamColumn);

    const venueColumn = new DatatableColumn(
      'venueName',
      $localize`Venue`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.venueName;
      }
    );
    columns.push(venueColumn);

    const productionTypeColumn = new DatatableColumn(
      'productionType',
      $localize`Production Type`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.productionType;
      }
    );
    columns.push(productionTypeColumn);

    const pixellotTimeOffsetColumn = new DatatableColumn(
      'pixellotEventStartTimeOffsetInMinutes',
      $localize`Pixellot Time Offset`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): number => {
        return csvData.program.pixellotEventStartTimeOffsetInMinutes;
      }
    );
    columns.push(pixellotTimeOffsetColumn);

    const programTimeZoneColumn = new DatatableColumn(
      'programTimeZone',
      $localize`Time Zone`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.programTimeZone ? csvData.programTimeZone : $localize`N/A`;
      }
    );
    columns.push(programTimeZoneColumn);

    const shortDescriptionColumn = new DatatableColumn(
      'shortDescription',
      $localize`Short Description`,
      DatatableColumnType.Text,
      (csvData: ViewableProgramFormObject): string => {
        return csvData.program.shortDescription;
      }
    );
    columns.push(shortDescriptionColumn);

    const errorColumn = new DatatableColumn(
      'error',
      $localize`Error`,
      DatatableColumnType.Icon,
      (programFormObject: ViewableProgramFormObject): string => {
        return this.getErrorMessageForItem(programFormObject)?.length > 0 ?
          '/assets/icons/dark/solid/error-filled.svg' :
          '/assets/icons/dark/solid/error.svg';
      }
    );
    errorColumn.className = 'custom-datatable-header text-right mr-2';
    errorColumn.headerClassName = 'custom-datatable-header text-right mr-2';
    errorColumn.iconClicked = (programFormObject: ViewableProgramFormObject) => {
      this.viewingError = this.getErrorMessageForItem(programFormObject);
    };
    columns.push(errorColumn);

    return columns;
  }

  validateTotalLength(formObject: ViewableProgramFormObject): string {
    const maxLength = 100;
    const lengthOfCsvData = [
      formObject.venueName,
      formObject.homeTeamName,
      formObject.visitingTeamName,
      formObject.productionType
    ];
    const totalLength = lengthOfCsvData.reduce((total, str) => total + (str ? str.length : 0), 0);
    if (totalLength > maxLength) {
      return `The total length of fields exceeds the maximum length of ${maxLength} characters.`;
    }
    return '';
  }

  getErrorMessageForItem(formObject: ViewableProgramFormObject): string {
    const errors: string[] = [];

    // leaving the code below commented out for now, we will modify this and bring it back
    // if (formObject.program.startDateUtc) {
    //   const currentTimePlusOneHour = new Date();
    //   currentTimePlusOneHour.setHours(currentTimePlusOneHour.getHours() + 1);
    //
    //   const programStartTime = new Date(formObject.program.startDateUtc);
    //   if (programStartTime < currentTimePlusOneHour) {
    //     errors.push('Program start time must be at least one hour in the future.');
    //   }
    // } else {
    //   errors.push('Start date and time are required');
    // }
    if (!formObject.program.startDateUtc) {
      errors.push('Start date and time are required');
    }
    if (!formObject.program.homeTeamId && !!formObject.homeTeamName) {
      errors.push(`${formObject.homeTeamName} does not exist in the selected League`);
    }
    if (!formObject.program.visitingTeamId && !!formObject.visitingTeamName) {
      errors.push(`${formObject.visitingTeamName} does not exist in the selected League`);
    }
    if (!formObject.program.venueStreamId && !!formObject.venueName) {
      errors.push(`${formObject.venueName} does not exist in the selected League or is not a valid venue`);
    }
    if (!formObject.venueName) {
      errors.push('Venue stream is required');
    }
    if (!formObject.productionType) {
      errors.push('Production type is required');
    }
    if (!formObject.programDate || !formObject.programTime) {
      errors.push('Start date is required');
    }
    const lengthError = this.validateTotalLength(formObject);
    if (lengthError) {
      errors.push(lengthError);
      console.log('idLengthError', lengthError);
    }

    if (errors.length > 1) {
      this.canBeSubmitted = false;
      return ('Multiple errors occurred: ' + errors.join(', '));
    } else if (errors.length === 1) {
      this.canBeSubmitted = false;
      return errors[0];
    }

    return '';
  }


  getTeamFromTeamName(hydratedTeams: HydratedTeam[], teamLocation: string): HydratedTeam {
    return hydratedTeams.find(team => team.name.toLocaleLowerCase() === teamLocation.toLocaleLowerCase());
  }

  getVenueStreamIdFromVenueStreamName(venueStreams: VenueStream[], venueStreamName: string): number {
    return venueStreams.find(vs => vs.name.toLocaleLowerCase() === venueStreamName.toLocaleLowerCase()) ?
      venueStreams.find(vs => vs.name.toLocaleLowerCase() === venueStreamName.toLocaleLowerCase()).id :
      null;
  }

  getProductionTypeIdFromProductionTypeName(productionTypes: ProductionType[], productionTypeName: string): number {
    return productionTypes.find(pt => pt.name.toLocaleLowerCase() === productionTypeName.toLocaleLowerCase()) ?
      productionTypes.find(pt => pt.name.toLocaleLowerCase() === productionTypeName.toLocaleLowerCase()).id :
      null;
  }

  getTeamNameFromTeamId(hydratedTeams: HydratedTeam[], id: number): string {
    const teamName= hydratedTeams.find(team => team.id===id).name;
    return teamName;
  }

  getProductionNameFromProductionTypeId(productionTypes: ProductionType[],productionTypeId: number): string{
    return productionTypes.find(pt => pt.id===productionTypeId)?
    productionTypes.find(pt => pt.id===productionTypeId).name:'';
  }

  getVenueStreamNameFromVenueStreamId(venueStreams: VenueStream[], venueStreamId: number): string {
    return venueStreams.find(venue=>venue.id===venueStreamId).name;
  }

}

