import {Subscribable} from './subscribable';
import {TypeUtils} from '../../utils/type-utils';
import {PermissionManagerService} from '../../services/admin-permission/admin-permission-service';

export class BaseViewModel extends Subscribable {
  Types = TypeUtils;
  PermissionManagerService = PermissionManagerService;
  public init() {
    this.PermissionManagerService.loadPermissionFactoryGetInstance();
  }
}
