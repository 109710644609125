import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Plan} from '../../../../models/resources/plan';
import {PlanFormObject} from '../../../../models/resources/plan-form-object';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';

@Injectable()
export class PlansDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  planId$ = this.activatedRoute.params.pipe(map(params => params.planId));
  plan: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(null);

  addNewPlan$ = this.activatedRoute.data.pipe(map(data => data.addPlan as boolean));

  updateFormItemStatesSubject$ = new BehaviorSubject<void>(null);

  plan$: Observable<any> = this.planId$.pipe(
    switchMap(planId => this.getPlanById(planId))
  );


  constructor(private domainModel: ResourceDomainModel,
              private toastService: ToastService,
              private router: Router,
              private lookupDomainModel: LookupDomainModel,
              private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  getPlanById(planId: number): Observable<Plan> {
    return this.domainModel.getPlanById(planId).pipe(tap(plan => plan));
  }
  savePlan(formObject: PlanFormObject): Observable<Plan> {
    return this.domainModel.savePlan(formObject);
  }
}
