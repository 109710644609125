import {BehaviorSubject} from 'rxjs';

export class CheckboxContainerOptions {
  public hideBulkCheckbox: boolean = false;
  public checkAllText: string = $localize`Check All`;
  public inlineOptions: boolean = false;
  public requireMinimumSelection: number = 0;
  public touched: boolean = false;
  public customCheckboxMargin: string;
  public showDivider: boolean = false;
  public filterString: BehaviorSubject<string>;
}

