import {Component, OnInit} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../../../services/toast-service';

@Component({
  selector: 'app-user-agreement-modal',
  templateUrl: './user-agreement-modal.component.html',
  styleUrls: ['./user-agreement-modal.component.scss']
})
export class UserAgreementModalComponent extends BaseModal {
  buttonDisabled = true;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
  }

  setupViews() {
  }

  continue() {
    this.activeModal.close();
  }

  onScroll($event: any) {
    if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight - 1) {
      return this.buttonDisabled = false;
    }
  }
}
