import {Component, Input, OnInit} from '@angular/core';
import {Location as _Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-detail-back-button',
  templateUrl: './detail-back-button.component.html',
  styleUrls: ['./detail-back-button.component.scss']
})
export class DetailBackButtonComponent implements OnInit {

  @Input() backRoute: string = null;

  constructor(private location: _Location,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
  }

  backClicked() {
    if (!!this.backRoute) {
      this.router.navigate([this.backRoute], {relativeTo: this.activatedRoute}).then();
    } else {
      this.location.back();
    }
  }
}
