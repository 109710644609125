import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StylesheetComponent} from './stylesheet/stylesheet.component';
import {CanDeactivateGuard} from '../../services/guards/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    component: StylesheetComponent,
    data: {
      title: 'Stylesheet'
    },
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StylesheetRoutingModule {
}
