import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {StylesheetComponent} from './stylesheet/stylesheet.component';
import {StylesheetRoutingModule} from './stylesheet-routing.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';


@NgModule({
  declarations: [
    StylesheetComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    StylesheetRoutingModule,
    BsDropdownModule,
  ],
})
export class StylesheetModule {
}
