import {Injectable} from '@angular/core';
import {AdminSections} from '../../models/account/enum/admin-permission.enum';
import {PermissionsFactory} from '../../models/account/dto/admin-permission/admin-permissions-factory';

@Injectable({
  providedIn: 'root'
})
export class PermissionManagerService {
  constructor(
  ) {}

  public static isGranted(section: AdminSections) {
    try {
      const sections = PermissionsFactory.getInstance().sections;
      if (!sections) {
        return false;
      }
      for (const sec of sections) {
        if (sec === section) {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  public static loadPermissionFactoryGetInstance() {
    try {
    const instance = PermissionsFactory.getInstance();
    }catch (e) {
      console.log(e);
    }
  }

  public static getUserAdminLevel(): string  {
    try {
      return  PermissionsFactory.getAdminUserLevel();
    }catch (e) {
      console.log(e);
    }
  }
}
