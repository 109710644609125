<div class="card-deck"
     [ngClass]="{ 'custom-card-deck' : horizontalScroller }"
     [ngStyle]="{ 'max-height': getMaxHeightRem() }">
  <ng-container *ngFor="let card of cards; let cardIndex = index;">
    <!--  Default Card  -->
    <app-card
      *ngIf="card.cardStyle === cardStyles.Default"
      [card]="card"
      [cardClass]="horizontalScroller ? (cardIndex === 0 ? 'ml-0' : cardIndex === cards.length - 1 ? 'mr-0' : '') : 'ml-0 custom-card-with-bottom-margin'"
      (click)="handleCardPress(card)">
    </app-card>
    <!--  Incomplete Product Card  -->
    <app-theme-carousel-image
      *ngIf="card.cardStyle === cardStyles.ThemeCarouselImage"
      [cardClass]="horizontalScroller ? (cardIndex === 0 ? 'ml-0' : cardIndex === cards.length - 1 ? 'mr-0' : '') : 'ml-0 custom-card-with-bottom-margin'"
      [card]="card"
      [id]="card.id">
    </app-theme-carousel-image>
  </ng-container>
</div>
