import {Component, EventEmitter} from '@angular/core';
import {AdvertisementDetailsViewModel} from './advertisement-details-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CustomFile} from '../../../../models/shared/custom-file';
import {AdvertisementFormObject} from '../../../../models/resources/advertisement-form-object';
import {AdvertisementPriorityType} from '../../../../models/lookup/advertisement-priority-type';
import {Team} from '../../../../models/resources/team';
import {Venue} from '../../../../models/resources/venue';
import {League} from '../../../../models/resources/league';
import {Event} from '../../../../models/resources/event';
import {AddExistingTeamModalComponent} from '../../shared/add-existing-team-modal/add-existing-team-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TeamId} from '../../../../models/resources/teamId';
import {VenueId} from '../../../../models/resources/venue-id';
import {LeagueId} from '../../../../models/resources/leagueId';
import {AddVenueModalComponent} from '../../shared/add-venue-modal/add-venue-modal.component';
import {AddLeagueModalComponent} from '../../shared/add-league-modal/add-league-modal.component';
import {AddRunDatesModalComponent} from '../../shared/add-run-dates-modal/add-run-dates-modal.component';
import {AdvertisementRunDate} from '../../../../models/resources/advertisement-run-date';
import {EventId} from '../../../../models/resources/eventId';
import {AddEventModalComponent} from '../../shared/add-event-modal/add-event-modal.component';
import {AdvertisementType} from '../../../../models/lookup/advertisement-type';
import {ToastService} from '../../../../services/toast-service';

@Component({
  selector: 'app-advertisement-details',
  templateUrl: './advertisement-details.component.html',
  styleUrls: ['./advertisement-details.component.scss'],
  providers: [AdvertisementDetailsViewModel],
})
export class AdvertisementDetailsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: AdvertisementFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();
  private addTeamClicked = new EventEmitter();
  private removeTeamClicked = new EventEmitter<TeamId>();
  private addVenueClicked = new EventEmitter();
  private removeVenueClicked = new EventEmitter<VenueId>();
  private addLeagueClicked = new EventEmitter();
  private removeLeagueClicked = new EventEmitter<LeagueId>();
  private addEventClicked = new EventEmitter();
  private removeEventClicked = new EventEmitter<EventId>();
  private addRunDateClicked = new EventEmitter();
  private removeRunDateClicked = new EventEmitter<AdvertisementRunDate>();
  private isEditing = true;

  constructor(
    public viewModel: AdvertisementDetailsViewModel,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
  }

  setupBindings() {
    combineLatest([this.viewModel.advertisement$.notNull(), this.viewModel.adUploads$])
      .pipe(debounceTime(100))
      .subscribe(([show, uploads]) => {
        this.activatedRoute.url.subscribe(segments => {
          const lastSegment = segments[segments.length - 1].path;
          if (lastSegment === 'add') {
            this.isEditing = false;
          }
        });
        this.formObject = AdvertisementFormObject.initWith(show, uploads);
        if (this.formItems.length === 0) {
          this.setupFormItems();
        } else {
          this.viewModel.updateFormItemStatesSubject$.next();
        }
      }).addTo(this.subscriptions);

    this.viewModel.formErrorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

    this.addTeamClicked.subscribe(() => this.showAddTeamModal()).addTo(this.subscriptions);
    this.addVenueClicked.subscribe(() => this.showAddVenueModal()).addTo(this.subscriptions);
    this.addLeagueClicked.subscribe(() => this.showAddLeagueModal()).addTo(this.subscriptions);
    this.addEventClicked.subscribe(() => this.showAddEventModal()).addTo(this.subscriptions);
    this.addRunDateClicked.subscribe(() => this.showAddRunDatesModal()).addTo(this.subscriptions);
    this.removeTeamClicked.subscribe(t => this.removeTeamAssociation(t)).addTo(this.subscriptions);
    this.removeVenueClicked.subscribe(v => this.removeVenueAssociation(v)).addTo(this.subscriptions);
    this.removeLeagueClicked.subscribe(l => this.removeLeagueAssociation(l)).addTo(this.subscriptions);
    this.removeEventClicked.subscribe(l => this.removeEventAssociation(l)).addTo(this.subscriptions);
    this.removeRunDateClicked.subscribe(d => this.removeRunDates(d)).addTo(this.subscriptions);
  }

  setupFormBindings() {
    this.viewModel.updateFormItemStatesSubject$.pipe(debounceTime(100)).subscribe(() => {
      this.updateFormItemStates();
    }).addTo(this.subscriptions);

    this.viewModel.priorityTypes$.pipe(debounceTime(100)).subscribe(p => {
      this.setPriorityOptions(p);
    });
  }

  advertisementIsBannerAd() {
    const advertisementType = this.formItems.find(f => f.inputName === 'advertisementTypeId');
    return advertisementType.getValue() === 2;
  }

  advertisementTypeChanged() {
    const advertisementType = this.formItems.find(f => f.inputName === 'advertisementTypeId');
    const destinationUrl = this.formItems.find(f => f.inputName === 'destinationUrl');
    if (advertisementType.getValue() === 1) {
      destinationUrl.itemType = FormItemType.Hidden;
      destinationUrl.setRequired(false);
      destinationUrl.label = '';
    } else {
      destinationUrl.itemType = FormItemType.Input;
      destinationUrl.setRequired(true);
      destinationUrl.label = 'Destination Url';
    }
  }

  setupFormItems() {
    const items: FormInputItem[] = [];
    items.push(FormInputItem.generateDivider());

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const descriptionTitle = new FormInputItem();
    descriptionTitle.itemType = FormItemType.Title;
    descriptionTitle.label = $localize`Advertisement Description`;
    items.push(descriptionTitle);

    const name = new FormInputItem();
    name.inputName = 'advertisementName';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Advertisement Name`;
    name.bindingProperty = 'advertisement.name';
    name.required = true;
    items.push(name);

    const priorityType = new FormInputItem();
    priorityType.itemType = FormItemType.Dropdown;
    priorityType.inputName = 'priorityTypeId';
    priorityType.label = $localize`Priority`;
    priorityType.placeholder = $localize`Choose a Priority`;
    priorityType.bindingProperty = 'advertisement.advertisementPriorityId';
    priorityType.dropdownIsObject = true;
    priorityType.required = true;
    priorityType.enabled = true;
    priorityType.dropdownOptions = [];
    items.push(priorityType);

    const advertisementType = new FormInputItem();
    advertisementType.itemType = FormItemType.Dropdown;
    advertisementType.inputName = 'advertisementTypeId';
    advertisementType.label = $localize`Advertisement Type`;
    advertisementType.placeholder = $localize`Choose a Type`;
    advertisementType.bindingProperty = 'advertisement.advertisementType';
    advertisementType.dropdownIsObject = false;
    advertisementType.required = true;
    advertisementType.enabled = !this.isEditing;
    advertisementType.dropdownOptions = [new AdvertisementType('Pre roll',1),new AdvertisementType('Banner Ad',2)];
    advertisementType.valueChanged.subscribe(() => this.advertisementTypeChanged());
    items.push(advertisementType);

    const destinationUrl = new FormInputItem();
    destinationUrl.inputName = 'destinationUrl';
    destinationUrl.inputType = FormInputType.Text;
    destinationUrl.label = $localize`Destination Url`;
    destinationUrl.placeholder = $localize`Destination Url`;
    destinationUrl.bindingProperty = 'advertisement.interactionUrl';
    destinationUrl.hidden = true;
    items.push(destinationUrl);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'advertisement.active';
    active.customClass = 'mb-4 mt-0';
    items.push(active);

    items.push(FormInputItem.generateDivider());

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    const teamsTitle = new FormInputItem();
    teamsTitle.itemType = FormItemType.Title;
    teamsTitle.label = $localize`Teams`;
    teamsTitle.titleButtonText = $localize`Add a Team`;
    teamsTitle.titleButtonClicked = this.addTeamClicked;
    teamsTitle.overrideFullWidth = true;
    items.push(teamsTitle);

    const teams = new FormInputItem();
    teams.inputName = 'teamFormObjects';
    teams.itemType = FormItemType.List;
    teams.bindingProperty = 'advertisement.associatedTeams';
    teams.listItemButtonText = $localize`Remove`;
    teams.listItemClicked = this.removeTeamClicked;
    items.push(teams);

    const venuesTitle = new FormInputItem();
    venuesTitle.itemType = FormItemType.Title;
    venuesTitle.label = $localize`Venues`;
    venuesTitle.titleButtonText = $localize`Add a Venue`;
    venuesTitle.titleButtonClicked = this.addVenueClicked;
    venuesTitle.overrideFullWidth = true;
    items.push(venuesTitle);

    const venues = new FormInputItem();
    venues.inputName = 'associatedVenues';
    venues.itemType = FormItemType.List;
    venues.bindingProperty = 'advertisement.associatedVenues';
    venues.listItemButtonText = $localize`Remove`;
    venues.listItemClicked = this.removeVenueClicked;
    items.push(venues);

    const leaguesTitle = new FormInputItem();
    leaguesTitle.itemType = FormItemType.Title;
    leaguesTitle.label = $localize`Leagues`;
    leaguesTitle.titleButtonText = $localize`Add a League`;
    leaguesTitle.titleButtonClicked = this.addLeagueClicked;
    leaguesTitle.overrideFullWidth = true;
    items.push(leaguesTitle);

    const leagues = new FormInputItem();
    leagues.inputName = 'associatedLeagues';
    leagues.itemType = FormItemType.List;
    leagues.bindingProperty = 'advertisement.associatedLeagues';
    leagues.listItemButtonText = $localize`Remove`;
    leagues.listItemClicked = this.removeLeagueClicked;
    items.push(leagues);


    const eventTitle = new FormInputItem();
    eventTitle.itemType = FormItemType.Title;
    eventTitle.label = $localize`Events`;
    eventTitle.titleButtonText = $localize`Add a Event`;
    eventTitle.titleButtonClicked = this.addEventClicked;
    eventTitle.overrideFullWidth = true;
    items.push(eventTitle);


    const events = new FormInputItem();
    events.inputName = 'associatedEvents';
    events.itemType = FormItemType.List;
    events.bindingProperty = 'advertisement.associatedEvents';
    events.listItemButtonText = $localize`Remove`;
    events.listItemClicked = this.removeEventClicked;
    items.push(events);

    const runDatesTitle = new FormInputItem();
    runDatesTitle.itemType = FormItemType.Title;
    runDatesTitle.label = $localize`Dates`;
    runDatesTitle.titleButtonText = $localize`Add Dates`;
    runDatesTitle.titleButtonClicked = this.addRunDateClicked;
    runDatesTitle.overrideFullWidth = true;
    items.push(runDatesTitle);

    const runDates = new FormInputItem();
    runDates.inputName = 'associatedRunDates';
    runDates.itemType = FormItemType.List;
    runDates.bindingProperty = 'advertisement.runDates';
    runDates.listItemButtonText = $localize`Remove`;
    runDates.listItemClicked = this.removeRunDateClicked;
    items.push(runDates);

    this.formItems = items;
    this.setupFormBindings();
    this.viewModel.updateFormItemStatesSubject$.next();
  }

  updateFormItemStates() {
    if (this.formItems.length === 0) {
      return;
    }
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.submitButtonText = $localize`Save`;
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Advertisement_Edit_Advertiser_Edit) ?
        '' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = true;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: AdvertisementFormObject) {
    if (!this.formObject.fileToUpload && !this.formObject.existingFile) {
      this.toastService.publishErrorMessage($localize`Please upload a file before submitting the form.`, null);
      return;
    }
    this.viewModel.saveAdvertisement(result);
  }

  fileList(f: CustomFile[], id: number) {
    if (!this.advertisementIsBannerAd()) {
    this.formObject.fileToUpload = f.length > 0 ? f[0] : undefined;
    } else {
      if (f.length > 0) {
        this.formObject.fileToUpload = f[0];
        this.formObject.fileToUpload.url = f[0].url;
        this.formObject.fileToUpload.type = f[0].type;
      } else {
        this.formObject.fileToUpload = undefined;
      }
    }
  }

  removeFileClicked() {
    if (this.formObject.existingFile) {
      this.formObject.deleteFileId = this.formObject.existingFile.id;
      this.formObject.existingFile = null;
    }
      this.formObject.fileToUpload = null;
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  setPriorityOptions(p: AdvertisementPriorityType[]) {
    const priorityInput = this.formItems.find(f => f.inputName === 'priorityTypeId');
    priorityInput.dropdownOptions = p;
  }

  private showAddTeamModal() {
    const modalRef = this.modalService.open(
      AddExistingTeamModalComponent,
      ModalUtils.addTeamModalOptions(),
    );
    (modalRef.componentInstance as AddExistingTeamModalComponent).hideCreateNewTeamButton = true;
    const compInstance = modalRef.componentInstance as AddExistingTeamModalComponent;
    compInstance.intialSetup();
    modalRef.result.then((addedTeam: Team) => {
      const existingAssociatedTeam = this.formObject.advertisement.associatedTeams?.find(t => t.teamId === addedTeam.id);
      if (existingAssociatedTeam) {
        existingAssociatedTeam.itemCreated = true;
      } else if (addedTeam) {
        const teamId = TeamId.initWithTeam(addedTeam);
        teamId.itemCreated = true;
        this.formObject.advertisement.associatedTeams.push(teamId);
      }
    }, reason => {});
  }

  removeTeamAssociation(teamId: TeamId) {
    const existingAssociatedTeam = this.formObject.advertisement.associatedTeams?.find(t => t.teamId === teamId.teamId);
    if (!!existingAssociatedTeam.itemCreated) {
      this.formObject.advertisement.associatedTeams
        ?.splice(this.formObject.advertisement.associatedTeams?.indexOf(existingAssociatedTeam), 1);
    } else if (!!existingAssociatedTeam) {
      existingAssociatedTeam.itemDeleted = true;
    }
  }

  private showAddVenueModal() {
    const modalRef = this.modalService.open(
      AddVenueModalComponent,
      ModalUtils.addVenueModalOptions(),
    );
    const compInstance = modalRef.componentInstance as AddVenueModalComponent;
    compInstance.intialSetup();
    modalRef.result.then((addedVenue: Venue) => {
      const existingAssociatedVenue = this.formObject.advertisement.associatedVenues?.find(v => v.venueId === addedVenue.id);
      if (existingAssociatedVenue) {
        existingAssociatedVenue.itemCreated = true;
      } else if (addedVenue) {
        const venueId = VenueId.initWithVenue(addedVenue);
        venueId.itemCreated = true;
        this.formObject.advertisement.associatedVenues.push(venueId);
      }    }, reason => {});
  }

  removeVenueAssociation(venueId: VenueId) {
    const existingAssociatedVenue = this.formObject.advertisement.associatedVenues?.find(t => t.venueId === venueId.venueId);
    if (!!existingAssociatedVenue.itemCreated) {
      this.formObject.advertisement.associatedVenues
        ?.splice(this.formObject.advertisement.associatedVenues?.indexOf(existingAssociatedVenue), 1);
    } else if (!!existingAssociatedVenue) {
      existingAssociatedVenue.itemDeleted = true;
    }
  }

  private showAddLeagueModal() {
    const modalRef = this.modalService.open(
      AddLeagueModalComponent,
      ModalUtils.addLeagueModalOptions(),
    );
    modalRef.result.then((addedLeague: League) => {
      const existingAssociatedLeague = this.formObject.advertisement.associatedLeagues?.find(v => v.leagueId === addedLeague.id);
      if (existingAssociatedLeague) {
        existingAssociatedLeague.itemCreated = true;
      } else if (addedLeague) {
        const leagueId = LeagueId.initWithLeague(addedLeague);
        leagueId.itemCreated = true;
        this.formObject.advertisement.associatedLeagues.push(leagueId);
      }    }, reason => {});
  }

  private showAddEventModal() {
    const modalRef = this.modalService.open(
      AddEventModalComponent,
      ModalUtils.addEventModalOptions(),
    );
    modalRef.result.then((addedEvent: Event) => {
      const existingAssociatedEvent = this.formObject.advertisement.associatedEvents?.find(v => v.eventId === addedEvent.id);
      if (existingAssociatedEvent) {
        existingAssociatedEvent.itemCreated = true;
      } else if (addedEvent) {
        const eventId = EventId.initWithEvent(addedEvent);
        eventId.itemCreated = true;
        this.formObject.advertisement.associatedEvents.push(eventId);
      }    }, reason => {});
  }

  removeLeagueAssociation(leagueId: LeagueId) {
    const existingAssociatedLeague = this.formObject.advertisement.associatedLeagues?.find(t => t.leagueId === leagueId.leagueId);
    if (!!existingAssociatedLeague.itemCreated) {
      this.formObject.advertisement.associatedLeagues
        ?.splice(this.formObject.advertisement.associatedLeagues?.indexOf(existingAssociatedLeague), 1);
    } else if (!!existingAssociatedLeague) {
      existingAssociatedLeague.itemDeleted = true;
    }
  }

  removeEventAssociation(eventId: EventId) {
    const existingAssociatedEvent = this.formObject.advertisement.associatedEvents?.find(t => t.eventId === eventId.eventId);
    if (!!existingAssociatedEvent.itemCreated) {
      this.formObject.advertisement.associatedEvents
        ?.splice(this.formObject.advertisement.associatedEvents?.indexOf(existingAssociatedEvent), 1);
    } else if (!!existingAssociatedEvent) {
      existingAssociatedEvent.itemDeleted = true;
    }
  }

  private showAddRunDatesModal() {
    const modalRef = this.modalService.open(
      AddRunDatesModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    modalRef.result.then((addedRunDate: AdvertisementRunDate) => {
      const existingRunDate = this.formObject.advertisement.runDates?.find(d => d.id === addedRunDate.id);
      if (existingRunDate) {
        existingRunDate.itemCreated = true;
      } else if (addedRunDate) {
        addedRunDate.itemCreated = true;
        this.formObject.advertisement.runDates.push(addedRunDate);
      }    }, reason => {});
  }

  removeRunDates(runDate: AdvertisementRunDate) {
    const existingRunDate = this.formObject.advertisement.runDates?.find(d => d.id === runDate.id);
    if (!!existingRunDate.itemCreated) {
      this.formObject.advertisement.runDates
        ?.splice(this.formObject.advertisement.runDates?.indexOf(existingRunDate), 1);
    } else if (!!existingRunDate) {
      existingRunDate.itemDeleted = true;
    }
  }
}
