import {DatatableColumn} from './datatable-column';

export class DatatableOptions {
  public columns: DatatableColumn[] = [];
  public nestedColumns: DatatableColumn[] = [];
  // Nested table
  public rowExpansionDisabled: boolean = true;
  // Bulk Selection
  public bulkEditPercentageChecked: number = 0;
  public bulkEditSelectedIds: string[] = [];
  public preselectedIds: string[] = [];
  public selectNestedRowOnClick: boolean = false;
  // Headers
  public stickyModalHeader: boolean = false;
  public hideNestedHeader: boolean = true;
  // Footer
  public footerClassName: string = 'custom-datatable-footer';
  public hideFooter: boolean = false;
  // Filters
  public defaultEntryCount: number = 10;
  // CTA
  public ctaButtonText: string = '';
  public ctaButtonIconSrc: string = null;
  public ctaButtonClass: string = 'preferred-button';
  public secondaryButtonText: string = '';
  public secondaryButtonIconSrc: string = null;
  public secondaryButtonClass: string = 'neutral-button';

  enableCtaButton(options: DatatableOptions): void {
    options.ctaButtonClass = 'enabled-button';
  }

}
