<app-detail-title i18n>
  Programs
</app-detail-title>
<app-content-container [narrow]="false">
  <div class="row align-items-center">
    <div class="col-6">
      <app-detail-back-button class="d-block mt-4" i18n>Program Uploader</app-detail-back-button>
    </div>

  </div>
  <div class="position-relative min-height-20">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <div class="label-lg mb-4" i18n>File</div>
    <app-upload-asset [narrow]="true"
      [allowTypes]="['csv']"
      [maxAssets]="1"
      [parentHandler]="this">
    </app-upload-asset>
    <button class="custom-button preferred-button mr-3 mb-3" (click)="uploadProgram()">Upload Programs</button>
        <div class="error-message" *ngIf="errorMessage && errorMessage.length > 0">{{errorMessage}}</div>
    <div class="table-container">
      <app-data-table
        [tableData]="dataProvider"
        [tableFilter]="datatableFilter"
        [resetTable]="resetTable"
        [updateTableData]="updateTableData"
        [filterApplied]="tableFilterChanged"
        [tableOptions]="datatableOptions"
      >
      </app-data-table>
      <app-program-uploader-error
        [hidden]="viewingError.length === 0"
        [error]="viewingError"
        (closeButtonClicked)="viewingError = ''"
      >
      </app-program-uploader-error>
    </div>
  </div>
</app-content-container>
