export class Checkbox {

  public label: string;
  public checked: boolean;
  public disabled: boolean;
  public description: string;
  public subDescription: string;
  public id: number|string;
  public altId: number|string;

  constructor(label: string, checked = false, disabled = false, description?, id?, subDescription?, altId?) {
    this.label = label;
    this.checked = checked;
    this.disabled = disabled;
    this.description = description;
    this.subDescription = subDescription;
    this.id = id;
    this.altId = altId;
  }

}
