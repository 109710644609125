import {LeagueId} from './leagueId';

export class FavouriteLeagues {
  favouriteLeagues: LeagueId[] = [];

  constructor(favouriteLeagues: LeagueId[] = null) {
    this.favouriteLeagues = favouriteLeagues ?? [];
  }

  toggleId(id: number) {
    const i = this.favouriteLeagues.findIndex(item => item.leagueId === id);
    if (i > -1) {
      this.favouriteLeagues.splice(i, 1);
    } else {
      this.favouriteLeagues.push(new LeagueId(id));
    }
  }
}
