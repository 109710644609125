import {Component, Input} from '@angular/core';
import {SignUpBannerViewModel} from './sign-up-banner-view-model';
import {BaseComponent} from '../../../../models/base/base-component';

@Component({
  selector: 'app-sign-up-banner',
  templateUrl: './sign-up-banner.component.html',
  styleUrls: ['./sign-up-banner.component.scss'],
  providers: [SignUpBannerViewModel],
})
export class SignUpBannerComponent extends BaseComponent {

  @Input() leagueLandingScreen: boolean = false;
  @Input() eventLandingScreen: boolean = false;

  constructor(
    public viewModel: SignUpBannerViewModel,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }
}
