import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {TabContentContainerDirective} from './tab-content-container.directive';
import {TabBarItem} from '../../../../../models/shared/stylesheet/tab-bar-item';
import {Tabbable} from '../../../../../models/protocols/tabbable';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss']
})
export class TabContentComponent implements OnInit {

  @Input() tab: TabBarItem;
  @ViewChild(TabContentContainerDirective, {static: true})
  contentContainer: TabContentContainerDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    const tab: TabBarItem = this.tab;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<Tabbable>(tab.componentType);
    const componentRef = this.contentContainer.viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as Tabbable).testName = `I'm a child of ${tab.title}`;
  }

}
