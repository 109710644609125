<app-program-player
  [programId]="viewModel.programId$|async"
  [isShow]="viewModel.isShow$|async"
  [showBottomDivider]="true">
</app-program-player>
<app-program-row
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.programRowsContentQuery">
</app-program-row>
<app-program-row
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.programRowsContentQuery">
</app-program-row>
<app-program-row
  [programRowType]="programRowType.Past"
  [programContentQuery]="viewModel.programRowsContentQuery">
</app-program-row>
<app-program-row
  [programRowType]="programRowType.Shows"
  [programContentQuery]="viewModel.programRowsContentQuery">
</app-program-row>
