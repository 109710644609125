<div class="modal-body">
  <div class="modal-title">
    {{getModalTitle()}}
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (secondaryButtonPressed)="deleteClicked()"
    (formSubmitted)="formSubmitted()">
    <div class="d-block w-100">
      <div class="label-md mb-4" i18n>Banner Image</div>
      <app-upload-asset
        *ngIf="!formObject.imageToUpload && !formObject.existingImageId"
        [allowTypes]="['image']"
        [maxAssets]="1"
        [parentHandler]="this">
      </app-upload-asset>
      <div class="banner-image-container pb-4" *ngIf="formObject.imageToUpload || formObject.existingImageId">
        <div class="banner-image">
          <img *ngIf="formObject.existingImageId" [src]="(formObject.bannerAd.imgSrc$|async)" alt="">
          <img *ngIf="!formObject.existingImageId" [src]="formObject.imageToUpload" alt="">
        </div>
        <div class="text-link-button mt-4" [hidden]="isEditing" (click)="removeImageClicked()" i18n>Remove Image</div>
      </div>
    </div>
  </app-form-group>
</div>
