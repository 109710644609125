<div class="modal-body">
  <div class="title-xs" [hidden]="imageToUpload">{{titleText}}</div>
  <div class="justify-content-between" [hidden]="!imageToUpload" [class.d-flex]="!!imageToUpload">
    <div class="title-xs">{{editTitleText}}</div>
    <div class="scale-image-controls">
      <ng-container i18n>Scale:</ng-container>
      <img [src]="'assets/icons/dark/outline/minus-circle.svg'" alt="" (click)="scaleImageDown()">
      <img [src]="'assets/icons/dark/outline/plus-circle.svg'" alt="" (click)="scaleImageUp()">
    </div>
  </div>
  <div class="mt-3 pt-1" [hidden]="imageToUpload">
    <app-upload-asset #uploadContainer
                      *ngIf="(!image && !imageToUpload)"
                      [allowTypes]="['image']"
                      [maxAssets]="1"
                      [parentHandler]="this">
    </app-upload-asset>
  </div>
  <div class="mt-3 pt-1 pb-3" [hidden]="!imageToUpload">
    <input type="text" autofocus="autofocus" style="display:none"/>
    <image-cropper
      class="image-cropper"
      format="png"
      [imageURL]="imageToUpload"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      [roundCropper]="roundedOverlay"
      [hideResizeSquares]="false"
      [transform]="transform"
      (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>
  <div class="footer-button w-100">
    <button class="custom-button destructive-button mr-auto border-0" [hidden]="!imageToUpload" (click)="removeImage()"
            i18n>Remove Image
    </button>
    <button class="custom-button neutral-button ml-3" (click)="closeModal()" i18n>Cancel</button>
    <button class="custom-button preferred-button ml-3" [hidden]="!imageToUpload" (click)="apply()" i18n>Apply</button>
  </div>
</div>
