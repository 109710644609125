/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError, map} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {ProgramStatusType} from '../models/lookup/program-status-type';
import {Program} from '../models/program/program';
import {HttpParams} from '@angular/common/http';
import {Image} from '../models/image/dto/image';
import {ProgramComment} from '../models/program/program-comment';
import {HydratedProgram} from '../models/program/hydrated-program';
import * as moment from 'moment';
import {HydratedShow} from '../models/program/hydrated-show';
import {Show} from '../models/program/show';
import {VideoUpload} from '../models/image/dto/video-upload';
import {ContentQuery, ProgramQueryType} from '../models/program/content-query';
import {HydratedAdvertisement} from '../models/resources/hydrated-advertisement';
import {Advertisement} from '../models/resources/advertisement';

@Injectable({
  providedIn: 'root',
})
export class ProgramApi implements LoggableAPI {
  // Variables
  public serviceName = 'Program';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }


  public createLeagueProgram(program: Program, leagueId: number): Observable<Program> {
    const url = Endpoints.createLeagueProgram(leagueId);
    return this.apiClient.postObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueProgram', err));
        return throwError(err);
      })
    );
  }

  public createEventProgram(program: Program, eventId: number): Observable<Program> {
    const url = Endpoints.createEventProgram(eventId);
    return this.apiClient.postObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createEventProgram', err));
        return throwError(err);
      })
    );
  }

  public completeProgram(program: Program, programId: string, leagueId: number): Observable<Program> {
    const url = Endpoints.completeLeagueProgram(programId, leagueId);
    return this.apiClient.postObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'completeProgram', err));
        return throwError(err);
      })
    );
  }

  public deleteLeagueProgram(leagueId: number, programId: string): Observable<Program> {
    const url = Endpoints.deleteLeagueProgram(leagueId, programId);
    return this.apiClient.deleteStr(url, leagueId).pipe(
      map(() => {
        return null;
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueProgram', err));
        return throwError(err);
      })
    );
  }

  public updateLeagueProgram(program: Program, leagueId: number): Observable<Program> {
    const url = Endpoints.updateLeagueProgram(program.id, leagueId);
    return this.apiClient.putObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateLeagueProgram', err));
        return throwError(err);
      })
    );
  }

  public updateEventProgram(program: Program, eventId: number): Observable<Program> {
    const url = Endpoints.updateEventProgram(program.id, eventId);
    return this.apiClient.putObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateEventProgram', err));
        return throwError(err);
      })
    );
  }

  public deleteEventProgram(eventId: number, programId: string): Observable<Program> {
    const url = Endpoints.deleteEventProgram(eventId, programId);
    return this.apiClient.deleteStr(url, eventId).pipe(
      map(() => {
        return null;
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteEventProgram', err));
        return throwError(err);
      })
    );
  }

  public createLeagueShow(show: Show, leagueId: number): Observable<Show> {
    const url = Endpoints.createLeagueShow(leagueId);
    return this.apiClient.postObj(Show, url, show).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueShow', err));
        return throwError(err);
      })
    );
  }

  public createEventShow(show: Show, eventId: number): Observable<Show> {
    const url = Endpoints.createEventShow(eventId);
    return this.apiClient.postObj(Show, url, show).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createEventShow', err));
        return throwError(err);
      })
    );
  }

  public updateLeagueShow(show: Show, leagueId: number): Observable<Show> {
    const url = Endpoints.updateLeagueShow(show.id, leagueId);
    return this.apiClient.putObj(Show, url, show).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateLeagueShow', err));
        return throwError(err);
      })
    );
  }

  public updateEventShow(show: Show, eventId: number): Observable<Show> {
    const url = Endpoints.updateEventShow(show.id, eventId);
    return this.apiClient.putObj(Show, url, show).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateEventShow', err));
        return throwError(err);
      })
    );
  }

  public createVenueProgram(program: Program, venueId: number): Observable<Program> {
    const url = Endpoints.createVenueProgram(venueId);
    return this.apiClient.postObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createVenueProgram', err));
        return throwError(err);
      })
    );
  }

  public updateVenueProgram(program: Program, venueId: number): Observable<Program> {
    const url = Endpoints.updateVenueProgram(program.id, venueId);
    return this.apiClient.putObj(Program, url, program).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateVenueProgram', err));
        return throwError(err);
      })
    );
  }

  private getHydratedProgramsEndpoint(programStatusId: number, programQueryType: ProgramQueryType = ProgramQueryType.Default): string {
    if (programStatusId === ProgramStatusType.PastId) {
      return Endpoints.getPastHydratedPrograms(programQueryType);
    } else if (programStatusId === ProgramStatusType.InProgressId) {
      return Endpoints.getLiveHydratedPrograms(programQueryType);
    } else if (programStatusId === ProgramStatusType.ScheduledId) {
      return Endpoints.getUpcomingHydratedPrograms(programQueryType);
    }
    return null;
  }

  private getContentQueryParams(contentQuery: ContentQuery): HttpParams {
    let updatedParams = new HttpParams();
    if (contentQuery.teamIds) {
      updatedParams = updatedParams.set('teamIds', contentQuery.getTeamIdsString());
    }
    if (contentQuery.leaguesIds) {
      updatedParams = updatedParams.set('leagueIds', contentQuery.getLeagueIdsString());
    }
    if (contentQuery.venueIds) {
      updatedParams = updatedParams.set('venueIds', contentQuery.getVenueIdsString());
    }
    if (contentQuery.eventIds) {
      updatedParams = updatedParams.set('eventIds', contentQuery.getEventIdsString());
    }
    if (contentQuery.venueStreamIds) {
      updatedParams = updatedParams.set('venueStreamIds', contentQuery.getVenueStreamIdsString());
    }
    if (contentQuery.subscriptionPlanIds) {
      updatedParams = updatedParams.set('subscriptionPlanIds', contentQuery.getSubscriptionPlanIdsString());
    }
    if (contentQuery.startDate) {
      updatedParams = updatedParams.set('startDateUtc[gte]', moment(contentQuery.startDate).utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS));
    }
    if (contentQuery.endDate) {
      updatedParams = updatedParams.set('startDateUtc[lte]', moment(contentQuery.endDate).utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS));
    }
    if (contentQuery.skip) {
      updatedParams = updatedParams.set('skip', contentQuery.skip);
    }
    if (contentQuery.take) {
      updatedParams = updatedParams.set('take', contentQuery.take);
    }
    return updatedParams;
  }

  public getHydratedProgramsRecursively(programStatusId: number = null, contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const url = this.getHydratedProgramsEndpoint(programStatusId, contentQuery.programQueryType);
    const params = this.getContentQueryParams(contentQuery);
    return this.apiClient.recursiveGetArr(HydratedProgram, url, null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedPrograms', err));
        return throwError(err);
      }),
    );
  }

  public getHydratedProgramsPage(programStatusId: number = null, contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const url = this.getHydratedProgramsEndpoint(programStatusId, contentQuery.programQueryType);
    const params = this.getContentQueryParams(contentQuery);
    return this.apiClient.getArr(HydratedProgram, url, null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedProgramsPage', err));
        return throwError(err);
      }),
    );
  }

  public getHydratedShowsPage(contentQuery: ContentQuery): Observable<HydratedShow[]> {
    const url = Endpoints.getHydratedShows(contentQuery.programQueryType);
    const params = this.getContentQueryParams(contentQuery);
    return this.apiClient.getArr(HydratedShow, url, null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedPrograms', err));
        return throwError(err);
      })
    );
  }
  public getSingleHydratedShow(contentQuery: ContentQuery): Observable<HydratedShow[]> {
    const url = Endpoints.getHydratedShows(contentQuery.programQueryType);
    const params = this.getContentQueryParams(contentQuery).set('skip', 0).set('take', 1);
    return this.apiClient.getArr(HydratedShow, url, null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSingleHydratedShow', err));
        return throwError(err);
      }),
    );
  }

  public getSingleHydratedProgram(programStatusId: number = null, contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const url = this.getHydratedProgramsEndpoint(programStatusId, contentQuery.programQueryType);
    const params = this.getContentQueryParams(contentQuery).set('skip', 0).set('take', 1);
    return this.apiClient.getArr(HydratedProgram, url, null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedPrograms', err));
        return throwError(err);
      }),
    );
  }

  public getHydratedProgram(programId: string): Observable<HydratedProgram> {
    const url = Endpoints.getHydratedProgram(programId);
    return this.apiClient.getObj(HydratedProgram, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedProgram', err));
        return throwError(err);
      })
    );
  }

  public getProgramImages(programId: string): Observable<Image[]> {
    const url = Endpoints.getProgramImages(programId);
    return this.apiClient.getArr(Image, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getProgramImages', err));
        return throwError(err);
      })
    );
  }

  public submitProgramComment(programId: string, programComment: ProgramComment): Observable<ProgramComment> {
    const url = Endpoints.createProgramComment(programId);
    return this.apiClient.postObj(ProgramComment, url, programComment).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'submitProgramComment', err));
        return throwError(err);
      })
    );
  }

  public submitShowComment(ShowId: string, comment: ProgramComment): Observable<ProgramComment> {
    const url = Endpoints.createShowComment(ShowId);
    return this.apiClient.postObj(ProgramComment, url, comment).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'submitShowComment', err));
        return throwError(err);
      })
    );
  }

  public deleteProgramImage(programId: string, imageId: string): Observable<string> {
    const url = Endpoints.deleteProgramImage(programId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteProgramImage', err));
        return throwError(err);
      })
    );
  }

  public deleteShowImage(showId: string, imageId: string): Observable<string> {
    const url = Endpoints.deleteShowImage(showId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteShowImage', err));
        return throwError(err);
      })
    );
  }

  public getLeagueHydratedPrograms(leagueId: number): Observable<HydratedProgram[]> {
    let params = new HttpParams();
    if (leagueId) {
      params = params.set('leagueIds', leagueId);
    }
    return this.apiClient.recursiveGetArr(HydratedProgram, Endpoints.getHydratedPrograms(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueHydratedPrograms', err));
        return throwError(err);
      })
    );
  }

  public getEventHydratedPrograms(eventId: number): Observable<HydratedProgram[]> {
    let params = new HttpParams();
    if (eventId) {
      params = params.set('eventIds', eventId);
    }
    return this.apiClient.recursiveGetArr(HydratedProgram, Endpoints.getHydratedPrograms(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getEventHydratedPrograms', err));
        return throwError(err);
      })
    );
  }

  public getAdminLeagueHydratedShows(leagueId: number): Observable<HydratedShow[]> {
    let params = new HttpParams();
    if (leagueId) {
      params = params.set('leagueIds', leagueId);
    }
    return this.apiClient.recursiveGetArr(HydratedShow, Endpoints.getHydratedShows(ProgramQueryType.Default), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueHydratedShows', err));
        return throwError(err);
      })
    );
  }

  public getAdminEventHydratedShows(eventId: number): Observable<HydratedShow[]> {
    let params = new HttpParams();
    if (eventId) {
      params = params.set('eventIds', eventId);
    }
    return this.apiClient.recursiveGetArr(HydratedShow, Endpoints.getHydratedShows(ProgramQueryType.Default), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getEventHydratedShows', err));
        return throwError(err);
      })
    );
  }

  public getHydratedShow(showId: string): Observable<HydratedShow> {
    const url = Endpoints.getHydratedShow(showId);
    return this.apiClient.getObj(HydratedShow, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedShow', err));
        return throwError(err);
      })
    );
  }

  public uploadShowVideo(showId: string, video: File): Observable<VideoUpload> {
    const formData = new FormData();
    formData.append('InputFile', video);
    const url = Endpoints.createShowUpload(showId);
    return this.apiClient.postFormData(VideoUpload, url, formData).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'uploadShowVideo', err));
        return throwError(err);
      })
    );
  }

  public deleteShowUpload(showId: string, uploadId: string): Observable<string> {
    const url = Endpoints.deleteShowUpload(showId, uploadId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteShowUpload', err));
        return throwError(err);
      })
    );
  }

  public getShowUploads(showId: string): Observable<VideoUpload[]> {
    const url = Endpoints.getShowUploads(showId);
    return this.apiClient.getArr(VideoUpload, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getShowUploads', err));
        return throwError(err);
      })
    );
  }

  public getVenueHydratedPrograms(leagueId: number): Observable<HydratedProgram[]> {
    let params = new HttpParams();
    if (leagueId) {
      params = params.set('venueIds', leagueId);
    }
    return this.apiClient.recursiveGetArr(HydratedProgram, Endpoints.getHydratedPrograms(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getVenueHydratedPrograms', err));
        return throwError(err);
      })
    );
  }

  public getPreRollAdvertisementsForProgram(programId: string): Observable<Advertisement[]> {
    return this.apiClient.getArr(Advertisement, Endpoints.getPreRollAdvertisementsForProgram(programId), null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPreRollAdvertisementsForProgram', err));
        return throwError(err);
      })
    );
  }

  public getPreRollAdvertisementsForShow(showId: string): Observable<Advertisement[]> {
    return this.apiClient.getArr(Advertisement, Endpoints.getPreRollAdvertisementsForShow(showId), null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPreRollAdvertisementsForShow', err));
        return throwError(err);
      })
    );
  }

  public trackAdvertisementPlayed(adId: string): Observable<Program> {
    const url = Endpoints.incrementAdvertisementPlayCount(adId);
    return this.apiClient.postObj(Program, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'trackAdvertisementPlayed', err));
        return throwError(err);
      })
    );
  }

  public trackProgramsIncrementPlayCount(programId: string): Observable<void> {
    const url = Endpoints.incrementProgramPlayCount(programId);
    return this.apiClient.postVoid(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getProgramsIncrementPlayCount', err));
        return throwError(err);
      })
    );
  }

  public trackShowIncrementPlayCount(showId: string): Observable<void> {
    const url = Endpoints.showsIncrementPlayCount(showId);
    return this.apiClient.postVoid(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getShowsIncrementPlayCount', err));
        return throwError(err);
      })
    );
  }
}
