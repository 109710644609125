import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from 'src/app/models/base/base-component';
import {EventDetailsViewModel} from '../event-details-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {EventFormObject} from '../../../../../models/resources/event-form-object';
import {ToastService} from '../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventStream } from 'src/app/models/resources/event-stream';
import { indicateOnNext } from 'src/app/utils/observable.extensions';
import { StringUtils } from 'src/app/utils/string-utils';

@Component({
  selector: 'app-event-streams',
  templateUrl: './event-streams.component.html',
  styleUrls: ['./event-streams.component.scss'],
})

export class EventStreamsComponent extends BaseComponent{
  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: EventStream;

  constructor(
    public viewModel: EventDetailsViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  updateFormItemStates() {
    if (this.formItems.length === 0) {
      return;
    }
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    //this.setupFormItems(false,false);
  }

  setupBindings() {
    this.viewModel.eventStream$.notNull()
    .pipe(indicateOnNext(this.viewModel.loadingOpts,'Loading eventStreams'))
    .subscribe(stream => {
      this.formObject = EventStream.initWithStream(stream);
      let isSecondEmailAvailable=false;
      let isThridEmailAvailable=false;
      if(stream.mp4ClientEmail2){
        isSecondEmailAvailable=StringUtils.checkStringNotNullOrEmpty(stream.mp4ClientEmail2);
      }
      if(stream.mp4ClientEmail3){
        isThridEmailAvailable=StringUtils.checkStringNotNullOrEmpty(stream.mp4ClientEmail3);
      }
      this.setupFormItems(isSecondEmailAvailable,isThridEmailAvailable);

    });
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'preferred-button ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Events_Edit_Event_Details_Edit) ?
        '' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormBindings() {
    this.viewModel.updateFormItemStatesSubject$.subscribe(() => {
      setTimeout(() => {
        this.updateFormItemStates();
      });
    }).addTo(this.subscriptions);
  }

  formSubmitted(formObject: any) {
    if(formObject?.id>0)
      {
        this.viewModel.saveEventStream(formObject).subscribe(()=>this.setupBindings());
      }
      else
      {
        const eventStream=new EventStream();
        const firstEmailMp4 = this.formItems.find(item => item.bindingProperty === 'mp4ClientEmail1').getValue();
        const secondEmailMp4 = this.formItems.find(item => item.bindingProperty === 'mp4ClientEmail2').getValue();
        const thirdEmailMp4 = this.formItems.find(item => item.bindingProperty === 'mp4ClientEmail3').getValue();
        const isMp4Required=this.formItems.find(item=>item.bindingProperty==='isMp4Required').getValue();
        eventStream.mp4ClientEmail2 = secondEmailMp4;
        eventStream.mp4ClientEmail1 = firstEmailMp4;
        eventStream.mp4ClientEmail3 = thirdEmailMp4;
        eventStream.isMp4Required=isMp4Required;
        eventStream.eventId=Number(this.activatedRoute.snapshot.params.eventId);
        this.viewModel.saveEventStream(eventStream).subscribe(()=>this.setupBindings());
      }
    }

  setupFormItems(isSecondEmailAvailable: boolean, isThridEmailAvailable: boolean) {
    const items: FormInputItem[] = [];

    const MP4LinkRequired = new FormInputItem();
    MP4LinkRequired.itemType = FormItemType.CheckboxItem;
    MP4LinkRequired.inputName = 'MP4LinkRequired';
    MP4LinkRequired.label = $localize`MP4 Link Required`;
    MP4LinkRequired.bindingProperty = 'isMp4Required';
    MP4LinkRequired.required = false;
    items.push(MP4LinkRequired);

    if (this.activatedRoute.snapshot.params.eventId) {
      let addButtonMp4ClickCount = 0;
      const addButtonMp4 = new FormInputItem();
      addButtonMp4.itemType = FormItemType.Title;
      addButtonMp4.inputName = 'addButtonMp4';
      addButtonMp4.bindingProperty = '';
      addButtonMp4.inputHasButton = false;
      addButtonMp4.titleButtonText = $localize`+`;
      addButtonMp4.autoGrowTextArea = true;
      addButtonMp4.titleButtonClass = 'custom-button preferred-button text-light text-lg ml-auto';

      addButtonMp4.titleButtonClicked.subscribe(() => {
        addButtonMp4ClickCount++;
        if (addButtonMp4ClickCount === 1) {
          items.find(item => item.inputName === 'secondClientEmailMp4').itemType = FormItemType.Input;
          if (items.find(item => item.inputName === 'secondClientEmailMp4').getValue() !== null){
            addButtonMp4ClickCount++;
          }
        } if (addButtonMp4ClickCount === 2) {
          items.find(item => item.inputName === 'thirdClientEmailMp4').itemType = FormItemType.Input;
          addButtonMp4.titleButtonClass = 'custom-button neutral-button ml-auto disabled';
        }
      });
      items.push(addButtonMp4);
    } else {
      let addButtonMp4ClickCount = 0;
      const addButtonMp4 = new FormInputItem();
      addButtonMp4.itemType = FormItemType.Title;
      addButtonMp4.inputName = 'addButtonMp4';
      addButtonMp4.bindingProperty = '';
      addButtonMp4.inputHasButton = false;
      addButtonMp4.titleButtonText = $localize`+`;
      addButtonMp4.autoGrowTextArea = true;
      addButtonMp4.titleButtonClass = 'custom-button preferred-button text-light text-lg ml-auto';
      addButtonMp4.titleButtonClicked.subscribe(() => {
        addButtonMp4ClickCount++;
        if (addButtonMp4ClickCount === 1  ) {
          items.find(item => item.inputName === 'secondClientEmailMp4').itemType = FormItemType.Input;
        } else if (addButtonMp4ClickCount === 2) {
          items.find(item => item.inputName === 'thirdClientEmailMp4').itemType = FormItemType.Input;
          addButtonMp4.titleButtonClass = 'custom-button neutral-button ml-auto disabled';
        }
      });
      if(isSecondEmailAvailable && isThridEmailAvailable)
        {
          addButtonMp4.titleButtonClass = 'custom-button neutral-button ml-auto disabled';
        }
      items.push(addButtonMp4);
    }

    const clientEmailMp4 = new FormInputItem();
    clientEmailMp4.inputName = 'clientEmailMp4';
    clientEmailMp4.inputType = FormInputType.Email;
    clientEmailMp4.label = $localize`Client Email`;
    clientEmailMp4.placeholder = $localize`Enter Client Email to send MP4 file download links to - Recipient 1`;
    clientEmailMp4.bindingProperty = 'mp4ClientEmail1';
    items.push(clientEmailMp4);

    const secondClientEmailMp4 = new FormInputItem();
    secondClientEmailMp4.inputName = 'secondClientEmailMp4';
    secondClientEmailMp4.inputType = FormInputType.Email;
    secondClientEmailMp4.itemType = isSecondEmailAvailable? FormItemType.Input : FormItemType.Hidden;
    secondClientEmailMp4.placeholder = $localize`Enter Client Email to send MP4 file download links to - Recipient 2`;
    secondClientEmailMp4.bindingProperty = 'mp4ClientEmail2';
    items.push(secondClientEmailMp4);

    const thirdClientEmailMp4 = new FormInputItem();
    thirdClientEmailMp4.inputName = 'thirdClientEmailMp4';
    thirdClientEmailMp4.inputType = FormInputType.Email;
    thirdClientEmailMp4.itemType =  isThridEmailAvailable? FormItemType.Input : FormItemType.Hidden;
    thirdClientEmailMp4.placeholder = $localize`Enter Client Email to send MP4 file download links to - Recipient 3`;
    thirdClientEmailMp4.bindingProperty = 'mp4ClientEmail3';
    items.push(thirdClientEmailMp4);
    items.push(FormInputItem.generateDivider());

    // this.viewModel.eventStream$.notNull().subscribe(stream => {
    //   if (!!stream.mp4ClientEmail2){
    //     items.find(item => item.inputName === 'secondClientEmailMp4').itemType = FormItemType.Input;
    //     if (!!stream.mp4ClientEmail3){
    //       items.find(item => item.inputName === 'thirdClientEmailMp4').itemType = FormItemType.Input;
    //     }
    //   }
    // });

    this.formItems = items;
    //this.setupFormBindings();
  }

}
