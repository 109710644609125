import { ItemPayment } from './Items-Payment';

export class Ecommerce{
    public transaction_id: string;
    public value: number;
    public currency: string;
    public coupon: string;
    public tax: number;
    public items: ItemPayment[];
}
