import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormInputItem, FormInputType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseComponent} from '../../../../models/base/base-component';
import {debounceTime, map, take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Favourites} from '../../../../models/resources/favourites';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import '../../../../utils/subscription.extensions';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {indicate} from '../../../../utils/observable.extensions';
import {FavouriteResource, FavouriteResourceType} from '../../../../models/protocols/favourite-resource';

@Component({
  selector: 'app-favourite-picker',
  templateUrl: './favourite-picker.component.html',
  styleUrls: ['./favourite-picker.component.scss']
})
export class FavouritePickerComponent extends BaseComponent {
  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject = '';

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 200);
  favourites = new Favourites();

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  sortedTeams$ = this.resourceDomainModel.activeHydratedTeams$
    .pipe(
      map(teams => teams.sort((a, b) => a.fullTeamName().localeCompare(b.fullTeamName()))),
      take(1),
      indicate(this.loadingOpts, $localize`Loading Teams`)
    );

  sortedLeagues$ = this.resourceDomainModel.activeHydratedLeagues$
    .pipe(
      map(teams => teams.sort((a, b) => a.name.localeCompare(b.name))),
      take(1),
      indicate(this.loadingOpts, $localize`Loading Leagues`)
    );

  sortedEvents$ = this.resourceDomainModel.activeHydratedEvents$
    .pipe(
      map(teams => teams.sort((a, b) => a.name.localeCompare(b.name))),
      take(1),
      indicate(this.loadingOpts, $localize`Loading Events`)
    );

  filteredTeams$: Observable<FavouriteResource[]> = combineLatest([this.searchText$, this.sortedTeams$])
    .pipe(
      debounceTime(200),
      map(([s, teams]) => {
        return teams.filter(t => {
          return t.fullTeamName().toUpperCase().includes(s.toUpperCase())
            || s?.length < 3;
        });
      })
    );

  filteredLeagues$: Observable<FavouriteResource[]> = combineLatest([this.searchText$, this.sortedLeagues$])
    .pipe(
      debounceTime(200),
      map(([s, teams]) => {
        return teams.filter(t => {
          return t.name.toUpperCase().includes(s.toUpperCase())
            || t.abbreviation.toUpperCase().includes(s.toUpperCase())
            || s?.length < 3;
        });
      })
    );

  filteredEvents$: Observable<FavouriteResource[]> = combineLatest([this.searchText$, this.sortedEvents$])
    .pipe(
      debounceTime(200),
      map(([s, teams]) => {
        return teams.filter(t => {
          return t.name.toUpperCase().includes(s.toUpperCase())
            || t.abbreviation.toUpperCase().includes(s.toUpperCase())
            || s?.length < 3;
        });
      })
    );

  filteredAll$: Observable<FavouriteResource[]> = combineLatest([this.filteredLeagues$, this.filteredEvents$, this.filteredTeams$]).pipe(
    map(([leagues, events, teams]) => [...leagues, ...events, ...teams])
  );

  selectedFavouriteIds: Map<FavouriteResourceType, number[]> = new Map<FavouriteResourceType, number[]>();

  @Input() updateFavourites: EventEmitter<Favourites> = new EventEmitter();
  @Output() selectedFavourites: EventEmitter<Favourites> = new EventEmitter();

  constructor(private resourceDomainModel: ResourceDomainModel,
              private cd: ChangeDetectorRef,
  ) {
    super();
  }

  setupBindings() {
    this.updateFavourites.subscribe(f => {
      this.favourites.favouriteLeagues.favouriteLeagues = f.favouriteLeagues.favouriteLeagues;
      this.favourites.favouriteTeams.favouriteTeams = f.favouriteTeams.favouriteTeams;
      this.initializeSelectedStates();
      this.cd.detectChanges();
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
    this.initializeSelectedStates();
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const search = new FormInputItem();
    search.inputName = 'search';
    search.inputType = FormInputType.Text;
    search.label = $localize`Search`;
    search.placeholder = $localize`Find your favourite leagues, events and teams`;
    search.bindingProperty = null;
    search.keyUpValueChanged.pipe(debounceTime(300)).subscribe(val => {
      this.searchText$.next(val[0]);
    });
    items.push(search);

    this.formItems = items;
  }

  initializeSelectedStates() {
    const favouriteLeagueIds = this.favourites?.favouriteLeagues?.favouriteLeagues?.map(l => l.leagueId) ?? [];
    const favouriteTeamIds = this.favourites?.favouriteTeams?.favouriteTeams?.map(l => l.teamId) ?? [];
    this.selectedFavouriteIds.set('team', favouriteTeamIds);
    this.selectedFavouriteIds.set('league', favouriteLeagueIds);

  }

  toggleSelectedFavourite(f: FavouriteResource) {
    const id = f.getFavouriteResourceId();
    const type = f.getFavouriteResourceType();
    const idIndex = this.selectedFavouriteIds.get(type).indexOf(id);
    if (idIndex >= 0) {
      this.selectedFavouriteIds.get(type).splice(idIndex, 1);
    } else {
      this.selectedFavouriteIds.get(type).push(id);
    }
    this.favourites.toggleFavouriteResource(f);
  }

  isSelected(f: FavouriteResource) {
    return this.selectedFavouriteIds.get(f.getFavouriteResourceType())?.includes(f.getFavouriteResourceId());
  }

  itemClicked(item: FavouriteResource) {
    this.toggleSelectedFavourite(item);
    this.selectedFavourites.emit(this.favourites);
  }

  formSubmitted() {
  }

  getNoResultsText() {
    return $localize`No Results for ‘${this.searchText$.value}’.`;
  }
}
