import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {SeasonFormObject} from '../../../../models/resources/season-form-object';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BaseModal} from '../../../../models/base/base-modal';
import {Component} from '@angular/core';
import {Season} from '../../../../models/resources/season';

@Component({
  selector: 'app-edit-season-modal',
  templateUrl: './edit-season-modal.component.html',
  styleUrls: ['./edit-season-modal.component.scss'],
})

export class EditSeasonModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: SeasonFormObject;
  isEditing: boolean = false;
  canRemoveSeasonOnEdit: boolean = true;


  constructor(
    private activeModal: NgbActiveModal,
    public modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  initWithNewFormObject() {
    this.formObject = SeasonFormObject.initWithSeason(new Season());
    this.formObject.itemCreated = true;
  }

  initWithFormObject(seasonFormObject: SeasonFormObject) {
    this.isEditing = true;
    this.formObject = seasonFormObject.getCopy();
    this.formObject.itemChanged = true;
  }


  setupFormItems() {
    const items: FormInputItem[] = [];

    const seasonName = new FormInputItem();
    seasonName.inputName = 'seasonName';
    seasonName.inputType = FormInputType.Text;
    seasonName.label = $localize`Season Name`;
    seasonName.placeholder = $localize`Season Name`;
    seasonName.bindingProperty = 'seasonName';
    seasonName.required = true;
    items.push(seasonName);

    const seasonStartDate = new FormInputItem();
    seasonStartDate.itemType = FormItemType.Input;
    seasonStartDate.inputType = FormInputType.Date;
    seasonStartDate.inputName = 'seasonStartDate';
    seasonStartDate.label = $localize`Season Start Date`;
    seasonStartDate.placeholder = $localize`Choose Date`;
    seasonStartDate.bindingProperty = 'seasonStartDate';
    seasonStartDate.required = true;
    items.push(seasonStartDate);

    const seasonEndDate = new FormInputItem();
    seasonEndDate.itemType = FormItemType.Input;
    seasonEndDate.inputType = FormInputType.Date;
    seasonEndDate.inputName = 'seasonEndDate';
    seasonEndDate.label = $localize`Season End Date`;
    seasonEndDate.placeholder = $localize`Choose Date`;
    seasonEndDate.bindingProperty = 'seasonEndDate';
    seasonEndDate.required = true;
    items.push(seasonEndDate);

    this.formItems = items;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.submitButtonText = $localize`Save Season`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Leagues_Edit_League_Seasons_Edit,) ?
        '' : 'preferred-button disabled';
  }

  formSubmitted() {
    this.activeModal.close(this.formObject);
  }
  deleteSeasonClicked() {
    this.formObject.itemDeleted = true;
    this.activeModal.close(this.formObject);
  }
  cancel() {
    this.activeModal?.dismiss();
  }

  getModalTitle() {
    return this.isEditing ? $localize`Edit Season` : $localize`Add New Season`;
  }
}
