import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SessionService} from '../services/session-service';
import {BaseDomainModel} from '../models/base/base-domain-model';
import '../utils/observable.extensions';
import {UserApi} from '../api/user-api';
import {User} from '../models/account/dto/user';
import {HydratedSubscriber} from '../models/account/dto/hydrated-subscriber';

@Injectable({
  providedIn: 'root'
})
export class UsersDomainModel extends BaseDomainModel {

  subscribers$ = new BehaviorSubject<HydratedSubscriber[]>(null);
  admins$ = new BehaviorSubject<User[]>(null);

  constructor(
    private sessionService: SessionService,
    private userApi: UserApi
  ) {
    super();
    this.init();
  }

  public init() {
  }

  getAdmins() {
    if (!(this.admins$.getValue()?.length > 0)) {
      this.userApi.getAdmins().subscribe(a => {
        this.admins$.next(a);
      }).addTo(this.subscriptions);
    }
  }

  getSubscribers() {
    if (!(this.subscribers$.getValue()?.length > 0)) {
      this.userApi.getHydratedSubscribers().subscribe(s => {
        this.subscribers$.next(s);
      }).addTo(this.subscriptions);
    }
  }

}
