import {BaseModel} from '../base/base-model';
import {Selectable} from '../protocols/selectable';
import {DatatableData} from '../protocols/datatable-data';
import {DeserializeHelper} from '../protocols/deserializable';


export class Season extends BaseModel implements Selectable, DatatableData {
  id: number;
  leagueId: number;
  name: string;
  startDate: Date;
  endDate: Date;
  timeStampVal: Date;

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any;
  getSelectionUniqueIdentifier(): string;
  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getTextColor(): string {
    return '';
  }
}
