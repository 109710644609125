

<div *ngIf="!isBoxed" [ngClass]="isBoxed ? 'radio-button-container' : ''">
  <app-radio-button
    [isBoxed]="isBoxed"
    *ngFor="let rdbtn of radiobuttons; let last = last"
    [radiobutton]="rdbtn"
    [refresh]="refresh"
    [inline]="radioContainerOptions.inlineOptions"
    [customCheckboxMargin]="radioContainerOptions.customRadiobuttonMargin"
    (clickEvent)="selectSingleOptionClicked(rdbtn)"
    [showDivider]="radioContainerOptions.showDivider && !last">
  </app-radio-button>
</div>



<div *ngIf="isBoxed" [ngClass]="isBoxed ? 'radio-button-container' : ''">
<app-radio-button
  [isBoxed]="isBoxed"
  [title]="getTitleBasedOnSubscriptionType(rdbtn)"
  *ngFor="let rdbtn of radiobuttons; let last = last"
  [radiobutton]="rdbtn"
  [refresh]="refresh"
  [inline]="radioContainerOptions.inlineOptions"
  [customCheckboxMargin]="radioContainerOptions.customRadiobuttonMargin"
  (clickEvent)="selectSingleOptionClicked(rdbtn)"
  [showDivider]="radioContainerOptions.showDivider && !last">
</app-radio-button>
</div>
