<!-- Basic elements can just be added in a section of their own, ie: buttons, labels, etc. -->

<!-- Should import components from shared > components where the dumb components can live with styling specific to that component -->
<!-- ie: a grouping of textfields might have padding that only applies when presented as a group -->
<!-- the default styling for the text field should still live in stylesheet.scss since that is global -->


<!--  Button Styles  -->
<div class="stylesheet-section">
  <h2 class="custom-headline" i18n>Buttons</h2>
  <div class="row">
    <!-- Primary Buttons -->
    <div class="col-md-3">
      <h4 class="custom-section-title" i18n>Primary Buttons</h4>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button preferred-button">Preferred Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button secondary-button">Secondary Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button neutral-button">Neutral Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button outlined-button">Outlined Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button (click)="handleDestructiveButtonClick()"
                  class="custom-button destructive-button">
            Destructive Button
          </button>
        </div>
        <div class="row row-vertical-spacing">
          <div class="col-md-12">
            <button class="custom-button destructive-button disabled" disabled>Disabled Button</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Icon Buttons -->
    <div class="col-md-2">
      <h4 class="custom-section-title">Icon Buttons</h4>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button preferred-button">
            <i class="fa fa-home icon-button-icon"></i>
            Home
          </button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button neutral-button">
            <i class="fa fa-home icon-button-icon"></i>
            Home
          </button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button dark-neutral-button rounded-icon-button">
            <i class="fa fa-home icon-button-icon"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Full Width Buttons -->
    <div class="col-md-4">
      <h4 class="custom-section-title">Full Width Buttons</h4>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button preferred-button full-width-button">Preferred Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button neutral-button full-width-button">Neutral Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button outlined-button full-width-button">Outlined Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button (click)="handleDestructiveButtonClick()"
                  class="custom-button destructive-button full-width-button">
            Destructive Button
          </button>
        </div>
      </div>
    </div>
    <!-- Text Buttons -->
    <div class="col-md-3">
      <h4 class="custom-section-title">Text Buttons</h4>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <button class="custom-button text-button">Text Button</button>
        </div>
      </div>
      <div class="row row-vertical-spacing">
        <div class="col-md-12">
          <span class="text-link-helper">Helper Text? <span class="text-link">Click here.</span></span>
        </div>
      </div>
    </div>
  </div> <!-- row end -->
</div> <!-- Button end -->

<!--  Label Styles  -->
<div class="stylesheet-section">
  <h2 class="custom-headline">Labels</h2>
  <div class="row row-vertical-spacing">
    <div class="col-md-12">
      <span class="custom-label preferred-label">Preferred Label</span>
      <span class="custom-label positive-label add-margin-start">Positive Label</span>
      <span class="custom-label neutral-label add-margin-start">Neutral Label</span>
      <span class="custom-label negative-label add-margin-start">Negative Label</span>
    </div>
  </div>
</div> <!-- Label End -->

<!--  Selection Styles  -->
<div class="stylesheet-section">
  <h2 class="custom-headline">Selection</h2>
  <!-- Tabs -->
  <div class="stylesheet-section">
    <h4 class="custom-section-title">Tabs</h4>
    <div class="col-md-12 inline-col">
      <app-tab-bar [tabs]="tabs"></app-tab-bar>
    </div>
  </div>
  <!-- Drop Down -->
  <div class="stylesheet-section">
    <h4 class="custom-section-title">Dropdown</h4>
    <div class="col-md-3 inline-col">
      <app-drop-down [dropdowns]="dropdowns" (selection)="selectionChanged($event)"></app-drop-down>
    </div>
  </div>
  <!-- Pills -->
  <div class="stylesheet-section">
    <h4 class="custom-section-title">Pills</h4>
    <div class="col-md-12 inline-col">
      <h5>Container</h5>
      <app-pill-container [pills]="pills"></app-pill-container>
      <h5 style="margin-top: 24px">Individual</h5>
      <app-pill [clickable]="false">Pill</app-pill>
      <app-pill [clickable]="false">A much larger pill with a lot of text</app-pill>
      <app-pill [disabled]="true">Disabled Pill</app-pill>
      <app-pill [clickable]="true" [selected]="true">Selected Removable Pill</app-pill>
    </div>
  </div>
  <div class="stylesheet-section">
    <h4 class="custom-section-title">Drop Down Menu</h4>
    <div class="row">
      <div class="col-md-2">
        <app-drop-down-menu
          [buttonText]="'Home'"
          [buttonClass]="'neutral-button'"
          [sections]="dropDownMenuSections"
          (output)="selectionChanged($event)">
        </app-drop-down-menu>
      </div>
      <div class="col-md-2">
        <app-drop-down-menu
          [buttonText]="'Nope'"
          [buttonClass]="'preferred-button'"
          [iconSrc]="'assets/icons/light/outline/home.svg'"
          [sections]="dropDownMenuSections"
          (output)="selectionChanged($event)">
        </app-drop-down-menu>
      </div>
      <div class="col-md-2">
        <app-drop-down-menu
          [buttonText]="'Destroy'"
          [buttonClass]="'destructive-button'"
          [sections]="dropDownMenuSections"
          (output)="selectionChanged($event)">
        </app-drop-down-menu>
      </div>
    </div>
  </div>

</div> <!-- Selection End -->

<!-- Check Boxes -->
<div class="stylesheet-section">
  <h4 class="custom-section-title">Checkboxes</h4>
  <div class="col-md-3 inline-col">
    <h5>Individual</h5>
    <app-checkbox>Short Label</app-checkbox>
    <br>
    <app-checkbox>Long label that spans multiple lines because we have a lot of information.</app-checkbox>
    <br>
    <app-checkbox>Long label that spans multiple lines because we have a lot of information. So much that it has to span
      across 3 lines. Wow.
    </app-checkbox>
    <br>
    <app-checkbox [checkbox]="testCheck"></app-checkbox>
    <br>
    <app-checkbox [disabled]="true">Disabled</app-checkbox>
    <br>
    <hr>
    <h5>Container</h5>
    <app-checkbox-container [checkBoxes]="checkBoxes"></app-checkbox-container>
  </div>
</div> <!-- Checkboxes End -->

<!-- Segmented Control -->
<div class="stylesheet-section">
  <h4 class="custom-section-title">Segmented Control</h4>
  <div class="col-md-12 inline-col">
    <app-segmented-control
      (optionSelected)="segmentedControlSelected($event)"
      [options]="segmentedControls">
    </app-segmented-control>
  </div>
</div>


<!-- Switch Boxes -->
<div class="stylesheet-section">
  <h4 class="custom-section-title">Switch</h4>
  <div class="col-md-3 inline-col">
    <div class="custom-switch-container">
      Switch 1
      <label class="custom-switch">
        <input type="checkbox">
        <span class="custom-slider"></span>
      </label>
    </div>
    <div class="custom-switch-container">
      Switch 2
      <label class="custom-switch">
        <input type="checkbox">
        <span class="custom-slider"></span>
      </label>
    </div>
    <div class="custom-switch-container">
      Switch 3
      <label class="custom-switch">
        <input type="checkbox">
        <span class="custom-slider"></span>
      </label>
    </div>
  </div>
</div> <!-- Switch Boxes End -->

<!-- Radio Buttons -->
<div class="stylesheet-section">
  <h4 class="custom-section-title">Inline Radio Buttons</h4>
  <div class="col-md-12 inline-col">
    <form class="custom-radio-form">
      <div class="custom-radio-option custom-radio-option-inline">
        <input class="custom-radio" type="radio" id="option1" name="radio-example" value="1">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="option1">Option 1</label>
      </div>
      <div class="custom-radio-option custom-radio-option-inline">
        <input class="custom-radio" type="radio" id="option2" name="radio-example" value="2">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="option2">Option 2</label>
      </div>
      <div class="custom-radio-option custom-radio-option-inline">
        <input class="custom-radio" type="radio" id="option3" name="radio-example" value="3">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="option3">Option 3</label>
      </div>
    </form>
  </div>

  <h4 class="custom-section-title">Block Radio Buttons</h4>
  <div class="col-md-3 inline-col">
    <form class="custom-radio-form">
      <div class="custom-radio-option">
        <input class="custom-radio" type="radio" id="inline-option1" name="inline-radio-example" value="1">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="inline-option1">Option 1</label>
      </div>
      <div class="custom-radio-option">
        <input class="custom-radio" type="radio" id="inline-option2" name="inline-radio-example" value="2">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="inline-option2">Option 2</label>
      </div>
      <div class="custom-radio-option">
        <input class="custom-radio" type="radio" id="inline-option3" name="inline-radio-example" value="3">
        <span class="custom-custom-radio"></span>
        <label class="custom-radio-label" for="inline-option3">Option 3</label>
      </div>
    </form>
  </div>
</div> <!-- Radio Buttons End -->

<!-- Toggles -->
<div class="stylesheet-section">
  <h4 class="custom-section-title">Toggles</h4>
  <div class="col-md-3 inline-col">
    <button class="custom-button custom-toggle">01</button>
    <button class="custom-button custom-toggle">02</button>
    <button class="custom-button custom-toggle active">03</button>
  </div>
</div> <!-- Toggles End -->

<!-- Media End -->
<div class="stylesheet-section">
  <h4 class="custom-section-title">Media Upload</h4>
  <div class="col-md-12 inline-col">
    <app-upload-asset>
    </app-upload-asset>
  </div>
</div> <!-- Upload Media End -->

<!-- Lists -->
<div class="stylesheet-section">
  <h2 class="custom-headline">Lists</h2>
  <div class="stylesheet-section">
    <h4 class="custom-section-title">Search Results</h4>
    <div class="col-md-4 inline-col">
      <div class="custom-search-results">
        <span class="custom-search-results-header">
            Results
        </span>
        <ul class="custom-list">
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
              <span class="custom-list-item-text">
                  Results Text 1
              </span>
            </div>
          </li>
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
              <span class="custom-list-item-text">
                  Results Text 2
              </span>
            </div>
          </li>
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
              <span class="custom-list-item-text">
                  Results Text 3
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="stylesheet-section">
      <h4 class="custom-section-title">Single Line List (with image)</h4>
      <div class="col-md-4 inline-col">
        <ul class="custom-list">
          <li class="custom-list-item">
            <img class="custom-list-item-image" src="/assets/placeholder/list-image.jpg"
                 alt="custom-result-image">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 1
                          </span>
              <span class="custom-list-item-subtext"></span>
            </div>
          </li>
          <li class="custom-list-item">
            <img class="custom-list-item-image" src="/assets/placeholder/list-image.jpg"
                 alt="custom-result-image">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 2
                          </span>
              <span class="custom-list-item-subtext"></span>
            </div>
          </li>
          <li class="custom-list-item active">
            <img class="custom-list-item-image" src="/assets/placeholder/list-image.jpg"
                 alt="custom-result-image">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 3 (active)
                          </span>
              <span class="custom-list-item-subtext"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="stylesheet-section">
      <h4 class="custom-section-title">Single Line List</h4>
      <div class="col-md-4 inline-col">
        <ul class="custom-list">
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 1
                          </span>
              <span class="custom-list-item-subtext"></span>
            </div>
          </li>
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 2
                          </span>
              <span class="custom-list-item-subtext"></span>
            </div>
          </li>
          <li class="custom-list-item active">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 3 (active)
                          </span>
              <span class="custom-list-item-subtext"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="stylesheet-section">
      <h4 class="custom-section-title">Two Line List</h4>
      <div class="col-md-4 inline-col">
        <ul class="custom-list">
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 1
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 1. Wowza
                          </span>
            </div>
          </li>
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 2
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 2. Wowza
                          </span>
            </div>
          </li>
          <li class="custom-list-item active">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 3 (active)
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 3. Wowza
                          </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="stylesheet-section">
      <h4 class="custom-section-title">Three Line List</h4>
      <div class="col-md-4 inline-col">
        <ul class="custom-list">
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 1
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 1. Wowza
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 2. Neat
                          </span>
            </div>
          </li>
          <li class="custom-list-item">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 2
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 2. Wowza
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 2. Neat
                          </span>
            </div>
          </li>
          <li class="custom-list-item active">
            <div class="custom-list-item-text-container">
                          <span class="custom-list-item-text">
                              List Item 3 (active)
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 3. Wowza
                          </span>
              <span class="custom-list-item-subtext">
                              List Item subtext 2. Neat
                          </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> <!-- Lists End -->

<!-- Text Fields -->
<div class="stylesheet-section">
  <!--  Text Fields Styles  -->
  <h2 class="custom-headline">Text Fields</h2>
  <div class="stylesheet-section">
    <h4 class="custom-section-title">Text Fields</h4>
    <div class="col-md-3 inline-col">

      <form class="custom-textfield-form">
        <label class="custom-textfield-label" for="textfield1">Label</label>
        <input class="custom-textfield" id="textfield1" name="textfield1"
               i18n-placeholder
               placeholder="Placeholder text here"
               type="text">
        <span class="custom-textfield-error"></span>
      </form>

      <form class="custom-textfield-form error">
        <label class="custom-textfield-label" for="textfield2">Label for Invalid</label>
        <input class="custom-textfield" id="textfield2" name="textfield1" placeholder="Placeholder text here"
               type="text" value="invalid input">
        <span class="custom-textfield-error">Error message here</span>
      </form>
    </div>
  </div>

  <div class="stylesheet-section">
    <h4 class="custom-section-title">Inline Text Fields</h4>
    <div class="col-md-6 inline-col">
      <form class="custom-textfield-form inline-form">
        <label class="custom-textfield-label inline-label" for="textfield4">Inline Label</label>
        <input class="custom-textfield inline-textfield" id="textfield4" name="textfield4"
               placeholder="Placeholder text here"
               type="text">
        <span class="custom-textfield-error"></span>
      </form>

      <form class="custom-textfield-form inline-form error">
        <label class="custom-textfield-label inline-label" for="textfield5">Inline Label Error</label>
        <div class="inline-textfield-container">
          <input class="custom-textfield inline-textfield" id="textfield5" name="textfield5"
                 placeholder="Placeholder text here"
                 type="text" value="Some invalid input here">
          <span class="custom-textfield-error">Uh oh, here is an inline textfield error message.</span>
        </div>
      </form>
    </div>
  </div>

  <div class="stylesheet-section">
    <h4 class="custom-section-title">Search Text Fields</h4>
    <div class="col-md-4 inline-col">
      <form class="custom-textfield-form">
        <label class="custom-textfield-label" for="textfield6">Search Label</label>
        <input class="custom-textfield search-textfield" id="textfield6" name="textfield6" placeholder="Search Placeholder"
               type="text">
        <button class="clear-search" type="reset">CLEAR</button>
        <span class="custom-textfield-error"></span>
      </form>

      <form class="custom-textfield-form error">
        <label class="custom-textfield-label" for="textfield8">Search Label With Error</label>
        <input class="custom-textfield search-textfield" id="textfield8" name="textfield8" placeholder="Search Placeholder"
               type="text">
        <button class="clear-search" type="reset">CLEAR</button>
        <span class="custom-textfield-error">Uh oh, here is a search error message.</span>
      </form>
    </div>
  </div>

  <div class="stylesheet-section">
    <h4 class="custom-section-title">Text Area</h4>
    <div class="col-md-4 inline-col">
      <form class="custom-textfield-form">
        <label for="textarea" hidden></label>
        <textarea class="custom-text-area" cols="50" id="textarea" name="textarea"
                  placeholder="Text Area Placeholder - Add some comments here"
                  rows="4"></textarea>
      </form>
    </div>
  </div>
</div> <!-- Text Fields End -->

<!-- Cards -->
<div class="stylesheet-section">
  <h4 class="custom-headline">Cards</h4>
  <app-card-deck
    [cards]="cards"
    (cardPressed)="cardPressed($event)">
  </app-card-deck>
</div> <!-- Cards End -->

<!--  Breadcrumb Styles  -->
<div class="stylesheet-section">
  <h2 class="custom-headline">Breadcrumbs</h2>
  <app-breadcrumbs
    [breadcrumbs]="breadcrumbs">
  </app-breadcrumbs>
</div>


<div class="stylesheet-section">
  <h2 class="custom-headline">Alerts</h2>
  <div class="stylesheet-section">
    <div class="col-md-6 inline-col">
      <button (click)="showSuccessToast(false)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button preferred-button">
        Success Toast
      </button>
      <button (click)="showSuccessToast(true)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button preferred-button">
        Success Toast (with title)
      </button>
      <hr>
      <button (click)="showErrorToast(false)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button destructive-button">
        Error Toasts
      </button>
      <button (click)="showErrorToast(true)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button destructive-button">
        Error Toast (with title)
      </button>
      <hr>
      <button (click)="showInfoToast(false)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button neutral-button">
        Info Toast
      </button>
      <button (click)="showInfoToast(true)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button neutral-button">
        Info Toast (with title)
      </button>
      <hr>
      <button (click)="showWarningToast(false)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button outlined-button">
        Warning Toast
      </button>
      <button (click)="showWarningToast(true)"
              [ngStyle]="{'margin-right': '0.5rem'}"
              class="custom-button outlined-button">
        Warning Toast (with title)
      </button>
    </div>
  </div>
</div>


<div class="stylesheet-section">
  <!--  Loading Styles  -->
  <h2 class="custom-headline">Loading Options</h2>
  <div class="stylesheet-section">
    <div class="col-md-10" style="margin: 20px; padding: 20px;">
      <app-loading
        [hidden]="!indeterminateOptions.isLoading"
        [options]="indeterminateOptions">
      </app-loading>
      <h3>Indeterminate Loading Spinner (3s)</h3>

      <label for="indeterminateBackgroundPicker">Background Color</label>
      <input (colorPickerChange)="indeterminateOptions.backgroundColor=$event"
             [(colorPicker)]="indeterminateOptions.backgroundColor"
             [style.background]="indeterminateOptions.backgroundColor"
             id="indeterminateBackgroundPicker"/>

      <label for="indeterminateColorPicker">Color</label>
      <input (colorPickerChange)="indeterminateOptions.color=$event"
             [(colorPicker)]="indeterminateOptions.color"
             [style.background]="indeterminateOptions.color"
             id="indeterminateColorPicker"/>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.
      </p>

      <br><br>

      <button (click)="showLoadingSpinner(null, false)" class="custom-button neutral-button">Show Loading Spinner
      </button>
      <button (click)="showLoadingSpinner('Yup, we are loading here..', false)"
              class="custom-button neutral-button">Show
        Loading Spinner (with text)
      </button>

      <br><br>

      <button (click)="showLoadingSpinner(null, true)" class="custom-button neutral-button">Show Fullscreen
        Loading Spinner
      </button>
      <button (click)="showLoadingSpinner('Yup, we are loading here..', true)"
              class="custom-button neutral-button">Show Fullscreen
        Loading Spinner (with text)
      </button>
    </div>

    <div class="col-md-8" style="margin: 20px; padding: 20px;">
      <app-loading
        [hidden]="!determinateOptions.isLoading"
        [options]="determinateOptions">
      </app-loading>

      <h3>Determinate Loading Spinner (3s)</h3>

      <label for="determinateBackgroundPicker">Background Color</label>
      <input [(colorPicker)]="determinateOptions.backgroundColor"
             [style.background]="determinateOptions.backgroundColor"
             id="determinateBackgroundPicker"/>

      <label for="determinateColorPicker">Color</label>
      <input [(colorPicker)]="determinateOptions.color"
             [style.background]="determinateOptions.color"
             id="determinateColorPicker"/>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.
      </p>

      <br><br>

      <button (click)="showLoadingBar(null, false)" class="custom-button neutral-button">Show Loading Bar
      </button>
      <button (click)="showLoadingBar('Yup, we are loading here..', false)"
              class="custom-button neutral-button">Show
        Loading Bar (with text)
      </button>

      <br><br>

      <button (click)="showLoadingBar(null, true)" class="custom-button neutral-button">Show Fullscreen
        Loading Bar
      </button>
      <button (click)="showLoadingBar('Yup, we are loading here..', true)"
              class="custom-button neutral-button">Show Fullscreen
        Loading Bar (with text)
      </button>

    </div>
  </div>
</div>

<!--  Form Group Styles  -->
<div class="stylesheet-section">
  <h2 class="custom-headline">Form Group</h2>
  <div class="stylesheet-section">

    <app-form-group
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formSubmitted)="formSubmitted($event)"
      (secondaryButtonPressed)="secondaryButtonPressed()"
      (tertiaryButtonPressed)="tertiaryButtonPressed()"
      (formCancelled)="formCancelled()">
    </app-form-group>

  </div>
</div>
