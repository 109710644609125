import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject} from 'rxjs';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import {map} from 'rxjs/operators';

@Injectable()
export class VenueStreamPickerModalViewModel extends BaseViewModel {

  hydratedVenue$ = new BehaviorSubject<HydratedVenue>(null);
  streams$ = this.hydratedVenue$.pipe(map(v => v?.streams));

  constructor() {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
