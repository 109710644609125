<div *ngIf="!isBoxed">
<label
  class="custom-checkbox-container"
  [ngClass]="{ 'inline-checkbox' : inline }"
  [ngStyle]="{ 'margin': customCheckboxMargin ? customCheckboxMargin : null }"
  [class.disabled]="disabled"
  for="{{cid}}">
  <input type="radio"
          class="custom-checkbox"
         id="{{cid}}"
         name="custom-cb"
         [checked]="checked"
         [disabled]="disabled"
         (change)="onClick()">
  <span class="custom-checkbox-label" [class.with-description]="radiobutton?.description">
    <ng-container *ngIf="label">{{label}}</ng-container>
  </span>
  <span class="custom-checkbox-description mt-1">
    <ng-container *ngIf="radiobutton?.description">{{radiobutton.description}}</ng-container>
  </span>
  <span class="custom-checkbox-description mt-2">
    <ng-container *ngIf="radiobutton?.subDescription">{{radiobutton.subDescription}}</ng-container>
  </span>
  <span class="custom-checkmark">
  </span>
</label>
  <hr *ngIf="showDivider">
</div>



<div *ngIf="isBoxed">
<label
  (click)="onBoxClick()"
       class="custom-radio-boxed-container"
       [ngClass]="{'selected': radiobutton?.selected}"
       [class.disabled]="disabled"
       for="{{cid}}">
  <span class="custom-radio-boxed-label" [class.with-description]="radiobutton?.description">
  </span>
  <span class="custom-radio-boxed-description mt-4"
        [ngStyle]="{'color': radiobutton.selected ? 'white' : 'grey'}">
  <ng-container *ngIf="title !== 'Private Access'; else privateAccessTemplate">
    <div class="price">{{title}}</div>
    <span>{{label}}</span>
  </ng-container>
  <ng-template #privateAccessTemplate>
    <span *ngFor="let word of title.split(' ')" class="private-access-checkbox"
         [ngStyle]="{'color': radiobutton.selected ? 'white' : 'grey'}">{{word}}</span>
  </ng-template>
</span>
</label>
</div>

