import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {ImageUrl} from './image-url';
import {MediaType} from '../../enum/dto/media-type.enum';
import {ImageSize} from '../../enum/dto/image-size.enum';
import {Cachable} from '../../protocols/cachable';
import {DateUtils} from '../../../utils/date-utils';
import {MediaUtils} from '../../../utils/media-utils';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';
import '../../../utils/array.extensions';

export class  Image implements Deserializable, Cachable {
  public id: string;
  public links: ImageUrl[];
  public fileName: string;
  public mediaType: MediaType;
  public cachedTime: number;

  static buildCacheKey(id: string, size: ImageSize): string {
    return `Image-${id}-${size}`;
  }

  public isEmpty() {
    return this.id === '';
  }

  public onDeserialize() {
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/) != null;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/) != null;
  }

  public getImageUrl(size: ImageSize): ImageUrl {
    return this.links.find(u => u.size === size);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(size: ImageSize): string {
    return Image.buildCacheKey(this.id, size);
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
