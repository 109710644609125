import {HydratedLeague} from './hydrated-league';
import {SafeResourceUrl} from '@angular/platform-browser';
import {TeamFormObject} from './team-form-object';
import {BannerAdvertisementFormObject} from './banner-ad-form-object';
import { LeagueStream } from './league-stream';

export class LeagueFormObject {
  public league: HydratedLeague;
  public leagueStream: LeagueStream;
  public imageToUpload: string | SafeResourceUrl;
  public existingImageId: string;
  public deleteImageId: string;
  public teamFormObjects: TeamFormObject[];
  public bannerAdFormObjects: BannerAdvertisementFormObject[];

  static initWithLeague(league: HydratedLeague): LeagueFormObject {
    const formObject = new LeagueFormObject();
    formObject.league = league;
    if (league.leagueLogos?.length > 0) {
      formObject.existingImageId = league.leagueLogos[0]?.id;
    }
    formObject.teamFormObjects = league.associatedTeams
      ?.map(t => TeamFormObject.initWithTeam(t)) ?? [];
    formObject.bannerAdFormObjects = league.advertisementBanners
      ?.map(b => BannerAdvertisementFormObject.initWithBannerAdvertisement(b)) ?? [];
    return formObject;
  }

  static initWithStream(leagueStream: LeagueStream): LeagueFormObject {
    const formObject = new LeagueFormObject();
    formObject.leagueStream = leagueStream;
    return formObject;
  }
}
