import {Deserializable} from '../../protocols/deserializable';

export class CouponCheck implements Deserializable {
    public coupon: string;
    public isPrivate: boolean;

    onDeserialize() {

    }
}
