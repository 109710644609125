import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Card} from '../../../../models/shared/stylesheet/card';
import {CardStyle} from '../../../../models/shared/stylesheet/card-style.enum';

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.scss'],
})
export class CardDeckComponent implements OnInit, AfterViewInit {

  @Input() cards: Card[] = [];
  @Input() horizontalScroller = true;
  @Input() maxHeightRem: number = 18;
  @Input() scrollToCardId: EventEmitter<string> = new EventEmitter<string>();

  @Output() cardPressed: EventEmitter<Card> = new EventEmitter<Card>();
  @Output() cardButtonPressed: EventEmitter<Card> = new EventEmitter<Card>();

  // View
  public cardStyles = CardStyle;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.setupBindings();
  }

  setupBindings() {
    // Bind to scroll to selected card
    this.scrollToCardId.subscribe((id) => {
      if (id) {
        document.getElementById(id).scrollIntoView();
        document.getElementById(id).focus();
      }
    });
  }

  handleCardPress(card: Card) {
    this.cardPressed.emit(card);
  }

  getMaxHeightRem(): string {
    return `${this.maxHeightRem}rem`;
  }

}
