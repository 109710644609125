<div class="fave-item mt-3">
  <div class="favourite-logo" [class.no-img]="!(favourite.imgSrc$|async)">
    <img class="logo-img" *ngIf="favourite.imgSrc$|async" [src]="favourite.imgSrc$|async" alt="logo">
  </div>
  <div class="fav-name">
    {{favourite.getFavouriteResourceName()}}
  </div>
  <div class="star-container">
    <img *ngIf="!selected" class="star" alt="star" [src]="'assets/icons/dark/outline/hollow-star.svg'">
    <img *ngIf="selected" class="star" alt="star" [src]="'assets/icons/dark/solid/filled-star.svg'">
  </div>
</div>
<hr>
